define('pretty-nuts/controllers/issuer/index', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    application: Ember.inject.controller(),

    reloadObligationOverviews: false,
    pieCharts: ['PrincipalAndInterest', 'Obligation'],

    query: {
      resolution: _constants.Constants.Frequency.ANNUAL
    },

    reloadObligationOverviewsObserver: function () {
      if (this.get('reloadObligationOverviews')) {
        var that = this;
        var legalUnitId = this.get('model.legalUnit.id');
        this.get('store').unloadAll('obligation-overview');
        return this.get('store').query('obligation-overview', { legalUnitId: legalUnitId }).then(function (obligationOverviews) {
          that.set('model.obligationOverviews', obligationOverviews);
          that.set('reloadObligationOverviews', false);
        });
      }
    }.observes('reloadObligationOverviews'),

    actions: {
      actionRefreshDebtProfile: function actionRefreshDebtProfile(debtProfile, obligation) {
        // console.log('Deleted pending obligation', obligation);
        debtProfile.reload();
      }
    }
  });
});