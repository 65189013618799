define('pretty-nuts/services/backendapi', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    usersapi: Ember.inject.service(),
    orgsapi: Ember.inject.service(),
    obligationsapi: Ember.inject.service(),
    authapi: Ember.inject.service()
  });
});