define('pretty-nuts/changeset', ['exports', 'pretty-nuts/relay'], function (exports, _relay) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Object.extend({

    /////////////
    // Proxy data

    source: null,
    values: null,
    wrappers: null,
    promises: null,

    // Proxy data
    /////////////

    /**
     * Assemble the changes array bey mapping the key with the old value and new value for all
     * the keys in the changed values.
     *
     * [
     *  {
     *    key: 'price',
     *    oldValue: 100,
     *    newValue: 101
     *  }, {
     *    key: 'yield',
     *    oldValue: 100,
     *    newValue: 99
     *  }
     * ]
     *
     */
    changes: Ember.computed('values', function () {
      var _this = this;

      var values = this.get('values');
      var keys = Object.keys(values);

      return keys.map(function (key) {
        var oldValue = _this.get('source.' + key);
        var newValue = values[key];

        return { key: key, oldValue: oldValue, newValue: newValue };
      });
    }),

    /**
     * Constructor
     *
     * Setup the default values
     */
    init: function init() {
      this._super.apply(this, arguments);
      this.values = {};
      this.wrappers = {};
      this.promises = {};
    },
    refresh: function refresh() {
      var _this2 = this;

      var keys = Object.keys(this.wrappers);
      var changeset = this;

      this.notifyPropertyChange('source');

      this.set('values', {});

      Object.keys(this.wrappers).forEach(function (key) {
        var relay = _this2.wrappers[key];

        if (relay) {
          if (!Ember.get(relay, '__changeset__')) {
            //console.log('before', this.wrappers[key]);
            //delete this.wrappers[key];
            //console.log('after', this.wrappers[key]);
            Reflect.deleteProperty(_this2.wrappers, key);
          }
        }
      });

      keys.forEach(function (key) {
        changeset._notifyParentRelay(key);
      });
    },


    /**
     * Route all unknown property lookups to the getValue() method, which will proxy the lookups
     * to the changeset and source instance appropriately.
     *
     * @param key name of the field/property requested
     * @returns {*} the requested value (or undefined if not present in source or changeset)
     */
    unknownProperty: function unknownProperty(key) {
      //console.log('getting unknown', key)

      return this.getValue(key);
    },


    /**
     * Route all property assignments for unknown properties to the setValue method which will proxy
     * the assignments to the changeset and source instance appropriately.
     *
     * @param key name of the field/property to which the value should be assigned
     * @param value the value to assign to the property
     * @returns {*} the assigned value
     */
    setUnknownProperty: function setUnknownProperty(key, value) {
      this.setValue(key, value);
      return value;
    },


    /**
     * Proxy all value assignments for properties to the changeset (protecting the source object)
     *
     * @param key name of the field to which the value should be assigned
     * @param value the value to assign
     */
    setValue: function setValue(key, value) {
      var values = this.get('values');
      var original = this.get('source.' + key);

      var _key$split = key.split('.'),
          _key$split2 = _slicedToArray(_key$split, 1),
          root = _key$split2[0];

      if (value !== original) {
        // If the user changed the value from the source value, log the change
        values[key] = value;
      } else {
        // If the user set the property to the old value, remove the change
        Reflect.deleteProperty(values, key);
      }

      // Setting the attribute overrides the promise value, get rid of it
      if (this.get('promises.' + key)) {
        Reflect.deleteProperty(this.get('promises'), key);
      }

      // Notify listeners that the property has changed, and that the changed values
      // has been updated
      this.notifyPropertyChange(root);
      this.notifyPropertyChange('values');

      //this._notifyParentRelay(key);
    },


    /**
     * Wrap up the key and value in a relay or array proxy.
     *
     * If a wrapper was already created in a previous lookup, use that instance instead of
     * creating a new one
     *
     * @param key full path to the field, including sub-objects
     * @param value the value to wrap for arrays for faster lookup
     * @returns {*} the wrapped sub-object
     * @private
     */
    _getWrappedValue: function _getWrappedValue(key, value) {
      var wrappers = this.get('wrappers');

      // If the wrapper exists for this key already, return it
      if (this.wrappers.hasOwnProperty(key)) {
        return wrappers[key];
      }

      // Create a new wrapper and add it to the map of wrappers
      var wrapped = this._wrapValue(key, value);

      // Only cache the value if it was wrapped in a relay (do not cache raw values)
      // if (wrapped) {
      //   if (Ember.get(wrapped, '__changeset__')) {
      //     this.wrappers[key] = wrapped;
      //   }
      // }

      // if (wrapped) {
      this.wrappers[key] = wrapped;
      // }

      return wrapped;
    },


    /**
     *
     *
     * @param key
     * @param value
     * @returns {*}
     * @private
     */
    _wrapValue: function _wrapValue(key, value) {
      var _this3 = this;

      var parent = this;
      var that = this;

      // Determine how to wrap the sub-object based on the type
      switch (Ember.typeOf(value)) {
        case 'array':
          {

            /**
             * Wrapper for sub-object arrays. Similar to the Relay object, but handles some array specific
             * method and properties
             */
            return Ember.ArrayProxy.create({

              /////////////
              // Proxy data

              content: value,
              parent: parent,
              key: key,

              // Proxy data
              /////////////

              /**
               * Intercept the forEach callback iterator and wrap the items before sending them to the
               * specified callback
               *
               * @param callback the user specified callback to intercept
               * @param target
               */
              forEach: function forEach(callback, target) {
                return this.content.forEach(function (item, index, enumerable) {

                  // Wrap the item in the iterator
                  var wrappedItem = that._getWrappedValue(key + "." + index, item);

                  // Send the wrapped item to the user specified callback
                  return callback(wrappedItem, index, enumerable);
                }, target);
              },


              /**
               * Proxy lookups to the changeset parent object
               *
               * @param key name of the field/index to retrieve
               */
              getValue: function getValue(key) {
                return this.parent.getValue(this.key + "." + key);
              },


              /**
               * Proxy value assignments to the parent changeset object
               *
               * @param key name of the field/index to assign the value
               * @param value is the value to assign
               */
              setValue: function setValue(key, value) {
                this.parent.setValue(this.key + "." + key, value);
              },


              /**
               * Intercept index lookups and wrap the item at the index
               *
               * @param index is the index into the array
               * @returns {*} the wrapped item at the index
               */
              objectAt: function objectAt(index) {
                return that._getWrappedValue(key + "." + index, this.content[index]);
              },


              /**
               * Intercept index lookup
               *
               * @param index
               * @returns {*|Object|DS.Model}
               */
              objectAtContent: function objectAtContent(index) {
                return this.content.objectAtContent(index);
              },


              /**
               * Intercept multi-index lookups
               *
               * @param indexes
               * @returns {*|Array}
               */
              objectsAt: function objectsAt(indexes) {
                return this.content.objectsAt(indexes);
              },


              /**
               * Intercept object comparator to compare based on the wrapped arrays
               *
               * @param other
               * @returns {boolean}
               */
              isEqual: function isEqual(other) {
                if (!other) {
                  return false;
                }

                return Ember.isEqual(this.content, other.content);
              },


              /**
               * Route all unknown property lookups to the getValue() method, which will proxy the lookups
               * to the changeset and source instance appropriately.
               *
               * @param key name of the field/property requested
               * @returns {*} the requested value (or undefined if not present in source or changeset)
               */
              unknownProperty: function unknownProperty(key) {
                return this.getValue(key);
              },


              /**
               * Route all property assignments for unknown properties to the setValue method which will proxy
               * the assignments to the changeset and source instance appropriately.
               *
               * @param key name of the field/property to which the value should be assigned
               * @param value the value to assign to the property
               * @returns {*} the assigned value
               */
              setUnknownProperty: function setUnknownProperty(key, value) {
                this.setValue(key, value);
                return value;
              }
            });
          }

        case 'instance':
        case 'object':
          {
            // If the object is a promise, rig the resolve of the promise to store the resolved
            // object back here so we can notify and proxy it
            if (value.then) {
              var relay = _relay.default.create({ parent: parent, key: key });
              value.then(function (v) {
                //parent.set(`promises.${key}`, v);
                var promises = parent.get('promises');

                promises[key] = v;

                parent.notifyPropertyChange(key);

                _this3._notifyParentRelay(key);
              });

              return relay;
            }

            // Wrap the regular instance object in the relay proxy object
            return _relay.default.create({ parent: parent, key: key });
          }

        default:
          {
            // If the type is a raw value or non-wrappable type, just return it without wrapping
            //return Ember.ObjectProxy.create({content: value});
            return value;
          }
      }
    },


    /**
     * Handle notifying the parent relay objects that contained a changed attribute (likely just a
     * promise resolution) so that the bindings to the relay proxy objects receive the change event
     * and fire and listeners.
     *
     * @param path changed attribute key (full-path)
     * @private
     */
    _notifyParentRelay: function _notifyParentRelay(path) {

      var keys = path.split('.');

      var attributeName = keys[keys.length - 1];

      // Only try to resolve parent relay if there are keys beyond a single attribute name, which
      // would be caught by notifying the changeset
      if (keys.length > 1) {
        //console.log('a');
        var parentKeys = [];

        // Chop the attribute name off the path so we are just left with the path to the parent
        for (var i = 0; i < keys.length - 1; i++) {
          parentKeys.push(keys[i]);
        }

        var parentPath = parentKeys.join('.');
        var _attributeName = keys[keys.length - 1];

        var relay = this.wrappers[parentPath];

        // If there is a parent relay for the key, notify of the property change
        if (relay) {
          relay.notifyPropertyChange(_attributeName);
        }
      } else {
        // console.log('b');
        this.notifyPropertyChange(attributeName);

        if (this.wrappers[attributeName]) {
          // console.log('wrapper', this.wrappers[attributeName]);

          if (this.wrappers[attributeName].hasOwnProperty('__changeset__')) {
            this.wrappers[attributeName].notifyPropertyChange('parent');
          }
        }
      }

      // const value = this.wrappers[path];
      //
      // if (value) {
      //   if (!Ember.get(value, '__changeset__')) {
      //     Reflect.deleteProperty(this.wrappers, path);
      //   }
      // }
    },


    /**
     * Handle lookups for values within the object graph from the source object by key
     *
     * If the value is unchanged, the value is pulled from the source instance. If the value has been
     * changed, the changed value is pulled from the changeset.
     *
     * @param key name of the field, including sub-object path, to retrieve
     * @returns {*}
     */
    getValue: function getValue(key) {
      var values = this.get('values');
      var promises = this.get('promises');

      // Check if it is a resolved promise
      if (promises.hasOwnProperty(key)) {
        return promises[key];
      }

      // If the changeset contains the key, return the current changed value
      if (values.hasOwnProperty(key)) {
        return values[key];
      }

      // No changed value in the changeset, get it from the source
      var original = this.get('source.' + key);

      // Wrap the value, if necessary, and return it
      return this._getWrappedValue(key, original);
    },


    /**
     * Raw lookup without wrapping
     *
     * @param key name of the field, including sub-object path, to retrieve
     * @private
     */
    _getValue: function _getValue(key) {
      return this.get('source.' + key);
    },
    _getSource: function _getSource(key) {
      return this._getValue(key);
    },


    /**
     * Clear the changeset (restore to source state)
     */
    rollback: function rollback() {
      var _this4 = this;

      var keys = Object.keys(this.get('values'));

      this.set('values', {});

      // Notify all the bound proxies for changed values that the values have been reverted
      keys.forEach(function (key) {
        var _key$split3 = key.split('.'),
            _key$split4 = _slicedToArray(_key$split3, 1),
            root = _key$split4[0];

        var splitKeys = key.split('.');
        var property = splitKeys.pop();
        var path = splitKeys.join('.');
        var proxy = _this4.getValue(path);

        _this4.notifyPropertyChange(root);
        proxy.notifyPropertyChange(property);
      });

      this.notifyPropertyChange('values');
      this.notifyPropertyChange('wrappers');
    },
    rollbackAttribute: function rollbackAttribute(name) {
      var _this5 = this;

      var changes = this.get('changes');

      changes.forEach(function (change) {
        if (change.key === name) {
          // console.log('name', name);
          // console.log('oldValue', change.oldValue);
          _this5.setValue(name, change.oldValue);
        }
      });
    },
    attributeChanged: function attributeChanged(name) {
      var values = this.get('values');

      return !Ember.isNone(values[name]);

      // this.get('changes').forEach((change) => {
      //   console.log('Comparing: ', change.key, name);
      //   if (change.key === name) {
      //     return true;
      //   }
      // });
      //
      // return false;
    }
  });
});