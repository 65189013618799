define('pretty-nuts/routes/settings', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      var session = this.get('session');
      var userId = session.get('data').authenticated.userId;

      return Ember.RSVP.hash({
        user: this.store.findRecord('user', userId),
        emails: this.store.query('email', { userId: userId })
      });
    }
  });
});