define('pretty-nuts/routes/reporting/obligations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var model = this.modelFor('reporting');
      model.organizationId = params.organizationId;
      return model;
    }
  });
});