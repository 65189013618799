define('pretty-nuts/models/address', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    addressId: _emberData.default.attr(),
    addressAquornId: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization'),
    type: _emberData.default.attr(),
    line1: _emberData.default.attr(),
    line2: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    country: _emberData.default.attr(),
    postalCode: _emberData.default.attr(),
    label: _emberData.default.attr(),
    hq: _emberData.default.attr('boolean'),

    cityState: Ember.computed('label', 'city', 'state', 'postalCode', function () {
      if (this.get('label')) {
        return this.get('label');
      } else {
        var city = this.get('city'),
            state = this.get('state');
        return [city, state].filter(function (item) {
          return item;
        }).join(', ');
      }
    })

  });
});