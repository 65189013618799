define('pretty-nuts/models/legal-entity-debt-profile', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    legalEntity: _emberData.default.belongsTo('legal-unit'),
    perspectiveDate: _emberData.default.attr(),
    outstandingPrincipal: _emberData.default.attr(),
    principalDueThisYear: _emberData.default.attr(),
    stats: _emberData.default.attr('embedded-models', { modelName: 'domain-legal-entity-stats-cashflow' }),
    obligations: _emberData.default.attr('embedded-models', { modelName: 'domain-obligation-overview' }),
    serviceProviderSummaries: _emberData.default.attr()
  });
});