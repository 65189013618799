define("pretty-nuts/controllers/onboarding/events", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    onboarding: Ember.inject.controller(),
    eventService: Ember.inject.service(),
    application: Ember.inject.controller(),
    routing: Ember.inject.service("-routing"),
    monthlyDays: [],

    actions: {
      updateMonthly: function updateMonthly(value) {
        var _this = this;

        this.set('startDate', value);
        var eventService = this.get('eventService');

        eventService.getDateContext(value).then(function (response) {
          _this.set('monthlyDays', eventService.getMonthlyDays(value, response.get('dayOfWeek'), response.get('businessDayNumber')));
        });
      },
      refreshCurrentRoute: function refreshCurrentRoute() {
        var currentRouteName = this.get("routing.currentRouteName");
        var currentRouteInstance = Ember.getOwner(this).lookup("route:" + currentRouteName);
        console.log('refreshing:', currentRouteName, 'and:', currentRouteInstance);
        currentRouteInstance.refresh();
      },
      refreshEventDraftMaster: function refreshEventDraftMaster() {
        var _this2 = this;

        var eventService = this.get('eventService');
        var model = this.get('model');
        return eventService.getEventMasterDrafts(model.obligationId).then(function (events) {
          _this2.set('model.events', events);
          _this2.send('reloadModel');
        });
      },
      recurrenceClicked: function recurrenceClicked() {},
      showAddEvent: function showAddEvent() {
        $('#newEvent').foundation('open');
      },
      setAdvanceUnit: function setAdvanceUnit(val) {
        this.set('advanceUnit', val);
      },
      actionSaveAndTransition: function actionSaveAndTransition() {
        this.get('onboarding').next('events');
      },
      foo: function foo() {},
      add_user_defined: function add_user_defined() {},
      deleteObligation: function deleteObligation(obligation) {
        this.get('onboarding').send('deleteObligation', obligation);
      },
      createEvent: function createEvent() {
        var _this3 = this;

        var eventService = this.get('eventService');
        var periodUnit = {};
        var frequencyUnit = this.get('frequencyUnit');
        if (frequencyUnit === "Business Days") {
          frequencyUnit = "BusinessDays";
        }
        if (this.get('recurringEvent')) {
          periodUnit = {
            by: frequencyUnit,
            byFrequency: this.get('frequencyNumber'),
            daysOfWeek: this.get('weekdays'),
            daysOfMonth: [],
            businessDaysOfMonth: [],
            daysOfWeekMonths: []
          };
        }
        if (!this.get('endDate')) {
          this.set('endDate', this.get('startDate'));
        }

        eventService.createEventMasterDraft(this.get('title'), this.get('description'), moment(this.get('startDate')).utc().format('YYYY-MM-DD' + 'T' + 'HH:mm:ss'), moment(this.get('endDate')).utc().format('YYYY-MM-DD' + 'T' + 'HH:mm:ss'), this.get('model.obligationId'), null, periodUnit).then(function (model) {
          $('#newEvent').foundation('close');
          var eventDraftMasters = eventService.getCachedEventMasterDrafts();
          _this3.set('startDate', '');
          _this3.set('endDate', '');
          _this3.set('title', '');
          _this3.set('description', '');
          _this3.set('model.events', eventDraftMasters);
          _this3.send('reloadModel');
        });
      },
      deleteEvent: function deleteEvent(eventId) {
        var _this4 = this;

        console.log('eventId to delete:', eventId);
        var that = this;
        var eventService = this.get('eventService');
        eventService.deleteCustomEvent(this.get('eventId')).then(function (model) {
          $('#event').foundation('close');
          _this4.send('refreshCurrentRoute');
          $('#calendar').fullCalendar('refetchEvents');
        });
      },
      deleteEventMaster: function deleteEventMaster(masterEventId, objectId) {
        var _this5 = this;

        console.log('masterEventId to delete:', masterEventId, objectId);
        var eventService = this.get('eventService');
        eventService.deleteEventMasterDraft(masterEventId, objectId).then(function (model) {
          var eventDraftMaster = eventService.getCachedEventMasterDrafts();
          _this5.set('model.events', eventDraftMaster);
          console.log(_this5.get('model.events'));
          _this5.send('reloadModel');
        });
      },
      closeEvent: function closeEvent() {
        $('#newEvent').foundation('close');
      }
    }
  });
});