define('pretty-nuts/routes/reporting/legal-entity', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    obligationService: Ember.inject.service(),

    model: function model(params) {
      var obligationService = this.get('obligationService');

      return Ember.RSVP.hash({
        legalEntityId: params.legalEntityId,
        legalEntity: this.get('store').findRecord('legal-unit', params.legalEntityId),
        legalEntityDebtProfile: obligationService.getObligorDebtProfile(params.legalEntityId)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    }
  });
});