define('pretty-nuts/serializers/legal-entity-debt-profile', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend(DS.EmbeddedRecordsMixin, {
    // primaryKey: 'legalEntityId',

    attrs: {
      legalEntity: 'legalEntityId'
    }

  });
});