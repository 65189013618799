define('pretty-nuts/models/domain-obligation-refund-determination', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    shortName: _emberData.default.attr(),
    mainUseOfProceeds: _emberData.default.attr(),
    federalTaxStatus: _emberData.default.attr(),
    outstandingPrincipal: _emberData.default.attr(),
    totalRefundPrincipal: _emberData.default.attr(),
    remainingPrincipal: _emberData.default.attr(),

    securityRefundDeterminations: _emberData.default.attr('embedded-models', { modelName: 'domain-security-refund-determination' })
  });
});