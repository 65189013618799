define('pretty-nuts/models/email', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    emailId: _emberData.default.attr(),
    source: _emberData.default.attr(),
    userId: _emberData.default.attr(),
    address: _emberData.default.attr(),
    primary: _emberData.default.attr(),
    profileURL: _emberData.default.attr(),
    verified: _emberData.default.attr()
  });
});