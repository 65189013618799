define("pretty-nuts/helpers/capitalize", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.capitalize = capitalize;
  function capitalize(params /*, hash*/) {
    if (Ember.isEmpty(params)) {
      return "";
    }

    if (Ember.isEmpty(params[0])) {
      return "";
    }

    return params[0].charAt(0).toUpperCase() + params[0].slice(1).toLowerCase();
  }

  exports.default = Ember.Helper.helper(capitalize);
});