define('pretty-nuts/helpers/file-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.fileIcon = fileIcon;
  function fileIcon(params) {
    var type = params[0];
    if (type === 'PDF') {
      return 'file-pdf-o';
    } else if (type === 'DOC' || type === 'DOCX') {
      return 'file-word-o';
    } else if (type === 'XLS' || type === 'XLSX') {
      return 'file-excel-o';
    }
    return 'file';
  }

  exports.default = Ember.Helper.helper(fileIcon);
});