define("pretty-nuts/users", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    getUserBasicProfiles: function getUserBasicProfiles(userIds, cb) {
      var url = "/api/users/profiles";

      $.get(url, { userIds: userIds.join() }).done(function (data) {
        cb(null, data);
      }).fail(function (data) {
        cb(true, data);
      });
    },
    getCurrentUserPendingRoleRequests: function getCurrentUserPendingRoleRequests(cb) {
      var url = "/api/users/roles/pending";

      $.get(url).done(function (data) {
        cb(null, data);
      }).fail(function (data) {
        cb(true, data);
      });
    },
    addUserWorkHistory: function addUserWorkHistory(employment, cb) {
      var url = "/api/users/employment";

      Ember.$.ajax({
        url: url,
        type: "PUT",
        contentType: "application/json",
        data: JSON.stringify(employment),

        success: function success(response) {
          cb(null, response);
        },

        error: function error(xhr, status, _error) {
          cb(true, _error);
        }
      });
    },
    deleteUserWorkHistory: function deleteUserWorkHistory(employmentId, cb) {
      var url = "/api/users/employment";

      Ember.$.ajax({
        url: url,
        type: "DELETE",
        contentType: "application/json",
        data: JSON.stringify({ id: employmentId }),

        success: function success(response) {
          cb(null, response);
        },

        error: function error(xhr, status, _error2) {
          cb(true, _error2);
        }
      });
    }
  };
});