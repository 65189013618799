define('pretty-nuts/models/domain-obligation-service-provider', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('domain-obligation'),
    organization: _emberData.default.belongsTo('organization'),

    perspectiveDate: _emberData.default.attr(),

    type: _emberData.default.attr(),

    contractPeriod: _emberData.default.attr(),
    paymentFramework: _emberData.default.attr(),

    members: _emberData.default.hasMany('domain-service-provider-member')
  });
});