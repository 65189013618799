define('pretty-nuts/controllers/trends/county-overview', ['exports', 'pretty-nuts/db'], function (exports, _db) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var controller = void 0;

  exports.default = Ember.Controller.extend({
    // ---------
    // Default Settings
    // ---------

    init: function init() {
      controller = this;
    },


    pie: 'use',
    donutView: 'by Use',
    maxBarThickness: 2,
    betweenGroupPadding: 0,
    withinGroupPadding: 0,
    maxLabelHeight: 40,
    stackBars: true,

    tooltip: {
      format: {
        title: function title(d) {
          return 'Data ' + d;
        },
        value: function value(_value, ratio, id) {
          var format = d3.format('$');
          return format(_value);
        }
        //            value: d3.format(',') // apply this format to both y and y2
      }
    },
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          format: '%Y'
        }
      },
      y: {
        label: {
          text: '$ in Millions',
          position: 'outer-middle'
        },
        tick: {
          format: function format(d) {
            return '$' + d / 1000000;
          }
        }
      }
    },
    labels: {
      //            format: function (v, id, i, j) { return "Default Format"; },
      format: {
        Airport: d3.format('$')
        //                data1: function (v, id, i, j) { return "Format for data1"; },
      }
    },

    color1: {
      pattern: ['rgb(63, 122, 175)', 'rgb(55, 106, 153)', 'rgb(82, 140, 193)', 'rgb(95, 148, 199)', 'rgb(109, 157, 205)', 'rgb(122, 165, 211)', 'rgb(136, 174, 216)', 'rgb(150, 183, 221)', 'rgb(164, 192, 227)']
    },
    color2: {
      pattern: ['rgb(193, 215, 183)', 'rgb(108, 167, 73)', 'rgb(91, 96, 38)', 'rgb(73, 113, 45)', 'rgb(80, 126, 50)', 'rgb(141, 187, 117)', 'rgb(176, 205, 163)', 'rgb(213, 228, 206)', 'rgb(227, 237, 223)']
    },
    color3: {
      pattern: ['rgb(220, 114, 55)', 'rgb(232, 149, 111)', 'rgb(130, 167, 24)', 'rgb(155, 80, 29)', 'rgb(174, 90, 33)', 'rgb(242, 188, 169)', 'rgb(246, 214, 200)', 'rgb(248, 224, 216)']
    },
    color4: {
      pattern: ['rgb(146, 146, 146)', 'rgb(192, 192, 192)', 'rgb(86, 86, 86)', 'rgb(107, 107, 107)', 'rgb(120, 120, 120)', 'rgb(130, 130, 130)', 'rgb(161, 161, 161)', 'rgb(176, 176, 176)', 'rgb(213, 213, 213)']
    },
    donut1: { title: 'Use' },
    donut2: { title: 'Tax Status' },
    donut3: { title: 'P & I' },
    donut4: { title: 'Purpose' },
    pie1: {
      // iris data from R
      columns: [['Airports', 16.28], ['General Purpose', 24.73], ['Government Buildings', 14.93], ['Mass Transit', 12.18], ['Fire Stations', 9.44], ['Parking Facilities', 4.81], ['Parks, Zoos, Beaches', 7.1], ['Solid Waste', 4.73], ['Water & Sewer Facilities', 5.81]],
      type: 'donut',
      onclick: function onclick(d, i) {
        console.log(JSON.stringify(controller.get('pie')));
        JSON.stringify(controller.set('pie', 'use'));
        JSON.stringify(controller.set('donutView', 'by Use'));
      }
    },
    legend: {
      hide: true
    },
    pieSize: {
      width: 250,
      height: 250
    },
    pie2: {
      // iris data from R
      columns: [['Tax-Exempt', 85.29], ['Taxable', 14.71]],
      legend: {
        hide: true
      },
      type: 'donut',
      onclick: function onclick(d, i) {
        console.log(JSON.stringify(controller.get('pie')));
        JSON.stringify(controller.set('pie', 'tax_status'));
        JSON.stringify(controller.set('donutView', 'by Tax Status'));
      }
    },
    actions: {
      foo: function foo() {
        console.log(this);
      }
    },
    pie3: {
      // iris data from R
      legend: {
        hide: true
      },
      columns: [['Principal', 46.36], ['Interest', 53.41], ['Accretted', 0.22]],
      type: 'donut',
      onclick: function onclick(d, i) {
        JSON.stringify(controller.set('pie', 'pni'));
        JSON.stringify(controller.set('donutView', 'by Principal & Interest'));
      }
    },
    pie4: {
      // iris data from R
      columns: [['New Money', 96.03], ['Refunding', 3.97]],
      legend: {
        hide: true
      },
      type: 'donut',
      onclick: function onclick(d, i) {
        JSON.stringify(controller.set('pie', 'purpose'));
        JSON.stringify(controller.set('donutView', 'by Purpose'));
      }
    }
  });
});