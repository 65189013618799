define('pretty-nuts/controllers/onboarding/cashflow', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ObligationTypeRefundedSecurities = 'Refunded Securities';
  var ObligationTypeNewObligations = 'New Obligations';

  exports.default = Ember.Controller.extend({
    onboarding: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    // obligationTypes: ['New Obligations','Refunded Securities'],
    obligationTypes: [ObligationTypeNewObligations, ObligationTypeRefundedSecurities],
    periods: ['Annual', 'Semi-Annual', 'Actual'],
    selectedPeriod: 'Actual',
    selectedType: ObligationTypeNewObligations,
    pieCharts: ['PrincipalAndInterest'],

    parameters: Ember.computed('model.integratedObligation.value', 'selectedType', function () {
      var integratedObligation = this.get('model.integratedObligation');
      var selectedType = this.get('selectedType');

      var cashflowView = _constants.Constants.CashflowView.ORIGINAL;

      if (selectedType === ObligationTypeRefundedSecurities) {
        cashflowView = _constants.Constants.CashflowView.PRIOR_REFUNDED;
      }

      console.log('getting perspective date parameters', integratedObligation.get('value.obligation'));

      return {
        perspectiveDate: integratedObligation.get('value.obligation.datedDate'),
        cashflowView: cashflowView
      };
    }),

    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        $('.obligations-table').floatThead({ top: 48 }).trigger("reflow");
      });
      this.set('entered', false);
    }.observes('entered'),

    actions: {
      selectPeriod: function selectPeriod(item) {
        console.log('selectPeriod');
        this.set('selectedPeriod', item);
      },
      selectType: function selectType(item) {
        console.log('selectType', item);
        this.set('selectedType', item);
      },
      actionSaveAndTransition: function actionSaveAndTransition() {
        this.get('onboarding').next('cashflow');
      },
      deleteObligation: function deleteObligation() {
        this.get('onboarding').next('parties');
      }
    }
  });
});