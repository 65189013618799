define('pretty-nuts/routes/application', ['exports', 'ember-simple-auth/mixins/application-route-mixin'], function (exports, _applicationRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_applicationRouteMixin.default, {

    currentUser: Ember.inject.service(),

    /**
     * Called when a successful authentication occurs. Continue setting up the session
     * with Ember Simple Auth but also load the current user service.
     */
    sessionAuthenticated: function sessionAuthenticated() {
      // return this._super(...arguments);
    },


    /**
     * Called when the session has been invalidated, either by logging out, clearing cookies,
     * or session expiration.
     */
    sessionInvalidated: function sessionInvalidated() {
      // Unload the user state information
      this.get('currentUser').unload();

      // Continue tearing down the session
      this._super.apply(this, arguments);
    },


    /**
     * Before entering any routes in the application, ensure that the user information is
     * loaded and ready.
     *
     * TODO: There should probably be an error handler here just in case
     *
     * @returns {*}
     */
    beforeModel: function beforeModel() {
      return this._loadCurrentUser();
    },


    /**
     * Local helper method for invoking the user service to load the state information
     *
     * @private
     */
    _loadCurrentUser: function _loadCurrentUser() {
      return this.get('currentUser').load();
    }
  });
});