define('pretty-nuts/components/top-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var county = 'County of Sacramento';
  var state = 'State of California';
  var lenses2 = ['Overview', 'Historical Activity', 'Forecasted Activity'];
  var subRoutes = ['state-overview', 'state-historical', 'state-forecasted'];

  function nav() {
    var subRoute = 'county-overview';
    if (this.get('selectedLocation') === state) {
      subRoute = subRoutes[this.get('lenses').indexOf(this.get('selectedLens'))];
    }
    this.get('router').transitionTo('reporting.' + subRoute);
  }

  exports.default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    modalService: Ember.inject.service(),
    resourceService: Ember.inject.service(),
    store: Ember.inject.service('store'),
    showModal: false,

    user: Ember.computed('currentUser.user', function () {
      var currentUser = this.get('currentUser');
      return currentUser.getProfile();
    }),

    organization: Ember.computed('currentUser.user', function () {
      return this.get('currentUser').getMyOrganization();
      // var employment = this.get('currentUser').getCurrentEmployment();
      // return employment.get('organization');
    }),

    breadcrumb: '',
    showCurrentOrgs: Ember.computed('router.currentRouteName', function () {
      var route = this.get('router.currentRouteName');
      if (route === 'obligations' || route === 'myorganization') {
        return true;
      } else {
        return false;
      }
    }),
    showSubPages: Ember.computed('router.currentRouteName', function () {
      var route = this.get('router.currentRouteName');
      console.log('ROUTE: ', route);
      if (route === 'government' || route === 'issuer' || route === 'obligation') {
        return true;
      } else {
        return false;
      }
    }),
    showSlash: Ember.computed('breadcrumb', function () {
      if (this.get('breadcrumb') === '') {
        return false;
      } else {
        return true;
      }
    }),
    pageName: Ember.computed('router.currentRouteName', function () {
      var pageName = '';
      var route = this.get('router.currentRouteName');
      if (route === 'index') {
        pageName = 'dashboard';
        return pageName;
      }
      if (route === 'onboarding.index') {
        pageName = 'onboarding';
        return pageName;
      }
      return route;
    }),
    showFilterField: Ember.computed('router.currentRouteName', function () {
      var route = this.get('router.currentRouteName');
      console.log('filter route', route);
      if (route === 'loading' || route === 'providers.all-service-providers' || route === 'governments' || route === 'people' || route === 'obligations' || route === 'documents' || route === 'government.documents' || route === 'issuer.documents' || route === 'obligation.documents') {
        return true;
      }
      return false;
    }),
    hideAdd: Ember.computed('router.currentRouteName', function () {
      var route = this.get('router.currentRouteName').split('.')[0];
      if (route === 'onboarding') {
        return true;
      }
      return false;
    }),

    showTools: Ember.computed('router.currentRouteName', function () {
      var route = this.get('router.currentRouteName');
      if (route === 'providers' || route === 'provider' || route === 'person' || route === 'people' || route === 'obligations' || route === 'obligation' || route === 'documents' || route === 'document') {
        return true;
      }
      return false;
    }),
    needs: ['application'],
    groups: ['Enterprise - Airport', 'Enterprise - Water', 'General Fund', 'CF Districts'],
    locations: [county, state],
    lenses: ['Overview'],

    selectedLocation: 'County of Sacramento',
    selectedLens: 'Overview',
    selectedGroup: 'All',

    currentRoute: Ember.computed('application.currentRoute', function () {
      var data = this.get('application.currentRoute');
      console.log('current route:', data);
      return data;
    }),

    isObligationsRoute: Ember.computed('router.currentRouteName', function () {
      console.log("Checking route");
      var routeName = this.get('router.currentRouteName');
      return routeName === "Obligations";
    }),

    currentOrganizations: [],

    /*
    currentOrganizations: Ember.computed('application.organizations', function () {
      var data = this.get('application.organizations');
       console.log("Setting nav organizations");
       if (data) {
        if (data.length > 0) {
          console.log("Setting default: " + data[0].name);
          this.set('currentOrganization', data[0].name)
        }
      }
       return data;
    }),
    */

    currentOrganization: "",

    init: function init() {
      this._super();
      if (this.get('selectedLocation') === state) {
        this.set('lenses', lenses2);
      }
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      $(window).trigger('load.zf.sticky');
    },
    uploadObligationDocument: function uploadObligationDocument(obligationId, documentType, file, categoryId, preparerOrg, preparerUser, description, documentDate, objectId, phase, status, documentCategory) {
      // console.log('uploadObligationDocument', obligationId, documentType, file, categoryId, preparerOrgId, preparerUserId, description, documentDate, objectId, phase, status,documentCategory);
      var that = this;
      var params = {
        obligationId: obligationId,
        documentType: documentType,
        // documentCategory: documentCategory,
        categoryId: categoryId,
        documentName: file.filename,
        preparerOrg: preparerOrg,
        phase: phase,
        status: status,
        preparerUser: preparerUser,
        resourceId: objectId,
        documentDate: documentDate,
        description: description
        // documentName: file.name
      };

      this.get('resourceService').uploadObligationDocument(file, obligationId, params).perform(function () {
        // that.getGroups(that.get('type'));
      });
    },
    uploadLegalUnitDocument: function uploadLegalUnitDocument(legalUnitId, documentType, file, categoryId, preparerOrg, preparerUser, description, documentDate, objectId, phase, status, documentCategory) {
      // console.log('uploadLegalUnitDocument', legalUnitId, documentType, file, categoryId, preparerOrgId, preparerUserId, description, documentDate, objectId, phase, status,documentCategory);
      var that = this;
      var params = {
        legalEntityId: legalUnitId,
        documentType: documentType,
        // documentCategory: documentCategory,
        categoryId: categoryId,
        documentName: file.filename,
        preparerOrg: preparerOrg,
        phase: phase,
        status: status,
        preparerUser: preparerUser,
        resourceId: objectId,
        documentDate: documentDate,
        description: description

        // documentName: file.name
      };
      console.log('LE FILE and PARAMS', file, params);
      this.get('resourceService').uploadLegalEntityDocument(file, legalUnitId, params).perform(function () {
        // that.getGroups(that.get('type'));
      });
    },
    uploadOrganizationDocument: function uploadOrganizationDocument(organization, documentType, file, categoryId, preparerOrg, preparerUser, description, documentDate, objectId, phase, status, documentCategory) {
      // console.log('uploadOrgDocument', organization, documentType, file, categoryId, preparerOrg, preparerUser, description, documentDate, objectId, phase, status,documentCategory);
      var that = this;
      var params = {
        documentType: documentType,
        // documentCategory: documentCategory,
        categoryId: categoryId,
        documentName: file.filename,
        preparerOrg: preparerOrg,
        documentPhase: phase,
        status: status,
        preparerUser: preparerUser,
        resourceId: objectId,
        documentDate: documentDate,
        description: description
        // documentName: file.name
      };
      console.log('ORG FILE and PARAMS', file, params);

      this.get('resourceService').uploadOrganizationDocument(file, organization, params).perform(function () {}, function (error) {
        // that.notifications.error(error);
      });
    },


    // uploadObligationDocument(obligationId, documentType, file) {
    //   let that = this;
    //   let params = {
    //     documentType: documentType,
    //     documentName: file.name
    //   };
    //
    //   this.get('resourceService').uploadObligationDocument(file, obligationId, params).perform(function () {
    //     that.get('documents').update();
    //   });
    //
    // },
    //
    // uploadLegalUnitDocument(legalUnitId, documentType, file) {
    //   console.log('uploadLegalUnitDocument');
    //   let that = this;
    //   let params = {
    //     documentType: documentType,
    //     documentName: file.name
    //   };
    //
    //   this.get('resourceService').uploadLegalEntityDocument(file, legalUnitId, params).perform(function () {
    //     that.get('documents').update();
    //   });
    //
    // },
    //
    // uploadOrganizationDocument(organizationId, documentType, file) {
    //   let that = this;
    //   let params = {
    //     documentType: documentType,
    //     documentName: file.name
    //   };
    //
    //   this.get('resourceService').uploadOrganizationDocument(file, organizationId, params).perform(function () {
    //     that.get('documents').update();
    //   });
    // },

    actions: {
      activeMenu: function activeMenu(id) {
        $('.active-icon-menu').removeClass('active-icon-menu');
        $('#' + id + ' i').addClass('active-icon-menu');
        $('.is-dropdown-submenu').removeClass('js-dropdown-active');
        $('.is-dropdown-submenu-parent').removeAttr('data-is-click');
      },
      navToLocation: function navToLocation(loc) {
        this.set('selectedLocation', loc);
        if (loc === county) {
          this.set('selectedLens', 'Overview');
        }
        nav.call(this);
      },
      navToLens: function navToLens(lens) {
        this.set('selectedLens', lens);
        nav.call(this);
      },
      navToGroup: function navToGroup(group) {
        this.set('selectedGroup', group);
        nav.call(this);
      },
      add: function add(group) {
        this.set('selectedGroup', group);
        nav.call(this);
      },
      logout: function logout() {
        this.get('onLogout')();
        this.get('router').transitionTo('login');
      },
      setCurrentOrganization: function setCurrentOrganization(org) {
        console.log("Setting current organization: " + JSON.stringify(org));
        this.set('currentOrganization', org);
      },
      createObligation: function createObligation() {
        var newObligation = this.get('store').createRecord('obligation');
        this.get('application').set('editObligationAction', 'Create');
        this.get('application').showObligationCreation(newObligation, function (newObligation) {
          console.log('callback:', newObligation);
        });
      },
      createDocument: function createDocument() {
        //this.get('application').showDocumentCreation(null);
        var that = this;

        var args = {
          organization: this.get('organization'),
          legalUnit: this.get('legalUnit'),
          obligation: this.get('obligation'),
          preparerOrgId: this.get('preparerOrgId'),
          preparerUserId: this.get('preparerUserId'),
          description: this.get('description'),
          documentDate: this.get('documentDate'),
          objectId: this.get('objectId'),
          documentCategory: this.get('documentCategory'),
          phase: this.get('phase'),
          status: this.get('status'),
          documentCategoryGroups: this.get('documentCategoryGroups'),
          callbacks: {
            success: function success(parentType, documentType, file, categoryId, organization, legalUnit, obligation, preparerOrgId, preparerUserId, description, documentDate, objectId, phase, status, documentCategory) {
              console.log('add document');
              console.log('documentType', documentType);
              console.log('file', file);
              that.set('organization', organization);
              that.set('legalUnit', legalUnit);
              that.set('obligation', obligation);
              var type = that.get('type');

              console.log('parentType', parentType);

              switch (parentType) {
                case 'obligation':
                  {
                    var _obligation = that.get('obligation');
                    console.log('PUSH OBLIGAATION DOC', _obligation.get('id'));
                    that.uploadObligationDocument(_obligation.get('id'), documentType, file, categoryId, preparerOrgId, preparerUserId, description, documentDate, objectId, phase, status, documentCategory);
                    break;
                  }
                case 'legal-entity':
                  {
                    var _legalUnit = that.get('legalUnit');
                    that.uploadLegalUnitDocument(_legalUnit.get('id'), documentType, file, categoryId, preparerOrgId, preparerUserId, description, documentDate, objectId, phase, status, documentCategory);
                    break;
                  }
                case 'organization':
                  {
                    var _organization = that.get('organization');
                    that.uploadOrganizationDocument(_organization, documentType, file, categoryId, preparerOrgId, preparerUserId, description, documentDate, objectId, phase, status, documentCategory);
                    break;
                  }
              }
            },

            cancel: function cancel() {
              that.set('showModal', false);
            }
          }
        };

        switch (this.get('type')) {
          case 'organization':
            {
              console.log('document widget organization', this.get('organization'));
              args.organizationDisabled = true;
              break;
            }

          case 'legal-unit':
            {
              args.organizationDisabled = true;
              args.legalUnitDisabled = true;
              break;
            }

          case 'obligation':
            {
              args.organizationDisabled = true;
              args.legalUnitDisabled = true;
              args.obligationDisabled = true;
              break;
            }
        }

        this.get('modalService').showModal('create_document', args);
        /*
              let that = this;
        
              let args = {
                organization: this.get('organization'),
                legalUnit: this.get('legalUnit'),
                obligation: this.get('obligation'),
                callbacks: {
                  success: function (parentType, documentType, file, organization, legalUnit, obligation) {
                    console.log('add document');
                    console.log('documentType', documentType);
                    console.log('file', file);
                    that.set('organization', organization);
                    that.set('legalUnit', legalUnit);
                    that.set('obligation', obligation);
                    const type = that.get('type');
        
        
                    console.log('parentType', parentType);
        
                    switch (parentType) {
                      case 'obligation': {
                        const obligation = that.get('obligation');
                        that.uploadObligationDocument(obligation.get('id'), documentType, file);
                        break;
                      }
                      case 'legal-entity': {
                        const legalUnit = that.get('legalUnit');
                        that.uploadLegalUnitDocument(legalUnit.get('id'), documentType, file);
                        break;
                      }
                      case 'organization': {
                        const organization = that.get('organization');
                        that.uploadOrganizationDocument(organization.get('id'), documentType, file);
                        break;
                      }
                    }
                  },
        
                  cancel: function () {
                    that.set('showModal', false)
                  }
                }
              };
        
              this.get('modalService').showModal('create_document', args);
        */
      },
      createOrganiztion: function createOrganiztion() {
        var organization = this.get('store').createRecord('organization');
        var address = this.get('store').createRecord('address', { hq: true, organization: organization });
        this.get('application').showOrgCreation(organization, address, function (createdOrganization) {
          console.log('callback:', createdOrganization);
        });
      },
      createLegalEntity: function createLegalEntity() {
        var newLE = this.get('store').createRecord('legal-unit', { type: 'GOVERNMENT ENTITY', industry: 'MUNICIPALITY' });
        this.get('application').showLECreation(newLE, function (createdLE) {
          console.log('callback:', createdLE);
        }, function (error) {
          console.log('error:', error);
        });
      },
      createUser: function createUser() {
        var newUser = this.get('store').createRecord('new-user');
        this.get('application').showUserCreation(newUser, function (newUser) {
          console.log('callback:', newUser);
        });
      }
    }
  });
});