define('pretty-nuts/models/operation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    createdTime: _emberData.default.attr(),
    lastUpdateTime: _emberData.default.attr(),
    completedTime: _emberData.default.attr(),
    type: _emberData.default.attr(),
    status: _emberData.default.attr(),
    result: _emberData.default.attr(),
    resultMessage: _emberData.default.attr()
  });
});