define('pretty-nuts/routes/obligation/index', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    opensubmissions: null,

    model: function model(params) {
      // console.log(params);
      //
      // var that = this;
      //
      // var result = {};
      // return new Ember.RSVP.Promise((resolve, reject) => {
      //   Ember.$.ajax({
      //     //url: '/api/obligation/501000000000000009&1',
      //     url: '/api/obligation/' + params.oid + '/security/' + params.sid,
      //     type: 'GET',
      //     //contentType: 'application/json',
      //     //dataType: 'json'
      //     success: function (response) {
      //       var content = response.Success; // Not sure why we need to unwrap this? ... not sure where its getting wrapped up
      //       var security = content;
      //
      //       // Parse the cashflow JSON
      //       security.cashflow = JSON.parse(atob(security.cashflowJson));
      //
      //       // Get the debt service from the cashflow
      //       var debtService = security.cashflow.debtService.debtServicePayments;
      //       var payments = [];
      //
      //       // Iterate the debt service payments to add them to the list for the cashflow
      //       // We need to do this as a workaround since the debt service data structure
      //       // in the backend is translated as named member parameters by payment date instead of a list
      //       for (var name in debtService) {
      //         if (debtService.hasOwnProperty(name)) {
      //           payments.push(debtService[name]);
      //         }
      //       }
      //
      //       // Construct the model
      //       result = {
      //         id: params.oid,
      //         obligation: content.obligationInformation,
      //         security: security,
      //         cashflows: payments,
      //         graph: {}
      //       };
      //
      //       // Tell ember that the data structure is ready and let loose
      //       // any delayed resolvers or templates waiting on the promise
      //       resolve(result);
      //     },
      //     error: function (xhr, status, error) {
      //       return 'error'
      //     }
      //   })
      // });
    }
  });
});