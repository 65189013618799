define('pretty-nuts/serializers/organization-user-role', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // primaryKey: 'aquornId',

    attrs: {
      user: 'granteeAquornId',
      organization: 'objectAquornId'
    }

  });
});