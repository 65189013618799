define('pretty-nuts/controllers/onboarding/call-options', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    obligationService: Ember.inject.service(),
    onboarding: Ember.inject.controller(),
    generate: true,

    callTypes: [_constants.Constants.CallOptionType.OPTIONAL_CALL, _constants.Constants.CallOptionType.MANDATORY_CALL],

    callAvailabilities: [_constants.Constants.CallOptionAvailability.CALL_DATE, _constants.Constants.CallOptionAvailability.ANY_DATE_AFTER, _constants.Constants.CallOptionAvailability.ANY_INTEREST_PAYMENT],

    callFrequencies: [_constants.Constants.Frequency.ANNUAL, _constants.Constants.Frequency.SEMI_ANNUAL, _constants.Constants.Frequency.QUARTERLY, _constants.Constants.Frequency.MONTHLY],

    //callOptionTypes: ["Optional Call", "Mandatory Call"],

    ///////
    // Data

    callStructure: {},

    optionalCallOptions: [],
    mandatoryCallOptions: [],

    // Data
    ///////

    // init() {
    //   this._super(...arguments);
    //
    //   //console.log('obligation', this.get('model.obligation'));
    //
    //   this.set('callStructure', this.get('store').createRecord('call-structure'));
    // },

    // obligationObserver: function () {
    //   this.set('callStructure.obligation', this.get('model.obligation'));
    // }.observes('model.obligation'),

    refreshCallOptions: function refreshCallOptions() {
      var optionalCallOptions = [];
      var mandatoryCallOptions = [];

      var callOptions = this.get('model.integratedObligation.value.callOptions');
      // const callOptionsValue = callOptions.get('value');

      if (callOptions) {
        Object.keys(callOptions).forEach(function (callOptionId) {

          var callOption = callOptions[callOptionId];

          console.log('call option', callOption);

          if (callOption) {

            // const callOptionsValue = callOption.get('value');

            var stype = callOption.get('type');

            switch (stype) {
              case _constants.Constants.CallOptionType.OPTIONAL_CALL:
                {
                  optionalCallOptions.pushObject(callOption);
                  break;
                }

              case _constants.Constants.CallOptionType.MANDATORY_CALL:
                {
                  mandatoryCallOptions.pushObject(callOption);
                  break;
                }

              default:
                {
                  console.log("Unexpected call-option type:", callOption.get('type'));
                }
            }
          }
        });
      }

      optionalCallOptions.sort(function (a, b) {
        var ad = Ember.get(a, 'callDate');
        var bd = Ember.get(b, 'callDate');

        if (ad === bd) {
          return 0;
        } else if (ad < bd) {
          return -1;
        } else {
          return 1;
        }
      });

      mandatoryCallOptions.sort(function (a, b) {
        var ad = Ember.get(a, 'callDate');
        var bd = Ember.get(b, 'callDate');

        if (ad === bd) {
          return 0;
        } else if (ad < bd) {
          return -1;
        } else {
          return 1;
        }
      });

      // if (Ember.isArray(callOptionsValue)) {
      //   console.log('call-options value', callOptionsValue);
      //
      // }

      this.set('optionalCallOptions', optionalCallOptions);
      this.set('mandatoryCallOptions', mandatoryCallOptions);
    },


    /**
     * When the list of call-options is updated, split them into individual lists
     * of optional calls and mandatory calls for displaying them separately.
     */
    callOptionsObserver: function () {
      console.log('call options obersver!!!!');
      this.refreshCallOptions();
    }.observes('model.integratedObligation.value.callOptions'),

    actions: {
      actionGenerateCallStructure: function actionGenerateCallStructure() {
        // const callStructure = this.get('callStructure');
        var data = this.get('callStructure');
        var callStructure = this.get('store').createRecord('call-structure', data);

        if (callStructure.get('firstCallPrice') === 100) {
          this.set('callStructure.basisPointsStep', 0);
          this.set('callStructure.lastStepDate', callStructure.get('firstStepDate'));
          this.set('callStructure.frequency', _constants.Constants.Frequency.ANNUAL);
        }

        callStructure.set('obligation', this.get('model.obligation.value'));

        var controller = this;

        // this.get('model.callOptions').generate(callStructure);
        this.get('model.integratedObligation').generateCallOptions(callStructure).then(function () {
          controller.refreshCallOptions();
          controller.set('callStructure', {});
        });
      },
      actionSetCallOptionAttribute: function actionSetCallOptionAttribute(callOption, name, value) {
        var path = 'callOptions.' + callOption.get('id') + '.' + name;
        console.log('actionSetCallOptionAttribute', callOption, name, value);
        Ember.set(callOption, name, value);
        this.get('model.integratedObligation').saveAttributes([path]);
      },
      actionSaveCallOption: function actionSaveCallOption(callOption) {

        this.get('model.integratedObligation').save();

        // console.log('Saving...', callOption);
        // callOption.save();


        // let that = this;
        //
        // this.get('onboarding.obligationService').saveCallOption(callOption).then(function () {
        //   that.get('onboarding.notifications').success("Call updated...", {autoClear: true});
        // });
      },
      actionDeleteCallOptions: function actionDeleteCallOptions(callOptions) {
        var callOptionIds = [];

        callOptions.forEach(function (callOption) {
          callOptionIds.push(callOption.get('id'));
        });

        var controller = this;

        return this.get('model.integratedObligation').removeCallOptions(callOptionIds).then(function () {
          controller.refreshCallOptions();
        });
        // const obligationService = this.get('obligationService');
        //
        // let model = this.get('model');
        //
        // let operation = obligationService.deleteCallOptions(callOptions);
        //
        // operation.perform(function () {
        //   model.callOptions.update();
        // });
      },
      actionDeleteCallOption: function actionDeleteCallOption(callOption) {
        var controller = this;

        this.get('model.integratedObligation').removeCallOption(callOption.get('id')).then(function () {
          controller.refreshCallOptions();
        });
        // const obligationService = this.get('obligationService');
        // let model = this.get('model');
        //
        // callOption.destroy({
        //   onSuccess: function () {
        //     console.log('success');
        //     Ember.get(model, 'callOptions').reload();
        //   }
        // });
      },
      actionSaveAndTransition: function actionSaveAndTransition() {
        this.get('onboarding').next('call-options');
      },
      deleteObligation: function deleteObligation(obligation) {
        this.get('onboarding').send('deleteObligation', obligation);
      }
    }
  });
});