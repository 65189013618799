define('pretty-nuts/transforms/currency', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {

      /*
      if (!Ember.isNone(serialized)) {
        return new BigNumber(serialized.replace(',', '')).toFormat(2);
      }
      */

      return serialized;
    },
    serialize: function serialize(deserialized) {

      /*
      if (!Ember.isNone(deserialized)) {
        return new BigNumber(deserialized.replace(',', '')).toFormat(2).replace(',', '');
      }
      */

      return deserialized;
    }
  });
});