define('pretty-nuts/helpers/has-access', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    currentUser: Ember.inject.service('current-user'),

    compute: function compute(params) {
      var currentUser = this.get('currentUser');

      return currentUser.hasAccess(params[0], params[1]);
    }
  });
});