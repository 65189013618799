define('pretty-nuts/controllers/reporting/obligation/debt-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    reporting: Ember.inject.controller(),
    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        this.get('reporting').send('switchToLandscape', false);
      });
      this.set('entered', false);
    }.observes('entered'),
    actions: {
      revert: function revert(item) {
        this.send('display', item);
      },
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      },
      actionRefreshReport: function actionRefreshReport() {
        var grouping = this.get('model.grouping');
        var start = this.get('model.start');

        var report = this.get('model.report');

        // report.setGrouping(grouping);
        // report.setStart(start);
        report.update(start);
        this.set('model.templateGrouping', grouping);
        this.send('display', 'editable');
      },
      actionSetFilterAttribute: function actionSetFilterAttribute(attribute, value) {
        this.get('setFilterAttribute')(attribute, value);
      },
      actionSetQueryAttribute: function actionSetQueryAttribute(attribute, value) {
        this.get('setQueryAttribute')(attribute, value);
      },
      actionSetQueryDate: function actionSetQueryDate(attribute, evt) {
        this.get('setQueryAttribute')(attribute, evt.target.value);
      }
    }

  });
});