define('pretty-nuts/controllers/aquorn-admin/network', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    networkService: Ember.inject.service(),

    cytoscape: '',
    currentOrganization: '',
    organizations: [],
    orgTotal: -1,

    allDataLoadedObserver: function () {
      console.log('In Observer', this.get('orgTotal'));

      if (this.get('orgTotal') && this.get('orgTotal') < 0) {
        return;
      }

      if (this.get('orgTotal') === 0) {
        console.log('no data found');
        var cy = this.get('cytoscape');
        var collection = cy.nodes("");
        cy.remove(collection);
      }

      if (this.get('orgTotal') === this.get('organizations').length) {
        var cy = this.get('cytoscape');
        var _collection = cy.nodes("");
        cy.remove(_collection);

        var currentOrg = this.get('currentOrganization');
        var currentOrgId = currentOrg.id;
        var logoImageUrl = currentOrg.get('logoImageUrl') ? currentOrg.get('logoImageUrl') : "/assets/img/generic/generic-org-pic.png";
        var outputArray = [];
        outputArray.push({
          group: "nodes", data: { id: currentOrgId, label: currentOrg.get('name') }, style: { shape: 'ellipse', width: 50, height: 50, 'background-color': '#f28026', 'background-image': logoImageUrl, 'background-fit': 'cover' }
        });

        this.get('organizations').forEach(function (organization) {
          var providerId = organization.id;
          var providerImageUrl = organization.get('logoImageUrl') ? organization.get('logoImageUrl') : "/assets/img/generic/generic-org-pic.png";

          outputArray.push({
            group: "nodes", data: { id: providerId, label: organization.get('name') }, style: { shape: 'ellipse', width: 30, height: 30, 'background-color': '#e2e2e2', 'background-image': providerImageUrl, 'background-fit': 'cover' }
          });

          outputArray.push({
            group: "edges", data: { id: currentOrgId + "_" + providerId, source: currentOrgId, target: providerId }
          });
        });

        cy.add(outputArray);
        cy.forceRender();
        var layout = cy.layout({
          name: 'cose',
          animate: true
        });
        layout.run();
      }

      return;
    }.observes('organizations').on('init'),

    modelObserver: function () {
      var initCy = this.get('initCy');
      Ember.run.schedule("afterRender", this, initCy);
    }.observes('model'),

    initCy: function initCy() {
      var cy = cytoscape({
        container: document.getElementById('cytoscape2'), // container to render in

        elements: [// list of graph elements to start with
        { // node a
          data: { id: 'a', label: 'Aquorn, Inc' },
          style: {
            shape: 'ellipse',
            width: 50,
            height: 50,
            'background-color': '#f28026'
          }
        }, { // node b
          data: { id: 'b', label: 'Play with Networks' }
        }, {
          data: { id: 'c', label: 'Enter an Org Name' }
        }, {
          data: { id: 'd', label: 'Discovery New Relations' }
        }, {
          data: { id: 'e', label: 'Explore Connections' }
        }, { // edge ab
          data: { id: 'ab', source: 'a', target: 'b' }
        }, { // edge ab
          data: { id: 'ac', source: 'a', target: 'c' }
        }, { // edge ab
          data: { id: 'ad', source: 'a', target: 'd' }
        }, { // edge ab
          data: { id: 'ae', source: 'a', target: 'e' }
        }],

        style: [// the stylesheet for the graph
        {
          selector: 'node',
          style: {
            'background-color': '#aaaaaa',
            'label': 'data(label)',
            'shape': 'ellipse',
            'width': 30,
            'height': 30,
            'font-size': '8px'
          }
        }, {
          selector: 'edge',
          style: {
            'width': 1,
            'line-color': '#f28026',
            'target-arrow-color': '#f28026',
            'target-arrow-shape': 'triangle'
          }
        }],

        layout: {
          name: 'cose',
          animate: true
        }
      });

      cy.on('tap', 'node', function (event) {
        var node = event.target;
        console.log('tapped ' + node.id());
      });

      this.set('cytoscape', cy);
    },

    init: function init() {
      this._super();
      console.log('main in here');
      var initCy = this.get('initCy');
      Ember.run.schedule("afterRender", this, initCy);
    },

    actions: {
      actionLoadOrg: function actionLoadOrg() {
        var _this = this;

        var cy = this.get('cytoscape');
        var collection = cy.nodes("");
        cy.remove(collection);

        this.set('currentOrganization', this.get('currentOrganization'));
        var networkService = this.get('networkService');
        var orgId = this.get('currentOrganization').id;

        this.set('orgTotal', -1);
        networkService.getOrgNetwork(orgId).then(function (providers) {
          if (providers) {
            var total = 0;
            providers.forEach(function (provider) {
              total++;
              provider.get('organization').then(function (organization) {
                var orgs = _this.get('organizations');
                orgs.push(organization);
                _this.set('organizations', orgs.slice());
              });
            });
            _this.set('orgTotal', total);
          } else {
            _this.set('orgTotal', 0);
          }
        });
      }
    }
  });
});