define('pretty-nuts/components/editable-entity-logo', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    resourceService: Ember.inject.service(),
    entity: null,
    entityType: null,

    avatarPickerOptions: {
      accept: ['image/*'],
      uploadInBackground: false,
      imageMax: [300, 300],
      maxSize: 2 * 1024 * 1024
    },

    actions: {
      editAvatar: function editAvatar() {
        var resourceService = this.get('resourceService');

        // resourceService.getSignedUploadRequest().then((policy) => {
        //   let filestack = this.get('filestack.instance');
        //   filestack.setSecurity({
        //     policy: policy.encodedPolicy,
        //     signature: policy.signature
        //   });

        //   this.set('showPicker', true);
        // });
      },
      pickerSelect: function pickerSelect(file) {
        var that = this;
        var resourceService = this.get('resourceService');
        var entity = this.get('entity');
        var entityId = entity.get('id');
        var entityType = entity.get('constructor.modelName');

        resourceService.getUploadUrl().then(function (response) {
          var key = response.data.key;
          Ember.set(file, 'key', key);

          file.uploadBinary(response.data.url, {
            method: 'PUT'
          }).then(function (response) {

            if (entityType === 'organization') {
              return resourceService.uploadOrganizationLogo(file, entityId, function () {
                entity.reload();
              });
            } else if (entityType === 'legal-unit') {
              return resourceService.uploadLegalEntityLogo(file, entityId, function () {
                entity.reload();
              });
            }
          });
        });

        this.set('showPicker', false);
      },
      pickerClosed: function pickerClosed() {
        this.set('showPicker', false);
      }
    }
  });
});