define('pretty-nuts/components/onboard-service-provider', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),
    obligationService: Ember.inject.service(),
    application: null,
    ///////
    // Data

    members: [],
    // members: Ember.computed('serviceProviderOrg', function () {
    //   console.log('members computed');
    //   return this.get('store').query('service-provider', {parentId: this.get('serviceProviderOrg.id')});
    // }),
    serviceProviderOrg: null,

    userToAdd: null,

    // observer1: function () {
    //   console.log('members computed');
    //
    //   // this.get('store').query('service-provider', {parentId: this.get('serviceProviderOrg.id')}).then((members) => {
    //   //   this.set('members', members);
    //   // });
    //
    //   this.set('members', this.get('serviceProviderOrg.members'));
    //
    // }.observes('serviceProviderOrg').on('init'),

    // Data
    ///////

    ////////////////
    // Configuration

    componentId: Ember.computed('serviceProviderOrg', function () {
      var serviceProviderOrg = this.get('serviceProviderOrg');

      if (serviceProviderOrg) {
        return serviceProviderOrg.get('id');
      }

      return '';
    }),

    // Configuration
    ////////////////

    //////////////////
    // Action bindings

    onAddMember: null,
    onRemoveMember: null,

    // Action bindings
    //////////////////

    refreshMembers: function refreshMembers() {
      var serviceProviderMembers = this.get('integratedObligation.value.serviceProviderMembers');
      var serviceProviderId = this.get('serviceProviderOrg.id');

      var members = [];

      Object.keys(serviceProviderMembers).forEach(function (serviceProviderMemberId) {
        var serviceProviderMember = serviceProviderMembers[serviceProviderMemberId];

        if (serviceProviderMember) {

          if (serviceProviderMember.get('serviceProviderId') === serviceProviderId) {
            members.push(serviceProviderMember);
          }
        }
      });

      this.set('members', members);
    },


    membersObserver: function () {
      console.log('members observer');
      this.refreshMembers();
    }.observes('serviceProviderOrg.members').on('init'),

    /**
     * Hiding and showing elements
     *
     * @param name name of the element to show (must include the prefix '#')
     * @param show true to show the element, false to hide the element
     */
    showElement: function showElement(name, show) {
      var element = $(name);

      console.log('showElement:', name, show);

      if (show) {
        element.show();
      } else {
        element.hide();
      }
    },


    /**
     * Convert the element name to the localized element name containing the component Id
     *
     * @param name of the element to convert
     * @returns {string} name<componentId> or name if no component id
     */
    getLocalElementName: function getLocalElementName(name) {
      var componentId = this.get('componentId');

      return name + (!Ember.isNone(componentId) ? componentId : '');
    },


    /**
     * Show the add member controls
     */
    showAddMember: function showAddMember() {
      this.showElement(this.getLocalElementName('#add_member'), true);
    },


    /**
     * Hide the add member controls
     */
    hideAddMember: function hideAddMember() {
      this.showElement(this.getLocalElementName('#add_member'), false);
    },


    actions: {
      actionRemoveMember: function actionRemoveMember(member) {

        var that = this;
        var onMemberRemoved = this.get('onMemberRemoved');

        this.get('integratedObligation').removeServiceProviderMember(member.get('id')).then(function () {
          that.refreshMembers();
          if (onMemberRemoved) {
            onMemberRemoved();
          }
        });

        // const obligationService = this.get('obligationService');
        // let that = this;
        //
        // obligationService.removeServiceProvider(member).perform(function () {
        //   console.log('members', that.get('members'));
        //   that.get('members').update();
        // });

        // let onRemoveMember = this.get('onRemoveMember');
        //
        // if (onRemoveMember) {
        //   onRemoveMember(member);
        // } else {
        //   console.log("No onRemoveMember");
        // }
      },
      actionAddMember: function actionAddMember(entityType, member) {

        var obligationService = this.get('obligationService');
        var serviceProviderOrg = this.get('serviceProviderOrg');
        var obligation = serviceProviderOrg.get('obligation');
        var that = this;

        console.log('member', member);

        var json = member.serialize();

        console.log('member json', json);
        console.log('id', member.get('user.id'));

        var onMemberAdded = this.get('onMemberAdded');

        return this.get('integratedObligation').addServiceProviderMember(serviceProviderOrg.get('id'), json.user.id).then(function () {
          that.hideAddMember();
          that.set('userToAdd', null);

          that.refreshMembers();

          if (onMemberAdded) {
            onMemberAdded();
          }

          // that.get('members').update();
        });

        // obligationService.addServiceProviderMember(obligation, serviceProviderOrg, member).perform(function () {
        //   that.hideAddMember();
        //   that.set('userToAdd', null);
        //   that.get('members').update();
        // });

        // let onAddMember = this.get('onAddMember');
        //
        // this.hideAddMember();
        //
        // if (onAddMember) {
        //   onAddMember(member);
        // } else {
        //   console.log("No onRemoveMember");
        // }
      },
      actionNewMember: function actionNewMember(entityType, searchTerm) {
        var that = this;
        var serviceProviderOrg = this.get('serviceProviderOrg.organization');

        var _searchTerm$name$spli = searchTerm.name.split(" "),
            _searchTerm$name$spli2 = _slicedToArray(_searchTerm$name$spli, 2),
            firstName = _searchTerm$name$spli2[0],
            lastName = _searchTerm$name$spli2[1];

        var newUser = this.get('application.store').createRecord('new-user', { firstName: firstName, lastName: lastName });
        this.get('application').showUserCreation(newUser, serviceProviderOrg, function (createdUser) {
          that.get('application.store').findRecord('integrated-user-profile', createdUser.get('id')).then(function (userProfile) {
            that.send('actionAddMember', '', userProfile);
          });
          console.log('successfully created new user:', createdUser);
        }, function (error) {
          console.log('error:', error);
        });
      },


      /**
       * Action handler for hiding and showing elements
       *
       * @param name name of the element to show (must include the prefix '#')
       * @param show true to show the element, false to hide the element
       */
      actionShowElement: function actionShowElement(name, show) {
        this.showElement(name, show);
      },


      /**
       * Action handler for showing the add member controls
       */
      actionShowAddMember: function actionShowAddMember() {
        this.showAddMember();
      }
    }
  });
});