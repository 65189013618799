define('pretty-nuts/components/decimal-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, _inputMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _inputMask.default.extend({

    /**
     * Default mask, allow 1-3 integer digits, but require 3 fraction digits
     */
    mask: 'decimal',

    /**
     * Default precision of 3 fraction digits
     */
    precision: 3,

    /**
     * Override the update mask to set the required digits for auto fill in if not provided
     */
    updateMask: function updateMask() {
      if (this.get('mask').toLowerCase() === 'regex') {
        // Regex has to capitalized for the plugin, but that's annoying
        // so let's just allow users to enter it however they want...
        this.set('mask', 'Regex');

        // Note: I like pattern better, but I'll leave regex in as an option
        // as well since that's what the plugin defines on the options hash
        this.set('options.regex', this.get('pattern') || this.get('regex'));
      }

      this.setProperties({
        'options.placeholder': this.get('maskPlaceholder'),
        'options.showMaskOnFocus': this.get('showMaskOnFocus'),
        'options.showMaskOnHover': this.get('showMaskOnHover'),
        'options.rightAlign': this.get('rightAlign'),
        'options.clearIncomplete': this.get('clearIncomplete'),
        'options.greedy': this.get('greedyMask'),
        'options.digits': this.get('precision'),
        'options.digitsOptional': false,

        /**
         * Override decimal onBeforeMask to prevent parsing as float and rounding instead of truncating
         *
         * @param initialValue
         * @param opts
         * @returns {string|*}
         */
        'options.onBeforeMask': function optionsOnBeforeMask(initialValue, opts) {
          opts.isNegative = undefined;

          initialValue = initialValue.toString().charAt(initialValue.length - 1) === opts.radixPoint ? initialValue.toString().substr(0, initialValue.length - 1) : initialValue.toString();

          // if (opts.numericInput === true) {
          // 	initialValue = initialValue.split("").reverse().join("");
          // }
          if (opts.radixPoint !== "" && isFinite(initialValue)) {
            var vs = initialValue.split("."),
                groupSize = opts.groupSeparator !== "" ? parseInt(opts.groupSize) : 0;
            if (vs.length === 2 && (vs[0].length > groupSize || vs[1].length > groupSize || vs[0].length <= groupSize && vs[1].length < groupSize)) initialValue = initialValue.replace(".", opts.radixPoint);
          }
          var kommaMatches = initialValue.match(/,/g);
          var dotMatches = initialValue.match(/\./g);
          if (dotMatches && kommaMatches) {
            if (dotMatches.length > kommaMatches.length) {
              initialValue = initialValue.replace(/\./g, "");
              initialValue = initialValue.replace(",", opts.radixPoint);
            } else if (kommaMatches.length > dotMatches.length) {
              initialValue = initialValue.replace(/,/g, "");
              initialValue = initialValue.replace(".", opts.radixPoint);
            } else {
              //equal
              initialValue = initialValue.indexOf(".") < initialValue.indexOf(",") ? initialValue.replace(/\./g, "") : initialValue = initialValue.replace(/,/g, "");
            }
          } else {
            initialValue = initialValue.replace(new RegExp(Inputmask.escapeRegex(opts.groupSeparator), "g"), "");
          }

          if (opts.digits === 0) {
            if (initialValue.indexOf(".") !== -1) {
              initialValue = initialValue.substring(0, initialValue.indexOf("."));
            } else if (initialValue.indexOf(",") !== -1) {
              initialValue = initialValue.substring(0, initialValue.indexOf(","));
            }
          }

          if (opts.radixPoint !== "" && isFinite(opts.digits) && initialValue.indexOf(opts.radixPoint) !== -1) {
            /*
            var valueParts = initialValue.split(opts.radixPoint),
              decPart = valueParts[1].match(new RegExp("\\d*"))[0];
            if (parseInt(opts.digits) < decPart.toString().length) {
              var digitsFactor = Math.pow(10, parseInt(opts.digits));
              //make the initialValue a valid javascript number for the parsefloat
              initialValue = initialValue.replace(Inputmask.escapeRegex(opts.radixPoint), ".");
              initialValue = Math.round(parseFloat(initialValue) * digitsFactor) / digitsFactor;
              initialValue = initialValue.toString().replace(".", opts.radixPoint);
            }
            */

            //
            // Override rounding and truncate instead
            var radixPosition = initialValue.indexOf(opts.radixPoint);

            //console.log('radixPosition', radixPosition);
            //console.log('pre initialValue', initialValue);

            if (radixPosition >= 0) {
              if (initialValue.length - radixPosition > 3) {
                initialValue = initialValue.substr(0, radixPosition + 4);
              }
            }

            //console.log('post initialValue', initialValue);
            //
            //
          }

          // if (opts.numericInput === true) {
          // 	initialValue = initialValue.split("").reverse().join("");
          // }
          return initialValue;
        }
      });

      this.setMask();
    }

  });
});