define("pretty-nuts/services/backend", ["exports", "pretty-nuts/constants", "pretty-nuts/adapters/deferred-result"], function (exports, _constants, _deferredResult) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    /**
     * UI notifications service
     */
    notifications: Ember.inject.service('notification-messages'),
    session: Ember.inject.service(),

    /**
     * Configuration attributes
     */
    // urlPrefix: '/api',
    urlPrefix: _constants.Constants.API_PREFIX,

    /**
     * Easy getter for notification service for displaying errors
     *
     * @returns {*}
     * @private
     */
    _getNotifications: function _getNotifications() {
      return Ember.Service.prototype.get.call(this, 'notifications');
    },
    _urlPrefix: function _urlPrefix() {
      return Ember.Service.prototype.get.call(this, 'urlPrefix');
    },
    _get: function _get(name) {
      return Ember.get(this, name);
    },


    /**
     * Raw Ember ajax request
     *
     * overrides error and success callbacks with custom wrappers
     * that will call cb after done
     *
     * @param args Ember.$.ajax() method parameters
     */
    request: function request(args) {
      var that = this;
      var cb = args["cb"];

      var session = this._get('session');

      var prefix = this._get('urlPrefix');

      if (!args['url'].startsWith(prefix)) {
        args["url"] = prefix + args["url"];
      }

      // Set the content-type to application/json if not GET and has data
      if ('get'.toUpperCase() !== args['type'].toUpperCase()) {
        if (args['data'] && !args['contentType']) {
          args['contentType'] = 'application/json';
        }
      }

      // Override the success callback for optional shim
      args["success"] = function (response) {
        // If user provided callback, callback with response
        if (cb) {
          cb(false, response);
        }
      };

      return new Ember.RSVP.Promise(function (resolve, reject) {

        return session.authorize('authorizer:aquorn-authorizer', function (sessionName, sessionValue) {

          var headers = args.headers || {};
          headers[sessionName] = sessionValue;

          args.headers = headers;

          // Make the ajax call
          return Ember.$.ajax(args).done(function (data, textStatus, jqXHR) {

            if (data.responseCode === 202) {
              var operation_meta = data.meta.operation;
              return new _deferredResult.default([operation_meta]);
            }

            // On success, just pass along the data down the chain
            resolve(data);
          }).fail(function (jqXHR, textStatus, errorThrown) {

            var notifications = that._getNotifications();

            // If we got a JSON response
            if (jqXHR.responseJSON) {
              var error = jqXHR.responseJSON;
              var code = 0;
              var errid = null;
              var message = "";

              //console.log(JSON.stringify(error));

              if (error.responseCode) {
                // If we got an 1812 explicit error message
                errid = error.responseId;
                code = error.responseCode;
                message = error.responseMessage;
              } else {
                // If we got a default Spring error message (ie... 404)
                code = error.status;
                message = error.error;
              }

              var content = "errid: " + errid + "\ncode: " + code + "\nmessage: " + message;

              // Notify the user through the UI of the error
              //notifications.error(errid + " " + code + ": " + message);
              notifications.error(content);
            } else {
              // TODO: handle generic errors
            }

            reject(jqXHR, textStatus, errorThrown);
          });
        });
      });
    },
    get: function get(args) {
      args['type'] = 'GET';
      return this.request(args);
    },
    post: function post(args) {
      args['type'] = 'POST';
      return this.request(args);
    },
    put: function put(args) {
      args['type'] = 'PUT';
      return this.request(args);
    },
    delete: function _delete(args) {
      args['type'] = 'DELETE';
      return this.request(args);
    },
    patch: function patch(args) {
      args['type'] = 'PATCH';
      return this.request(args);
    }
  });
});