define("pretty-nuts/transforms/enum", ["exports", "ember-data", "pretty-nuts/constants"], function (exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized, options) {
      return _constants.Constants.getJsonToEnum(options.type, serialized);
    },
    serialize: function serialize(deserialized) {
      if (!deserialized) {
        return null;
      }

      return deserialized.jsonValues[0];
    }
  });
});