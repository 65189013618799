define('pretty-nuts/models/user-other-info', ['exports', 'ember-data', 'pretty-nuts/mixins/aquorn-embed-model'], function (exports, _emberData, _aquornEmbedModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_aquornEmbedModel.default, {
    profile: _emberData.default.attr('embedded-model', { modelName: 'user-other-info-profile', generateId: true }),
    initialSetup: _emberData.default.attr(),
    betaAcknowledgement: _emberData.default.attr()
  });
});