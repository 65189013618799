define('pretty-nuts/controllers/obligations', ['exports', 'pretty-nuts/utils', 'pretty-nuts/db'], function (exports, _utils, _db) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    groups: ['General Fund', 'Enterprise - Airport', 'Enterprise - Water', 'C.F. Districts', ''],
    locations: ['State of California', 'County of Sacramento', 'City of Sacramento'],
    lenses: ['Overview', 'Cashflow', 'Call Options', 'Events', 'Documents', 'Service Providers'],
    longName: 'THIS NAME IS LONG',
    notifications: Ember.inject.service('notification-messages'),
    orgsapi: Ember.inject.service(),
    obligationsapi: Ember.inject.service(),
    sortProperties: ['name'],
    sortAscending: false,
    desc: true,
    activeColumn: '',
    finalMaturity: '07/01/2041',
    routeName: 'Obligations',
    stacked: {
      x: 'x',
      columns: [['x', '2016-01-01', '2017-01-01', '2018-01-01', '2019-01-01', '2020-01-01', '2021-01-01', '2022-01-01', '2023-01-01', '2024-01-01', '2025-01-01', '2026-01-01', '2027-01-01', '2028-01-01', '2029-01-01', '2030-01-01', '2031-01-01', '2032-01-01', '2033-01-01', '2034-01-01', '2035-01-01', '2036-01-01', '2037-01-01', '2038-01-01', '2039-01-01', '2040-01-01', '2041-01-01'], ['Principal Paid', 70.74, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], ['Interest Paid', 182.65, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], ['Principal', 23.58, 99.72, 105.6, 110.56, 109.92, 127.52, 135.6, 123.32, 133.98, 141.68, 148.82, 123.34, 101.815, 136.92, 144.375, 152.53, 161.14, 170.135, 154.05, 135.625, 114.7, 75.775, 60.838, 33.83, 39.99, 33.62], ['Interest', 60.88423, 239.9321, 235.6255, 230.8459, 225.5679, 220.475, 214.2985, 207.7033, 189.0759, 169.8135, 162.6329, 154.9154, 148.3398, 142.815, 135.3618, 127.215, 118.6056, 109.5077, 85.6275, 64.10375, 45.09095, 24.08963, 15.05883, 6.118688, 4.2086, 1.9526], ['Accretted', 0, 0, 3.15, 3.52, 3.77, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
      type: 'bar',
      groups: [['Principal', 'Principal Paid', 'Interest', 'Interest Paid', 'Accretted']],
      onclick: function onclick(a) {
        // alert('Item ' + a.index + ' of value ' + a.value);
      }
    },
    zoom: {
      enabled: false,
      onzoom: function onzoom(domain) {
        console.log(this, domain);
      }
    },
    tooltip: {
      format: {
        title: function title(d) {
          return 'Data ' + d;
        },
        value: function value(_value, ratio, id) {
          var format = d3.format('$');
          return format(_value);
        }
        // value: d3.format(',') // apply this format to both y and y2

      }
    },
    axis: {
      x: {
        type: 'timeseries',
        tick: {
          format: '%Y'
        }
      }
    },
    color: {
      pattern: ['#F9AB6B', // Orange Fade
      '#d3d3d3', // Grey Fade
      '#E46C0A', // Aquorn Orange
      '#7e7e7e', // Grey
      '#50AB85', // Ocean Green
      '#3fc33f', // Green Fade
      '']
    },
    sortedModel: Ember.computed.sort('model', 'sortProperties'),
    previewModel: {},
    previewEvents: {},
    openRow: '',

    draftObligations: [],
    finalObligations: [],

    obligationsObserver: function () {
      var obligations = this.get('model.obligations');
      var draftObligations = [];
      var finalObligations = [];

      if (obligations) {
        obligations.forEach(function (obligation) {
          switch (obligation.get('status')) {
            case 0:
              {
                draftObligations.push(obligation);
                break;
              }

            case 2:
              {
                finalObligations.push(obligation);
                break;
              }

            default:
              {
                console.log("Placing obligation in final for unknown status: ", obligation.get('status'));
              }
          }
        });
      }

      this.set('draftObligations', draftObligations);
      this.set('finalObligations', finalObligations);
    }.observes('model.obligations').on('init'),

    runEveryTime: function () {
      var count = 0;
      Ember.run.schedule('afterRender', this, function () {
        var controller = this;
        Em.$('table.obligations-table').on('click', 'tbody tr', function () {
          var oid = $(this).attr('data-oid');
          var obligation = _db.default.getTable('obligations').find(function (o) {
            return o.Id === oid;
          });
          var events = _db.default.getTable('events').find(function (e) {
            return e.Id === oid;
          });
          controller.set('previewModel', obligation);
          controller.set('previewEvents', events);

          $('.simple-panel').insertAfter(this);
        }).floatThead({ top: 57 });
      });
      this.set('entered', false);
    }.observes('entered'),

    actions: {
      notifyMe: function notifyMe() {
        // Info
        this.get('notifications').info('You have one unread message');

        // Error
        this.get('notifications').error('Something went wrong');

        // Success
        this.get('notifications').success('Saved successfully!', { autoClear: true });

        // Warning
        this.get('notifications').warning('You have unsaved changes');
      },
      goToItem: function goToItem(item) {
        alert('I\'d navigate to ' + item + ' page');
      },
      secondaryActions: function secondaryActions(item) {
        alert('Actions to take on ' + item + ' item');
      },
      sortBy: function sortBy(property) {
        this.set('sortProperties', [property]);
        this.toggleProperty('sortAscending');
        this.set('model', this.get('sortedModel')); // set the model to the sorted array
      },
      sortColumn: function sortColumn(prop) {
        this.set('desc', !this.get('desc'));
        this.set('activeColumn', prop);
        var model = this.get('model').sort(_utils.ArrayComparer.property(prop, this.get('desc')));
        this.set('model', model.slice());
      },
      foo: function foo() {
        console.log(this);
      },
      actionDeleteObligation: function actionDeleteObligation(obligationId) {
        var that = this;
        var obligationsapi = this.get('obligationsapi');
        obligationsapi.deleteObligation(obligationId, function (err, data) {
          if (!err) {
            that.get('notifications').success("Obligation deleted", { autoClear: true });
            that.getCurrentOrganizationObligations();
          } else {
            that.get('notificatinos').error(data);
          }
        });
      }
    }
  });
});