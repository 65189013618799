define('pretty-nuts/controllers/obligation/overview', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    obligationService: Ember.inject.service(),
    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        $('.obligations-table').floatThead({ top: 66 });
        window.setTimeout(function () {
          window.scrollTo(0, 1);
        }, 100);
      });
      this.set('entered', false);
    }.observes('entered'),

    isVariableRate: Ember.computed('model.structureType', function () {
      console.log('IS VARIABLE', this.get('model.obligation.structureType'));
      console.log('CONSTANT', _constants.Constants.ObligationStructure.VARIABLE_RATE);
      return _constants.Constants.ObligationStructure.VARIABLE_RATE === this.get('model.obligation.structureType');
    }),

    officialStatementUrl: Ember.computed('model.documents', function () {
      var documents = this.get('model.documents');
      console.log('DOC length', documents.content.length);
      for (var i = 0; i < documents.content.length; i++) {
        console.log('DOCUMENT', i, documents.content[i].__data.documentCategory);
        if (documents.content[i].__data.documentCategory == "Official Statements") {
          return "https://test.1812.io/resource/" + documents.content[i].id;
          break;
        }
      }
      return false;
    })
  });
});