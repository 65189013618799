define('pretty-nuts/routes/trends/county-overview', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/db'], function (exports, _aquornAuthenticatedRouteMixin, _db) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function tableToC3(table, trans) {
    var keys = [];
    for (var key in table[0]) {
      if (key !== 'Total') {
        keys.push(key);
      }
    }
    return keys.map(function (key) {
      var array = [key];
      table.forEach(function (row) {
        array.push(trans(key, row[key]));
      });
      return array;
    });
  }

  function yearTrans(key, value) {
    if (key === 'Year') return value + '-1-1';
    if (value === '-') return '0';
    return value.replace ? value.replace('$', '').replace(/,/g, '') : value;
  }

  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model(params) {
      console.log(tableToC3(_db.default.getTable('county-overview-1'), yearTrans));
      return {
        id: params.oid,
        obligation: _db.default.getTable('obligations'),
        use: _db.default.getTable('county-overview-1'),
        tax: _db.default.getTable('county-overview-2'),
        pi: _db.default.getTable('county-overview-3'),
        purpose: _db.default.getTable('county-overview-4'),
        graph_use: {
          x: 'Year',
          columns: tableToC3(_db.default.getTable('county-overview-1'), yearTrans),
          type: 'area-spline',
          groups: [['Airports', 'GeneralPurpose', 'GovernmentBuildings', 'MassTransit', 'FireStationsEquipment', 'ParkingFacilities', 'ParksZoosBeaches', 'SolidWaste', 'WaterSewerFacilities']]
        },
        graph_tax_status: {
          x: 'Year',
          columns: tableToC3(_db.default.getTable('county-overview-2'), yearTrans),
          type: 'area-spline',
          groups: [['TaxExempt', 'Taxable']]
        },
        graph_pi: {
          x: 'Year',
          columns: tableToC3(_db.default.getTable('county-overview-3'), yearTrans),
          type: 'area-spline',
          groups: [['Principal', 'Interest', 'Accretted']]
        },
        graph_purpose: {
          x: 'Year',
          columns: tableToC3(_db.default.getTable('county-overview-4'), yearTrans),
          type: 'area-spline',
          groups: [['NewMoney', 'Refunding']]
        }
      };
    }
  });
});