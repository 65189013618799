define('pretty-nuts/routes/issuer/debt-overview', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
		model: function model(params) {
			return null;
		}
	});
});