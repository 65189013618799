define('pretty-nuts/serializers/document-view', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'documentId',

    attrs: {
      resource: 'resourceId'
    }
  });
});