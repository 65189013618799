define("pretty-nuts/services/usersapi", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    /**
     * Backend request wrapper
     */
    store: Ember.inject.service(),
    backend: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    getUserBasicProfiles: function getUserBasicProfiles(userIds, cb) {
      this.get("backend").request({
        type: "GET",
        url: "/api/users/profiles",
        data: { userIds: userIds.join() },
        cb: cb
      });
    },
    getCurrentUserPendingRoleRequests: function getCurrentUserPendingRoleRequests(cb) {
      this.get("backend").request({
        type: "GET",
        url: "/api/users/roles/pending",
        cb: cb
      });
    },
    addNewUser: function addNewUser(newUser) {
      var newRoleRequest = this.get('store').createRecord('new-user', newUser);
      return newRoleRequest.save().then(function () {
        return newRoleRequest;
      });
    },
    addUserWorkHistory: function addUserWorkHistory(employment, cb) {
      this.get("backend").request({
        type: "PUT",
        url: "/api/users/employment",
        contentType: "application/json",
        data: JSON.stringify(employment),
        cb: cb
      });
    },
    deleteUserWorkHistory: function deleteUserWorkHistory(employmentId, cb) {
      this.get("backend").request({
        type: "DELETE",
        url: "/api/users/employment",
        contentType: "application/json",
        cb: cb
      });
    },
    generateError: function generateError(cb) {
      this.get("backend").request({
        type: "GET",
        url: "/api/obligation/error",
        cb: cb
      });
    },
    uploadAvatar: function uploadAvatar(formData, userId) {
      var currentUser = this.get('currentUser');
      var form = new FormData(formData.get(0));
      var resourceInit = {
        userId: currentUser.getUserId(),
        resourceType: 'AVATAR'
      };
      var resource = this.get('store').createRecord('resource', resourceInit);
      return this.get('store').findRecord('user', userId).then(function (user) {

        return resource.save().then(function () {

          return Ember.$.ajax({
            method: 'POST',
            url: '/api/upload/uploadResource?resourceId=' + resource.get('id'),
            data: form,
            processData: false,
            contentType: false
          }).then(function () {

            return resource.reload().then(function () {

              user.set('profileImage', resource);
              return user.save();
            });
          });
        });
      });
    }
  });
});