define('pretty-nuts/routes/government/service-providers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor('government');
      return model;

      // return Ember.RSVP.hash({
      //   //serviceProviders: this.get('store').query('service-provider', {organizationId: model.organizationId}),
      //   serviceProviderSummaries: this.get('store').query('service-provider-summary', {governmentId: model.organizationId})
      //
      // }).then((hash) => {
      //
      //   //model.serviceProviders = hash.serviceProviders;
      //
      //   Ember.set(model, 'serviceProviderSummaries', Ember.get(hash, 'serviceProviderSummaries'));
      //   //model.serviceProviderSummaries = hash.serviceProviderSummaries;
      //
      //   return model;
      // });

      // return this.get('store').query('service-provider', {
      //   organizationId: model.organizationId
      // }).then((serviceProviders) => {
      //   model.serviceProviders = serviceProviders;
      //
      //   return model;
      // });
    }
  });
});