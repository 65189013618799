define('pretty-nuts/routes/logout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    session: Ember.inject.service(),

    afterModel: function afterModel() {
      var route = this;

      console.log('Logging out...');
      this.get('session').invalidate().then(function () {
        console.log('Logged out, transitioning to login page...');
        route.transitionTo('login');
      });
    }
  });
});