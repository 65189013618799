define('pretty-nuts/constants', ['exports', 'pretty-nuts/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Constants = undefined;


  var domainName = window.location.hostname;
  var bucket = _environment.default.APP.RESOURCE_LOCATION;

  console.log('domainName', domainName);

  if (domainName.endsWith(".1812.io")) {
    var subDomainName = domainName.split(".1812.io")[0];

    bucket = 'aquorn-1812-resources-';
    switch (subDomainName) {
      case "test":
        bucket += 'test';break;
      case "demo":
        bucket += 'demo';break;
      case "beta":
        bucket += 'beta';break;
      case "us1":
        bucket += 'us1';break;
      default:
        bucket += 'test';
    }

    console.log("bucketName:" + bucket);
  } else {
    bucket = 'aquorn-1812-resources-test';
  }

  var API_PREFIX = '/api';

  var Constants = exports.Constants = {

    AUTHORIZER_NAME: 'authorizer:aquorn-authorizer',

    RESOURCE_LOCATION: 'https://s3.amazonaws.com/' + bucket,
    API_PREFIX: API_PREFIX,

    FIXED_RATE: "Fixed Rate",
    VARIABLE_RATE: "Variable Rate",
    STEPPED_COUPON: "Stepped",
    CAPITAL_APPRECIATION: "Capital Appreciation",

    SERIAL: "Serial",
    TERM: "Term",
    BULLET: "Bullet",

    DAILY: "Daily",
    WEEKLY: "Weekly",
    MONTHLY: "Monthly",
    QUARTERLY: "Quarterly",
    SEMI_ANNUAL: "Semi-Annual",
    ANNUAL: "Annual",

    KEY_ESCAPE: 27,
    KEY_ENTER: 13,
    KEY_DOWN: 40,
    KEY_UP: 38,
    KEY_LEFT: 37,
    KEY_RIGHT: 39,

    DEPOSITORY: 'Depository',
    PAYING_AGENT: 'Paying Agent',
    TRUSTEE: 'Trustee',
    BOND_COUNSEL: 'Bond Counsel',
    FINANCIAL_ADVISOR: 'Financial Advisor',
    LEAD_UNDERWRITER: 'Lead Underwriter',
    UNDERWRITER_MEMBER: 'Underwriter Member',
    ARBITRAGE_REBATE_AGENT: 'Arbitrage Rebate Agent',
    DISSEMINATION_AGENT: 'Dissemination Agent',
    UNDERWRITERS_COUNSEL: 'Underwriter\'s Counsel',
    DISCLOSURE_COUNSEL: 'Disclosure Counsel',
    SPECIAL_TAX_COUNSEL: 'Special Tax Counsel',
    REMARKETING_AGENT: 'Remarketing Agent',
    VERIFICATION_AGENT: 'Verification Agent',
    OBLIGOR: 'Obligor',
    ISSUER: 'Issuer',
    CREDIT_ENHANCER: 'Credit Enhancer',
    ESCROW_AGENT: 'Escrow Agent',
    AUDITOR: 'Auditor',
    GENERAL_COUNCEL: 'General Counsel',
    FEASIBILITY_CONSULTANT: 'Feasibility Consultant',
    FISCAL_CONSULTANT: 'Fiscal Consultant',
    // DISSEMINATION_AGENT: 'Dissemination Agent',


    FINANCE_TEAM: 'Finance Team',
    UNDERWRITER: 'Underwriter',
    OTHER: 'Other',

    UNDERWRITER_DISCOUNT: 'Underwriter\'s Discount',
    UNDERWRITER_DETAIL: 'Underwriter\'s Detail',
    UNDERWRITER_EXPENSE: 'Underwriter\'s Expenses',

    COST_OF_ISSUANCE: 'Cost of Issuance',

    PRIMARY_USE: 'Primary Use',

    ARBITRAGE_REBATE_REPORT: 'Arbitrage Rebate Report',
    AUDITED_FINANCIALS: 'Audited Financials',
    BOND_ISSUE: 'Bond Issue',
    CAFR: 'CAFRs',
    CLOSING_REPORT: 'Closing Report',
    OFFICIAL_STATEMENT: 'Official Statement',
    PRELIMINARY_OFFICIAL_STATEMENT: 'Preliminary Official Statement',
    REFUNDING_ANALYSIS: 'Refunding Analyses',

    /////////////////
    // Document types

    // ARBITRAGE_REBATE_REPORT: 'Arbitrage Rebate Report',
    // OFFICIAL_STATEMENT: 'Official Statement',


    // Document types
    /////////////////

    ////////////////
    // Rating Agency

    SHORT_TERM: 'Short Term',
    LONG_TERM: 'Long Term',

    // Rating Agency
    ////////////////

    //////////////////
    // Operation Types

    OperationTypes: {},

    // Operation Types
    //////////////////

    _ValuesMap: {},
    //_ComplexEnums: [Constants.Frequency],

    Endpoints: {
      RESOURCE_UPLOAD: API_PREFIX + '/upload/uploadResource'
    },

    UsesOfFunds: {
      "Airports": ["Airports"],
      "Combined Utilities": ["Combined Utilities"],
      "Economic Development": ["Economic Development"],
      "Education": ["Primary & Secondary Education", "Higher Education", "Libraries & Museums", "Other Education"],
      "Public Power": ["General Power", "Renewable Facilities"],
      "General Purpose": ["Agriculture", "Churches, Temples & Mosques", "Civic & Convention Centers", "Correctional Faclities", "Fire Stations & Equipment", "General Purpose", "Government Buildings", "Parks, Zoos & Beaches", "Other Recreation", "Police Stations & Equipment", "Stadiums & Sports Complexes", "Telecommunications", "Theatres", "Veterans (Excluding Housing)"],
      "Healthcare": ["General Medical", "General Acute Care Hospital", "Childrens Hospital", "Single Specialty Hospital", "Hospital Equipment Loans"],
      "Housing": ["Single Family", "Multi-Family"],
      "Industrial Development": ["Industrial Development", "Office Buildings"],
      "Nursing Homes/Life care": ["Assisted Living", "Continuous Care Retirement Facilities", "Nursing Homes"],
      "Pollution Control": ["Pollution Control"],
      "Seaports/Marine Terminals": ["Seaports/Marine Terminals"],
      "Solid Waste/Resource Recovery": ["Recycling", "Sanitation", "Solid Waste"],
      "Student Loans": ["Student Loans"],
      "Transportation": ["Bridges", "Mass Transportation", "Parking Facilities", "Highways & Streets", "Toll Roads", "Tunnels", "Other Transportation"],
      "Water, Sewer & Gas Facilities": ["Flood Control", "Gas", "Water & Sewer Facilities"]
    },

    CommandOperationProcessors: {
      PerspectiveCalculator: 'Calculate_Perspective',
      ObligationUpdated: 'Obligation_Updated'
    },

    ValueType: {
      STRING: {
        jsonValues: ['STRING'],
        queryValue: ['STRING'],
        display: 'String'
      },
      MONEY: {
        jsonValues: ['MONEY'],
        queryValue: ['MONEY'],
        display: 'Money'
      },
      DECIMAL: {
        jsonValues: ['DECIMAL'],
        queryValue: ['DECIMAL'],
        display: 'Decimal'
      }
    },

    ColumnValueType: {
      STRING: {
        jsonValues: ['String'],
        queryValue: ['STRING'],
        display: 'String'
      },
      MONEY: {
        jsonValues: ['Money'],
        queryValue: ['MONEY'],
        display: 'Money'
      },
      DECIMAL: {
        jsonValues: ['Decimal'],
        queryValue: ['DECIMAL'],
        display: 'Decimal'
      },
      DATE: {
        jsonValues: ['Date'],
        queryValue: ['DATE'],
        display: 'Date'
      }
    },

    DayBasis: {
      DB_30_360: {
        // jsonValues: ['30/360', 'Thirty_360'],
        jsonValues: ['Thirty_360', '30/360'],
        queryValue: 'Thirty_360',
        display: '30/360'
      },
      DB_ACTUAL_360: {
        // jsonValues: ['Actual/360', 'Actual_360'],
        jsonValues: ['Actual_360', 'Actual/360'],
        queryValue: 'Actual_360',
        display: 'Actual/360'
      },
      DB_ACTUAL_365: {
        // jsonValues: ['Actual/365', 'Actual_365'],
        jsonValues: ['Actual_365', 'Actual/365'],
        queryValue: 'Actual_365',
        display: '30/365'
      },
      DB_ACTUAL_ACTUAL: {
        jsonValues: ['Actual_Actual', 'Actual/Actual'],
        queryValue: 'Actual_Actual',
        display: 'Actual/Actual'
      }
    },

    ObligationStatus: {
      PENDING: {
        jsonValues: ['Pending'],
        queryValue: 'PENDING',
        display: 'Pending'
      },

      OUTSTANDING: {
        jsonValues: ['Outstanding'],
        queryValue: 'OUTSTANDING',
        display: 'Outstanding'
      },

      RETIRED: {
        jsonValues: ['Retired'],
        queryValue: 'RETIRED',
        display: 'Retired'
      },

      REFUNDED: {
        jsonValues: ['Refunded'],
        queryValue: 'REFUNDED',
        display: 'Refunded'
      },

      PARTIALLY_REFUNDED: {
        jsonValues: ['Partially Refunded'],
        queryValue: 'PARTIALLY_REFUNDED',
        display: 'Partially Refunded'
      },

      SOLD: {
        jsonValues: ['Sold'],
        queryValue: 'SOLD',
        display: 'Sold'
      }
    },

    SecurityStatus: {

      OUTSTANDING: {
        jsonValues: ['OUTSTANDING'],
        queryValue: 'OUTSTANDING',
        display: 'Outstanding'
      },

      MATURED: {
        jsonValues: ['MATURED'],
        queryValue: 'MATURED',
        display: 'Matured'
      },

      REFUNDED: {
        jsonValues: ['REFUNDED'],
        queryValue: 'REFUNDED',
        display: 'Refunded'
      },

      PARTIALLY_REFUNDED: {
        jsonValues: ['PARTIALLY_REFUNDED'],
        queryValue: 'PARTIALLY_REFUNDED',
        display: 'Partially Refunded'
      }
    },

    TaxStatus: {
      TAXABLE: {
        jsonValues: ['Taxable'],
        queryValue: 'Taxable',
        display: 'Taxable'
      },

      TAX_EXEMPT: {
        jsonValues: ['Tax-Exempt', 'Tax_Exempt'],
        queryValue: 'Tax_Exempt',
        display: 'Tax-Exempt'
      }
    },

    SecurityPledge: {
      GENERAL_OBLIGATION: {
        jsonValues: ['General Obligation', 'General_Obligation'],
        queryValue: 'General_Obligation',
        display: 'General Obligation'
      },

      REVENUE: {
        jsonValues: ['Revenue'],
        queryValue: 'Revenue',
        display: 'Revenue'
      }
    },

    ObligationPurpose: {
      NEW_MONEY: {
        jsonValues: ['New Money', 'New_Money'],
        queryValue: 'New_Money',
        display: 'New Money'
      },

      REFUNDING: {
        jsonValues: ['Refunding'],
        queryValue: 'Refunding',
        display: 'Refunding'
        // disabled: true
      },

      MIXED_USE: {
        jsonValues: ['Mixed_Use'],
        queryValue: 'Mixed_Use',
        display: 'Mixed Use'

        // disabled: true
      }
    },

    ObligationStructure: {
      FIXED_RATE: {
        jsonValues: ['Fixed Rate', 'Fixed_Rate'],
        queryValue: 'Fixed_Rate',
        display: 'Fixed Rate'
      },
      VARIABLE_RATE: {
        jsonValues: ['Variable Rate', 'Variable_Rate'],
        queryValue: 'Variable_Rate',
        display: 'Variable Rate'
      },
      CAPITAL_APPRECIATION: {
        jsonValues: ['Capital Appreciation', 'Capital_Appreciation'],
        queryValue: 'Capital_Appreciation',
        display: 'Capital Appreciation',
        disabled: true
      }
    },

    Frequency: {
      DAILY: {
        jsonValues: ['DAILY'],
        queryValue: 'DAILY',
        display: 'Daily'
      },
      WEEKLY: {
        jsonValues: ['WEEKLY'],
        queryValue: 'WEEKLY',
        display: 'Weekly'
      },
      MONTHLY: {
        jsonValues: ['MONTHLY'],
        queryValue: 'MONTHLY',
        display: 'Monthly'
      },
      QUARTERLY: {
        jsonValues: ['QUARTERLY'],
        queryValue: 'QUARTERLY',
        display: 'Quarterly'
      },
      SEMI_ANNUAL: {
        jsonValues: ['SEMI-ANNUALLY', 'SEMI_ANNUALLY', 'SEMI-ANNUAL'],
        queryValue: 'SEMI_ANNUALLY',
        display: 'Semi-Annual'
      },
      ANNUAL: {
        jsonValues: ['ANNUAL'],
        queryValue: 'ANNUALLY',
        display: 'Annual'
      },
      ACTUAL: {
        jsonValues: ['ACTUAL'],
        queryValue: 'ACTUAL',
        display: 'Actual'
      }
    },

    CallOptionAvailability: {
      CALL_DATE: {
        jsonValues: ['Call Date', 'Call_Date'],
        queryValue: 'Call_Date',
        display: 'Call Date'
      },
      ANY_DATE_AFTER: {
        jsonValues: ['Any Date After', 'Any_Date_After'],
        queryValue: 'Any_Date_After',
        display: 'Any Date After'
      },
      ANY_INTEREST_PAYMENT: {
        jsonValues: ['Any Interest Payment', 'Any_Interest_Payment'],
        queryValue: 'Any_Interest_Payment',
        display: 'Any Interest Payment'
      }
    },

    CallOptionType: {
      OPTIONAL_CALL: {
        jsonValues: ['Optional Call', 'Optional_Call'],
        queryValue: 'Optional_Call',
        display: 'Optional Call'
      },
      MANDATORY_CALL: {
        jsonValues: ['Mandatory Call', 'Mandatory_Call'],
        queryValue: 'Mandatory_Call',
        display: 'Mandatory Call'
      }
    },

    SecurityType: {
      SERIAL: {
        jsonValues: ['Serial'],
        queryValue: 'Serial',
        display: 'Serial'
      },
      TERM: {
        jsonValues: ['Term'],
        queryValue: 'Term',
        display: 'Term'
      },
      STEPPED: {
        jsonValues: ['Stepped'],
        queryValue: 'Stepped',
        display: 'Stepped',
        disabled: true
      },
      BULLET: {
        jsonValues: ['Bullet'],
        queryValue: 'Bullet',
        display: 'Bullet'
      },
      CAPITAL_APPRECIATION: {
        jsonValues: ['Capital_Appreciation', 'Capital Appreciation'],
        queryValue: 'Capital_Appreciation',
        display: 'Capital Appreciation',
        disabled: true
      }
    },

    CashflowView: {
      OUTSTANDING: {
        jsonValues: ['Outstanding'],
        queryValue: 'Outstanding',
        display: 'Outstanding'
      },
      REFUNDED: {
        jsonValues: ['Refunded'],
        queryValue: 'Refunded',
        display: 'Refunded Prior To Maturity'
      },
      PRIOR_REFUNDED: {
        jsonValues: ['PriorRefunded'],
        queryValue: 'PriorRefunded',
        display: 'Prior Obligations Refunded'
      },
      ORIGINAL: {
        jsonValues: ['Original'],
        queryValue: 'Original',
        display: 'Original'
      },
      PAID: {
        jsonValues: ['Paid'],
        queryValue: 'Paid',
        display: 'Paid'
      }
    },

    CommandOperationStatus: {
      RECEIVED: {
        jsonValues: ['Received'],
        queryValue: 'Received',
        display: 'Received'
      },
      COMPLETE: {
        jsonValues: ['Completed'],
        queryValue: 'Completed',
        display: 'Completed'
      },
      ERROR: {
        jsonValues: ['Error'],
        queryValue: 'Error',
        display: 'Error'
      }
    },

    AMTStatus: {
      AMT: {
        jsonValues: ['AMT'],
        queryValues: ['AMT'],
        display: 'AMT'
      },
      Non_AMT: {
        jsonValues: ['Non_AMT', 'Non-AMT'],
        queryValues: ['Non_AMT'],
        display: 'Non-AMT'
      }
    },

    Yes_No: {
      YES: {
        jsonValues: ['YES'],
        queryValues: ['YES'],
        display: 'Yes'
      },
      NO: {
        jsonValues: ['No'],
        queryValues: ['No'],
        display: 'No'
      }
    },

    SaleType: {
      COMPETITIVE: {
        jsonValues: ['Competitive'],
        queryValues: ['Competitive'],
        display: 'Competitive'
      },
      NEGOTIATED: {
        jsonValues: ['Negotiated'],
        queryValues: ['Negotiated'],
        display: 'Negotiated'
      }
    },

    OfferingType: {
      PUBLIC: {
        jsonValues: ['Public'],
        queryValues: ['Public'],
        display: 'Public'
      },
      PRIVATE: {
        jsonValues: ['Private'],
        queryValues: ['Private'],
        display: 'Private'
      }
    },

    Currency: {
      USD: {
        jsonValues: ['USD'],
        queryValues: ['USD'],
        display: 'USD'
      }
    },

    UseOfFundsType: {
      NONE: {
        jsonValues: ['None'],
        queryValues: ['NONE'],
        display: 'None'
      },
      COST_OF_ISSUANCE: {
        jsonValues: ['Cost_Of_Issuance'],
        queryValues: ['Cost_Of_Issuance'],
        display: 'Cost of Issuance'
      },
      UNDERWRITERS_DISCOUNT: {
        jsonValues: ['Underwriters_Discount'],
        queryValues: ['Underwriters_Discount'],
        display: 'Underwriter\'s Discount'
      },
      PRIMARY_USE: {
        jsonValues: ['Primary_Use'],
        queryValues: ['Primary_Use'],
        display: 'Primary Use'
      }
    },

    UseOfFundsSubType: {
      NONE: {
        jsonValues: ['None'],
        queryValues: ['NONE'],
        display: 'None'
      },
      OTHERS: {
        jsonValues: ['Others'],
        queryValues: ['OTHERS'],
        display: 'Others'
      },
      COSTS_OF_ISSUANCE: {
        jsonValues: ['Costs_Of_Issuance'],
        queryValues: ['COSTS_OF_ISSUANCE'],
        display: 'Costs of Issuance'
      },
      PROVIDER_COST_OF_ISSUANCE: {
        jsonValues: ['Provider_Cost_Of_Issuance'],
        queryValues: ['PROVIDER_COST_OF_ISSUANCE'],
        display: 'Provider Cost of Issuance'
      },
      UNDERWRITERS_EXPENSES: {
        jsonValues: ['Underwriters_Expenses'],
        queryValues: ['UNDERWRITERS_EXPENSES'],
        display: 'Underwriter\'s Expenses'
      },
      UNDERWRITERS_DETAILS: {
        jsonValues: ['Underwriters_Details'],
        queryValues: ['UNDERWRITERS_DETAILS'],
        display: 'Underwriter\'s Detail'
      }
    },

    ReportType: {
      PERIOD_DEBT_SERVICE: {
        jsonValues: ['PeriodDebtService'],
        queryValues: ['PeriodDebtService'],
        display: 'Period Debt Service'
      },
      THIRTY_YEAR_DEBT_REPORT: {
        jsonValues: ['ThirtyYearReport'],
        queryValues: ['ThirtyYearReport'],
        display: '30 Year Debt Report'
      }
    },

    Grouping: {
      STRUCTURE_TYPE: {
        jsonValues: ['StructureType'],
        queryValues: ['StructureType'],
        display: 'Structure Type'
      },
      SECURITY_PLEDGE: {
        jsonValues: ['SecurityPledge'],
        queryValues: ['SecurityPledge'],
        display: 'Security Pledge'
      },
      FEDERAL_TAX_STATUS: {
        jsonValues: ['FederalTaxStatus'],
        queryValues: ['FederalTaxStatus'],
        display: 'Federal Tax Status'
      },
      STATE_TAX_STATUS: {
        jsonValues: ['StateTaxStatus'],
        queryValues: ['StateTaxStatus'],
        display: 'State Tax Status'
      },
      PURPOSE: {
        jsonValues: ['Purpose'],
        queryValues: ['Purpose'],
        display: 'Purpose'
      },
      SALE_METHOD: {
        jsonValues: ['SaleMethod'],
        queryValues: ['SaleMethod'],
        display: 'Sale Method'
      },
      OFFERING_TYPE: {
        jsonValues: ['OfferingType'],
        queryValues: ['OfferingType'],
        display: 'Offering Type'
      },
      AMT_STATUS: {
        jsonValues: ['AMTStatus'],
        queryValues: ['AMTStatus'],
        display: 'AMT Status'
      },
      RATE_TYPE: {
        jsonValues: ['RateType'],
        queryValues: ['RateType'],
        display: 'Rate Type'
      },
      CALL_STATUS: {
        jsonValues: ['CallStatus'],
        queryValues: ['CallStatus'],
        display: 'Call Status'
      },
      OBLIGATION: {
        jsonValues: ['Obligation'],
        queryValues: ['Obligation'],
        display: 'Obligation'
      }
    },

    ResourceObjectType: {
      USER: {
        jsonValues: ['User'],
        queryValues: ['User'],
        display: ['User']
      },
      OBLIGATION: {
        jsonValues: ['Obligation'],
        queryValues: ['Obligation'],
        display: ['Obligation']
      },
      LEGAL_ENTITY: {
        jsonValues: ['LegalEntity'],
        queryValues: ['LegalEntity'],
        display: ['Legal Entity']
      },
      ORGANIZATION: {
        jsonValues: ['Organization'],
        queryValues: ['Organization'],
        display: ['Organization']
      }
    },

    OperationStatusResolver: {
      PERSPECTIVE_CALCULATED: {
        jsonValues: ['Calculate_Perspective'],
        queryValues: ['Calculate_Perspective'],
        display: ['Calculate Perspective']
      },
      //Calculate_Obligation_Cashflow,
      //     Calculate_Obligor_Cashflow,
      //     Calculate_Organization_Cashflow,
      CALCULATE_OBLIGATION_CASHFLOW: {
        jsonValues: ['Calculate_Obligation_Cashflow'],
        queryValues: ['Calculate_Obligation_Cashflow'],
        display: ['Calculate_Obligation_Cashflow']
      },
      CALCULATE_OBLIGOR_CASHFLOW: {
        jsonValues: ['Calculate_Obligor_Cashflow'],
        queryValues: ['Calculate_Obligor_Cashflow'],
        display: ['Calculate_Obligor_Cashflow']
      },
      CALCULATE_ORGANIZATION_CASHFLOW: {
        jsonValues: ['Calculate_Organization_Cashflow'],
        queryValues: ['Calculate_Organization_Cashflow'],
        display: ['Calculate_Organization_Cashflow']
      },
      CALCULATE_OBLIGOR_DEBT_PROFILE: {
        jsonValues: ['Calculate_Obligor_Debt_Profile'],
        queryValues: ['Calculate_Obligor_Debt_Profile'],
        display: ['Calculate_Obligor_Debt_Profile']
      },
      CALCULATE_ORGANIZATION_DEBT_PROFILE: {
        jsonValues: ['Calculate_Organization_Debt_Profile'],
        queryValues: ['Calculate_Organization_Debt_Profile'],
        display: ['Calculate_Organization_Debt_Profile']
      },
      BUILD_LEGAL_ENTITY_REPORT: {
        jsonValues: ['Build_Legal_Entity_Report'],
        queryValues: ['Build_Legal_Entity_Report'],
        display: ['Build_Legal_Entity_Report']
      },
      UPLOAD_AVATAR: {
        jsonValues: ['Upload_Avatar'],
        queryValues: ['Upload_Avatar'],
        display: ['Upload_Avatar']
      },
      DELETE_DOCUMENT: {
        jsonValues: ['Delete_Document'],
        queryValues: ['Delete_Document'],
        display: ['Delete_Document']
      },
      UPLOAD_DOCUMENT: {
        jsonValues: ['Upload_Document'],
        queryValues: ['Upload_Document'],
        display: ['Upload_Document']
      }
    },

    //   Public ("Public"),
    // Private ("Private");

    Enumeration: {
      ALL: {
        jsonValues: [],
        queryValue: null,
        display: 'All'
      }

    },

    getEnumDisplayToValue: function getEnumDisplayToValue(e, v) {
      return e.Display[v];
    },
    getJsonToEnum: function getJsonToEnum(e, v) {
      var enumValue = null;

      //console.log('keys', Object.keys(e));

      Object.keys(e).forEach(function (key) {

        var ev = e[key];

        if (ev.jsonValues.includes(v)) {
          enumValue = ev;
        }
      });

      return enumValue;
    },
    getEnumDisplayValues: function getEnumDisplayValues(e) {
      var displayValues = [];

      var keys = Object.keys(e);

      keys.forEach(function (key) {
        displayValues.push(e[key].display);
      });

      return displayValues;
    },
    getEnumValues: function getEnumValues(e) {
      var values = [];

      var keys = Object.keys(e);

      keys.forEach(function (key) {
        values.push(e[key]);
      });

      return values;
    }
  };
});