define('pretty-nuts/controllers/government/cashflow', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),

    obligationService: Ember.inject.service(),

    filterResults: {
      obligations: null
    },

    filter: {},

    query: {
      resolution: _constants.Constants.Frequency.ANNUAL
    },

    debtService: null,

    runEveryTime: function () {
      var count = 0;
      Ember.run.schedule('afterRender', this, function () {
        $('.obligations-table').floatThead({ top: 66 });
        var controller = this;
      });
      this.set('entered', false);
    }.observes('entered'),

    actions: {}
  });
});