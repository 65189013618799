define('pretty-nuts/controllers/aquorn-admin/upgrade', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    backend: Ember.inject.service(),

    responseValue: null,

    actions: {
      actionDeleteRefundingOrphans: function actionDeleteRefundingOrphans() {
        var _this = this;

        this.get('backend').post({
          url: '/upgrade/deleteRefundingOrphans'
        }).then(function (response) {
          //console.log('response', response);
          _this.set('responseValue', response.data);
        });
      },
      actionObligationsRecalculate: function actionObligationsRecalculate() {
        var _this2 = this;

        this.set('responseValue', 'Running...');

        this.get('backend').post({
          url: '/upgrade/obligationRecalculate'
        }).then(function (response) {
          //console.log('response', response);
          _this2.set('responseValue', response.data);
        });
      }
    }
  });
});