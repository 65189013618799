define('pretty-nuts/routes/trends/state-historical', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/db'], function (exports, _aquornAuthenticatedRouteMixin, _db) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model(params) {
      return {
        id: params.oid,
        historical: _db.default.getTable('state-overview-7')
      };
    }
  });
});