define("pretty-nuts/controllers/onboarding/refunding", ["exports", "pretty-nuts/constants", "accounting"], function (exports, _constants, _accounting) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    obligationService: Ember.inject.service(),
    onboarding: Ember.inject.controller(),

    Constants: _constants.Constants,

    updateChecked: function () {

      var isChecked = {};
      var refundedDebtDetermination = this.get('model.refundedDebtDetermination.value');

      if (refundedDebtDetermination) {
        var obligationRefundDeterminations = refundedDebtDetermination.get('obligationRefundDeterminations');

        // Iterate sorted obligation refund determinations
        Object.keys(obligationRefundDeterminations).forEach(function (ord) {
          // console.log('ord', ord, obligationRefundDeterminations[ord]);
          var securityRefundDeterminations = Ember.get(obligationRefundDeterminations[ord], 'securityRefundDeterminations');
          var key = Ember.get(obligationRefundDeterminations[ord], 'obligationId');
          isChecked[key] = false;
          // Iterate sorted security refund determinations
          Object.keys(securityRefundDeterminations).forEach(function (srd) {
            var key = Ember.get(securityRefundDeterminations[srd], 'securityId');
            var prepopulate = Ember.get(securityRefundDeterminations[srd], 'outstandingPrincipal') === Ember.get(securityRefundDeterminations[srd], 'refundPrincipal') ? true : false;
            // console.log('srd', securityRefundDeterminations[srd]);
            // console.log('comparing', Ember.get(securityRefundDeterminations[srd], 'outstandingPrincipal'), Ember.get(securityRefundDeterminations[srd], 'refundPrincipal'));
            // console.log('prepopulate', prepopulate);
            isChecked[key] = prepopulate;
            // securityRefundDeterminations[srd].set('isChecked',prepopulate);
            var paymentDeterminations = Ember.get(securityRefundDeterminations[srd], 'paymentDeterminations');
            Object.keys(paymentDeterminations).forEach(function (pd) {
              var key = Ember.get(securityRefundDeterminations[srd], 'securityId') + Ember.get(paymentDeterminations[pd], 'paymentDate');
              var prepopulate = Ember.get(paymentDeterminations[pd], 'principalAmount') === Ember.get(paymentDeterminations[pd], 'refundPrincipalAmount') ? true : false;
              // console.log('prd', paymentDeterminations[pd]);
              // console.log('comparing', Ember.get(paymentDeterminations[pd], 'principalAmount'), Ember.get(paymentDeterminations[pd], 'refundPrincipalAmount'));
              // console.log('prepopulate', prepopulate);
              isChecked[key] = prepopulate;
              // console.log('payment key',key,prepopulate)
            });
          });
        });
        // console.log('prepopulated isChecked',isChecked);
        // return isChecked;

        this.set('isChecked', isChecked);
      }
    }.observes('model.refundedDebtDetermination'),

    securityRefundings: {},

    buildMap: function buildMap() {

      var refundSecurities = {};

      var refundings = this.get('model.integratedObligation.value.refundings');

      // console.log('refundings', refundings);

      if (!refundings) {
        return {};
      }

      Object.keys(refundings).forEach(function (refundingId) {
        var refunding = refundings[refundingId];
        var refundedSecurities = Ember.get(refunding, 'refundedSecurities');

        // console.log('refunding', refunding);
        // console.log('refundedSecurities', refundedSecurities);

        Object.keys(refundedSecurities).forEach(function (refundedSecurityId) {
          refundSecurities[refundedSecurityId] = refundedSecurities[refundedSecurityId];
        });
      });

      // console.log('refundedSecuritiesMap', refundSecurities);

      return refundSecurities;
    },


    srfObserver: function () {

      this.set('securityRefundings', this.buildMap());
    }.observes('model.integratedObligation.value.refundings'),

    setRefunding: function setRefunding(securityId, paymentDate, principalAmount, callDate, callPrice) {
      var rdd = this.get('model.refundedDebtDetermination');
      var integratedObligation = this.get('model.integratedObligation');

      return integratedObligation.setRefundAmount(securityId, paymentDate, principalAmount, callDate, callPrice).then(function () {
        rdd.reload();
      });
    },


    actions: {
      closeAccordion: function closeAccordion(element) {
        console.log('element', element);
        // $('#'+element).toggleClass('is-active');
        $('#' + element).parent().foundation('up', $('#' + element + ' .accordion-content'));
      },
      actionSaveAndTransition: function actionSaveAndTransition(obligation) {

        // console.log('saving obligation', obligation);
        // console.log('saving refundDebtDetermination', this.get('model.refundedDebtDetermination').serialize());

        this.get('onboarding').next('refunding');
      },
      actionClickSecurityCheck: function actionClickSecurityCheck(refundedSecurityDetermination, refundedPaymentDetermination) {
        var principalAmount = Ember.get(refundedPaymentDetermination, 'principalAmount');
        var refundSecurityId = Ember.get(refundedSecurityDetermination, 'securityId');
        var refundPaymentDate = Ember.get(refundedPaymentDetermination, 'paymentDate');
        var refundPrincipalAmount = Ember.get(refundedPaymentDetermination, 'refundPrincipalAmount');
        var callDate = Ember.get(refundedSecurityDetermination, 'callDate');
        var callPrice = _accounting.default.unformat(Ember.get(refundedSecurityDetermination, 'callPrice'), '.');

        // console.log('Comparing', principalAmount, refundPrincipalAmount);

        if (principalAmount !== refundPrincipalAmount) {
          refundPrincipalAmount = principalAmount;
        } else {
          refundPrincipalAmount = 0;
        }

        this.setRefunding(refundSecurityId, refundPaymentDate, refundPrincipalAmount, callDate, callPrice);
      },
      actionSetRefundAmount: function actionSetRefundAmount(refundedSecurityDetermination, refundedPaymentDetermination, event) {

        var refundSecurityId = Ember.get(refundedSecurityDetermination, 'securityId');
        var refundPaymentDate = Ember.get(refundedPaymentDetermination, 'paymentDate');
        var refundPrincipalAmount = _accounting.default.unformat(event.target.value, '.');
        var callDate = Ember.get(refundedSecurityDetermination, 'callDate');
        var callPrice = _accounting.default.unformat(Ember.get(refundedSecurityDetermination, 'callPrice'), '.');

        this.setRefunding(refundSecurityId, refundPaymentDate, refundPrincipalAmount, callDate, callPrice);
      },
      actionSetCallDate: function actionSetCallDate(refundedSecurityDetermination, refundedPaymentDetermination, event) {
        var refundSecurityId = Ember.get(refundedSecurityDetermination, 'securityId');
        var refundPaymentDate = Ember.get(refundedPaymentDetermination, 'paymentDate');
        var refundPrincipalAmount = _accounting.default.unformat(Ember.get(refundedSecurityDetermination, 'amount'), '.');
        var callDate = event.target.value;
        var callPrice = _accounting.default.unformat(Ember.get(refundedSecurityDetermination, 'callPrice'), '.');

        this.setRefunding(refundSecurityId, refundPaymentDate, refundPrincipalAmount, callDate, callPrice);
      }
    }

  });
});