define('pretty-nuts/controllers/obligation/cashflow', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    pieCharts: ['PrincipalAndInterest'],
    // out: true,
    // zoom: {
    //   enabled: true,
    //   onzoom(domain) {
    //     console.log(this, domain);
    //   }
    // },
    //
    // chart: Ember.computed('model', function(){
    //   var principalTotal = new BigNumber(0);
    //   var interestTotal = new BigNumber(0);
    //   var totalTotal = new BigNumber(0);
    //   var now = new Date();
    //   let debtService = this.get('model.debtService').get('payments');
    //   for (var j=0; j<debtService.length; j++){
    //     if (moment(debtService[j].paymentDate).isBefore(now) ){
    //       debtService[j]['status'] = 'Paid';
    //     } else {
    //       debtService[j]['status'] = 'Outstanding';
    //     }
    //     debtService[j]['rowTotal'] = new BigNumber(debtService[j].interestAmount).add(debtService[j].principalAmount);
    //     principalTotal = principalTotal.add(debtService[j].principalAmount);
    //     interestTotal = interestTotal.add(debtService[j].interestAmount);
    //     totalTotal = totalTotal.add(debtService[j].rowTotal);
    //   }
    //   debtService['principalTotal'] = principalTotal;
    //   debtService['interestTotal'] = interestTotal;
    //   debtService['totalTotal'] = totalTotal;
    //
    //   return debtService;
    // }),
    //
    // graph: function(){
    //   let that = this;
    //   var years = ['x'];
    //   var principalPaid = ['Principal Paid'];
    //   var interestPaid = ['Interest Paid'];
    //   var principal = ['Principal'];
    //   var interest = ['Interest'];
    //   var now = new Date();
    //   let debtService = that.get('model.debtService').get('payments');
    //   console.log('EHEM',debtService);
    //   for (var j=0; j<debtService.length; j++){
    //     years.push(debtService[j].paymentDate);
    //     if (moment(debtService[j].paymentDate).isBefore(now) ){
    //       principalPaid.push(debtService[j].principalAmount);
    //       interestPaid.push(debtService[j].interestAmount);
    //       principal.push('0');
    //       interest.push('0');
    //     } else {
    //       principal.push(debtService[j].principalAmount);
    //       interest.push(debtService[j].interestAmount);
    //       principalPaid.push('0');
    //       interestPaid.push('0');
    //
    //     }
    //   }
    //   let g = {
    //     x: 'x',
    //     columns: [
    //       years,principalPaid, interestPaid, principal,interest
    //     ],
    //     type: 'bar',
    //     groups: [
    //       ['Principal Paid','Interest Paid','Principal', 'Interest'],
    //     ],
    //     // onclick(a) {
    //     //   // alert('Item ' + a.index + ' of value ' + a.value);
    //     // }
    //   }
    //   var c3Build = {
    //     bindto: '#c3cashflow',
    //     data: g,
    //     axis: {
    //       x: {
    //         type: 'timeseries',
    //         tick: {
    //           format: '%Y'
    //         }
    //       },
    //       y: {
    //         label: {
    //           text: '$ in Thousands',
    //           position: 'outer-middle'
    //         },
    //         tick: {
    //           format: d => '$' + d / 1000
    //         }
    //       }
    //     },
    //     color: {
    //       pattern: [
    //         '#d3d3d3', // Grey Fade
    //         '#F9AB6B', // Orange Fade
    //         '#7e7e7e', // Grey
    //         '#E46C0A', // Aquorn Orange
    //         '#50AB85', // Ocean Green
    //         '#3fc33f', // Green Fade
    //         ''
    //       ]
    //     },
    //   }
    //   this.set('c3Build',c3Build);
    //   c3.generate(c3Build);
    //   window.scrollTo(0,300);
    //   window.setTimeout(function(){window.scrollTo(0,0) },10);
    //   console.log('C3BUILD GENERATED')
    //   console.log('c3Data is ',g)
    //   console.log('c3Build is', c3Build)
    // }.observes('model'),


    // runEveryTime: function () {
    //   Ember.run.schedule('afterRender', this, function () {
    //     $('.obligations-table').floatThead({ top: 66 });
    //     c3.generate(this.get('c3Build'));
    //     $('#graph_annual').addClass('selected');
    //     window.setTimeout(function(){
    //       window.scrollTo(0,1)
    //     },100);
    //   });
    //   this.set('entered', false);
    // }.observes('entered'),
    actions: {
      annual: function annual() {
        $('.selected').removeClass('selected');
        $('#graph_annual').addClass('selected');
      },
      biAnnual: function biAnnual() {
        $('.selected').removeClass('selected');
        $('#graph_biannual').addClass('selected');
      },
      monthly: function monthly() {
        $('.selected').removeClass('selected');
        $('#graph_monthly').addClass('selected');
      },
      toggle_variable_rates: function toggle_variable_rates(e, status) {
        if (status == 'Paid') {
          $('.fore').removeClass('cast');
        } else {
          $('.fore').addClass('cast');
        }
        $('tr.active-row').removeClass('active-row');
        $('#' + e).addClass('active-row');
        console.log('parent =', $('#variable_rates_table').parent().attr('id'));
        if ($('#variable_rates_table').parent().attr('id') === 'hidden_table') {
          console.log('check hidden passed');
          $('#variable_rates_table').insertAfter($('#' + e));
          // $('#variable_rates_table').hasClass('active-row') ? console.log('has?') : $('#variable_rates_table').addClass('active-row');
        } else {
          console.log('check hidden failed');
          if ($('#variable_rates_table').prev().attr('id') == e) {
            console.log('check eq passed');
            $('#' + e).removeClass('active-row');
            $('#variable_rates_table').appendTo($('#hidden_table'));
          } else {
            console.log('check eq failed', e);
            $('#variable_rates_table').insertAfter($('#' + e));
          }
        }
        // $('#variable_rates_table').toggleClass('hide');
      }
    }
  });
});