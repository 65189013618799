define('pretty-nuts/components/noclick-decimal-input', ['exports', 'pretty-nuts/components/decimal-input'], function (exports, _decimalInput) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _decimalInput.default.extend({
    click: function click() {
      return false;
    }
  });
});