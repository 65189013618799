define('pretty-nuts/routes/obligation', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/constants'], function (exports, _aquornAuthenticatedRouteMixin, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    obligationService: Ember.inject.service(),
    organizationService: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    obligation: '',

    model: function model(params) {
      var obligationService = this.get('obligationService');
      var organizationService = this.get('organizationService');
      var obligationId = params.obligationId;

      var wrappedIntegratedObligation = obligationService.getIntegratedObligation(obligationId).loadIgnore();

      return Ember.RSVP.hash({
        // integratedObligation: obligationService.getIntegratedObligation(obligationId).load()
        integratedObligation: Ember.get(wrappedIntegratedObligation, 'taskResult')
      }).then(function (model) {

        console.log('hash', model);

        // const legalEntityId = model.integratedObligation.get('obligation').belongsTo('legalEntity').id();
        var legalUnit = model.integratedObligation.get('obligation.legalEntity');

        var securitiesById = {};

        // console.log('integratedObligation.securities', model.integratedObligation.get('securities'))
        // console.log('integratedObligation.obligation', model.integratedObligation.get('obligation'))
        console.log('integratedObligation.usesOfFunds', model.integratedObligation.get('usesOfFunds'));
        // console.log('legalUnit', legalUnit);

        var securities = model.integratedObligation.get('securities');
        var list_securities = [];

        Object.keys(securities).forEach(function (security_id) {
          var security = securities[security_id];
          securitiesById[security_id] = security;
          list_securities.push(security);
        });

        model.securities = list_securities;
        model.securitiesById = securitiesById;

        model.cashflowFilter = {
          resolution: _constants.Constants.Frequency.SEMI_ANNUAL
        };

        model.obligation = model.integratedObligation.get('obligation');

        model.legalEntity = legalUnit;
        model.organization = legalUnit.get('organization');
        model.wrappedIntegratedObligation = wrappedIntegratedObligation;

        return model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    }
  });
});