define('pretty-nuts/transforms/embedded-models', ['exports', 'pretty-nuts/transforms/embedded-model'], function (exports, _embeddedModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _embeddedModel.default.extend({
    store: Ember.inject.service(),

    _deserializeMap: function _deserializeMap(serialized, modelName, generateId) {
      var _this = this;

      var map = {};

      Object.keys(serialized).forEach(function (key) {
        var model = _this._deserializeObject(serialized[key], modelName, generateId);

        map[key] = model;
      });

      return map;
    },
    deserialize: function deserialize(serialized, options) {

      var items = null;

      if (!serialized) {
        return serialized;
      }

      var generateId = options.generateId === true;

      if (Ember.isArray(serialized)) {
        items = this._deserializeArray(serialized, options.modelName, generateId);
      } else {
        items = this._deserializeMap(serialized, options.modelName, generateId);
      }

      //console.log('modelName', items);

      return items;
    },
    _serializeArray: function _serializeArray(deserialized, modelName) {
      var _this2 = this;

      var items = [];

      deserialized.forEach(function (s) {
        items.pushObject(_this2._serializeObject(s, modelName));
      });

      return items;
    },
    _serializeMap: function _serializeMap(deserialized, modelName) {
      var _this3 = this;

      var map = {};

      Object.keys(deserialized).forEach(function (key) {
        var deserializedValue = deserialized[key];

        if (deserializedValue) {
          map[key] = _this3._serializeObject(deserializedValue, modelName);
        }
      });

      return map;
    },
    serialize: function serialize(deserialized, options) {

      var items = null;

      if (!deserialized) {
        return deserialized;
      }

      if (Ember.isArray(deserialized)) {
        items = this._serializeArray(deserialized, options.modelName);
      } else {
        items = this._serializeMap(deserialized, options.modelName);
      }

      //console.log('modelName', items);

      return items;
    }
  });
});