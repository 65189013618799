define('pretty-nuts/components/foundation-js', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // classNameBindings: ['class'],
    didInsertElement: function didInsertElement() {
      this.$().foundation();
    }
  });
});