define('pretty-nuts/models/user-access', ['exports', 'ember-data'], function (exports, _emberData) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });
   exports.default = _emberData.default.Model.extend({
      objectAquornId: _emberData.default.belongsTo('organization'),

      structureMap: _emberData.default.attr('json')
   });
});