define('pretty-nuts/controllers/trends/state-overview', ['exports', 'pretty-nuts/db', 'accounting'], function (exports, _db, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var controller = void 0;

  exports.default = Ember.Controller.extend({
    // ---------
    // Default Settings
    // ---------

    init: function init() {
      controller = this;
    },


    graph_consolidated: Ember.computed('model', function () {
      var data = _db.default.getTable('state-overview-1'); //this.get('model.consolidated');
      var y1 = [];
      var y2 = [];
      var y3 = [];
      var proposed = [];
      y1.push('2014');
      y2.push('2015');
      y3.push('2016');
      proposed.push('Proposed');
      for (var i = 0; i < data.length; i++) {
        y1.push(_accounting.default.unformat(data[i]['2014']));
        y2.push(_accounting.default.unformat(data[i]['2015']));
        y3.push(_accounting.default.unformat(data[i]['2016']));
        proposed.push(_accounting.default.unformat(data[i].Proposed));
      }
      var thing = { columns: [y1, y2, y3, proposed], type: 'bar', groups: [['2016', 'Proposed']] };
      return thing;
    }),
    consolidated_axis: Ember.computed('model', function () {
      var data = _db.default.getTable('state-overview-1'); //this.get('model.consolidated');
      var cats = [];
      for (var i = 0; i < data.length; i++) {
        cats.push(data[i].Period);
      }
      var thing = {
        x: { type: 'category', categories: cats },
        y: {
          show: true,
          label: {
            text: 'Proceeds in $Millions',
            position: 'outer-middle'
          },
          tick: {
            format: function format(d) {
              return '$' + d / 1000000;
            }
          }
        }
      };
      return thing;
    }),
    pie: 'use',
    donutView: 'by Use',
    maxBarThickness: 5,
    betweenGroupPadding: 0,
    withinGroupPadding: 0,
    maxLabelHeight: 40,
    stackBars: true,

    // graph_tax_status: {
    //   x: 'x',
    //   columns: [
    //     ['x', '2016-01-01', '2017-01-01', '2018-01-01', '2019-01-01', '2020-01-01', '2021-01-01', '2022-01-01', '2023-01-01', '2024-01-01', '2025-01-01', '2026-01-01', '2027-01-01', '2028-01-01', '2029-01-01', '2030-01-01', '2031-01-01', '2032-01-01', '2033-01-01', '2034-01-01', '2035-01-01', '2036-01-01', '2037-01-01', '2038-01-01', '2039-01-01', '2040-01-01', '2041-01-01'],
    //     ['Interest', 243.5369, 239.9321, 235.6255, 230.8459, 225.5679, 220.475, 214.2985, 207.7033, 189.0759, 169.8135, 162.6329, 154.9154, 148.3398, 142.815, 135.3618, 127.215, 118.6056, 109.5077, 85.6275, 64.10375, 45.09095, 24.08963, 15.05883, 6.118688, 4.2086, 1.9526],
    //     ['Principal', 94.32, 99.72, 105.6, 110.56, 109.92, 127.52, 135.6, 123.32, 133.98, 141.68, 148.82, 123.34, 101.815, 136.92, 144.375, 152.53, 161.14, 170.135, 154.05, 135.625, 114.7, 75.775, 60.838, 33.83, 39.99, 33.62],
    //     ['Accretted', 0, 0, 3.15, 3.52, 3.77, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    //   ],
    //   type: 'bar',
    //   groups: [
    //     ['Principal', 'Interest', 'Accretted']
    //   ]
    // },

    graph_historical: Ember.computed('model', function () {
      var data = _db.default.getTable('state-overview-7'); //this.get('model.consolidated');
      var date = [];
      var obligations = [];
      var proceeds = [];
      date.push('x');
      obligations.push('Obligations');
      proceeds.push('Proceeds');
      for (var i = 0; i < data.length; i++) {
        date.push(data[i].Year + '-' + data[i].Month + '-01');
        obligations.push(data[i].NumberofObligations);
        proceeds.push(_accounting.default.unformat(data[i].Proceeds));
      }
      var thing = {
        x: 'x',
        columns: [date, obligations, proceeds],
        types: {
          Obligations: 'spline',
          Proceeds: 'bar'
        },
        axes: {
          Proceeds: 'y2'
        },
        onclick: function onclick(a) {
          controller.transitionToRoute('reporting.state-historical');
        }
      };
      console.log(thing);
      return thing;
    }),

    tooltip: {
      format: {
        title: function title(d) {
          return 'Data ' + d;
        },
        value: function value(_value, ratio, id) {
          var format = d3.format('$');
          return format(_value);
        }
        //            value: d3.format(',') // apply this format to both y and y2
      }
    },
    tooltip_historical: {
      format: {
        title: function title(d) {
          return 'Data ' + d;
        },
        value: function value(_value2, ratio, id) {
          var format = id === 'Obligations' ? d3.format(',') : d3.format('$');
          return format(_value2);
        }
        //            value: d3.format(',') // apply this format to both y and y2
      }
    },
    color1: {
      pattern: ['rgb(249, 106, 27)', 'rgb(63, 122, 175)', 'rgb(55, 106, 153)', 'rgb(220, 114, 55)', 'rgb(95, 148, 199)', 'rgb(109, 157, 205)', 'rgb(122, 165, 211)', 'rgb(136, 174, 216)', 'rgb(150, 183, 221)', 'rgb(164, 192, 227)']
    },
    color2: {
      pattern: ['rgb(193, 215, 183)', 'rgb(108, 167, 73)', 'rgb(91, 96, 38)', '#E46C0A', 'rgb(73, 113, 45)', 'rgb(80, 126, 50)', 'rgb(141, 187, 117)', 'rgb(176, 205, 163)', 'rgb(213, 228, 206)', 'rgb(227, 237, 223)']
    },
    color: {
      pattern: ['rgb(249, 106, 27)', 'rgb(251, 166, 118)', 'rgb(8, 161, 217)', 'rgb(90, 206, 249)', 'rgb(124, 152, 74)', 'rgb(178, 199, 140)', 'rgb(194, 173, 141)', 'rgb(218, 206, 187)', 'rgb(121, 123, 126)', 'rgb(175, 176, 178)', 'rgb(80, 110, 148)', 'rgb(145, 176, 196)', 'rgb(91, 115, 120)', 'rgb(205, 215, 217)', 'rgb(166, 166, 166)', 'rgb(217, 217, 217)']
    },
    axis: {
      y2: {
        show: true,
        label: {
          text: 'Proceeds in $Millions',
          position: 'outer-middle'
        }
      },
      x: {
        type: 'timeseries',
        tick: {
          format: '%Y'
        }
      },
      y: {
        max: 350,
        min: 31,
        label: {
          text: 'Number of Obligations',
          position: 'outer-middle'
        }
      }
    },
    labels: {
      //            format: function (v, id, i, j) { return "Default Format"; },
      format: {
        Airport: d3.format('$')
        //                data1: function (v, id, i, j) { return "Format for data1"; },
      }
    },

    pie1: {
      // iris data from R
      columns: [['Cities', 10.8], ['County', 6.61], ['JPA & Marks-Roos', 14.94], ['K-14 Schools', 23.06], ['Mello Roos', 1.37], ['Special Districts', 10.38], ['State of California', 25.24], ['UC & CSU', 7.61]],
      type: 'pie',
      onclick: function onclick(d, i) {
        console.log(JSON.stringify(controller.get('pie')));
        JSON.stringify(controller.set('pie', 'use'));
        JSON.stringify(controller.set('donutView', 'by Use'));
      }
    },
    legend: {
      hide: true
    },
    pieSize: {
      width: 320,
      height: 320
    },
    actions: {
      foo: function foo() {
        console.log(this);
      }
    }
  });
});