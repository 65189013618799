define('pretty-nuts/components/new-org', ['exports', 'pretty-nuts/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    types: ['Service Provider', 'Municipality'],
    selectedType: 'Municipality',
    orgName: '',
    orgUrl: '',
    actions: {
      newOrg: function newOrg() {
        $('#new_org').show();
      },
      chooseType: function chooseType(term) {
        this.set('selectedType', term);
      },
      addOrg: function addOrg() {
        // add a new person to the database here
        var term = {
          "type": this.get('selectedType').toUpperCase(),
          "name": this.get('orgName'),
          "companyUrl": this.get('orgUrl')
        };
        _utils.Backend.createOrg(term, function (data) {
          console.log(JSON.stringify(term), data);
        });

        $('#new_org').hide();
        $('html').focus();
      }
    }
  });
});