define('pretty-nuts/controllers/onboarding/securities', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Controller.extend((_Ember$Controller$ext = {
    obligationService: Ember.inject.service(),
    onboarding: Ember.inject.controller(),

    //////////////////
    // Securities Data

    serialSecurities: [],
    termSecurities: [],
    bulletSecurities: [],

    serialFirstMaturityDate: null,
    serialFinalMaturityDate: null,
    //generateSecuritiesConfig: {},
    generateComponentType: null,

    // Securities Data
    //////////////////

    generateComponentTypeObserver: function () {
      console.log('generateComponentTypeObserver');
      var componentType = this.get('generateComponentType');

      var value = componentType ? componentType.value : null;

      console.log('generateComponentTypeObserver value', value);
      this.set('model.generateSecuritiesConfig.type', value);
    }.observes('generateComponentType'),

    isVariableRateAndSecurities: Ember.computed('serialSecurities', 'termSecurities', function () {
      var serialSecurities = this.get('serialSecurities');
      var termSecurities = this.get('termSecurities');
      var bulletSecurities = this.get('bulletSecurities');
      var isVariableRate = this.get('isVariableRate');

      return isVariableRate && (serialSecurities.length > 0 || termSecurities.length > 0 || bulletSecurities.length > 0);
    }),

    securitiesExist: Ember.computed('serialSecurities', 'termSecurities', 'bulletSecurities', function () {
      var serialSecurities = this.get('serialSecurities');
      var termSecurities = this.get('termSecurities');
      var bulletSecurities = this.get('bulletSecurities');

      return serialSecurities.length + termSecurities.length + bulletSecurities.length > 0;
    }),

    types: Ember.computed('isVariableRate', function () {
      if (this.get('isVariableRate')) {
        return [{ value: _constants.Constants.SecurityType.TERM }, { value: _constants.Constants.SecurityType.BULLET }];
      } else {
        return [{ value: _constants.Constants.SecurityType.SERIAL }, { value: _constants.Constants.SecurityType.TERM }, { value: _constants.Constants.SecurityType.BULLET }];
      }
    }
    // {value: Constants.SecurityType.CAPITAL_APPRECIATION, disabled: true}
    ),

    interest_structures: [{ name: 'Fixed Rate' }, { name: 'Variable Rate' }, { name: 'Stepped', disabled: true }],
    cycles: [_constants.Constants.Frequency.DAILY, _constants.Constants.Frequency.WEEKLY, _constants.Constants.Frequency.MONTHLY, _constants.Constants.Frequency.QUARTERLY, _constants.Constants.Frequency.SEMI_ANNUAL, _constants.Constants.Frequency.ANNUAL],
    accrual_periods: ['Monthly', 'Semi-Annual', 'Annual', 'Weekly', 'Quarterly'],
    reset_periods: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Semi-Annual', 'Annual'],

    refreshSecurities: function refreshSecurities() {
      console.log('refreshSecurities');
      var securities = [];

      var obligationSecurities = this.get('model.integratedObligation.value.securities');

      if (!obligationSecurities) {
        return;
      }

      Object.keys(obligationSecurities).forEach(function (securityId) {
        securities.pushObject(obligationSecurities[securityId]);
      });

      securities.sort(function (a, b) {
        var ad = a.get('maturityDate');
        var bd = b.get('maturityDate');

        if (ad < bd) {
          return -1;
        } else if (ad > bd) {
          return 1;
        }

        return 0;
      });

      var serialSecurities = [];
      var termSecurities = [];
      var bulletSecurities = [];

      securities.forEach(function (security) {

        if (security) {
          //let type = (!Ember.isNone(security.get('securityType')) ? security.get('securityType').toLowerCase() : 'null');

          // switch (type.toLowerCase()) {
          switch (security.get('securityType')) {

            // case "serial": {
            case _constants.Constants.SecurityType.SERIAL:
              {
                serialSecurities.pushObject(security);

                break;
              }

            // case "term": {
            case _constants.Constants.SecurityType.TERM:
              {
                termSecurities.pushObject([security]);
                break;
              }

            // case "bullet": {
            case _constants.Constants.SecurityType.BULLET:
              {
                bulletSecurities.pushObject([security]);
                break;
              }

            default:
              {
                console.log('security', security);
                console.log("Unexpected security type:", security.get('securityType'));
              }
          }
        }
      });

      this.set('serialSecurities', serialSecurities);
      this.set('termSecurities', termSecurities);
      this.set('bulletSecurities', bulletSecurities);
    },


    /**
     * When the list of securities is updated, split them into individual lists by security type
     *
     */
    securitiesObserver: function () {
      console.log('securitiesObserver');
      this.refreshSecurities();
    }.observes('model.integratedObligation.value.securities'),

    /**
     * Reset the securities generation configuration options
     */
    resetGenerateSecurities: function resetGenerateSecurities() {
      this.set('model.generateSecuritiesConfig', this.get('store').createRecord('generate-securities-config'), {
        obligationId: this.get('model.integratedObligation.value.obligation.id'),
        couponPeriod: this.get('model.integratedObligation.value.obligation.otherInfo.interestFrequency')
      });

      this.set('generateComponentType', null);
      // this.set('generateSecuritiesConfig', {
      //   type: null,
      //
      //   firstMaturityDate: null,
      //   finalMaturityDate: null,
      //   maturityFrequency: null,
      //
      //   firstPrincipalDate: null,
      //   finalPrincipalDate: null,
      //   principalFrequency: null,
      //
      //   forecastRate: null,
      //   couponPeriod: null,
      //   couponPaymentDateOffset: null,
      //   firstCouponDate: null,
      //   secondCouponBegin: null,    // TODO: Huh?
      //
      //   resetDateOffset: null,
      //   resetFrequency: null,
      //   secondInterestBegin: null,  // TODO: huh?
      // });
    },


    generate: true,
    firstMaturityDate: null,
    finalMaturityDate: null,
    isRefunding: false,
    isFixedRate: false,
    isVariableRate: false,
    isSteppedCoupon: false,
    isCapitalAppreciation: false,

    structureTypeObserver: function () {
      console.log('structureTypeObserver', this.get('model.integratedObligation.value.obligation.otherInfo.interestFrequency'));
      var structureType = this.get('model.integratedObligation.value.obligation.structureType');
      var isVariableRate = _constants.Constants.ObligationStructure.VARIABLE_RATE === structureType;
      var isFixedRate = _constants.Constants.ObligationStructure.FIXED_RATE === structureType;
      this.set('isFixedRate', isFixedRate);
      this.set('isVariableRate', isVariableRate);

      // this.resetGenerateSecurities();
      this.set('model.generateSecuritiesConfig.couponPeriod', this.get('model.integratedObligation.value.obligation.otherInfo.interestFrequency'));

      //this.set('isSteppedCoupon', this.checkStructureType(Constants.ObligationStructure.));
      // this.set('isCapitalAppreciation', this.checkStructureType(Constants.ObligationStructure.CAPITAL_APPRECIATION));
    }.observes('model.integratedObligation.value.obligation.structureType'),

    // refundingObserver: function() {
    //   this.set('isRefunding', this.checkPurpose(Constants.ObligationPurpose.REFUNDING));
    //   console.log('isRefunding',this.get('isRefunding'));
    // }.observes('model.obligation.purpose'),

    isSerial: false,
    isTerm: false,
    isBullet: false
  }, _defineProperty(_Ember$Controller$ext, 'isCapitalAppreciation', false), _defineProperty(_Ember$Controller$ext, 'securityTypeObserver', function () {
    var type = this.get('model.generateSecuritiesConfig.type');

    // console.log('securityTypeObserver', type);

    this.set('isSerial', false);
    this.set('isTerm', false);
    this.set('isBullet', false);

    switch (type) {
      case _constants.Constants.SecurityType.SERIAL:
        {
          this.set('isSerial', true);
          break;
        }

      case _constants.Constants.SecurityType.TERM:
        {
          this.set('isTerm', true);
          break;
        }
      case _constants.Constants.SecurityType.BULLET:
        {
          this.set('isBullet', true);
          break;
        }

      default:
        {
          console.log("Unknown security type:", type);
        }
    }
  }.observes('model.generateSecuritiesConfig.type')), _defineProperty(_Ember$Controller$ext, 'checkStructureType', function checkStructureType(t) {
    console.log('STRUCTURE-TYPE', t, this.get('model.obligation.structureType'));
    console.log('STRUCTURE-TYPE SURVEY SAYS', this.get('model.obligation.structureType') === t);
    return this.get('model.obligation.structureType') === t;
  }), _defineProperty(_Ember$Controller$ext, 'checkPurpose', function checkPurpose(p) {
    return this.get('model.obligation.purpose') === p;
  }), _defineProperty(_Ember$Controller$ext, 'saveSecurity', function saveSecurity(security) {
    var that = this;

    console.log("Saving security:", security);

    security.save({ patch: true }).then(function () {
      that.get('model.securities').update();
      that.get('model.obligation').reload();
      that.get('onboarding.notifications').success("Security updated...", { autoClear: true });
    });
  }), _defineProperty(_Ember$Controller$ext, 'updateSecurities', function updateSecurities() {
    this.refreshSecurities();
  }), _defineProperty(_Ember$Controller$ext, 'actions', {
    editRow: function editRow(row) {
      console.log('row', row);
      $('td .active').removeClass('active');
      $('#' + row + ' td div').addClass('active');
      $('#' + row + ' td span').addClass('active');
      $('#' + row + 'div.editing-row').addClass('active');
    },
    actionGenerateSecurities: function actionGenerateSecurities() {
      var that = this;
      var config = this.get('model.generateSecuritiesConfig');
      // console.log('config', config, config.serialize());
      return this.get('model.integratedObligation').generateSecurities(config).then(function () {
        that.resetGenerateSecurities();
        that.refreshSecurities();
      });
    },
    actionSetSecurityValue: function actionSetSecurityValue(security, attribute) {
      security.set(attribute, $(event)[0].target.value);
    },
    actionSetSecurityValueAndSave: function actionSetSecurityValueAndSave(security, attribute) {
      security.set(attribute, $(event)[0].target.value);
      this.saveSecurity(security);
    },
    actionToggleSecurityValue: function actionToggleSecurityValue(security, attribute) {
      var curVal = security.get(attribute);

      security.set(attribute, !Ember.isNone(curVal) ? !curVal : true);
      this.saveSecurity(security);
    },
    actionSaveSecurity: function actionSaveSecurity(security) {
      this.saveSecurity(security);
    },
    actionDeleteSecurity: function actionDeleteSecurity(security) {
      var that = this;

      this.get('obligationService').deleteSecurity(security).then(function () {
        that.get('model.securities').update();
        that.get('model.obligation').update();
        that.get('model.notifications').success("Security deleted...", { autoClear: true });
      });
    },
    actionDeleteSecurities: function actionDeleteSecurities(securities) {
      var that = this;
      var obligationService = this.get('obligationService');

      obligationService.deleteSecurities(securities).perform(function () {
        console.log('deleteSecurities securities post', that.get('model.securities'));
        that.get('model.securities').update();
        that.get('model.obligation').update();
      }, function (operation) {
        console.log('operation result', operation.get('result'));
      });
    },
    actionSaveAndTransition: function actionSaveAndTransition() {
      this.get('onboarding').next('securities');
    },
    deleteObligation: function deleteObligation(obligation) {
      this.get('onboarding').send('deleteObligation', obligation);
    }
  }), _Ember$Controller$ext));
});