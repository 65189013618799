define('pretty-nuts/authorizers/aquorn-authorizer', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    authorize: function authorize(data, block) {
      block('SESSION', data['sessionId']);
    }
  });
});