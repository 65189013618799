define('pretty-nuts/serializers/service-provider-summary', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      organization: 'organizationId',
      users: 'userIds',
      obligations: 'obligationIds'
    }
  });
});