define('pretty-nuts/routes/license-agreement', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({

    currentUser: Ember.inject.service(),
    backend: Ember.inject.service(),

    beforeModel: function beforeModel() {
      var currentUser = this.get('currentUser');

      if (currentUser.isLicenseAccepted()) {
        return this.transitionTo('index');
      }

      return this._super.apply(this, arguments);
    },
    model: function model() {
      var currentUser = this.get('currentUser');
      var user = currentUser.getProfile();

      var currentEmployment = currentUser.getCurrentEmployment();
      var primaryPhone = currentUser.getPrimaryPhone();

      var that = this;

      return this.get('backend').get({
        url: _constants.Constants.API_PREFIX + '/domainToOrg'
      }).then(function (response) {
        return that.get('store').findRecord('organization', response.data.aquornId).then(function (organization) {
          return {
            setupContent: {
              salutation: user.get('otherInfo.profile.salutation'),
              firstName: user.get('firstName'),
              middleInitial: user.get('middleInitial'),
              lastName: user.get('lastName'),

              phoneNumber: primaryPhone ? primaryPhone.get('number') : null,

              //currentOrganizationId: (currentEmployment ? currentEmployment.get('organization').get('id') : null),
              currentOrganizationId: organization.get('id'),
              title: currentEmployment ? currentEmployment.get('position') : null

            },

            domainOrganization: organization
          };
        });
      });
    }
  });
});