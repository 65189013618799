define('pretty-nuts/controllers/reporting/obligation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    groups: ['None', 'Legal Units', 'Obligations'],
    groupedBy: 'None',
    reports: [{ value: 'overview', name: 'Obligation Overview' }, { value: 'debt-service', name: 'Obligation Debt Service' }],
    currentReport: { value: 'overview', name: 'Obligation Overview' },
    periodStart: moment().format("YYYY-MM-DD"), //first day of month
    periodEnd: moment(), // defaults 1 year later
    onGroupedBy: function () {
      console.log('Grouped By', this.get('groupedBy'));
      if (this.get('groupedBy') == 'None') {
        console.log(':-(');
      }
    }.observes("groupedBy"),

    period: function () {
      console.log('Date Change', this.get('periodStart'), this.get('periodEnd'));
      if (this.get('groupedBy') == 'None') {}
      return;
    }.observes("periodStart", "periodEnd"),

    onCurrentReport: function (o) {
      // go to the value as route
      this.transitionToRoute('reporting.obligation.' + o.get('currentReport.value'));
    }.observes('currentReport'),

    actions: {}
  });
});