define('pretty-nuts/routes/onboarding/refunding', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    obligationService: Ember.inject.service(),

    model: function model() {

      var model = this.modelFor('onboarding');

      // model.uiSteps.set('refunding',  true);
      // model.uiSteps.save();

      model.refundedDebtDetermination.reload();

      return model;

      // return this.get('store').findRecord('refunded-debt-determination', model.obligation.get('id')).then((rdd) => {
      //   Ember.set(model, 'refundedDebtDetermination', rdd);
      //
      //   return model;
      // }).catch((reason) => {
      //   console.log('Error exception', reason);
      //   return model;
      // });
    },


    afterModel: function afterModel(model) {
      this.controllerFor('onboarding').updateProgress(model, 'refunding');
    },

    actions: {
      // reloadModel() {
      //   console.log('refreshed');
      //   this.refresh();
      // }
    }
  });
});