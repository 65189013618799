define('pretty-nuts/models/domain-source-of-funds', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('domain-obligation'),

    perspectiveDate: _emberData.default.attr(),

    name: _emberData.default.attr(),
    amount: _emberData.default.attr()
  });
});