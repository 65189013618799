define('pretty-nuts/services/response-validator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    notifications: Ember.inject.service('notification-messages'),

    /**
     * Deep model state rollback. Examine the submitted model for embedded models and recursively call this method to
     * rollback each layer of the embedded model. Ember doesn't do this for nested values, so we have to do it ourselves.
     *
     * @param model model instance to rollback
     * @private
     */
    _rollbackModel: function _rollbackModel(model) {
      // model.eachAttribute((name, meta) => {
      //   if ('embedded-model' === meta['type']) {
      //     this._rollbackModel(model.get(name));
      //   }
      // });
      model.rollbackAttributes();
    },


    /**
     * Handle an error response by adding the invalid class (highlight red) to the corresponding input boxes from the
     * feedback response and popup notification to the user containing brief message.
     *
     * @param response the error response from the backend
     * @param elementSuffix suffix to apply to element name(s)
     */
    handleError: function handleError(response, elementSuffix) {
      var notifications = this.get('notifications');

      response.errors.forEach(function (error) {
        Object.keys(error).forEach(function (name) {
          var elementId = name.replace(/\./g, "_") + (elementSuffix ? elementSuffix : "");
          var input = $('#' + elementId);
          input.addClass('invalid');

          notifications.error(input.attr('name') + ': ' + error[name].message);
        });
      });
    },


    /**
     * Handle a successful response by clearing any errors that may have been triggered for this input section.
     *
     * @param response successful response from the backend
     * @param name name of the section for the input
     * @returns {*}
     */
    handleSuccess: function handleSuccess(response, name) {
      this.clearErrors(name);
      return response;
    },


    /**
     * Clear errors for a section by acquiring all elements with the corresponding group attribute name and removing
     * the invalid class.
     *
     * @param name name of the group of elements
     */
    clearErrors: function clearErrors(name) {
      var elements = $('[group=' + name + ']');
      elements.removeClass('invalid');
    },


    /**
     * For a request, handle success or failure. When handling failure, show the errors and indicators, but then rethrow
     * the exception so that we don't eat the exception and then execute then() statements further down the chain.
     * @param promise
     * @param name
     * @param suffix
     */
    handleSubmissionResponse: function handleSubmissionResponse(promise, name, suffix) {
      var controller = this;

      return promise.then(function (response) {
        return controller.handleSuccess(response, name);
      }).catch(function (response) {
        controller.handleError(response, suffix);

        throw response;
      });
    },


    /**
     * Save the provided models and wrap all the promises into a single aggregate promise. Then wrap the aggregate
     * promise in the response handler.
     *
     * @param models models to save
     * @param name name of the section being submitted (group of controls)
     * @param suffix suffix to apply to section name (where multiple like named element groups may be)
     * @returns {*}
     */
    submitSection: function submitSection(models, name, suffix) {
      var promises = [];

      // Save the provided models and collect the corresponding promise
      models.forEach(function (model) {
        promises.addObject(model.save());
      });

      // Wrap into aggregate promise
      var allPromise = Ember.RSVP.all(promises);

      // Wrap promise with handler for success/error validation
      return this.handleSubmissionResponse(allPromise, name, suffix);
    },


    /**
     * Revert an input section by rolling back the provided models and clearing any errors related
     * to this section.
     *
     * @param models models to rollback to pre-edit state
     * @param name name of the section to revert
     */
    revertSection: function revertSection(models, name) {
      var _this = this;

      models.forEach(function (model) {
        _this._rollbackModel(model);
      });

      this.clearErrors(name);
    }
  });
});