define('pretty-nuts/routes/onboarding/review', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      var model = this.modelFor('onboarding');

      // model.obligation.reload();
      // model.callOptions.update();
      // model.securities.update();
      // model.debtService.reload();
      // model.serviceProviders.update();
      // model.obligors.update();
      // model.sourcesOfFunds.update();
      // model.usesOfFunds.update();
      // model.documents.update();

      // model.uiSteps.set('review',  true);
      // model.uiSteps.save();
      // return this.get('store').findRecord('refunded-debt-determination', model.obligation.get('id')).then((rdd) => {
      //   Ember.set(model, 'refundedDebtDetermination', rdd);
      //
      //   return model;
      // }).catch((reason) => {
      //   console.log('Error exception', reason);
      //   return model;
      // });

      return model;
    },


    afterModel: function afterModel(model) {
      this.controllerFor('onboarding').updateProgress(model, 'review');
    },

    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});