define('pretty-nuts/instance-initializers/session-events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(applicationInstance) {
    var applicationRoute = applicationInstance.lookup('route:application');
    var session = applicationInstance.lookup('service:session');
    var currentUser = applicationInstance.lookup('service:currentUser');

    session.on('authenticationSucceeded', function () {
      currentUser.load().then(function () {
        applicationRoute.transitionTo('index');
      });
    });
  }

  exports.default = {
    name: 'session-events',
    initialize: initialize
  };
});