define('pretty-nuts/services/obligationsapi', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    /**
     * Backend request wrapper
     */
    backend: Ember.inject.service("Backend"),

    getSecurity: function getSecurity(obligationId, securityId, cb) {
      return this.get('backend').get({
        url: "/api/obligation/" + obligationId + "/security/" + securityId,
        cb: cb
      });
    },
    getObligation: function getObligation(obligationId, cb) {
      return this.get('backend').get({
        url: "/api/obligation/" + obligationId + "&0",
        cb: cb
      });
    },
    createDraft: function createDraft(obligation, cb) {
      return this.get('backend').put({
        url: "/api/obligation",
        data: JSON.stringify(obligation),
        cb: cb
      });
    },
    saveDraft: function saveDraft(obligation, cb) {
      return this.get('backend').patch({
        url: "/api/obligation/saveAsDraft",
        data: JSON.stringify(obligation),
        cb: cb
      });
    },
    getSummarySummaryStatistics: function getSummarySummaryStatistics(obligationId, cb) {
      return this.get('backend').get({
        url: "/api/obligation/" + obligationId + "&0/statistics",
        cb: cb
      });
    },
    deleteObligation: function deleteObligation(obligtionId, cb) {
      return this.delete({
        url: "/api/obligation",
        data: JSON.stringify({
          obligationId: obligtionId
        }),
        cb: cb
      });
    },
    submitObligation: function submitObligation(obligation, cb) {
      return this.get('backend').put({
        url: "/api/obligation/submit",
        data: JSON.stringify(obligation),
        cb: cb
      });
    },
    generateSecurities: function generateSecurities(dayBasis, securityType, maturityFrequency, couponPeriod, resetFrequency, firstMaturityDate, finalMaturityDate, cb) {

      console.log("Generating securities");

      return this.get('backend').post({
        url: "/api/obligation/generateSecurities",
        data: JSON.stringify({
          dayBasis: dayBasis,
          securityType: securityType,
          maturityFrequency: maturityFrequency,
          //couponPeriod: this.get('onboarding.inputs.couponPeriod'),
          couponPeriod: couponPeriod,
          //resetFrequency: this.get('onboarding.inputs.resetFrequency'),
          resetFrequency: resetFrequency,
          firstMaturityDate: firstMaturityDate,
          finalMaturityDate: finalMaturityDate
        }),
        cb: cb
      });
    }
  });
});