define('pretty-nuts/components/json-editor', ['exports', 'ember-cli-jsoneditor/components/json-editor'], function (exports, _jsonEditor) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _jsonEditor.default;
    }
  });
});