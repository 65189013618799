define('pretty-nuts/services/reporting-service', ['exports', 'pretty-nuts/async-model', 'pretty-nuts/constants'], function (exports, _asyncModel, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ReportModel = _asyncModel.default.extend({
    legalEntityId: null,
    reportType: _constants.Constants.ReportType.THIRTY_YEAR_DEBT_REPORT,
    grouping: _constants.Constants.Grouping.OBLIGATION,
    // start: new Date().toDateString(),
    start: moment().format('YYYY-MM-DD'),

    _query: function _query(legalEntityId, reportType, start, end) {
      var v = this.get('operationService').deferrableModelQueryRecord2('legal-entity-report', {
        legalEntityId: legalEntityId,
        reportType: reportType,
        // grouping: this.get('grouping').queryValues[0],
        start: start,
        end: end
        // end: '2049-03-05'
      }, _constants.Constants.OperationStatusResolver.BUILD_LEGAL_ENTITY_REPORT);

      return v.then(function (v1) {
        console.log('report', legalEntityId, reportType, start, end, v1);
        return v1;
      });

      // return v;
    },
    doLoad: function doLoad(params) {
      params = params || {};
      return this._query(Ember.get(params, 'legalEntityId') || this.get('legalEntityId'), Ember.get(params, 'reportType') || this.get('reportType').queryValues[0], Ember.get(params, 'start') || this.get('start'), Ember.get(params, 'end') || this.get('end'));
      // return this.get('operationService').deferrableModelQueryRecord2(
      //   'legal-entity-report',
      //   {
      //     legalEntityId: this.get('legalEntityId'),
      //     reportType: this.get('reportType').queryValues[0],
      //     // grouping: this.get('grouping').queryValues[0],
      //     start: this.get('start'),
      //     end: this.get('end')
      //     // end: '2049-03-05'
      //   },
      //   Constants.OperationStatusResolver.BUILD_LEGAL_ENTITY_REPORT
      // );
    },
    setStartDate: function setStartDate(d) {
      // this.set('start', d);
      this.reload({
        params: {
          start: d
        }
      });
    },
    setEndDate: function setEndDate(d) {
      // this.set('end', d);
      this.reload({
        params: {
          end: d
        }
      });
    },
    setGrouping: function setGrouping(g) {
      this.set('grouping', g);
      this.reload();
    },
    update: function update(start, end) {
      this.set('start', start);
      this.set('end', end);

      this.reload();
    }
  });

  exports.default = Ember.Service.extend({

    operationService: Ember.inject.service(),

    toISODate: function toISODate(date) {
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var dt = date.getDate();

      if (dt < 10) {
        dt = '0' + dt;
      }
      if (month < 10) {
        month = '0' + month;
      }

      return year + '-' + month + '-' + dt;
    },
    getReport: function getReport(legalEntityId, reportType, start, end) {

      if (!start) {
        start = new Date();
      }

      return ReportModel.create({
        legalEntityId: legalEntityId,
        reportType: reportType,
        operationService: this.get('operationService'),
        start: moment().format('YYYY-MM-DD'),
        end: end
      });
    }
  });
});