define('pretty-nuts/models/domain-obligation-overview', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligor: _emberData.default.belongsTo('legal-unit'),
    active: _emberData.default.attr(),
    shortName: _emberData.default.attr(),
    longName1: _emberData.default.attr(),
    longName2: _emberData.default.attr(),
    longName3: _emberData.default.attr(),
    longName4: _emberData.default.attr(),
    saleDate: _emberData.default.attr(),
    datedDate: _emberData.default.attr(),
    originalPrincipal: _emberData.default.attr(),
    outstandingPrincipal: _emberData.default.attr(),
    amountDueThisYear: _emberData.default.attr(),
    purpose: _emberData.default.attr('enum', { type: _constants.Constants.ObligationPurpose }),
    structureType: _emberData.default.attr('enum', { type: _constants.Constants.ObligationStructure }),
    securityPledge: _emberData.default.attr('enum', { type: _constants.Constants.SecurityPledge }),
    federalTaxStatus: _emberData.default.attr('enum', { type: _constants.Constants.TaxStatus }),
    creditRatings: _emberData.default.attr(),
    firstMaturityDate: _emberData.default.attr(),
    finalMaturityDate: _emberData.default.attr()
  });
});