define("pretty-nuts/components/onboard-securities", ["exports", "ember-concurrency", "pretty-nuts/constants"], function (exports, _emberConcurrency, _constants) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['accordion-item'],
    attributeBindings: ['data-accordion-item'],
    'data-accordion-item': '',
    obligationService: Ember.inject.service(),
    store: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),

    obligation: null,

    securities: [],

    focusedSecurity: null,

    firstMaturityDate: null,
    finalMaturityDate: null,

    rateType: 'RATE TYPE',
    structureType: 'STRUCTURE TYPE',

    frequency: Ember.computed('securities.[]', function () {
      var securities = this.get('securities');

      var security = securities.objectAt(0);

      var frequency = null;

      switch (security.get('securityType')) {
        case _constants.Constants.SecurityType.SERIAL:
          {
            frequency = security.get('otherInfo.maturityFrequency');
            break;
          }

        case _constants.Constants.SecurityType.TERM:
          {
            frequency = security.get('otherInfo.principalFrequency');
            break;
          }

        default:
          {
            console.warn("Unknown security frequency type:", security.get('securityType'));
          }
      }

      return frequency;
    }),

    totalPrincipal: Ember.computed('securities.@each.principalAmount', function () {
      return this._sumSecuritiesPrincipal(this.get('securities'));
    }),

    _sumSecuritiesPrincipal: function _sumSecuritiesPrincipal(securities) {
      var total = new BigNumber("0");

      securities.forEach(function (security) {
        var amount = security.get('principalAmount');

        if (!Ember.isNone(amount)) {
          total = total.plus(new BigNumber(amount));
        }
      });

      return total;
    },


    securitiesObserver: function () {

      var securities = this.get('securities');
      var obligation = this.get('integratedObligation.value.obligation');

      if (securities.length > 0) {
        this.set('firstMaturityDate', securities[0].get('maturityDate'));
        this.set('finalMaturityDate', securities[securities.length - 1].get('maturityDate'));

        if (obligation) {
          this.set('rateType', obligation.get('structureType'));
          console.log('rateType', this.get('rateType'));
        }

        this.set('structureType', securities[0].get('securityType'));
      }

      //this.set('totalPrincipal', this._sumSecuritiesPrincipal(securities));
    }.observes('securities.@each').on('init'),

    //

    onSaveSecurity: null,
    onDeleteSecurity: null,

    deleteSecurity: function deleteSecurity(security) {
      var obligationService = this.get('obligationService');
      var onDelete = this.get('onDeleteSecurity');

      var promise = obligationService.deleteSecurity(security);

      if (onDelete) {
        promise.then(function () {
          onDelete();
        });
      }
    },


    onDeleteSecurities: null,

    deleteSecurities: function deleteSecurities(securityType) {
      var onDeleteSecurities = this.get('onDeleteSecurities');

      this.get('integratedObligation').removeSecuritiesByType(securityType).then(function () {
        if (onDeleteSecurities) {
          onDeleteSecurities();
        }
      });
    },


    //

    actions: {
      actionSaveSecurity: function actionSaveSecurity(security) {
        this.saveSecurity(security);
      },
      actionDeleteSecurity: function actionDeleteSecurity(security) {
        this.deleteSecurity(security);
      },
      actionDeleteSecurities: function actionDeleteSecurities(securityType) {
        this.deleteSecurities(securityType);
      }
    }

  });
});