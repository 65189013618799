define("pretty-nuts/models/provider-view", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    organizationId: _emberData.default.attr(),
    organizationName: _emberData.default.attr(),
    legalEntityId: _emberData.default.attr(),
    legalEntityName: _emberData.default.attr(),
    obligationId: _emberData.default.attr(),
    obligationShortName: _emberData.default.attr(),
    providerId: _emberData.default.attr(),
    providerType: _emberData.default.attr(),
    providerOrgId: _emberData.default.attr(),
    providerOrgName: _emberData.default.attr(),
    providerOrgResource: _emberData.default.belongsTo("resource"),
    providerUserId: _emberData.default.attr(),
    providerUserFirstName: _emberData.default.attr(),
    providerUserLastName: _emberData.default.attr(),
    providerUserMiddleInitial: _emberData.default.attr(),
    providerUserSuffix: _emberData.default.attr(),
    providerUserResource: _emberData.default.belongsTo("resource")
  });
});