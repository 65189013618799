define('pretty-nuts/controllers/reporting', ['exports', 'pretty-nuts/utils', 'pretty-nuts/db', 'pretty-nuts/constants'], function (exports, _utils, _db, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _actions;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Controller.extend({
    needs: ['application'],
    reports: [{ value: 'period', name: 'Period Debt Service' }, { value: '30-year', name: '30 Year Debt Service' }, { value: 'cashflow', name: 'Cashflow Analysis' }],
    application: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    organizationService: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    resourceService: Ember.inject.service(),
    groups: ['General Fund', 'Enterprise - Airport', 'Enterprise - Water', 'C.F. Districts', ''],
    locations: ['State of California', 'County of Sacramento', 'City of Sacramento'],
    lenses: ['Overview', 'Cashflow', 'Call Options', 'Events', 'Documents', 'Service Providers'],
    companySizes: ['1-10', '11-50', '51-100', '101-500', '501-1000', '1001-10000', '10000+'],
    longName: 'THIS NAME IS LONG',
    notifications: Ember.inject.service('notification-messages'),
    orgsapi: Ember.inject.service(),
    obligationsapi: Ember.inject.service(),
    sortProperties: ['name'],
    sortAscending: false,
    legalEntities: null,
    legalUnitsWithoutObligations: [],
    desc: true,
    activeColumn: '',
    finalMaturity: '07/01/2041',
    routeName: 'Obligations',
    newAddress: null,
    defaultSubCategories: true,
    landscape: false,
    query: '',

    foundedDateLabel: Ember.computed('model.organization.operatingStatus', function () {
      var operatingStatus = this.get('model.organization.operatingStatus');
      if (operatingStatus == 'Subsidiary' || operatingStatus == 'Acquired') {
        return 'Acquired Date';
      } else {
        return 'Founded Date';
      }
    }),

    applicationController: Ember.inject.controller('application'),
    isAquornAdmin: Ember.computed.reads('applicationController.isAquornAdmin'),

    permissionsString: 'Administrator',

    orgTypes: ['Government Entity', 'Public Company', 'Privately Held', 'Partnership', 'Sole Proprietorship'],
    orgCategories: Ember.computed('model.organization.type', function () {
      if (this.get('model.organization.type') == 'MUNICIPALITY') {
        return ['Federal Government Agency', 'State Government', 'State Authority', 'County', 'City/Town', 'District', 'Local Authority', 'Utility', 'Tribal Government'];
      } else if (this.get('model.organization.type') == 'SERVICE_PROVIDER') {
        return ['Bank/Banking Services', 'Legal/Legal Services', 'Advisory/Advisory Services', 'Compliance/Complaince Services', 'Management Services', 'Other'];
      } else {
        return [];
      }
    }),
    orgSubCategories: Ember.computed('model.organization.category', function () {
      var category = this.get('model.organization.category');
      if (category == 'Federal Government Agency' || category == 'State Government') {
        return ['Regulatory Agency', 'Other'];
      } else if (category == 'State Authority' || category == 'Local Authority') {
        return ['Finance Authority', 'Housing Authority', 'Pollution Control Authority', 'Transportation Authority', 'Education & Educational Facilties', 'Economic Development', 'Health & Health Facilities', 'Other'];
      } else if (category == 'District') {
        return ['Water District', 'Fire District', 'Assessment District', 'Facilities District', 'Irrigation District', 'School District', 'Other'];
      } else if (category == 'Utility') {
        return ['Electric Utility', 'Water Utility', 'General Utilities', 'Other'];
      } else if (category == 'Bank/Banking Services') {
        return ['Investment Banking', 'Trust & Custody Banking', 'Other'];
      } else if (category == 'Advisory/Advisory Services') {
        return ['General Municipal Advisory', 'Financial Advisory', 'Investment Advisory', 'Other'];
      } else if (['County', 'City/Town', 'Tribal Government', 'Legal/Legal Services', 'Compliance/Complaince Services', 'Management Services', 'Other'].includes(category)) {
        return ['None', 'Other'];
      }
    }),

    sortedModel: Ember.computed.sort('model', 'sortProperties'),
    previewModel: {},
    previewEvents: {},
    openRow: '',

    draftObligations: [],
    finalObligations: [],

    runEveryTime: function () {
      window.setTimeout(function () {
        window.scrollTo(0, 1);
      }, 100);
      var count = 0;
      Ember.run.schedule('afterRender', this, function () {
        var controller = this;
        $('.menu.vertical a').removeClass('active');
        $('#reportingNav a').addClass('active');

        var roles = this.get('model.permissions');
        var organizationId = this.get("model.organizationId");

        var permissions = { 'Submitter': 'Employee', 'Admin': 'Administrator', 'Aquorn Admin': 'Aquorn Admin' };
        var permissionsString = " ";

        if (permissions['Aquorn Admin'] !== undefined) {
          delete permissions['Aquorn Admin'];
        }

        this.set('permissions', Object.values(permissions));

        if (Object.keys(permissions).length > 0) {
          this.set('selectedPermission', permissions[Object.keys(permissions)[0]]);
        } else {
          this.set('selectedPermission', "");
        }

        this.set('permissionsString', permissionsString.substr(0, permissionsString.length - 1));

        Em.$('table.obligations-table').on('click', 'tbody tr', function () {}); //.floatThead({ top: 57 });
      });
      this.set('entered', false);
    }.observes('entered'),

    operatingStatuses: ['Active', 'Subsidiary', 'No Longer Doing Business', 'Acquired'],

    reloadModel: function reloadModel() {
      this.send('reloadModel');
    },


    actions: (_actions = {
      switchToLandscape: function (val) {
        console.log(val);
        var css;
        if (val == true) {
          console.log('landscape!');
          css = '@page { size: landscape; }';
        } else {
          console.log('!landscape');
          css = '@page { size: portrait; }';
        }

        var head = document.head || document.getElementsByTagName('head')[0],
            style = document.createElement('style');

        style.type = 'text/css';
        style.media = 'print';

        if (style.styleSheet) {
          style.styleSheet.cssText = css;
        } else {
          style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);

        //don't forget to find and remove style if you don't want all you documents stay in landscape
      }.observes('landscape'),

      setParentOrganization: function setParentOrganization() {
        console.log('wtf');
      },
      onSelectionChange: function onSelectionChange(item) {
        this.set('selectedPermission', item);
      },
      requestRole: function requestRole() {
        var store = this.get('store');

        var role = this.get('selectedPermission');

        switch (role) {
          case "Employee":
            role = "Submitter";
            break;
          case "Administrator":
            role = "Admin";
            break;
        }

        // const record = {
        //   requestType: 'Request',
        //
        //   requestorId: this.get('model.userId'),
        //   requestorType: 'User',
        //
        //   objectId: this.get('model.organizationId'),
        //   objectType: 'Organization',
        //
        //   role: role
        // };
        //
        // console.log(record);

        this.get('organizationService').requestUserOrganizationRole(this.get('model.user'), this.get('model.organization'), role);
      },
      notifyMe: function notifyMe() {
        // Info
        this.get('notifications').info('You have one unread message');

        // Error
        this.get('notifications').error('Something went wrong');

        // Success
        this.get('notifications').success('Saved successfully!', { autoClear: true });

        // Warning
        this.get('notifications').warning('You have unsaved changes');
      },
      goToItem: function goToItem(item) {
        alert('I\'d navigate to ' + item + ' page');
      },
      secondaryActions: function secondaryActions(item) {
        alert('Actions to take on ' + item + ' item');
      },
      sortBy: function sortBy(property) {
        this.set('sortProperties', [property]);
        this.toggleProperty('sortAscending');
        this.set('model', this.get('sortedModel')); // set the model to the sorted array
      },
      browse_file: function browse_file() {
        document.getElementById('file').click();
        return false;
      },
      sortColumn: function sortColumn(prop) {
        this.set('desc', !this.get('desc'));
        this.set('activeColumn', prop);
        var model = this.get('model').sort(_utils.ArrayComparer.property(prop, this.get('desc')));
        this.set('model', model.slice());
      },
      actionDeleteObligation: function actionDeleteObligation(obligationId) {
        var that = this;
        var obligationsapi = this.get('obligationsapi');
        obligationsapi.deleteObligation(obligationId, function (err, data) {
          if (!err) {
            that.get('notifications').success("Obligation deleted", { autoClear: true });
            that.getCurrentOrganizationObligations();
          } else {
            that.get('notifications').error(data);
          }
        });
      },
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      setOrganizationType: function setOrganizationType(type) {
        if (type == 'Government Entity') {
          this.set('model.organization.type', 'MUNICIPALITY');
        } else {
          this.set('model.organization.type', 'SERVICE_PROVIDER');
        }
        this.set('model.organization.industry', type);
        this.set('model.organization.category', null);
        this.set('model.organization.subCategory', null);
      },
      setOrganizationCategory: function setOrganizationCategory(category) {
        this.set('model.organization.category', category);
        this.set('model.organization.subCategory', null);
      },
      setOrganizationSubCategory: function setOrganizationSubCategory(subCategory) {
        if (subCategory == 'None') {
          this.set('model.organization.subCategory', this.get('model.organization.category'));
        } else if (subCategory == 'Other') {
          this.set('defaultSubCategories', false);
        } else {
          this.set('model.organization.subCategory', subCategory);
        }
      },
      showSubCategoryDefaults: function showSubCategoryDefaults() {
        this.set('defaultSubCategories', true);
      },
      setOperatingStatus: function setOperatingStatus(operatingStatus) {
        this.set('model.organization.operatingStatus', operatingStatus);
        if (operatingStatus == 'Subsidiary' || operatingStatus == 'Acquired') {
          $('#org-parent .entity-selector input').prop('disabled', false);
          $('#org-parent .entity-selector input').val('');
        } else {
          $('#org-parent .entity-selector input').prop('disabled', true);
          $('#org-parent .entity-selector input').val('None');
        }
      },
      revert: function revert(item) {
        this.get('model').organization.rollbackAttributes();
        this.send('display', item);
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      },
      saveSection: function saveSection(section) {
        var that = this;
        this.get('model.organization').save().then(function () {
          that.get('model.addresses').forEach(function (address) {
            if (address.hasDirtyAttributes) {
              address.save().then(function () {
                that.reloadModel();
              });
            }
          });
          if (that.get('newAddress')) {
            that.get('newAddress').save().then(function () {
              that.set('newAddress', null);
              that.reloadModel();
            });
          }
          that.send('display', section);
          that.get('notifications').success('organization saved', { autoClear: true });
        }).catch(function (error) {
          that.send('display', section);
          that.get('notifications').error("failed: " + error);
        });
      }
    }, _defineProperty(_actions, 'sortColumn', function sortColumn(prop) {
      console.log('sort column by', prop);
      this.set('desc', !this.get('desc'));
      this.set('activeColumn', prop);
    }), _defineProperty(_actions, 'selectCompanySize', function selectCompanySize(size) {
      this.set('model.organization.companySize', size);
    }), _actions)
  });
});