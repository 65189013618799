define('pretty-nuts/components/obligation-party-members', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({

    ///////
    // Data

    members: [],
    serviceProviderOrg: null,

    // Data
    ///////

    ////////////////
    // Configuration

    componentId: Ember.computed('serviceProviderOrg', function () {
      var serviceProviderOrg = this.get('serviceProviderOrg');

      if (serviceProviderOrg) {
        return serviceProviderOrg.get('id');
      }

      return '';
    }),

    // Configuration
    ////////////////

    //////////////////
    // Action bindings

    onAddMember: null,
    onRemoveMember: null,
    userToAdd: null,

    // Action bindings
    //////////////////

    /**
     * Hiding and showing elements
     *
     * @param name name of the element to show (must include the prefix '#')
     * @param show true to show the element, false to hide the element
     */
    showElement: function showElement(name, show) {
      var element = $(name);

      console.log('showElement:', name, show);

      if (show) {
        element.show();
      } else {
        element.hide();
      }
    },


    /**
     * Convert the element name to the localized element name containing the component Id
     *
     * @param name of the element to convert
     * @returns {string} name<componentId> or name if no component id
     */
    getLocalElementName: function getLocalElementName(name) {
      var componentId = this.get('componentId');

      return name + (!Ember.isNone(componentId) ? componentId : '');
    },


    /**
     * Show the add member controls
     */
    showAddMember: function showAddMember() {
      this.showElement(this.getLocalElementName('#add_member'), true);
    },


    /**
     * Hide the add member controls
     */
    hideAddMember: function hideAddMember() {
      this.showElement(this.getLocalElementName('#add_member'), false);
    },


    actions: {
      actionRemoveMember: function actionRemoveMember(member) {

        var onRemoveMember = this.get('onRemoveMember');

        if (onRemoveMember) {
          onRemoveMember(member);
        } else {
          console.log("No onRemoveMember");
        }
      },
      actionAddMember: function actionAddMember(entityType, member) {
        var onAddMember = this.get('onAddMember');

        this.hideAddMember();

        if (onAddMember) {
          onAddMember(member);
          this.set('userToAdd', null);
        } else {
          console.log("No onRemoveMember");
        }
      },
      actionNewMember: function actionNewMember(entityType, searchTerm) {
        var that = this;
        var serviceProviderOrg = this.get('serviceProviderOrg.organization');

        var _searchTerm$name$spli = searchTerm.name.split(" "),
            _searchTerm$name$spli2 = _slicedToArray(_searchTerm$name$spli, 2),
            firstName = _searchTerm$name$spli2[0],
            lastName = _searchTerm$name$spli2[1];

        var newUser = this.get('application.store').createRecord('new-user', { firstName: firstName, lastName: lastName });
        this.get('application').showUserCreation(newUser, serviceProviderOrg, function (createdUser) {
          that.get('application.store').findRecord('integrated-user-profile', createdUser.get('id')).then(function (userProfile) {
            that.send('actionAddMember', '', userProfile);
          });
          console.log('successfully created new user:', createdUser);
        }, function (error) {
          console.log('error:', error);
        });
      },


      /**
       * Action handler for hiding and showing elements
       *
       * @param name name of the element to show (must include the prefix '#')
       * @param show true to show the element, false to hide the element
       */
      actionShowElement: function actionShowElement(name, show) {
        this.showElement(name, show);
      },


      /**
       * Action handler for showing the add member controls
       */
      actionShowAddMember: function actionShowAddMember() {
        this.showAddMember();
      }
    }
  });
});