define("pretty-nuts/helpers/find-by", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.findBy = findBy;
  function findBy(params /*, hash*/) {
    console.log("FIND BY", params);
    return params[0].findBy(params[1], params[2] || null);
  }

  exports.default = Ember.Helper.helper(findBy);
});