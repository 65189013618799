define('pretty-nuts/components/select-obligation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		obligations: ['foo', 'bar', 'baz'],
		selectedObligation: '',
		actions: {
			searchObligations: function searchObligations() {},
			chooseObligation: function chooseObligation() {}
		}
	});
});