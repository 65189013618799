define('pretty-nuts/models/structures', ['exports', 'ember-data'], function (exports, _emberData) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _emberData.default.Model.extend({
		structureId: _emberData.default.attr(),
		structureName: _emberData.default.attr(),
		description: _emberData.default.attr(),
		roles: _emberData.default.attr()
	});
});