define('pretty-nuts/controllers/legal-entity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    organizationService: Ember.inject.service(),
    defaultSubCategories: true,
    operatingStatus: ['Active', 'No Longer Doing Business'],
    leCategories: ['Federal Government Agency', 'State Government', 'State Authority', 'County', 'City/Town', 'District', 'Local Authority', 'Utility', 'Tribal Government'],
    leSubCategories: Ember.computed('model.legalUnit.category', function () {
      var category = this.get('model.legalUnit.category');
      if (category == 'Federal Government Agency' || category == 'State Government') {
        return ['Regulatory Agency', 'Other'];
      } else if (category == 'State Authority' || category == 'Local Authority') {
        return ['Finance Authority', 'Housing Authority', 'Pollution Control Authority', 'Transportation Authority', 'Education & Educational Facilties', 'Economic Development', 'Health & Health Facilities', 'Other'];
      } else if (category == 'District') {
        return ['Water District', 'Fire District', 'Assessment District', 'Facilities District', 'Irrigation District', 'School District', 'Other'];
      } else if (category == 'Utility') {
        return ['Electric Utility', 'Water Utility', 'General Utilities', 'Other'];
      } else if (category == 'Bank/Banking Services') {
        return ['Investment Banking', 'Trust & Custody Banking', 'Other'];
      } else if (category == 'Advisory/Advisory Services') {
        return ['General Municipal Advisory', 'Financial Advisory', 'Investment Advisory', 'Other'];
      } else if (['County', 'City/Town', 'Tribal Government', 'Legal/Legal Services', 'Compliance/Complaince Services', 'Management Services', 'Other'].contains(category)) {
        return ['None', 'Other'];
      }
    }),
    stuff: function stuff() {
      console.log(this.get('model'));
      return this.get('model');
    },
    actions: {
      sortColumn: function sortColumn(prop) {
        this.set('desc', !this.get('desc'));
        this.set('activeColumn', prop);
        var model = this.get('model').sort(ArrayComparer.property(prop, this.get('desc')));
        this.set('model', model.slice());
      },
      edit: function edit(item) {
        $('#' + item + ' .display').toggle();
        $('#' + item + ' .edit').toggle();
      },
      browse_file: function browse_file() {
        $('#file').click();
      },
      uploadLogo: function uploadLogo() {
        var $form = $('#logo-form');
        var legalUnit = this.get('model.legalUnit');
        this.get('organizationService').uploadLegalUnitLogo($form, legalUnit.id);
      },
      setLECategory: function setLECategory(category) {
        this.set('model.legalUnit.category', category);
        this.set('model.legalUnit.subCategory', null);
      },
      setLESubCategory: function setLESubCategory(subCategory) {
        if (subCategory == 'None') {
          this.set('model.legalUnit.subCategory', this.get('model.legalUnit.category'));
        } else if (subCategory == 'Other') {
          this.set('defaultSubCategories', false);
        } else {
          this.set('model.legalUnit.subCategory', subCategory);
        }
      },
      showSubCategoryDefaults: function showSubCategoryDefaults() {
        this.set('defaultSubCategories', true);
      },
      setLEAddress: function setLEAddress(address) {
        console.log('POA', this.get('model.parentOrgAddresses'));
        this.set('model.legalUnit.address', address);
      },
      updateLE: function updateLE(section) {
        var that = this;
        this.get('model.legalUnit').save().then(function (legalUnit) {
          that.get('notifications').success('Legal unit updated', { autoClear: true });
          that.send('edit', section);
        }).catch(function (error) {
          that.get('notifications').error("failed: " + error);
        });
      },
      selectOperatingStatus: function selectOperatingStatus(operatingStatus) {
        this.set('model.legalUnit.operatingStatus', operatingStatus);
      }
    }
  });
});