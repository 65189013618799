define('pretty-nuts/routes/legal-entities', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/constants'], function (exports, _aquornAuthenticatedRouteMixin, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      var data = $.get(_constants.Constants.API_PREFIX + '/search?searchType=&searchText=&searchAllLegalUnits=yes&searchAllOrganizations=yes');
      console.log('route-providers:', data);
      return data;
    }
  }
  // setupController(controller, model) {
  //   this._super(controller, model);
  //   controller.set('entered', true);
  // },
  );
});