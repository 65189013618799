define('pretty-nuts/controllers/providers/my-service-providers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      clickRow: function clickRow(providerId) {
        this.send('activeMenu', providerId);
        this.transitionToRoute('provider', providerId);
      },
      activeMenu: function activeMenu(providerId) {
        $('.active-icon-menu').removeClass('active-icon-menu');
        var idi = '#' + providerId + ' i';
        $(idi).addClass('active-icon-menu');
      },
      sortColumn: function sortColumn(prop) {
        return true;
      }
    }
  });
});