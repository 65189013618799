define('pretty-nuts/services/modal-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    /////////////////////////
    // Configuration and data

    modals: {},

    // Configuration and data
    /////////////////////////

    addModal: function addModal(name) {
      //console.log('Adding modal', name);
      this.set('modals.' + name, {
        show: false,
        callbacks: {},
        args: {}
      });

      //console.log('modals', this.get('modals'));
    },
    showModal: function showModal(name, args) {
      //console.log('Showing modal', name);
      this.set('modals.' + name + '.args', args);

      // Set this last to prevent race condition on args lookup
      this.set('modals.' + name + '.show', true);

      //console.log('modals', this.get('modals'));
    },
    hideModal: function hideModal(name, clear) {
      //console.log('Hiding modal', name);
      this.set('modals.' + name + '.show', false);

      if (clear) {
        this.set('modals.' + name + '.args', {});
      }

      var cancelCallback = this.get('modals.' + name + '.callbacks.cancel');

      if (cancelCallback) {
        cancelCallback();
      }
    },
    getModal: function getModal(name) {
      return this.get('modals.' + name);
    },
    invokeModalCallback: function invokeModalCallback(name) {}
  });
});