define('pretty-nuts/controllers/issuer/cashflow', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function init_query() {
    return {
      resolution: _constants.Constants.Frequency.SEMI_ANNUAL
    };
  }

  function init_filter() {
    return {
      cashflowView: _constants.Constants.CashflowView.OUTSTANDING
    };
  }

  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),

    obligationService: Ember.inject.service(),

    query: init_query(),

    filter: init_filter(),

    debtService: null,

    pieCharts: ['PrincipalAndInterest', 'Obligation'],

    runEveryTime: function () {
      //console.log('running every time I enter the page')
      var count = 0;
      Ember.run.schedule('afterRender', this, function () {
        window.setTimeout(function () {
          window.scrollTo(0, 1);
        }, 100);
        $('.obligations-table').floatThead({ top: 66 });
        var controller = this;
      });
      this.set('entered', false);
      // this.set('model.accumulatedUsers',null);
    }.observes('entered'),

    actions: {}
  });
});