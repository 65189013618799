define('pretty-nuts/serializers/event-draft', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'eventId',
    attrs: {
      eventId: 'eventAquornId',
      masterEventId: 'masterEventAquornId',
      organizationId: 'organizationAquornId',
      userId: 'userAquornId',
      objectId: 'objectAquornId'
    }
  });
});