define('pretty-nuts/routes/issuer/index', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/constants'], function (exports, _aquornAuthenticatedRouteMixin, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      var model = this.modelFor('issuer');

      return model;

      // return this.store.findRecord('legal-entity-debt-profile', model.legalEntityId).then((profile) => {
      //   Ember.set(model, 'profile', profile);
      //
      //   let outstandingObligations = [];
      //
      //   profile.get('outstandingObligationsSummary').forEach((summary) => {
      //     //console.log('summary obligation', summary.get('obligation'));
      //     outstandingObligations.push(summary.get('obligation'));
      //   });
      //
      //   Ember.set(model, 'outstandingObligations', outstandingObligations);
      //
      //   return model;
      // });
    },

    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});