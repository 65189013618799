define('pretty-nuts/transitions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.transition(this.fromRoute(null), this.toRoute('onboarding.overview'), this.use('toDown'));

    this.transition(
    //this.toRoute('onboarding.attributes'),
    //this.toRoute(['onboarding', any]),
    this.withinRoute(anyOnboarding), this.use('toLeft', { duration: 500 }), this.reverse('toRight', { duration: 500 }));
  };

  function anyOnboarding(routeName) {
    return routeName.startsWith('onboarding.');
  }
});