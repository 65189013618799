define('pretty-nuts/components/top-nav-report', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var county = 'County of Sacramento';
  var state = 'State of California';
  var lenses2 = ['Overview', 'Historical Activity', 'Forecasted Activity'];
  var subRoutes = ['state-overview', 'state-historical', 'state-forecasted'];

  function nav() {
    var subRoute = 'county-overview';
    if (this.get('selectedLocation') === state) {
      subRoute = subRoutes[this.get('lenses').indexOf(this.get('selectedLens'))];
    }
    this.get('router').transitionTo('reporting.' + subRoute);
  }

  exports.default = Ember.Component.extend({
    groups: ['Enterprise - Airport', 'Enterprise - Water', 'General Fund', 'CF Districts'],
    locations: [county, state],
    lenses: ['Overview'],

    selectedLocation: 'County of Sacramento',
    selectedLens: 'Overview',
    selectedGroup: 'All',

    init: function init() {
      this._super();
      if (this.get('selectedLocation') === state) {
        this.set('lenses', lenses2);
      }
    },


    actions: {
      navToLocation: function navToLocation(loc) {
        this.set('selectedLocation', loc);
        if (loc === county) {
          this.set('selectedLens', 'Overview');
        }
        nav.call(this);
      },
      navToLens: function navToLens(lens) {
        this.set('selectedLens', lens);
        nav.call(this);
      },
      navToGroup: function navToGroup(group) {
        this.set('selectedGroup', group);
        nav.call(this);
      }
    }
  });
});