define('pretty-nuts/transforms/embedded-model', ['exports', 'ember-data', 'ember-uuid'], function (exports, _emberData, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({

    store: Ember.inject.service(),

    _deserializeArray: function _deserializeArray(serialized, modelName, generateId) {
      var _this = this;

      var items = [];

      serialized.forEach(function (s) {
        var model = _this._deserializeObject(s, modelName, generateId);

        items.pushObject(model);
      });

      return items;
    },
    __deserializeObject: function __deserializeObject(serialized, modelName, generateId) {

      // If we said we need a random id to make ember data happy
      if (generateId) {
        var uuid = (0, _emberUuid.v4)();
        Ember.set(serialized, 'id', uuid);
      }

      var store = this.get('store');
      var serializer = store.serializerFor(modelName);
      var modelClass = store.modelFor(modelName);
      var normalized = serializer.normalize(modelClass, serialized);

      var model = store.push(normalized);

      return model;
    },
    _deserializeObject: function _deserializeObject(serialized, modelName, generateId) {
      if (Ember.isArray(serialized)) {
        return this._deserializeArray(serialized, modelName, generateId);
      }

      return this.__deserializeObject(serialized, modelName, generateId);
    },
    deserialize: function deserialize(serialized, options) {

      if (!serialized) {
        return serialized;
      }

      return this._deserializeObject(serialized, options.modelName, options.generateId);
    },
    _serializeObject: function _serializeObject(deserialized, modelName, generateId) {

      var store = this.get('store');

      // Is object already a model instance (sometimes this happens from arrays)
      if (deserialized.toJSON) {
        return deserialized.serialize({ includeId: !generateId });
      }

      // const store = this.get('store');
      var modelClass = store.createRecord(modelName, deserialized);

      return modelClass.serialize({ includeId: !generateId });
    },
    serialize: function serialize(deserialized, config) {
      if (!deserialized) {
        return deserialized;
      }

      return this._serializeObject(deserialized, config.modelName, config.generateId);
    }
  });
});