define('pretty-nuts/controllers/government/employees', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    applicationController: Ember.inject.controller('application'),
    isAquornAdmin: Ember.computed.reads('applicationController.isAquornAdmin'),

    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        $('.obligations-table').floatThead({ top: 66 });
      });
      this.set('entered', false);
    }.observes('entered')
  });
});