define('pretty-nuts/routes/onboarding/overview', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'ember-changeset'], function (exports, _aquornAuthenticatedRouteMixin, _emberChangeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      console.log('overview route');
      var controller = this.controllerFor('onboarding');
      // let obligation = controller.get('obligation');

      // if (Ember.isNone(obligation)) {
      //   controller.set('obligation', this.store.createRecord('obligation'));
      // }

      var model = this.modelFor('onboarding');

      // model.obligation.reload();

      console.log('uisteps', model.uiSteps);

      // return model.uiSteps.then((uiSteps) => {
      //   console.log('updating uisteps', uiSteps);
      //   model.uiSteps.set('overview',  true);
      //   model.uiSteps.save();
      //
      //   return model;
      // });

      return model;

      //return new Changeset(model, null);
    },


    afterModel: function afterModel(model) {
      this.controllerFor('onboarding').updateProgress(model, 'overview');
    }

  });
});