define('pretty-nuts/components/obligation-filter', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function enumSelectValues(e) {
    return [].concat(_constants.Constants.getEnumValues(e), [_constants.Constants.Enumeration.ALL]);
  }

  function mainUsesCombiner() {
    var mainUses = ['All'];

    var generalUses = Object.keys(_constants.Constants.UsesOfFunds);

    generalUses.forEach(function (key) {
      var uses = _constants.Constants.UsesOfFunds[key];
      mainUses = mainUses.concat(uses);
    });

    return mainUses;
  }

  function initCashflowViews(allowOriginal) {
    var views = [_constants.Constants.CashflowView.OUTSTANDING, _constants.Constants.CashflowView.PAID, _constants.Constants.CashflowView.REFUNDED, _constants.Constants.CashflowView.PRIOR_REFUNDED];

    if (allowOriginal) {
      views.push(_constants.Constants.CashflowView.ORIGINAL);
    }

    return views;
  }

  exports.default = Ember.Component.extend({

    Constants: _constants.Constants,

    store: Ember.inject.service(),

    frequencies: [_constants.Constants.Frequency.ANNUAL, _constants.Constants.Frequency.SEMI_ANNUAL, _constants.Constants.Frequency.ACTUAL],
    // statuses: enumSelectValues(Constants.ObligationStatus),
    taxStatuses: enumSelectValues(_constants.Constants.TaxStatus),
    pledges: enumSelectValues(_constants.Constants.SecurityPledge),
    purposes: enumSelectValues(_constants.Constants.ObligationPurpose),
    structures: enumSelectValues(_constants.Constants.ObligationStructure),
    mainUses: mainUsesCombiner(),
    cashflowViews: Ember.computed('allowOriginalCashflowView', function () {
      return initCashflowViews(this.get('allowOriginalCashflowView'));
    }),

    ////////////////////////
    // Configuration & state

    allowViewOptions: true,
    allowFilter: true,

    allowPerspectiveDate: true,
    allowFromDate: true,
    allowToDate: true,

    legalEntity: null,
    organization: null,

    // query: {},
    // filter: {cashflowView: Constants.CashflowView.OUTSTANDING},

    parameters: {},
    internalParameters: {},

    filterResults: null,

    // Configuration & state
    ////////////////////////

    incomingParamsObserver: function () {
      var internalParameters = {};

      // console.log('incoming parameters', this.get('parameters'));

      internalParameters = Ember.merge(internalParameters, this.get('parameters'));

      this.set('allowPerspectiveDate', Ember.get(internalParameters, 'cashflowView') !== _constants.Constants.CashflowView.ORIGINAL);

      // console.log('finalizing parameters', internalParameters);
      this.set('internalParameters', internalParameters);
    }.observes('parameters').on('init'),

    cashflowViewObserver: function () {

      var cashflowView = this.get('internalParameters.cashflowView');

      var allowPerspectiveDate = true;
      var allowFromDate = true;
      var allowToDate = true;

      if (_constants.Constants.CashflowView.ORIGINAL === cashflowView) {
        allowPerspectiveDate = false;
        allowFromDate = false;
        allowToDate = false;
      }

      this.set('allowPerspectiveDate', allowPerspectiveDate);
      this.set('allowFromDate', allowFromDate);
      this.set('allowToDate', allowToDate);
    }.observes('internalParameters.cashflowView'),

    actions: {
      saveSection: function saveSection(item) {
        this.send('display', item);
      },
      revert: function revert(item) {
        this.send('display', item);
      },
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      },
      actionRefreshReport: function actionRefreshReport(item) {
        this.send('display', item);
        var parameters = this.get('internalParameters');
        console.log('actionRefreshReport', parameters);
        this.get('updateParameters')(parameters);
        // const grouping = this.get('model.grouping');
        // const start = this.get('model.start');
        //
        // let report = this.get('model.report');
        //
        // // report.setGrouping(grouping);
        // // report.setStart(start);
        // report.update(start);
        // this.set('model.templateGrouping', grouping);
        // this.send('display', 'editable');
      },
      actionSetFilterAttribute: function actionSetFilterAttribute(attribute, value) {
        this.get('setFilterAttribute')(attribute, value);
      },
      actionSetQueryAttribute: function actionSetQueryAttribute(attribute, value) {
        this.get('setQueryAttribute')(attribute, value);
      },
      actionSetQueryDate: function actionSetQueryDate(attribute, evt) {
        this.get('setQueryAttribute')(attribute, evt.target.value);
      }
    }
  });
});