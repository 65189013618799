define('pretty-nuts/adapters/application', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'pretty-nuts/adapters/deferred-result', 'pretty-nuts/constants'], function (exports, _emberData, _dataAdapterMixin, _deferredResult, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    // namespace: '/api',
    namespace: _constants.Constants.API_PREFIX,
    session: Ember.inject.service(),
    authorizer: 'authorizer:aquorn-authorizer',

    urlForQueryRecord: function urlForQueryRecord(query, modelName) {
      // console.log('urlForQueryRecord', query);
      var id = query.id;
      delete query.id;

      return this.buildURL(modelName, id);
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);

      //console.log('update snapshot', snapshot);

      serializer.serializeIntoHash(data, type, snapshot);

      //console.log('snapshot serialized data', data);

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      var content = {
        data: data
      };

      //console.log('adapterOptions', snapshot.adapterOptions);

      if (snapshot.adapterOptions) {
        if (snapshot.adapterOptions.patch) {
          content.data.changedAttributes = Object.keys(snapshot.changedAttributes());
        }
      }

      return this.ajax(url, "PATCH", content);
    },
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      // if (status === 202) {
      //   console.log('status', status);
      //   console.log('headers', headers);
      //   console.log('payload', payload);
      //   console.log('requestData', requestData);
      // }

      if (status !== 200) {
        var message = null;

        switch (status) {
          case 401:
            {
              message = "Session has been logged out, return to login";
              //return new DS.AdapterError(message);
              return this._super.apply(this, arguments);
            }

          case 400:
            {
              var validationErrors = Ember.get(payload, 'meta.validationErrors');
              return new _emberData.default.InvalidError([validationErrors]);
            }

          case 202:
            {
              var operation_meta = payload.meta.operation;
              return new _deferredResult.default([operation_meta]);
            }
        }

        return this._super.apply(this, arguments);
      }

      var data = payload.data;

      if (!data) {
        data = [];
      }

      var meta = payload.meta;

      if (meta) {
        data.meta = meta;
      }

      return this._super(status, headers, data, requestData);
    }
  });
});