define('pretty-nuts/models/security-refund-determination', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    // security: DS.belongsTo('security'),
    aquornId: _emberData.default.attr(),

    callDate: _emberData.default.attr(),
    callPrice: _emberData.default.attr(),
    outstandingPrincipal: _emberData.default.attr(),
    refundPrincipal: _emberData.default.attr(),
    remainingPrincipal: _emberData.default.attr(),

    paymentDeterminations: _emberData.default.attr('embedded-models', {
      modelName: 'principal-payment-refund-determination',
      generateId: true
    })

    // security: DS.attr('embedded-model', {modelName: 'security'})

    // security: Ember.computed('securityAquornId', function () {
    //   const aquornId = this.get('securityAquornId');
    //
    //   if (aquornId) {
    //     return this.get('store').query('security', {aquornId: aquornId}).then((securities) => {
    //       return securities.objectAt(0);
    //     });
    //   }
    //
    //   return {};
    // })
  });
});