define('pretty-nuts/components/column-header', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      sort: function sort(prop) {
        this.get('onSort')(prop);
      }
    }
  });
});