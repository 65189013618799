define('pretty-nuts/routes/providers/all-service-providers', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'ember-infinity/mixins/route', 'pretty-nuts/accumulated-result'], function (exports, _aquornAuthenticatedRouteMixin, _route, _accumulatedResult) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, _route.default, {
    query: null,
    sortBy: '',

    model: function model() {
      return Ember.RSVP.hash({
        providersInfinite: this.store.query('organization', {
          filter: this.get('query'),
          type: 'SERVICE_PROVIDER',
          pageSize: 50,
          searchType: 'WILD_CARD',
          sortBy: this.get('sortBy'),
          isAsc: !this.get('desc'),
          pageNo: 1
        })
      }).then(function (model) {
        var accumulatedProviders = _accumulatedResult.default.create();
        accumulatedProviders.initData(model.providersInfinite);
        return {
          accumulatedProviders: accumulatedProviders
        };
      });
    },
    setupController: function setupController(controller, model) {
      this.set('query', '');
      this._super(controller, model);
      controller.set('entered', true);
    },


    actions: {
      actionReloadFilter: function actionReloadFilter(query, sortBy, desc, filterByCurrentUserOrganization) {
        this.set('query', query);
        this.set('sortBy', sortBy);
        this.set('desc', desc);
        this.set('filterByCurrentUserOrganization', filterByCurrentUserOrganization);
        this.refresh();
      }
    }
  });
});