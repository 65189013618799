define('pretty-nuts/models/use-of-funds', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('obligation'),
    copyId: _emberData.default.attr(),

    type: _emberData.default.attr(),
    subType: _emberData.default.attr(),

    provider: _emberData.default.belongsTo('organization'),
    name: _emberData.default.attr(),
    role: _emberData.default.attr(),

    price: _emberData.default.attr(),
    pricePerBond: _emberData.default.attr(),
    parPrice: _emberData.default.attr()
  });
});