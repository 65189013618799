define('pretty-nuts/routes/provider', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    notifications: Ember.inject.service('notification-messages'),
    organizationService: Ember.inject.service(),
    authapi: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var organizationService = this.get('organizationService');
      var orgId = params.uid;
      return Ember.RSVP.hash({
        // organization: this.store.findRecord('organization', orgId),
        organization: this.store.findRecord('organization', orgId),
        addresses: organizationService.getOrganizationAddresses(orgId),
        subsidiaries: organizationService.getOrganizationSubsidiaries(orgId),
        employeeUserProfiles: organizationService.getEmployeeUserProfiles(orgId)
      }).then(function (model) {
        _this.set('org', model.organization);
        model.headquarters = model.addresses.find(function (address) {
          return address.get('hq');
        });
        return model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('oldHQAddress', model.headquarters);
      controller.set('selectedHQAddress', model.headquarters);
    },


    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});