define('pretty-nuts/components/site-nav', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    currentUser: Ember.inject.service(),
    store: Ember.inject.service('store'),
    showModal: false,

    user: Ember.computed('currentUser.user', function () {
      var currentUser = this.get('currentUser');
      return currentUser.getProfile();
    }),

    organization: Ember.computed('currentUser.user', function () {
      return this.get('currentUser').getMyOrganization();
      // var employment = this.get('currentUser').getCurrentEmployment();
      // return employment.get('organization');
    }),
    pageName: Ember.computed('router.currentRouteName', function () {
      var pageName = '';
      var route = this.get('router.currentRouteName');
      if (route == 'index') {
        pageName = 'dashboard';
        return pageName;
      }
      if (route == 'onboarding.index') {
        pageName = 'onboarding';
        return pageName;
      }
      return route;
    }),
    actions: {
      activeMenu: function activeMenu(id) {
        this.set('query', null);
        $('.active-icon-menu').removeClass('active-icon-menu');
        $('#' + id + ' i').addClass('active-icon-menu');
        $('.is-dropdown-submenu').removeClass('js-dropdown-active');
        $('.is-dropdown-submenu-parent').removeAttr('data-is-click');
      },
      toggleTutorial: function toggleTutorial() {
        $('.side-nav .tools').toggleClass('tutorial');
        $('.expand-handle > i').toggleClass('fa-chevron-circle-left');
        $('.expand-handle > i').toggleClass('fa-chevron-circle-right');
        $('.app-content').toggleClass('tutorial');
        $('.tools-header').toggleClass('tutorial');
      },
      clearNavMenu: function clearNavMenu() {
        $('.active-icon-menu').removeClass('active-icon-menu');
        console.log('CLEAR', this.get('query'));
        this.set('query', null);
      },
      logout: function logout() {
        this.get('onLogout')();
      }
    } /*,
       actionClickOnboardObligation() {
        console.log("Onboard obligation action!!!!");
        var controller = this.controllerFor('onboarding').resetObligation();
        controller.transitionToRoute("onboarding");
      }
      */

  });
});