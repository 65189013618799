define('pretty-nuts/controllers/reporting/legal-entity/cashflow', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function init_filter() {
    return {
      cashflowView: _constants.Constants.CashflowView.OUTSTANDING
    };
  }

  exports.default = Ember.Controller.extend({
    // Constants: Constants,
    // government: Ember.inject.controller(),
    // application: Ember.inject.controller(),
    // obligationService: Ember.inject.service(),
    // organizationService: Ember.inject.service(),
    // currentUser: Ember.inject.service('current-user'),
    // issuer: Ember.inject.controller(),
    // resolution: Constants.Frequency.ANNUAL,
    // reloadObligationOverviews: false,
    //
    // applicationController: Ember.inject.controller('application'),
    // isAquornAdmin: Ember.computed.reads('applicationController.isAquornAdmin'),
    //
    // hasAccess: Ember.computed('currentUser', function() {
    //   const currentUser = this.get('currentUser');
    //   return currentUser.hasAccess(this.get('model.organization.id'), 'springapp/organizations/roles/approveRequest');
    // }),
    // displayDescription: Ember.computed('government.permissionsString', function() {
    //   return this.get('model.organization.description') || this.get('hasAccess');
    // }),
    // displayContact: Ember.computed('government.permissionsString', function() {
    //   let hasContactInfo = this.get('phoneNumber') || this.get('email')
    //   return this.get('model.organization.description') || this.get('hasAccess');
    // }),
    //
    query: {
      resolution: _constants.Constants.Frequency.ANNUAL
    },
    filter: init_filter(),
    //
    pieCharts: ['PrincipalAndInterest', 'Obligation'],
    // currentChartView: 'obligation',
    //
    // reloadModel() {
    //   this.send('reloadModel');
    // },
    // outstandingObligations: Ember.computed('profile',function(){
    //   let outstandingObligations = [];
    //   console.log('PROFILE',this.get('profile'))
    //
    //   this.get('profile').get('outstandingObligationsSummary').forEach((summary) => {
    //     //console.log('summary obligation', summary.get('obligation'));
    //     outstandingObligations.push(summary.get('obligation'));
    //   });
    //
    //   return outstandingObligations;
    // }),
    // reloadObligationOverviewsObserver: function() {
    //   if(this.get('reloadObligationOverviews')) {
    //     var that = this;
    //     var organizationId = this.get('model.organization.id');
    //     this.get('store').unloadAll('obligation-overview');
    //     return this.get('store').query('obligation-overview',
    //       { organizationId: organizationId }
    //     ).then((obligationOverviews) => {
    //       that.set('model.obligationOverviews', obligationOverviews);
    //       that.set('reloadObligationOverviews', false);
    //     });
    //   }
    // }.observes('reloadObligationOverviews'),
    actions: {
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      }
    }
  });
});