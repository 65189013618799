define('pretty-nuts/components/new-person', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		actions: {
			new_person: function new_person() {
				$('#new_person').show();
			},
			add_person: function add_person() {
				// add a new person to the database here
				$('#new_person').hide();
				$('html').focus();
			}
		}
	});
});