define('pretty-nuts/routes/reporting/obligation', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'ember-concurrency'], function (exports, _aquornAuthenticatedRouteMixin, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    obligationService: Ember.inject.service(),
    organizationService: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model(params) {
      var obligationService = this.get('obligationService');

      return Ember.RSVP.hash({
        integratedObligation: obligationService.getIntegratedObligation(params.obligationId).load()
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    }
  });
});