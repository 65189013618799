define('pretty-nuts/controllers/reporting/legal-entity/30-year', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    currentReport: { value: '30-year', name: '30 Year Debt Service' },
    operationService: Ember.inject.service(),
    reporting: Ember.inject.controller(),
    landscape: false,
    entered: false,

    groupings: [_constants.Constants.Grouping.OBLIGATION, _constants.Constants.Grouping.AMT_STATUS,
    // Constants.Grouping.CALL_STATUS,
    _constants.Constants.Grouping.FEDERAL_TAX_STATUS, _constants.Constants.Grouping.OFFERING_TYPE, _constants.Constants.Grouping.PURPOSE,
    // Constants.Grouping.RATE_TYPE,
    _constants.Constants.Grouping.SALE_METHOD, _constants.Constants.Grouping.SECURITY_PLEDGE, _constants.Constants.Grouping.STATE_TAX_STATUS, _constants.Constants.Grouping.STRUCTURE_TYPE],

    groupingToEnumMap: {
      Obligation: null,
      AMTStatus: _constants.Constants.AMTStatus,
      // Constants.Grouping.CALL_STATUS: null,
      FederalTaxStatus: _constants.Constants.TaxStatus,
      OfferingType: _constants.Constants.OfferingType,
      Purpose: _constants.Constants.ObligationPurpose,
      // Constants.Grouping.RATE_TYPE:, null,
      SaleMethod: _constants.Constants.SaleType,
      SecurityPledge: _constants.Constants.SecurityPledge,
      StateTaxStatus: _constants.Constants.TaxStatus,
      StructureType: _constants.Constants.ObligationStructure
    },

    applicationController: Ember.inject.controller('application'),
    isAquornAdmin: Ember.computed.reads('applicationController.isAquornAdmin'),
    enumType: Ember.computed('model.grouping', function () {
      var grouping = this.get('model.grouping');
      var map = this.get('groupingToEnumMap');

      return map[grouping.jsonValues[0]] || null;
    }),

    overlayObserver: function () {
      var running = this.get('model.report.task.isRunning');

      if (running) {
        console.log('hiding overlay');
        $('.report').addClass('calculating');
      } else {
        console.log('showing overlay');
        $('.report').removeClass('calculating');
      }
    }.observes('model.report.task.isRunning'),

    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        this.get('reporting').send('switchToLandscape', true);
      });
      this.set('entered', false);
    }.observes('entered'),

    actions: {
      saveSection: function saveSection(item) {
        this.send('display', item);
      },
      revert: function revert(item) {
        this.send('display', item);
      },
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').css('display', 'flex');
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      },
      actionRefreshReport: function actionRefreshReport() {
        var grouping = this.get('model.grouping');
        var start = this.get('model.start');

        var report = this.get('model.report');

        // report.setGrouping(grouping);
        // report.setStart(start);
        report.update(start);
        this.set('model.templateGrouping', grouping);
        this.send('display', 'editable');
      }
    }
  });
});