define('pretty-nuts/services/notification-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    getNotifications: function getNotifications(pageNo, pageSize, seen, userId) {
      var query = {};

      if (pageNo !== undefined && pageNo > 0 && pageSize !== undefined && pageSize > 0) {
        query['pageNo'] = pageNo;
        query['pageSize'] = pageSize;
      }

      if (seen !== undefined) {
        query['seen'] = seen;
      }

      if (userId !== undefined) {
        query['userId'] = userId;
      }

      return this.get('store').query('notification', query);
    },
    getNotification: function getNotification(notificationId) {
      return this.get('store').findRecord('notification', notificationId);
    },
    updateNotificationSeen: function updateNotificationSeen(notificationId, seen) {
      return this.get('store').findRecord('notification', notificationId).then(function (notification) {
        notification.set('seen', seen);
        return notification.save().then(function () {
          // console.log(notification);
          return notification;
        });
      });
    },
    deleteNotification: function deleteNotification(notificationId) {
      return this.get('store').findRecord('notification', notificationId).then(function (notification) {
        notification.deleteRecord();
        return notification.save().then(function () {
          return;
        });
      });
    }
  });
});