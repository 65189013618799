define('pretty-nuts/models/security-cashflow', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    accrualPeriods: _emberData.default.attr(),
    interestPeriods: _emberData.default.attr(),
    accretions: _emberData.default.attr(),
    principalPayments: _emberData.default.attr(),
    interestPayments: _emberData.default.attr(),
    principalSchedule: _emberData.default.attr(),
    refundPrincipalMap: _emberData.default.attr(),
    outstandingPrincipal: _emberData.default.attr(),

    principalPaymentIdMap: Ember.computed('principalPayments', function () {
      var principalPayments = this.get('principalPayments');
      var idMap = {};

      if (!principalPayments) {
        return idMap;
      }

      Object.keys(principalPayments).forEach(function (paymentDate) {

        principalPayments[paymentDate].forEach(function (principalPayment) {
          idMap[principalPayment.id] = principalPayment;
        });
      });

      return idMap;
    })
  });
});