define('pretty-nuts/routes/issuer/events', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    eventService: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    organizationService: Ember.inject.service(),

    queryParams: {
      selectedStartDate: {
        refreshModel: true
      },
      selectedEndDate: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var eventService = this.get('eventService');
      var organizationService = this.get('organizationService');
      var issuerModel = this.modelFor('issuer');
      console.log('this issuer is ', issuerModel);

      return Ember.RSVP.hash({
        events: eventService.getEvents(params.selectedStartDate, params.selectedEndDate, '', '', '', issuerModel.legalUnit.id)
      }).then(function (model) {
        return model;
      });
    }
  });
});