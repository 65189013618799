define('pretty-nuts/helpers/is-empty', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEmpty = isEmpty;
  function isEmpty(params /*, hash*/) {
    if (params[0] === undefined || params[0] === '') {
      return true;
    }
    return false;
  }

  exports.default = Ember.Helper.helper(isEmpty);
});