define('pretty-nuts/models/organization-role-request', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    requestId: _emberData.default.attr(),

    requestType: _emberData.default.attr(),

    requestorId: _emberData.default.belongsTo('user'),
    requestorType: _emberData.default.attr(),

    objectId: _emberData.default.belongsTo('organization'),
    objectType: _emberData.default.attr(),

    requestDate: _emberData.default.attr(),
    approvalDate: _emberData.default.attr(),

    status: _emberData.default.attr(),

    role: _emberData.default.attr(),

    approver: _emberData.default.belongsTo('user')
  });
});