define('pretty-nuts/routes/my-submissions', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model(params) {
      //return db.getTable('obligations');
      //console.log("OBLIGATION PARAMS: ");
      //console.log(params);
      var that = this;

      var result = {};
      console.log('PRE PROMISE');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        console.log('POST PROMISE');
        Ember.$.ajax({
          //url: '/api/org/203000000000000001/obligations',
          url: '/obligation/mysubmissions',
          type: 'GET',
          //contentType: 'application/json',
          //dataType: 'json'
          success: function success(response) {
            console.log('PRE JSON', response);
            var content = response;
            console.log('OBLIGATIONS:', content);

            // Build the model data structure
            var result = {
              obligations: content,
              submissions: [],
              outstanding: []
            };

            result.obligations.forEach(function (obligation) {
              var status = obligation.obligationOwner.status;
              if (status == 0) {
                result.submissions.pushObject(obligation);
              } else if (status != 0) {
                result.outstanding.pushObject(obligation);
              }
            });

            // Tell ember the data model is ready...
            resolve(result);
          },
          error: function error(xhr, status, _error) {
            return 'error';
          }
        });
      });
    }
  });
});