define('pretty-nuts/routes/person', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    notifications: Ember.inject.service('notification-messages'),
    authapi: Ember.inject.service(),

    model: function model(params) {
      var userId = params.uid;
      return Ember.RSVP.hash({
        user: this.store.findRecord('user', userId),
        emails: this.store.query('email', { userId: userId }),
        employments: this.store.query('employment', { userId: userId }),
        phones: this.store.query('phone', { userId: userId })
      });
    },

    setupController: function setupController(controller, model) {
      controller.set("model", model);
    },
    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});