define("pretty-nuts/transforms/date-time", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {

      if (serialized) {
        var parts = serialized.split("T");

        return {
          date: parts[0],
          time: parts[1]
        };
      }

      return {
        date: null,
        time: null
      };
    },
    serialize: function serialize(deserialized) {
      var dt = deserialized;

      if (!Ember.isNone(dt)) {
        if (!Ember.isNone(dt.date) && !Ember.isNone(dt.time)) {
          return dt.date + "T" + dt.time;
        }
      }

      return null;
    }
  });
});