define('pretty-nuts/models/stats-cashflow', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    tables: _emberData.default.attr('embedded-models', { modelName: 'stats-cashflow-table', generateId: true }),

    row_tables: Ember.computed('tables', function () {
      var tables = this.get('tables');
      var row_charts = {};

      if (!tables) {
        return row_charts;
      }

      var table_names = Object.keys(tables);

      table_names.forEach(function (table_name) {
        var table_columns = Ember.get(tables, table_name + '.columns');
        var dates = null;
        var columns = [];
        var rows = [];

        // console.log('table_columns', table_columns);

        table_columns.forEach(function (column) {
          // console.log('column', column);
          if (column.get('name') === 'Date') {
            dates = column.get('values');
          } else {
            columns.push(column);
          }
        });

        // console.log('dates', dates);

        var _loop = function _loop(i) {
          var row = [];

          row.push(dates[i]);

          columns.forEach(function (column) {
            row.push({
              valueType: column.get('valueType'),
              value: column.get('values')[i]
            });
          });

          rows.push(row);
        };

        for (var i = 0; i < dates.length; i++) {
          _loop(i);
        }

        row_charts[table_name] = rows;
      });

      // console.log('row_charts', row_charts);

      return row_charts;
    })
  });
});