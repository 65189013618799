define('pretty-nuts/helpers/format-date-now', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatDateNow = formatDateNow;
  function formatDateNow(params /*, hash*/) {
    var date = params[0];
    return moment(date).fromNow();
  }

  exports.default = Ember.Helper.helper(formatDateNow);
});