define('pretty-nuts/components/variable-rates', ['exports', 'pretty-nuts/constants', 'pretty-nuts/paginated-result', 'pretty-nuts/accumulated-result'], function (exports, _constants, _paginatedResult, _accumulatedResult) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    // infinity: Ember.inject.service(),
    obligationService: Ember.inject.service(),
    accumulatedData: undefined,

    didInsertElement: function didInsertElement() {
      // brings the view into focus in order to capture keyUps.
      // there are a few ways to handle this, this is just one.
      return this.$().attr({ tabindex: 1 }), this.$().focus();
    },
    keyDown: function keyDown(e) {
      console.log('PRESSED', e.keyCode);
      // Do we need this? Will the even continue propagating even though we caught it here
      e.stopPropagation();

      switch (e.keyCode) {
        case _constants.Constants.KEY_LEFT:
          {
            // Move up list
            this.pseudoTab(-1);

            break;
          }
        case _constants.Constants.KEY_RIGHT:
          {
            // Move up list
            this.pseudoTab(1);

            break;
          }

        case _constants.Constants.KEY_DOWN:
          {
            // Move down list
            this.changeSelection(1);

            break;
          }

        case _constants.Constants.KEY_ENTER:
          {
            // User has selected the highlighted item

            break;
          }

        case _constants.Constants.KEY_ESCAPE:
          {

            break;
          }

        default:
          {}
      }
    },
    pseudoTab: function pseudoTab(offset) {
      if (offset == 1) {
        console.log('pseudoTab');
        var inputs = $(this).closest('form').find(':input');
        inputs.eq(inputs.index(this) + 1).select();
      }
    },
    changeSelection: function changeSelection(offset) {
      console.log('entities', this.get('entities'));
      console.log('offset', offset);
      var size = this.get('entities').length;
      var listPos = this.get('listPos');
      // If there are entities to set, update the highlight cursor
      if (size > 0) {
        listPos = (listPos + offset) % size;

        // Remove existing highlight and apply new highlight position
        $('a.hover').removeClass('hover');
        $('#' + $(this)[0].elementId + ' #' + listPos).addClass('hover');
      } else {
        // No entities to select
        listPos = -1;
      }

      // Save the new position
      this.set('listPos', listPos);
    },

    rowIsBeingEdited: false,

    data: Ember.computed('security.cashflow.interestPeriods', function () {
      var data = [];
      data = this.get('security.cashflow.interestPeriods');
      data.dataQueryParams = {
        groupValue: 'NONE',
        searchText: this.get('application').get('query'),
        sortBy: this.get('activeColumn'),
        isAsc: !this.get('desc'),
        pageSize: 20,
        pageNo: 1
      };
      return data;
    }),
    actions: {
      loadBelow: function loadBelow(item, index) {
        console.log('LOADBELOW', item, index);
      },
      actionMoreGroup: function actionMoreGroup(data) {
        data.more();
      },
      select: function select(event) {
        $(event.target).select();
      },
      actionEditRow: function actionEditRow(index, attribute, event) {

        console.log('editRow', index, attribute, event);

        var integratedObligation = this.get('integratedObligation');
        var securityId = this.get('security.id');
        var value = event.target.value;

        var interestPeriod = this.get('security.cashflow.interestPeriods.' + index);

        Ember.set(interestPeriod, attribute, value);

        integratedObligation.setInterestPeriod(securityId, index, interestPeriod.resetDate, interestPeriod.forecastRate, interestPeriod.actualRate, interestPeriod.startDate, interestPeriod.endDate);
      },
      actionSaveSecurity: function actionSaveSecurity(security) {
        var obligationService = this.get('obligationService');
        var controller = this;

        console.log('Saving security');
        obligationService.updateSecurity(security).perform(function () {
          controller.get('security').reload().then(function () {
            security.refresh();
          });
        });
      }
    }
  });
});