define('pretty-nuts/components/aquorn-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    modalService: Ember.inject.service(),

    ////////////////
    // Configuration

    name: null,

    onHide: null,
    onShow: null,

    // Configuration
    ////////////////

    _setupModal: function _setupModal() {
      var name = this.get('name');

      this.get('modalService').addModal(name);

      Ember.addObserver(this, 'modalService.modals.' + name + '.show', this, 'show');
    },
    init: function init() {
      this._super.apply(this, arguments);
      this._setupModal();
    },
    show: function show() {
      var modalService = this.get('modalService');
      var name = this.get('name');
      var state = modalService.getModal(name);

      //console.log('state.show', state.show);

      if (state.show) {
        $('#' + name).foundation('open');

        var onShow = this.get('onShow');

        if (onShow) {
          //onShow(state.args);
          this.onShow(state.args);
        }
      } else {
        //console.log(`#${name}`, $(`#${name}`));
        $('#' + name).foundation('close');

        var onHide = this.get('onHide');

        if (onHide) {
          this.onHide();
        }
      }
    },
    hide: function hide(clear) {
      this.get('modalService').hideModal(this.get('name'), clear);
    },


    actions: {
      actionHideModal: function actionHideModal(clear) {
        this.hide(clear);
      }
    }

  });
});