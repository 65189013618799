define('pretty-nuts/models/obligation-debt-service-summary', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('obligation'),

    beginDate: _emberData.default.attr(),
    endDate: _emberData.default.attr(),

    parIssued: _emberData.default.attr(),

    firstPrincipalDate: _emberData.default.attr(),
    finalPrincipalDate: _emberData.default.attr(),

    totalPrincipal: _emberData.default.attr(),
    totalInterest: _emberData.default.attr(),
    totalPayments: _emberData.default.attr(),

    outstandingPrincipal: _emberData.default.attr()

  });
});