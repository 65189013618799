define('pretty-nuts/transforms/decimal', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var DEFAULT_PRECISION = 3;

  exports.default = _emberData.default.Transform.extend({
    _setPrecision: function _setPrecision(number, precision) {
      var splitNumber = number.split('.');
      var radix = splitNumber.length > 1 ? splitNumber[1] : '';

      if (radix.length > precision) {
        radix = radix.substr(0, precision);
      } else {
        while (radix.length < precision) {
          radix += '0';
        }
      }

      return splitNumber[0] + '.' + radix;
    },
    deserializeValue: function deserializeValue(value, options) {
      //return new BigNumber(value).toFormat(options.precision || DEFAULT_PRECISION);

      if (!value) {
        return null;
      }

      var deserialized = value.replace(/,/g, '');

      return this._setPrecision(value, options.precision);

      // let radixPosition = deserialized.indexOf('.');
      //
      // // console.log('pre deserialized', deserialized);
      //
      // if (radixPosition >= 0) {
      //   if (deserialized.length - radixPosition > options.precision) {
      //     deserialized = deserialized.substr(0, radixPosition + options.precision + 1);
      //   }
      // }
      //
      // // console.log('post deserialized', deserialized);
      //
      // return deserialized;
    },
    serializeValue: function serializeValue(value, options) {

      var parsedNumber = new BigNumber(value.replace(/,/g, ''));
      var serializedNumber = parsedNumber.toFormat(options.precision || DEFAULT_PRECISION).replace(/,/g, '');

      return this._setPrecision(serializedNumber, options.precision);

      // return new BigNumber(value.replace(/,/g, '')).toFormat(options.precision || DEFAULT_PRECISION).replace(/,/g, '');
    },
    deserialize: function deserialize(serialized, options) {
      var _this = this;

      if (!Ember.isNone(serialized)) {
        if (Ember.isArray(serialized)) {
          var arr = [];

          serialized.forEach(function (entry) {
            arr.push(_this.deserializeValue(entry, options));
          });

          return arr;
        } else {
          return this.deserializeValue(serialized, options);
        }
      }

      return serialized;
    },
    serialize: function serialize(deserialized, options) {
      var _this2 = this;

      //return deserialized;

      var serialized = deserialized;

      //console.log('decimal deserialized:', deserialized);

      if (!Ember.isNone(deserialized)) {
        if (Ember.isArray(deserialized)) {
          var arr = [];

          deserialized.forEach(function (entry) {
            arr.push(_this2.serializeValue(entry, options));
          });

          //return arr;
          serialized = arr;
        } else {
          serialized = this.serializeValue(serialized, options);
        }
      }

      //console.log('decimal serialized:', serialized);

      return serialized;
    }
  });
});