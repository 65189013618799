define('pretty-nuts/services/admin-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    getDomainWhitelist: function getDomainWhitelist(domainWhiteListId) {
      return this.get('store').findRecord('domain-Whitelist', domainWhiteListId);
    },
    getDomainWhitelists: function getDomainWhitelists() {
      return this.get('store').query('domain-Whitelist', {});
    },
    createDomainWhitelist: function createDomainWhitelist(domainWhitelist) {
      domainWhitelist = this.get('store').createRecord('domain-Whitelist', domainWhitelist);

      return domainWhitelist.save().then(function () {
        return domainWhitelist;
      });
    },
    deleteDomainWhitelist: function deleteDomainWhitelist(domainWhitelistId) {
      var id = domainWhitelistId;
      this.get('store').findRecord('domain-Whitelist', id, { backgroundReload: false }).then(function (domainWhitelist) {
        domainWhitelist.deleteRecord();
        domainWhitelist.get('isDeleted');
        domainWhitelist.save();
      });
    }
  });
});