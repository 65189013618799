define('pretty-nuts/controllers/onboarding/parties', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    //////////////////////////////////
    // Constants mapping for local use

    OBLIGOR: _constants.Constants.OBLIGOR,
    ISSUER: _constants.Constants.ISSUER,
    FINANCE_TEAM: _constants.Constants.FINANCIAL_ADVISOR,
    UNDERWRITER: _constants.Constants.LEAD_UNDERWRITER,
    OTHER_PARTY: _constants.Constants.OTHER,
    isNull: null,
    serviceProviderArgs: { type: 'SERVICE_PROVIDER' },
    serviceProviderToAdd: null,

    financeTeamTypes: [_constants.Constants.BOND_COUNSEL, _constants.Constants.FINANCIAL_ADVISOR, _constants.Constants.UNDERWRITERS_COUNSEL, _constants.Constants.DISCLOSURE_COUNSEL, _constants.Constants.SPECIAL_TAX_COUNSEL, _constants.Constants.VERIFICATION_AGENT, _constants.Constants.CREDIT_ENHANCER, _constants.Constants.TRUSTEE, _constants.Constants.PAYING_AGENT, _constants.Constants.ESCROW_AGENT, _constants.Constants.AUDITOR, _constants.Constants.GENERAL_COUNCEL, _constants.Constants.FEASIBILITY_CONSULTANT, _constants.Constants.FISCAL_CONSULTANT, _constants.Constants.DISSEMINATION_AGENT],

    underwriterTeamTypes: [_constants.Constants.LEAD_UNDERWRITER, _constants.Constants.UNDERWRITER_MEMBER],

    // Constants mapping for local use
    //////////////////////////////////

    ///////////////////////////
    // Services and Controllers

    onboarding: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    application: Ember.inject.controller(),

    // Services and Controllers
    ///////////////////////////

    ///////
    // Data

    issuers: [],
    obligors: [],
    financingTeam: [],
    underwriters: [],
    otherParties: [],

    // Data
    ///////


    /**
     * Financing Team (Parties involved at issuance but not necessarily ongoing)
     1.  Bond Counsel
     2.  Financial Advisor
     3.  Underwriter\'s Counsel
     4.  Disclosure Counsel
     5.  Special Tax Counsel
     6.  Verification Agent
     7.  Credit Enhancer
     8.  Trustee/Paying Agent (entity can be either or both depending on deal type)
     9.  Maybe a few others...
      Underwriter:   Pretty straight forward.
      Other Parties.  Anyone else.
     */
    refreshServiceProviders: function refreshServiceProviders() {
      var _this = this;

      var serviceProviders = this.get('model.integratedObligation.value.serviceProviders');
      var issuers = [];
      var financingTeam = [];
      var underwriters = [];
      //let obligors = [];
      var otherParties = [];

      console.log('service providers', serviceProviders);

      if (!Ember.isEmpty(serviceProviders)) {

        // Split provider orgs and members
        Object.keys(serviceProviders).forEach(function (serviceProviderId) {

          var serviceProvider = serviceProviders[serviceProviderId];

          if (serviceProvider) {
            console.log('service provider', serviceProvider.serialize());

            //console.log('a');
            //console.log('serviceProvider.organization', serviceProvider.get('organization'));
            //console.log('serviceProvider.user', serviceProvider.get('user'));

            switch (_this.getTypeCategory(serviceProvider.get('type'))) {

              case _constants.Constants.FINANCE_TEAM:
                {
                  financingTeam.push(serviceProvider);
                  break;
                }

              case _constants.Constants.UNDERWRITER:
                {
                  underwriters.push(serviceProvider);
                  break;
                }
              // Obligors are now separate entities
              /*
              case Constants.OBLIGOR: {
                obligors.push(serviceProvider);
                break;
              }
              */
              case _constants.Constants.OTHER:
                {
                  otherParties.push(serviceProvider);
                  break;
                }

              default:
                {
                  console.log('unknown type:', serviceProvider.get('type'));
                }
            }

            // if (!Ember.isNone(serviceProvider.get('organization')) && Ember.isNone(serviceProvider.get('user').get('content'))) {
            //
            //
            // } else {
            //   if (serviceProvider.get('type') === Constants.ISSUER) {
            //     console.log('Got issuer',serviceProvider);
            //     issuers.push(serviceProvider);
            //   }
            // }
          }
        });
      }

      this.set('issuers', issuers);
      this.set('financingTeam', financingTeam);
      this.set('underwriters', underwriters);
      this.set('otherParties', otherParties);
    },


    serviceProvidersObserver: function () {

      this.refreshServiceProviders();
    }.observes('model.integratedObligation.value.serviceProviders', 'model.integratedObligation.value.serviceProviderMembers'),

    getTypeCategory: function getTypeCategory(type) {
      if (this.financeTeamTypes.includes(type)) {
        return _constants.Constants.FINANCE_TEAM;
      } else if (this.underwriterTeamTypes.includes(type)) {
        return _constants.Constants.UNDERWRITER;
      } else {
        return _constants.Constants.OTHER;
      }
    },
    showElement: function showElement(name) {
      $(name).show();
    },
    hideElement: function hideElement(name) {
      $(name).hide();
    },
    addServiceProviderOrg: function addServiceProviderOrg(organization, type) {
      var controller = this;
      var integratedObligation = this.get('model.integratedObligation');
      return integratedObligation.addServiceProvider(organization.get('aquornId'), type).then(function () {
        controller.refreshServiceProviders();
      });
      // let that = this;
      // let obligation = this.get('model.obligation');
      // let obligationService = this.get('obligationService');
      // //console.log('TYPE: ',type);
      // return obligationService.addServiceProviderOrg(obligation, organization, type).perform(function () {
      //   that.get('model.serviceProviders').update();
      //   //that.get('onboarding.notifications').success("Service provider added", {autoClear: true});
      // });
    },
    saveServiceProvider: function saveServiceProvider(serviceProvider) {
      var that = this;
      var obligationService = this.get('obligationService');

      obligationService.updateServiceProvider(serviceProvider).then(function (serviceProvider) {
        that.get('onboarding.notifications').success("Service provider updated", { autoClear: true });
      });
    },
    addObligor: function addObligor(legalEntity) {

      var integratedObligation = this.get('model.integratedObligation');
      var controller = this;

      return integratedObligation.addObligor(legalEntity.get('aquornId')).then(function () {
        controller.refreshServiceProviders();
      });

      // let obligation = this.get('model.obligation');
      // let obligationService = this.get('obligationService');
      // const that = this;
      //
      // return obligationService.addObligor(obligation, legalEntity).perform(function () {
      //   that.get('model.obligors').update();
      // });

      // return obligationService.addObligor(obligation, legalEntity).then((obligor) => {
      //   this.get('model.obligors').update();
      // });
    },
    createLegalUnit: function createLegalUnit(legalEntity, cb) {
      this.get('application').showLECreation(legalEntity, cb);
    },
    createOrganization: function createOrganization(organization, cb) {
      this.get('application').showOrgCreation(organization, cb);
    },


    actions: {
      actionAddIssuer: function actionAddIssuer(issuer) {
        var that = this;
        var obligation = this.get('model.obligation');
        var obligationService = this.get('obligationService');

        console.log('seleted', issuer);

        obligationService.addIssuer(obligation, issuer).perform(function () {
          that.get('model.serviceProviders').update();
          that.hideElement("#add_issuer");
        });
      },
      actionCreateOrganization: function actionCreateOrganization(entityType, entity, searchTerm) {
        var organization = void 0;

        if (entityType == 'service-provider') {
          organization = this.get('store').createRecord('organization', { name: searchTerm });
        } else {
          organization = entity;
        }

        var address = this.get('store').createRecord('address', { organization: organization, hq: true });

        this.get('application').showOrgCreation(organization, address, function (organization) {
          console.log('organization created:', organization);
        });
      },
      actionCreateLegalUnit: function actionCreateLegalUnit(legalUnit, type) {
        var organization = this.get('model.organization');
        var that = this;

        legalUnit.set('organization', organization);

        this.get('application').showLECreation(legalUnit, function (legalUnit) {
          that.addServiceProviderMember();
        });
      },
      actionSaveServiceProvider: function actionSaveServiceProvider(serviceProvider) {
        this.saveServiceProvider(serviceProvider);
      },
      actionSetProviderType: function actionSetProviderType(serviceProvider, type) {
        serviceProvider.set('type', type);
        this.saveServiceProvider(serviceProvider);
      },
      actionSetAddProviderType: function actionSetAddProviderType(type) {
        console.log('change', this.get('serviceProviderType'), 'to', type);
        this.set('serviceProviderType', type);
      },
      actionAddObligor: function actionAddObligor(entityType, legalEntity) {
        var that = this;
        if (entityType && legalEntity.get('isNew')) {
          this.createLegalUnit(legalEntity, function (createdLegalEntity) {
            if (createdLegalEntity) {
              that.addObligor(createdLegalEntity);
            }
          });
        } else {
          this.addObligor(legalEntity);
        }
        this.set('obligorToAdd', null);
      },
      actionRemoveObligor: function actionRemoveObligor(obligor) {
        console.log('obligor', obligor);
        this.get('model.integratedObligation').removeObligor(obligor.get('id'));
        // let obligationService = this.get('obligationService');
        // let that = this;
        //
        // return obligationService.removeObligor(obligor).perform(function () {
        //   that.get('model.obligors').update();
        // });

        // return obligationService.removeObligor(obligor).then(function () {
        //   that.get('model.obligors').update();
        // })
      },
      actionEnterServiceProvider: function actionEnterServiceProvider(type, entityType, organization) {
        this.set('serviceProviderToAdd', organization);
      },
      actionAddServiceProvider: function actionAddServiceProvider(serviceProviderOrganization, serviceProviderType) {
        // let org = this.get('serviceProviderToAdd');
        // let type = this.get('serviceProviderType');
        // if (this.get('serviceProviderToAdd') && this.get('serviceProviderType')){
        //   this.addServiceProviderOrg(org, type);
        //   this.set('serviceProviderType',null);
        //   this.set('serviceProviderToAdd',null);
        // }
        var controller = this;

        if (!serviceProviderOrganization) {
          serviceProviderOrganization = this.get('serviceProviderToAdd');
        }

        this.set('serviceProviderToAdd', null);

        if (!serviceProviderType) {
          serviceProviderType = this.get('serviceProviderType');
        }

        this.set('serviceProviderType', null);

        this.get('model.integratedObligation').addServiceProvider(serviceProviderOrganization.get('aquornId'), serviceProviderType).then(function () {
          controller.refreshServiceProviders();
        });
      },
      actionAddNewServiceProvider: function actionAddNewServiceProvider(objectType, entity, searchTerm) {
        this.send('actionCreateOrganization', 'organization', entity);
      },
      actionAddOtherProvider: function actionAddOtherProvider() {
        var _this2 = this;

        var org = this.get('otherToAdd');
        var otherType = this.get('otherType') || this.get('OTHER_PARTY');

        return this.get('model.integratedObligation').addServiceProvider(org.get('aquornId'), otherType).then(function () {
          _this2.set('otherType', null);
          _this2.set('otherToAdd', null);
        });

        // if (this.get('otherToAdd') && this.get('otherType')){
        //   this.addServiceProviderOrg(org, otherType);
        //   this.set('otherType',null);
        //   this.set('otherToAdd',null);
        // }

        // if (this.get('otherToAdd') && this.get('otherType')){
        //   this.addServiceProviderOrg(org, type).then((promise) => {
        //   // need to insert otherType into otherInfo.type for this entry but don't know how
        //   });
        //   this.set('otherType',null);
        //   this.set('otherToAdd',null);
        // }
      },
      actionAddFinanceTeam: function actionAddFinanceTeam(entityType, organization) {
        var that = this;
        this.addServiceProviderOrg(organization, '').perform(function () {
          that.get('model.serviceProviders').update();
        });

        this.hideElement("#add_finance_team");
      },
      actionAddFinanceMember: function actionAddFinanceMember(serviceProviderOrg, user) {
        var that = this;
        var obligation = this.get('model.obligation');
        var obligationService = this.get('obligationService');

        obligationService.addServiceProviderMember(obligation, serviceProviderOrg, user).perform(function () {
          that.get('model.serviceProviders').update();
        });
      },
      actionAddSyndicateTeam: function actionAddSyndicateTeam(entityType, organization) {
        this.addServiceProviderOrg(organization, _constants.Constants.LEAD_UNDERWRITER);
        this.set('underwriterToAdd', null);
      },
      actionAddSyndicateMember: function actionAddSyndicateMember(serviceProviderOrg, user) {
        var that = this;
        var obligation = this.get('model.obligation');
        var obligationService = this.get('obligationService');

        obligationService.addServiceProviderMember(obligation, serviceProviderOrg, user).perform(function () {
          that.get('model.serviceProviders').update();
          this.set('underwriterToAdd', null);
        });
      },
      actionAddOtherTeam: function actionAddOtherTeam(entityType, organization) {
        this.addServiceProviderOrg(organization, "Other");
        this.set('otherToAdd', null);
      },
      actionAddOtherMember: function actionAddOtherMember(serviceProviderOrg, user) {
        var that = this;
        var obligation = this.get('model.obligation');
        var obligationService = this.get('obligationService');

        obligationService.addServiceProviderMember(obligation, serviceProviderOrg, user).perform(function () {
          that.get('model.serviceProviders').update();
          that.hideElement('#add_other_member_' + serviceProviderOrg.get('id'));
        });
      },
      actionRemoveServiceProvider: function actionRemoveServiceProvider(serviceProvider) {
        var controller = this;

        this.get('model.integratedObligation').removeServiceProvider(serviceProvider.get('id')).then(function () {
          controller.refreshServiceProviders();
        });
        // let that = this;
        // let obligationService = this.get('obligationService');
        //
        // obligationService.removeServiceProvider(serviceProvider).perform(function () {
        //   that.get('model.serviceProviders').update();
        // });

        // obligationService.removeServiceProvider(serviceProvider).then(function () {
        //   that.get('model.serviceProviders').update();
        // });
      },
      actionShowElement: function actionShowElement(element, index) {
        var modifier = index ? "_" + index : "";
        this.showElement(element + modifier);
      },
      actionHideElement: function actionHideElement(element, index) {
        var modifier = index ? "_" + index : "";
        this.hideElement(element + modifier);
      },
      actionSaveAndTransition: function actionSaveAndTransition() {
        this.get('onboarding').next('parties');
      },
      deleteObligation: function deleteObligation(obligation) {
        this.get('onboarding').send('deleteObligation', obligation);
      }
    }
  });
});