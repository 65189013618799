define('pretty-nuts/controllers/person', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _avatarPickerOptions, _Ember$Controller$ext;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Controller.extend((_Ember$Controller$ext = {
    application: Ember.inject.controller(),
    currentUser: Ember.inject.service(),
    resourceService: Ember.inject.service(),

    applicationController: Ember.inject.controller('application'),
    isAquornAdmin: Ember.computed.reads('applicationController.isAquornAdmin'),
    currentEmployer: Ember.computed('model.employments', function () {
      var employments = this.get('model.employments');
      var currentEmployer = employments.content[0];
      console.log('CURRENT', currentEmployer);
      return currentEmployer.record;
    }),
    employments: Ember.computed('model.employments', function () {
      return this.get('model.employments');
      // var employments = this.get('model.employments').toArray();
      // if(!employments[employments.length - 1].get('endDate')) {
      //   while(employments[0].get('endDate')) {
      //     employments.push(employments.shift());
      //   }
      // }
      // return employments
    }),
    salutations: ['Mr.', 'Mrs.', 'Ms.', 'Dr.'],
    selectedSalutation: Ember.computed('model.user.suffix', function () {
      return this.get('model.user.otherInfo.salutation');
    }),
    phoneTypes: [{ name: 'work', display: 'Work' }, { name: 'cell', display: 'Mobile' }],
    selectedPhoneType: '',
    profilePhones: Ember.computed('model.phones', function () {
      return this.get('model.phones');
    })
  }, _defineProperty(_Ember$Controller$ext, 'phoneTypes', [{ name: 'work', display: 'Work' }, { name: 'cell', display: 'Mobile' }]), _defineProperty(_Ember$Controller$ext, 'primaryPhone', Ember.computed('model.phones', function () {
    var phone = this.get('model.phones.firstObject');
    return phone || this.get('store').createRecord('phone', { userId: this.get('model.user.id') });
  })), _defineProperty(_Ember$Controller$ext, 'primaryEmail', Ember.computed('model.emails', function () {
    return this.get('model.emails').objectAt(0);
  })), _defineProperty(_Ember$Controller$ext, 'newPhone', {
    countryCode: '+1',
    number: '',
    type: 'work'
  }), _defineProperty(_Ember$Controller$ext, 'newEmail', {
    source: 'Username',
    address: 'sample@aquorn.com',
    isPrimary: false,
    isVerified: false
  }), _defineProperty(_Ember$Controller$ext, 'avatarPickerOptions', (_avatarPickerOptions = {
    accept: ['image/*'],
    uploadInBackground: false,
    imageMax: [300, 300]
  }, _defineProperty(_avatarPickerOptions, 'imageMax', [300, 300]), _defineProperty(_avatarPickerOptions, 'maxSize', 2 * 1024 * 1024), _defineProperty(_avatarPickerOptions, 'transformations', {
    crop: {
      aspectRatio: 1,
      force: true
    },
    circle: false
  }), _avatarPickerOptions)), _defineProperty(_Ember$Controller$ext, 'currentEmployments', Ember.computed('model.employments', function () {
    var currentEmployments = [];
    this.get('model.employments').forEach(function (employment) {
      if (!employment.data.endDate || employment.data.endDate == '2100-12-31') {
        Ember.set(employment, 'current', true);
        currentEmployments.pushObject(employment);
      }
    });

    return currentEmployments;
  })), _defineProperty(_Ember$Controller$ext, 'currentEmployment', Ember.computed('model.user.otherInfo.profile.currentEmploymentId', function () {
    var currentEmploymentId = this.get('model.user.otherInfo.profile.currentEmploymentId');
    return this.get('currentEmployments').find(function (employment) {
      return employment.get('id') == currentEmploymentId;
    });
  })), _defineProperty(_Ember$Controller$ext, 'runEveryTime', function runEveryTime() {
    Ember.run.schedule('afterRender', this, function () {
      $('.menu.vertical a').removeClass('active');
      $('#peopleNav a').addClass('active');
    });
  }), _defineProperty(_Ember$Controller$ext, 'actions', {
    edit: function edit(item) {
      $('#' + item + ' .display').hide();
      $('#' + item + ' .edit').show();
    },
    display: function display(item) {
      $('#' + item + ' .display').show();
      $('#' + item + ' .edit').hide();
    },
    saveSection: function saveSection(section) {
      this.get('model').user.save();
      this.get('primaryPhone').save();
      this.send('display', section);
    },
    revert: function revert(item) {
      this.get('model').user.rollbackAttributes();
      this.send('display', item);
    },
    sortColumn: function sortColumn(prop) {
      this.set('desc', !this.get('desc'));
      this.set('activeColumn', prop);
      var model = this.get('model').sort(ArrayComparer.property(prop, this.get('desc')));
      this.set('model', model.slice());
    },
    resetEndDate: function resetEndDate(employment) {
      if (employment.current) {
        employment.set('endDate', null);
        employment.set('current', false);
      } else {
        employment.set('endDate', '2100-12-31');
        employment.set('current', true);
      }
    },
    saveEmployment: function saveEmployment(employment) {
      // TODO Actually save something

      console.log('employment to save', employment);
      console.log('employment current', employment.get('current'));
      console.log('employment endDate', employment.get('endDate'));

      if (employment.get('current')) {
        console.log('Blanking end date');
        employment.set('endDate', null);
      }

      console.log('employment endDate', employment.get('endDate'));

      employment.save().then(function () {});
      console.log('SAVED', employment);
      this.send('display', employment.id);
    },
    cancelEmployment: function cancelEmployment(id) {
      this.send('display', id);
    },
    resetNewEndDate: function resetNewEndDate() {
      this.set('newEmployment.endDate', null);
      console.log('CURRENTDATE UPDATED');
    },
    updatePrimaryEmail: function updatePrimaryEmail(value) {
      this.set('model.user.otherInfo.primaryEmail', JSON.parse(value.target.value));
      console.log('primaryEmail', this.get('model.user.otherInfo.primaryEmail'));
    },
    updatePrimaryPhone: function updatePrimaryPhone(value) {
      this.set('model.user.otherInfo.primaryPhone', JSON.parse(value.target.value));
    },
    setCurrentEmployer: function setCurrentEmployer(employment) {
      var user = this.get('model.user');
      user.set('otherInfo.profile.currentEmploymentId', employment.get('id'));
      user.save();
    },
    hasPermission: function hasPermission(organization) {
      if (this.get('currentUser').getOrganizationRole(organization)) {
        return true;
      } else {
        return false;
      }
    },
    savePrimary: function savePrimary() {
      var curr_user = this.get('currentUser');
      var currPhone = curr_user.phones[0];
      currPhone.set('number', this.get('phone'));
      currPhone.save();
      console.log(curr_user);
      var user = curr_user.get('user');
      var other_info = user.get('otherInfo');
      other_info['phone'] = this.get('phone');
      other_info['email'] = this.get('email');
      user.set('other_info', other_info);
      user.save();
      this.send('display', 'contact');
    },
    setPhoneType: function setPhoneType(item) {
      this.set('newPhone.type', item);
    },
    joinOrg: function joinOrg(orgId) {
      var _this = this;

      console.log("Joining org: " + orgId);
      var orgsapi = this.get('orgsapi');
      orgsapi.userJoinRequest(orgId, function (err, data) {
        if (!err) {
          _this.get('notifications').success("Request sent :: request id(" + data.requestId + ")", { autoClear: true });
        } else {
          _this.get('notifications').error(data);
        }
      });
    },
    joinNowClicked: function joinNowClicked(orgId) {
      var that = this;
      var orgsapi = this.get('orgsapi');
      console.log("Joining org as admin now: " + orgId);
      orgsapi.userJoinNow(orgId).then(function (response) {
        console.log('JOIN_NOW', response);
        that.get('notifications').success("Got access :: access id(" + response.data.accessId + ")", { autoClear: true });
      });
      this.get('currentUser').load();
    },
    joinClicked: function joinClicked(orgId) {
      var that = this;
      var organizationService = this.get('organizationService');
      var currentUser = this.get('currentUser');

      var organization = this.store.findRecord('organization', orgId).then(function (organization) {
        organizationService.requestUserOrganizationRole(currentUser.getProfile(), organization, 'Admin', function (roleRequest) {
          that.get('notifications').success('Request sent: ' + roleRequest.get('id'));
        });
      });
    },
    actionCreateOrganiztion: function actionCreateOrganiztion(entityType, org) {
      var that = this;

      var address = this.get('store').createRecord('address', { organization: org });

      this.get('application').showOrgCreation(org, addres, function (createdOrganization) {
        console.log('callback:', that.get('newEmployment.organization'));

        //that.set('newEmployment.organization', createdOrganization);
        //setValue(null);
        //that.send('reloadModel');
      });
    },
    actionAddEmployer: function actionAddEmployer() {
      var that = this;
      var organizationService = this.get('organizationService');
      var currentUser = this.get('currentUser');

      // Get the employment details
      var newEmployment = this.get('newEmployment');
      var employment = this.store.createRecord('employment', newEmployment);

      // Get the employment org info
      // var org = this.get("newEmploymentOrg");
      // employment.set('organization', org);

      // Was request access checked
      var requestAccess = this.get('requestAccess');

      // Add the employment record to the current user
      this.get('currentUser').addEmployment(employment).then(function () {
        that.get('notifications').success('Employment added');
        // that.set('newEmploymentOrg', null);
        console.log('NEW EMP ORG', that.get('newEmploymentOrg'));

        if (requestAccess) {
          // If request access was checked, make the access request
          employment.get('organization').then(function (organization) {
            organizationService.requestUserOrganizationRole(currentUser.getProfile(), organization, 'Admin').then(function (roleRequest) {
              that.get('notifications').success('Access requested');
            });
          });
        }

        // Hide the form
        $("#new_employer_form").hide();

        return employment;
      });
    },
    actionDeleteEmployment: function actionDeleteEmployment(employment) {
      var that = this;

      // Remove the employment from the work history
      this.get('currentUser').removeEmployment(employment).then(function () {
        that.get('notifications').success('Removed employment');
      });
    },
    editAvatar: function editAvatar() {
      // this.set('showPicker', true);
      var resourceService = this.get('resourceService');

      // resourceService.getSignedUploadRequest().then((policy) => {
      //   let filestack = this.get('filestack.instance');
      //   filestack.setSecurity({
      //     policy: policy.encodedPolicy,
      //     signature: policy.signature
      //   });

      //   this.set('showPicker', true);
      // });
    },
    pickerClosed: function pickerClosed() {
      this.set('showPicker', false);
    },
    pickerSelect: function pickerSelect(result) {
      var controller = this;
      var userId = this.get('model.user.id');

      console.log('selected file', result);

      if (result.filesUploaded.length > 0) {
        var file = result.filesUploaded[0];

        this.get('resourceService').uploadUserAvatar(file, userId, function () {
          controller.get('model.user').reload();
        });
      }

      this.set('showPicker', false);
    }
  }), _Ember$Controller$ext));
});