define('pretty-nuts/components/obligation-bread-crumb', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var alias = Ember.computed.alias,
	    observer = Ember.observer;
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),

		currentRouteName: alias('router.currentRouteName'),
		routerState: alias('router.currentState.routerJsState'),

		init: function init() {
			this._super.apply(this, arguments);
			this.buildBreadCrumbs();
		},


		fileStateObserver: function () {
			if (this.get('currentRouteName').includes('obligation.')) {
				this.buildBreadCrumbs();
			}
		}.observes('routerState'),

		buildBreadCrumbs: function buildBreadCrumbs() {
			var that = this;
			var obligationId = this.get('routerState.params')['obligation']['obligationId'];

			this.set('obligationId', obligationId);

			this.set('obligation', this.get('store').findRecord('domain-obligation', obligationId)).then(function (obligation) {
				that.buildObligationsList(obligation);
				return obligation;
			});
		},

		buildObligationsList: function buildObligationsList(obligation) {
			var currentRouteName = this.get('currentRouteName');
			var array = [['Debt Overview', 'obligation.overview'], ['Cashflow', 'obligation.cashflow'], ['Events', 'obligation.events'], ['Statistics', 'obligation.statistics'], ['Documents', 'obligation.documents'], ['Service Providers', 'obligation.service-providers'], ['Version History', 'obligation.version-history']];

			if (this.get('obligation.structureType') === _constants.Constants.ObligationStructure.VARIABLE_RATE) {
				array.splice(2, 0, ['Variable Rates', 'obligation.variable-rates']);
			}

			this.set('currentObligationView', array.find(function (item) {
				return item[1] == currentRouteName;
			}));
			this.set('obligationView', array);
		},

		actions: {
			onSelectionChange: function onSelectionChange(item) {
				this.get('router').transitionTo(item[1], { queryParams: item[2] });
			}
		}
	});
});