define('pretty-nuts/controllers/reporting/index', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function init_filter() {
    return {
      cashflowView: _constants.Constants.CashflowView.OUTSTANDING
    };
  }

  exports.default = Ember.Controller.extend((_Ember$Controller$ext = {
    // queryParams: ['organizationId'],
    Constants: _constants.Constants,
    government: Ember.inject.controller(),
    application: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    organizationService: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    issuer: Ember.inject.controller(),
    resolution: _constants.Constants.Frequency.ANNUAL,
    reloadObligationOverviews: false,
    isAquornAdmin: Ember.computed.reads('application.isAquornAdmin'),

    description: "This is where you'll find a description of the thing your mouse is over",

    pieCharts: ['PrincipalAndInterest', 'Obligation'],

    applicationController: Ember.inject.controller('application')
  }, _defineProperty(_Ember$Controller$ext, 'isAquornAdmin', Ember.computed.reads('applicationController.isAquornAdmin')), _defineProperty(_Ember$Controller$ext, 'hasAccess', Ember.computed('currentUser', function () {
    var currentUser = this.get('currentUser');
    return currentUser.hasAccess(this.get('model.organization.id'), 'springapp/organizations/roles/approveRequest');
  })), _defineProperty(_Ember$Controller$ext, 'displayDescription', Ember.computed('government.permissionsString', function () {
    return this.get('model.organization.description') || this.get('hasAccess');
  })), _defineProperty(_Ember$Controller$ext, 'displayContact', Ember.computed('government.permissionsString', function () {
    var hasContactInfo = this.get('phoneNumber') || this.get('email');
    return this.get('model.organization.description') || this.get('hasAccess');
  })), _defineProperty(_Ember$Controller$ext, 'query', {
    resolution: _constants.Constants.Frequency.ANNUAL
  }), _defineProperty(_Ember$Controller$ext, 'filter', init_filter()), _defineProperty(_Ember$Controller$ext, 'currentChartView', 'obligation'), _defineProperty(_Ember$Controller$ext, 'reloadModel', function reloadModel() {
    this.send('reloadModel');
  }), _defineProperty(_Ember$Controller$ext, 'outstandingObligations', Ember.computed('profile', function () {
    var outstandingObligations = [];
    console.log('PROFILE', this.get('profile'));

    this.get('profile').get('outstandingObligationsSummary').forEach(function (summary) {
      //console.log('summary obligation', summary.get('obligation'));
      outstandingObligations.push(summary.get('obligation'));
    });

    return outstandingObligations;
  })), _defineProperty(_Ember$Controller$ext, 'reloadObligationOverviewsObserver', function () {
    if (this.get('reloadObligationOverviews')) {
      var that = this;
      var organizationId = this.get('model.organization.id');
      this.get('store').unloadAll('obligation-overview');
      return this.get('store').query('obligation-overview', { organizationId: organizationId }).then(function (obligationOverviews) {
        that.set('model.obligationOverviews', obligationOverviews);
        that.set('reloadObligationOverviews', false);
      });
    }
  }.observes('reloadObligationOverviews')), _defineProperty(_Ember$Controller$ext, 'actions', {
    openOrgForAquornAdmin: function openOrgForAquornAdmin() {
      this.send('reloadModel');
    },
    describe: function describe(item) {
      var descriptions = {
        'default': "This is where you'll find a description of the thing your mouse is over",
        'le': 'This is the description for the Legal Entity Reports.  I have no idea what to write here but I know it should change',
        'obligation': 'Here I have the obligation description. I assume all this will come from the backend eventually.  Right now it is all hardcoded',
        'cashflow': 'description of cashflow thingie',
        '30-year': 'This is how 30 year reports do',
        'period': 'I like to go swimming with bowlegged women and swim between their legs, swim between their legs, swim between their legs'
      };
      this.set('description', descriptions[item]);
    },
    edit: function edit(item) {
      $('#' + item + ' .display').hide();
      $('#' + item + ' .edit').show();
    },
    display: function display(item) {
      $('#' + item + ' .display').show();
      $('#' + item + ' .edit').hide();
    },
    revert: function revert(item) {
      this.get('model').organization.rollbackAttributes();
      this.send('display', item);
    },
    actionLoadOrg: function actionLoadOrg() {
      console.log('oh loady', this.get('currentOrganization.id'));
      this.transitionToRoute('reporting', this.get('currentOrganization.id'));
      this.set('currentOrganizationId', this.get('currentOrganization.id'));
    },
    saveSection: function saveSection(section) {
      var that = this;
      this.get('government.model.organization').save().then(function () {
        that.get('government.model.addresses').forEach(function (address) {
          if (address.hasDirtyAttributes) {
            address.save().then(function () {
              that.reloadModel();
            });
          }
        });
        if (that.get('newAddress')) {
          that.get('newAddress').save().then(function () {
            that.set('newAddress', null);
            that.reloadModel();
          });
        }
        that.send('display', section);
        that.get('notifications').success('organization saved', { autoClear: true });
      }).catch(function (error) {
        that.send('display', section);
        that.get('notifications').error("failed: " + error);
      });
    },
    actionRefreshDebtProfile: function actionRefreshDebtProfile(debtProfile, obligation) {
      // console.log('Deleted pending obligation', obligation);
      debtProfile.reload();
    }
  }), _Ember$Controller$ext));
});