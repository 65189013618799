define('pretty-nuts/services/authapi', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var LS_KEY = 'login-session';
  var debug = true;

  exports.default = Ember.Service.extend({
    /**
     * Backend request wrapper
     */
    backend: Ember.inject.service("Backend"),
    notifications: Ember.inject.service('notification-messages'),

    /*
    login(email, password, remember, cb) {
      const url = '/api/auth/login';
      if (debug) {
        if (email === 'admin@aquorn.com' && password === 'admin') {
          const data = {
            user: {
              firstName: 'Aquorn',
              lastName: 'Admin'
            }
          };
          localStorage.setItem(LS_KEY, JSON.stringify(data));
          cb(null, data);
          return;
        }
      }
      $.post(url, { email, password }).done(data => {
        console.log("AUTH DATA:", JSON.stringify(data));
        localStorage.setItem(LS_KEY, JSON.stringify(data));
        cb(null, data);
      }).fail(xhr => {
        localStorage.removeItem(LS_KEY);
        cb(true, xhr.responseText);
      });
    },
    */

    /*
    logout(cb) {
      const url = '/api/auth/logout';
      if (debug) {
        localStorage.removeItem(LS_KEY);
        cb(null, null);
      }
      $.get(url).done(xhr => {
        localStorage.removeItem(LS_KEY);
        cb(null, xhr.responseText);
      });
    },
    */
    resetPasswordRequest: function resetPasswordRequest(resetPassword, token, userId) {
      var url = _constants.Constants.API_PREFIX + '/auth/resetPassword';
      var notification = this.get('notifications');

      var form = {
        token: token,
        userId: userId,
        newPassword: resetPassword
      };

      $.post(url, form).done(function (data) {
        notification.success("Password successfully reset.");
      }).fail(function (data) {
        notification.error("Could not reset password, please contact the admin.");
      });
    },
    changePassword: function changePassword(oldPassword, newPassword) {
      var url = _constants.Constants.API_PREFIX + '/auth/changePassword';
      var notification = this.get('notifications');
      var backend = this.get('backend');

      var form = {
        oldPassword: oldPassword,
        newPassword: newPassword
      };

      return backend.post({
        url: url,
        data: JSON.stringify({ data: form })
      }).then(function (response) {
        notification.success("Password successfully changed.");
      }).catch(function (response) {
        notification.error("Could not change password, please contact the admin.");
      });

      // $.post(url, form).done(data => {
      //   notification.success("Password successfully changed.");
      // }).fail(data => {
      //   notification.error("Could not change password, please contact the admin.");
      // });
    },
    forgotPasswordRequest: function forgotPasswordRequest(email) {
      // /auth/forgotPassword/{emailId}
      var url = _constants.Constants.API_PREFIX + '/auth/forgotPassword?emailId=' + email;
      var notification = this.get('notifications');
      $.get(url).done(function (data) {
        notification.success("A verification link has been send via email.");
      }).fail(function (data) {
        notification.error("Could not send verification email, please contact the admin.");
      });
    },
    signup: function signup(form, cb) {
      var url = _constants.Constants.API_PREFIX + '/signup';
      $.post(url, form).done(function (data) {
        cb(null, data);
      }).fail(function (data) {
        cb(true, data);
      });
    },
    verifyUser: function verifyUser(token, userId) {
      var url = _constants.Constants.API_PREFIX + '/auth/verify?token=' + token + '&userId=' + userId;
      var notification = this.get('notifications');
      $.get(url).done(function (data) {
        if (data.data === '"OK"') {
          notification.success("Email address successfully verified.");
        } else {
          notification.error("Could not verify the link, please contact the admin.");
        }
      }).fail(function (data) {
        notification.error("Could not verify the link, please contact the admin.");
      });
    },


    /*
    getSession() {
      const login = localStorage.getItem(LS_KEY);
      return login ? JSON.parse(login) : undefined;
    },
    */

    /*
    getUserId() {
      var session = this.getSession();
      var userId = null;
       if (session) {
        userId = session.user.userId;
      }
       return userId;
    },
    */

    /*
    isAuthenticated() {
      return !!this.getSession();
    },
    */

    syncAuthState: function syncAuthState(cb) {
      var session = this.getSession();
      if (!session) cb(true, null);
      var url = _constants.Constants.API_PREFIX + '/auth/touch';
      $.get(url).done(function (data) {
        cb(null, data);
      }).fail(function (data) {
        localStorage.removeItem(LS_KEY);
        cb(true, data);
      });
    },


    /*
    currentUser() {
      const session = this.getSession();
      return session ? session.user : null;
    },
    */

    currentUserFullName: function currentUserFullName() {
      var person = this.currentUser();
      return person ? (person.firstName || '') + ' ' + (person.lastName || '') : '';
    },
    currentUserImageUrl: function currentUserImageUrl() {
      var person = this.currentUser();
      return person.profileImageUrl;
    },
    isUserInRole: function isUserInRole(user, role) {
      return true;
    },
    getRoles: function getRoles(cb) {
      var url = _constants.Constants.API_PREFIX + '/users/getAccess';
      $.get(url).done(function (data) {
        cb(null, data);
      }).fail(function (data) {
        cb(true, data);
      });
    }
  });
});