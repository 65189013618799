define('pretty-nuts/controllers/onboarding/variable-rates', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    onboarding: Ember.inject.controller(),

    securitiesObserver: function () {
      console.log('VR security computed');
      var securities = this.get('model.integratedObligation.value.securities');
      console.log('securities', securities);

      if (!securities) {
        return null;
      }

      var securityId = Object.keys(securities).objectAt(0);

      console.log('securityId', securityId);

      var security = Ember.get(securities, securityId);

      this.set('security', security);

      console.log('security', security);
    }.observes('model.integratedObligation.value.securities'),

    actions: {
      actionSaveAndTransition: function actionSaveAndTransition() {
        this.get('onboarding').next('variable-rates');
      }
    }
  });
});