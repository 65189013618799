define('pretty-nuts/components/document-modal', ['exports', 'pretty-nuts/components/aquorn-modal'], function (exports, _aquornModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _aquornModal.default.extend({

    /////////////////////////
    // Configuration and data
    resourceService: Ember.inject.service(),
    organizationService: Ember.inject.service(),
    obligationService: Ember.inject.service(),
    documentService: Ember.inject.service(),

    name: 'create_document',

    organization: null,
    legalUnit: null,
    obligation: null,
    file: null,
    documentCreateDisabled: true,

    organizationDisabled: null,
    legalUnitDisabled: true,
    obligationDisabled: null,

    documentType: null,

    successCallback: null,
    cancelCallback: null,

    documentStatuses: ['Active', 'Historical'],

    documentPhases: ['Draft', 'Final Version', 'Execution'],

    documentCategories: Ember.computed('documentCategoryGroup', function () {
      this.set('documentCategory', null);
      var results = [];
      var documentCategoryGroup = this.get('documentCategoryGroup');

      if (documentCategoryGroup) {
        return this.get('documentService').getDocumentCategoriesByGroup(documentCategoryGroup).then(function (categories) {
          Object.keys(categories).forEach(function (categoryId) {
            results.push({
              'categoryId': categoryId,
              'categoryName': categories[categoryId]
            });
          });
          return results;
        });
      }
    }),

    documentLEOptions: Ember.computed('organization', function () {
      if (!this.get('legalUnitDisabled')) {
        return this.get('organizationService').getOrganizationLegalUnits(this.get('organization.id'));
      }
    }),

    docObligationOptions: Ember.computed('legalUnit', function () {
      if (!this.get('obligationDisabled')) {
        return this.get('obligationService').getLegalUnitObligations(this.get('legalUnit.id'));
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this._setup();
    },
    _setup: function _setup() {
      this.set('documentCategoryGroups', this.get('documentService').getDocumentCategoryGroups());
    },


    // Configuration and data
    /////////////////////////

    onShow: function onShow(args) {
      var that = this;
      this.set('file', null);
      this.set('description', '');
      this.set('preparerOrg', null);
      this.set('preparerUser', null);
      this.set('documentType', null);
      this.set('documentStatus', null);
      this.set('documentPhase', null);
      this.set('documentDate', null);
      this.set('documentCreateDisabled', true);

      this.set('organizationDisabled', args.organizationDisabled);
      this.set('legalUnitDisabled', args.legalUnitDisabled);
      this.set('obligationDisabled', args.obligationDisabled);

      this.set('organization', args.organization);
      this.set('legalUnit', args.legalUnit);
      this.set('obligation', args.obligation);

      this.set('documentCategoryGroups', args.documentCategoryGroups);

      if (!this.get('organization')) {
        this.set('organizationDisabled', false);
      }

      this.set('successCallback', args.callbacks.success);
      this.set('cancelCallback', args.callbacks.cancel);

      $("#create_document").on('closed.zf.reveal', function () {
        that.send('actionCancel');
      });
    },
    onHide: function onHide() {
      this.set('organization', null);
      this.set('legalUnit', null);
      this.set('obligation', null);
      this.set('file', null);
      this.set('documentCategoryGroup', null);
      this.set('documentCategory', null);
      this.set('documentCategories', null);

      this.set('successCallback', null);
      this.set('cancelCallback', null);
    },


    actions: {

      // actionSelectFile(file) {
      //   this.set('file');
      // },

      actionRemoveFile: function actionRemoveFile() {
        this.set('file', null);
        this.set('documentCreateDisabled', true);
      },
      actionContinue: function actionContinue() {
        var dd = this.get('documentDate');
        console.log('ACTIONCONTINUE', dd);
        if (!dd) {
          dd = moment().format();
        }
        var cb = this.get('successCallback');
        var documentType = this.get('documentType');
        var categoryId = this.get('documentCategory.categoryId');
        var documentCategory = this.get('documentCategory.categoryName');
        var organization = this.get('organization');
        var legalUnit = this.get('legalUnit');
        var obligation = this.get('obligation');
        var preparerOrg = this.get('preparerOrg');
        var preparerUser = this.get('preparerUser');
        var description = this.get('description');
        var documentStatus = this.get('documentStatus');
        var documentPhase = this.get('documentPhase');
        var documentDate = dd;
        var objectId = this.get('objectId');
        var phase = this.get('phase');
        var parentType;
        var file = this.get('file');
        if (this.get('obligation')) {
          parentType = 'obligation';
        } else if (this.get('legalUnit')) {
          parentType = 'legal-entity';
        } else {
          parentType = 'organization';
        }
        this.hide();

        if (cb) {
          console.log('modal actionContinue', parentType, documentType, file, categoryId, organization, legalUnit, obligation, preparerOrg, preparerUser, description, documentDate, objectId, phase, status, documentCategory);
          cb(parentType, documentType, file, categoryId, organization, legalUnit, obligation, preparerOrg, preparerUser, description, documentDate, objectId, phase, status, documentCategory);
        }
      },
      actionCancel: function actionCancel() {
        var cb = this.get('cancelCallback');
        if (cb) {
          cb();
        }
        this.send('actionHideModal', true);
      },
      showFilePicker: function showFilePicker() {
        var resourceService = this.get('resourceService');
        this.set('showPicker', true);
      },
      pickerClosed: function pickerClosed() {
        this.set('showPicker', false);
      },
      pickerSelect: function pickerSelect(file) {
        var controller = this;
        console.log('result', file);

        this.get('resourceService').getUploadUrl().then(function (response) {
          console.log('upload url', response);
          var key = response.data.key;
          // file.upload(response.data).then(response => {
          file.uploadBinary(response.data.url, {
            method: 'PUT'
          }).then(function (response) {
            Ember.set(file, 'key', key);
            controller.set('file', file);
          });
        });

        // if (result.filesUploaded.length > 0) {
        //   let file = result.filesUploaded[0];
        //   this.set('documentCreateDisabled', false);
        //   this.set('file', file);
        // }

        // if (result.filesFailed.length > 0) {
        //   // HANDLE ERROR:
        // }

        this.set('showPicker', false);
      }
    }

  });
});