define('pretty-nuts/serializers/organization', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'aquornId',

    attrs: {
      legacyId: 'id',
      companyLogo: 'logoResourceId',
      verifiedByUser: 'verifiedByUserId',
      verifiedByOrganization: 'verifiedByOrganizationId',
      parent: 'parentAquornId'
    }
  });
});