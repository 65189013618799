define("pretty-nuts/models/legal-entity-report", ["exports", "ember-data", "pretty-nuts/constants"], function (exports, _emberData, _constants) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    entityId: _emberData.default.attr(),
    // grouping: DS.attr('enum', {type: Constants.Grouping}),
    start: _emberData.default.attr(),
    end: _emberData.default.attr(),
    report: _emberData.default.attr()
  });
});