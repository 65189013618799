define('pretty-nuts/models/obligation-ui-steps', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    index: _emberData.default.attr('boolean', { defaultValue: false }),
    overview: _emberData.default.attr('boolean', { defaultValue: false }),
    attributes: _emberData.default.attr('boolean', { defaultValue: false }),
    parties: _emberData.default.attr('boolean', { defaultValue: false }),
    refunding: _emberData.default.attr('boolean', { defaultValue: false }),
    'call-options': _emberData.default.attr('boolean', { defaultValue: false }),
    securities: _emberData.default.attr('boolean', { defaultValue: false }),
    'sources-uses': _emberData.default.attr('boolean', { defaultValue: false }),
    cashflow: _emberData.default.attr('boolean', { defaultValue: false }),
    events: _emberData.default.attr('boolean', { defaultValue: false }),
    documents: _emberData.default.attr('boolean', { defaultValue: false }),
    review: _emberData.default.attr('boolean', { defaultValue: false })
  });
});