define('pretty-nuts/components/bread-crumb', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var alias = Ember.computed.alias,
	    observer = Ember.observer;
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),

		routerState: alias('router.currentState.routerJsState'),
		currentRouteName: alias('router.currentRouteName'),

		specialCaseBreadcrumbComponent: null,

		init: function init() {
			this._super.apply(this, arguments);
			var that = this;

			$.getJSON("/bread-crumb.json").then(function (file) {
				that.set('file', file);
			});
		},


		routerStateObserver: function () {
			this.set('specialCaseBreadcrumbComponent', null);

			if (this.get('currentRouteName') == 'loading') {
				return;
			}

			if (this.get('file')) {
				this.buildBreadcrumbs();
			}
		}.observes('routerState', 'file'),

		buildBreadcrumbs: function buildBreadcrumbs() {
			var file = this.get('file');
			var currentRouteName = this.get('currentRouteName');
			var breadcrumbsConfiguration = void 0;

			if (currentRouteName.match('^reporting\.')) {
				if (currentRouteName.match('^reporting\.legal-entity')) {
					this.set('specialCaseBreadcrumbComponent', 'legal-entity-reporting-bread-crumb');
				} else if (currentRouteName.match('^reporting\.obligation')) {
					this.set('specialCaseBreadcrumbComponent', 'obligation-reporting-bread-crumb');
				} else {
					this.set('specialCaseBreadcrumbComponent', 'organization-reporting-bread-crumb');
				}
			} else if (currentRouteName.match('^obligation\.(?!security)')) {
				this.set('specialCaseBreadcrumbComponent', 'obligation-bread-crumb');
			} else if (currentRouteName.match('onboarding\.')) {
				this.set('specialCaseBreadcrumbComponent', 'onboarding-bread-crumb');
			} else {
				breadcrumbsConfiguration = file[currentRouteName];

				if (Array.isArray(breadcrumbsConfiguration) && breadcrumbsConfiguration[0].sharedBreadcrumb) {
					breadcrumbsConfiguration = file[breadcrumbsConfiguration[0].sharedBreadcrumb];
				}

				var largeBreadcrumbsConfiguration = breadcrumbsConfiguration && breadcrumbsConfiguration.large || breadcrumbsConfiguration;
				var smallBreadcrumbsConfiguration = breadcrumbsConfiguration && breadcrumbsConfiguration.small;
				var breadcrumbRecords = breadcrumbsConfiguration && breadcrumbsConfiguration.records;

				this.set('largeBreadcrumbs', largeBreadcrumbsConfiguration ? this.evaluateBreadcrumbs(largeBreadcrumbsConfiguration) : null);
				this.set('smallBreadcrumbs', smallBreadcrumbsConfiguration ? this.evaluateBreadcrumbs(smallBreadcrumbsConfiguration) : null);

				if (!!breadcrumbRecords) {
					this.getRecords(breadcrumbRecords);
				}
			}
		},

		evaluateBreadcrumbs: function evaluateBreadcrumbs(breadcrumbsConfiguration) {
			var _this = this;

			return breadcrumbsConfiguration.map(function (breadcrumbParams, i) {
				if (breadcrumbParams.type == 'list') {
					breadcrumbParams = _this.setBreadCrumbListSelectedItem(breadcrumbParams);
				}
				return breadcrumbParams;
			});
		},

		getRecords: function getRecords(breadcrumbRecords) {
			var that = this;
			var resourceId = void 0;

			breadcrumbRecords.forEach(function (record) {
				resourceId = that.get('routerState.params')[record.browserStateRoute][record.browserStateParam];

				that.set(record.resourceModel, that.get('store').findRecord(record.resourceModel, resourceId));
			});
		},

		setBreadCrumbListSelectedItem: function setBreadCrumbListSelectedItem(breadcrumbParams) {
			var _this2 = this;

			var selectItem = breadcrumbParams.list.find(function (i) {
				return i[1] == _this2.get('currentRouteName');
			});

			Ember.setProperties(breadcrumbParams, { selectItem: selectItem });

			return breadcrumbParams;
		},

		actions: {
			onSelectionChange: function onSelectionChange(item) {
				this.get('router').transitionTo(item[1], { queryParams: item[2] });
			}
		}
	});
});