define('pretty-nuts/helpers/in-arr', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    compute: function compute(params) {
      return params[0].indexOf(params[1]) > -1;
    }
  });
});