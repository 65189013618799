define('pretty-nuts/components/add-person', ['exports', 'pretty-nuts/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    people: [],
    // INITIAL LIST
    // orgs: Ember.computed(function(){
    //   Backend.doSearchAll(' ', 'Users', data => {
    //     this.set('orgs', data.users);
    //     console.log(this.get('orgs'));
    //   });
    // }),
    selectedPerson: '',
    actions: {
      selectPerson: function selectPerson(user) {
        console.log('SELECTED', user);
        // this.send('searchLU',co);
        this.set('selectedPerson', user);
        this.set('valToSet', user);
        //       this.sendAction('setValue', user);
      },
      newPerson: function newPerson() {
        $('#new_person').show();
      },
      addPerson: function addPerson() {
        // add a new person to the database here
        $('#new_person').hide();
      },
      handleBlur: function handleBlur(select, e) {
        select.actions.close();
      },
      searchLU: function searchLU(term) {
        var _this = this;

        _utils.Backend.doSearchAll(term, 'Users', function (data) {
          _this.set('people', data.users);
        });
        console.log('PEOPLE:', this.get('people'));
      }
    }
  });
});