define('pretty-nuts/routes/onboarding/variable-rates', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      var model = this.modelFor('onboarding');

      // model.uiSteps.set('variable-rates',  true);
      // model.uiSteps.save();


      return model;
    }
  });
});