define('pretty-nuts/components/noclick-one-way-checkbox', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    click: function click() {
      return false;
    }
  });
});