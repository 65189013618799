define('pretty-nuts/components/dual-confirmation-modal', ['exports', 'pretty-nuts/components/aquorn-modal'], function (exports, _aquornModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _aquornModal.default.extend({

    name: 'dual_confirmation',

    prompt: null,
    confirmWord: null,
    cancelWord: null,

    confirmationInput: null,
    textToMatch: null,

    setOrgAddress: Ember.observer('confirmationInput', function () {
      $('#dual_confirmation_button').prop('disabled', this.get('confirmationInput') != this.get('textToMatch'));
    }),

    onShow: function onShow(args) {
      this.set('prompt', args.prompt);
      this.set('confirmWord', args.confirmWord);
      this.set('cancelWord', args.cancelWord);

      this.set('successCallback', args.callbacks.success);
      this.set('cancelCallback', args.callbacks.cancel);

      this.set('textToMatch', args.textToMatch);
    },
    onHide: function onHide() {
      this.set('prompt', null);
      this.set('confirmWord', null);
      this.set('cancelWord', null);
      this.set('confirmationInput', null);

      this.set('successCallback', null);
      this.set('cancelCallback', null);
    },


    actions: {
      actionConfirm: function actionConfirm() {
        var cb = this.get('successCallback');

        this.hide();

        if (cb) {
          cb();
        }
      },
      actionCancel: function actionCancel() {
        var cb = this.get('cancelCallback');
        this.onHide();
        this.hide();
        if (cb) {
          cb();
        }
      }
    }

  });
});