define('pretty-nuts/components/entity-selector', ['exports', 'pretty-nuts/constants', 'pretty-nuts/paginated-result'], function (exports, _constants, _paginatedResult) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    store: Ember.inject.service(),

    ////////////////
    // Configuration

    entityType: '', // Model type to query for (must follow entity attributes format
    results: 25, // Default number of results to show

    onEntitySelected: null,
    onNoMatch: null,

    args: null,
    membersOnly: false,

    // Configuration
    ////////////////

    ///////
    // Data

    entities: [],
    selectedEntity: null,

    // Data
    ///////

    ////////
    // State

    listPos: -1,
    showResults: false,
    currentSearchTerm: '',

    // State
    ////////

    isNull: function isNull(val) {
      if (val) {
        if (typeof val.then == 'function') {
          if (val.get('isFulfilled')) {
            return Ember.isNone(val.get('content'));
          }
        }

        return false;
      }

      return true;
    },


    /**
     * Handle a key-down event in the component to move the selection highlight and select an item
     *
     * @param e the key-press event
     */
    keyDown: function keyDown(e) {

      // Do we need this? Will the even continue propagating even though we caught it here
      e.stopPropagation();

      switch (e.keyCode) {
        case _constants.Constants.KEY_UP:
          {
            // Move up list
            this.changeSelection(-1);

            break;
          }

        case _constants.Constants.KEY_DOWN:
          {
            // Move down list
            this.changeSelection(1);

            break;
          }

        case _constants.Constants.KEY_ENTER:
          {
            // User has selected the highlighted item
            this.selectCurrent();

            break;
          }

        case _constants.Constants.KEY_ESCAPE:
          {
            // Clear input and stop showing search results
            var selectedEntity = this.get('selectedEntity');
            //console.log('escaping');

            if (!this.isNull(selectedEntity)) {
              //console.log('selectedEntity is not null', selectedEntity);
              this.set('selectedEntity', null);
            }

            this.set('currentSearchTerm', '');
            this.set('showResults', false);
            this.set('listPos', -1); // No highlight

            break;
          }

        default:
          {
            this.set('showResults', true);

            // Remove any current selection since input has changed
            //this.set('selectedEntity', null);
            var _selectedEntity = this.get('selectedEntity');

            if (!this.isNull(_selectedEntity)) {
              this.set('selectedEntity', null);
            }
          }
      }
    },

    /**
     * When the search term changes from user input, send the filter to the backend
     * to query for entities
     */
    currentSearchTermObserver: function (e) {
      //console.log('currentSearchTermOberserver', this.get('currentSearchTerm'));
      var showResults = this.get('showResults');

      if (showResults) {
        var term = this.get('currentSearchTerm');

        // Input is search value
        if (term) {

          // Only search backend if there is content in the search box (no search on empty)
          if (term.length > 0) {
            //this.set('showResults', true);
            this.searchEntity(term);
          }
        }
      }
    }.observes('currentSearchTerm'),

    /**
     * React to selected entity value changing by updating the current search term to match
     */
    selectedEntityObserver: function () {
      //console.log('selectedEntity observer');
      var that = this;
      var selectedEntity = this.get('selectedEntity');
      var currentSearchTerm = this.get('currentSearchTerm');

      // If there is a valid entity selected, set its name as the search term,
      // otherwise stick with empty string for reset from null
      if (!this.isNull(selectedEntity)) {

        //console.log('selectedEntityObserver selectedEntity', selectedEntity);

        // Check if we were handed a promise
        if (typeof selectedEntity.then === 'function') {

          //console.log('promise resolving');

          // Resolve the promise and set the current search term
          selectedEntity.then(function (entity) {
            //console.log('resolved', entity);

            if (entity) {
              //console.log('entity.name', entity.get('name'));
              that.set('currentSearchTerm', entity.get('name'));
            } else {
              //this.set('selectedEntity', null);
            }
          });
        } else {
          // Value is raw model instance, get the name as the current search term
          currentSearchTerm = selectedEntity.get('name');
        }

        // Value is raw model instance, get the name as the current search term
        //currentSearchTerm = selectedEntity.get('name');
      } else {
        //console.log('blanking search term');
        currentSearchTerm = '';
      }

      //console.log('Setting currentSearchTerm:', currentSearchTerm);
      this.set('currentSearchTerm', currentSearchTerm);
    }.observes('selectedEntity').on('init'),

    /**
     * Select the currently highlighted entity or the entered value
     */
    selectCurrent: function selectCurrent() {
      //console.log('selectCurrent');
      var entities = this.get('entities');
      var listPos = this.get('listPos');

      if (entities.length > 0 && listPos >= 0) {
        // User selected a highlighted entity from the list
        this.chooseEntity(entities[listPos]);
      }

      // Force focus out of input box
      $('.entity-selector input').blur();
    },


    /**
     * Make the specified entity the current value of the control and stop displaying the
     * selection list.
     *
     * @param entity The entity to set as the selected entity value
     */
    chooseEntity: function chooseEntity(entity) {
      //console.log('chooseEntity');
      this.set('selectedEntity', entity); // Set as current selected value
      this.set('currentSearchTerm', entity.get('name')); // Set search term to entity value
      this.set('showResults', false); // Stop showing selection list
      this.set('listPos', -1); // No highlight

      var entityType = this.get('entityType');
      var onEntitySelected = this.get('onEntitySelected');

      // If an entity selected handler was specified, call it with the model type and selected entity
      if (onEntitySelected) {
        onEntitySelected(entityType, entity);
      } else {
        console.log('No onEntitySelected');
      }
    },


    /**
     * Apply offset to the current highlight index of the search returns
     *
     * A positive value will move the highlight cursor down the list, and a negative value
     * will move the highlight cursor up the list
     *
     * @param offset Highlight cursor offset value (positive = down, negative = up)
     */
    changeSelection: function changeSelection(offset) {
      var size = this.get('entities').length;
      var listPos = this.get('listPos');

      // If there are entities to set, update the highlight cursor
      if (size > 0) {
        listPos = (listPos + offset) % size;

        // Remove existing highlight and apply new highlight position
        $('a.hover').removeClass('hover');
        $('#' + $(this)[0].elementId + ' #' + listPos).addClass('hover');
      } else {
        // No entities to select
        listPos = -1;
      }

      // Save the new position
      this.set('listPos', listPos);
    },


    /**
     * Perform query for entities based on the provided term
     *
     * @param term The filter term to send for the query
     */
    searchEntity: function searchEntity(term) {
      var _this = this;

      var entityType = this.get('entityType');

      // Build the query parameters
      var query = {
        pageSize: this.get('results'),
        filter: term,
        searchType: 'WILD_CARD'
      };
      query = Ember.merge(query, this.get('args'));
      // if gov or service-provider specific set type
      if (entityType == 'service-provider') {
        entityType = 'organization';
        query.type = 'SERVICE_PROVIDER';
      }
      if (entityType == 'government') {
        entityType = 'organization';
        query.type = 'MUNICIPALITY';
      }
      // Query for the data entities using the parameters specified
      this.get('store').query(entityType, query).then(function (data) {

        // DEBUG
        //console.log(data.get('meta'));
        //console.log(data);
        // DEBUG

        //
        // Move to regular array for non-promise use
        //
        _this.set('entities', data.toArray());

        /*
        let result = PaginatedResults.create();
        result.initData(data);
        this.set('entities', result);
        */
      });
    },


    actions: {

      /**
       * Action to occur when the input box loses focus
       */
      focusOut: function focusOut() {
        //console.log('focus out');
        if ($('.auto-complete:active').length == 0) {
          this.set('showResults', false);
          var currentSearchTerm = this.get('currentSearchTerm');

          if (currentSearchTerm) {
            var selectedEntity = this.get('selectedEntity');

            if (this.isNull(selectedEntity)) {
              // User input a term for which there was no matching entity found
              var onNoMatch = this.get('onNoMatch');

              // If a no match handler was specified, call it to let the upper level decide
              // what to do with the input
              if (onNoMatch) {
                var _currentSearchTerm = this.get('currentSearchTerm');
                var entityType = this.get('entityType');
                var _selectedEntity2 = this.get('store').createRecord(entityType, { name: _currentSearchTerm });

                this.set('selectedEntity', _selectedEntity2);

                onNoMatch(entityType, _selectedEntity2, _currentSearchTerm);
              }
            }
          }
        }
        //console.log('Clearing currentSearchTerm');

        //this.set('currentSearchTerm', '');
      },
      chooseEntity: function chooseEntity(entity) {
        //console.log('chooseEntity action');
        // event.preventDefault();
        this.set('selectedEntity', entity); // Set as current selected value
        this.set('currentSearchTerm', entity.get('name')); // Set search term to entity value
        this.set('showResults', false); // Stop showing selection list
        this.set('listPos', -1); // No highlight

        var entityType = this.get('entityType');
        var onEntitySelected = this.get('onEntitySelected');

        // If an entity selected handler was specified, call it with the model type and selected entity
        if (onEntitySelected) {
          onEntitySelected(entityType, entity);
        }
      }
    }
  });
});