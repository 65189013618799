define('pretty-nuts/models/document', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    resource: _emberData.default.belongsTo('resource'),

    user: _emberData.default.belongsTo('user'),

    organization: _emberData.default.belongsTo('organization'),
    legalEntity: _emberData.default.belongsTo('legal-unit'),
    obligation: _emberData.default.belongsTo('domain-obligation'),

    documentName: _emberData.default.attr(),
    documentType: _emberData.default.attr(),
    documentCategory: _emberData.default.attr(),

    createdDate: _emberData.default.attr(),
    lastUpdated: _emberData.default.attr(),

    key: _emberData.default.attr(),

    categoryId: _emberData.default.attr(),
    preparerOrg: _emberData.default.belongsTo('organization'),
    preparerUser: _emberData.default.belongsTo('user'),
    description: _emberData.default.attr(),
    documentDate: _emberData.default.attr('local-date'),
    objectId: _emberData.default.attr(),
    phase: _emberData.default.attr(),
    status: _emberData.default.attr()

  });
});