define('pretty-nuts/routes/government/index', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model(params) {
      var model = this.modelFor('government');
      if (model.organization.get('legalUnits.length') == 1) {
        console.log('les for this org', model.organization.get('legalUnits'));
        console.log('I ADMIN?', params.isAquornAdmin);
        if (!params.isAquornAdmin) {
          this.get('router').transitionTo('issuer', model.organization.get('legalUnits').get('firstObject').id);
        }
        // need to go directly into the one le
      }

      // return this.store.findRecord('organization-debt-profile', model.organizationId).then((profile) => {
      // return this.store.queryRecord('organization-debt-profile', {
      //   id: model.organizationId,
      //   // id: model.perspectiveDate,
      //   perspectiveDate: model.perspectiveDate
      // }).then((profile) => {
      //   Ember.set(model, 'debtProfile', profile)
      //
      //   return model;
      //
      // });

      return model;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    },

    actions: {
      didTransition: function didTransition() {
        this._super();
        this.controller.get('chartRebuild');
      },
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});