define('pretty-nuts/models/obligor', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    legalEntity: _emberData.default.belongsTo('legal-unit'),
    obligation: _emberData.default.belongsTo('obligation')
  });
});