define('pretty-nuts/routes/providers/my-service-providers', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    obligationService: Ember.inject.service(),

    model: function model() {
      var obligationService = this.get('obligationService');

      return this.get('currentUser').getMyOrganization().then(function (currentUserOrganization) {
        // return Ember.RSVP.hash({
        //   serviceProviderSummaries: this.get('store').query('service-provider-summary', {
        //     governmentId: currentUserOrganization.get('id')
        //   })
        // });

        return Ember.RSVP.hash({
          // debtProfile: obligationService.getOrganizationDebtProfile("6308d1e2-ca3f-4236-b58f-9b7f2c8b86d3")
          debtProfile: obligationService.getOrganizationDebtProfile(currentUserOrganization.get('aquornId'))
        });
      });
    }
  });
});