define('pretty-nuts/routes/onboarding/resume', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    //controller: Ember.inject.controller('onboarding'),

    model: function model(params) {
      var that = this;

      var controller = this.controllerFor('onboarding.resume');

      console.log('Resume onboarding route: ', JSON.stringify(params));

      //console.log("PARAMS: " + JSON.stringify(params));

      //console.log("CONTROLLER: " + controller);
      //console.log("CONTROLLER JSON: " + JSON.stringify(controller));

      this.store.findRecord('obligation', params.obligationId).then(function (obligation) {
        controller.set('obligation', obligation);
        controller.send('setupResume', obligation);
      });

      /*
      var result = {};
      return new Ember.RSVP.Promise((resolve, reject) => {
        //console.log('POST PROMISE');
        Ember.$.ajax({
          //url: '/api/org/203000000000000001/obligations',
          url: '/api/obligation/' + params.obligationid + '&0',
          type: 'GET',
          //contentType: 'application/json',
          //dataType: 'json'
          success: function (response) {
            //var controller = that.get('controller');
             //console.log("HERE");
            //console.log(JSON.stringify(controller));
            //console.log("RESPONSE: " + JSON.stringify(response));
             controller.set('obligation', response);
            controller.send('setupResume', response);
            //controller.send('setupResume', obligation);
            //that.transitionTo('onboarding.overview');
          },
          error: function (xhr, status, error) {
            //console.log("ERROR: " + error);
            return 'error'
          }
        })
      });
      */
    }
  });
});