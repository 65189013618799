define('pretty-nuts/routes/onboarding/securities', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      var model = this.modelFor('onboarding');

      // model.securities.update();

      Ember.set(model, 'generateSecuritiesConfig', this.get('store').createRecord('generate-securities-config', {
        couponPeriod: Ember.get(model, 'integratedObligation.value.obligation.otherInfo.interestFrequency')
      }));
      //model.generateSecuritiesConfig = this.get('store').createRecord('generate-securities-config', {});

      // Ember.set(model, 'generateSecuritiesConfig.obligation', Ember.get(model, 'obligation'));
      // Ember.set(model, 'generateSecuritiesConfig.couponPeriod', Ember.get(model, 'obligation.otherInfo.interestFrequency'));


      // model.uiSteps.set('securities',  true);
      // model.uiSteps.save();

      return model;
    },


    afterModel: function afterModel(model) {
      this.controllerFor('onboarding').updateProgress(model, 'securities');
    }

  });
});