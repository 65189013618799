define('pretty-nuts/controllers/myorganization', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var listShow = false;

  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service('notification-messages'),
    organizationService: Ember.inject.service(),
    application: Ember.inject.controller(),
    resourceService: Ember.inject.service(),
    newAddress: null,
    defaultSubCategories: true,
    profile: null,

    avatarPickerOptions: {
      accept: ['image/*'],
      uploadInBackground: false,
      imageMax: [300, 300],
      maxSize: 2 * 1024 * 1024
    },

    orgTypes: ['Government Entity', 'Public Company', 'Privately Held', 'Partnership', 'Sole Proprietorship'],

    operatingStatuses: ['Active', 'Subsidiary', 'No Longer Doing Business', 'Acquired'],

    orgCategories: Ember.computed('model.organization.type', function () {
      if (this.get('model.organization.type') == 'MUNICIPALITY') {
        return ['Federal Government Agency', 'State Government', 'State Authority', 'County', 'City/Town', 'District', 'Local Authority', 'Utility', 'Tribal Government'];
      } else if (this.get('model.organization.type') == 'SERVICE_PROVIDER') {
        return ['Bank/Banking Services', 'Legal/Legal Services', 'Advisory/Advisory Services', 'Compliance/Complaince Services', 'Management Services', 'Other'];
      } else {
        return [];
      }
    }),

    orgSubCategories: Ember.computed('model.organization.category', function () {
      var category = this.get('model.organization.category');
      if (category == 'Federal Government Agency' || category == 'State Government') {
        return ['Regulatory Agency', 'Other'];
      } else if (category == 'State Authority' || category == 'Local Authority') {
        return ['Finance Authority', 'Housing Authority', 'Pollution Control Authority', 'Transportation Authority', 'Education & Educational Facilties', 'Economic Development', 'Health & Health Facilities', 'Other'];
      } else if (category == 'District') {
        return ['Water District', 'Fire District', 'Assessment District', 'Facilities District', 'Irrigation District', 'School District', 'Other'];
      } else if (category == 'Utility') {
        return ['Electric Utility', 'Water Utility', 'General Utilities', 'Other'];
      } else if (category == 'Bank/Banking Services') {
        return ['Investment Banking', 'Trust & Custody Banking', 'Other'];
      } else if (category == 'Advisory/Advisory Services') {
        return ['General Municipal Advisory', 'Financial Advisory', 'Investment Advisory', 'Other'];
      } else if (['County', 'City/Town', 'Tribal Government', 'Legal/Legal Services', 'Compliance/Complaince Services', 'Management Services', 'Other'].includes(category)) {
        return ['None', 'Other'];
      }
    }),

    foundedDateLabel: Ember.computed('model.organization.operatingStatus', function () {
      var operatingStatus = this.get('model.organization.operatingStatus');
      if (operatingStatus == 'Subsidiary' || operatingStatus == 'Acquired') {
        return 'Acquired Date';
      } else {
        return 'Founded Date';
      }
    }),

    reloadModel: function reloadModel() {
      this.send('reloadModel');
    },


    actions: {
      saveSection: function saveSection(section) {
        var that = this;

        this.get('model.organization').save().then(function () {
          if (section == 'contact') {
            that.send('saveAddresses', that);
          }

          that.send('display', section);
          that.get('notifications').success('organization saved', { autoClear: true });
        }).catch(function (error) {
          that.send('display', section);
          that.get('notifications').error("failed: " + error);
        });
      },
      saveAddresses: function saveAddresses(that) {
        that.get('model.addresses').forEach(function (address) {
          if (address.hasDirtyAttributes) {
            address.save();
          }
        });

        if (that.get('newAddress')) {
          that.get('newAddress').save().then(function () {
            that.set('newAddress', null);
          });
        }
      },
      revert: function revert(item) {
        this.get('model').organization.rollbackAttributes();

        this.get('model.addresses').forEach(function (address) {
          if (address.get('hasDirtyAttributes')) {
            address.rollbackAttributes();
          }
        });

        this.reloadModel();
        this.send('display', item);
      },
      browse_file: function browse_file() {
        document.getElementById('file').click();
        return false;
      },
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      },
      setOperatingStatus: function setOperatingStatus(operatingStatus) {
        this.set('model.organization.operatingStatus', operatingStatus);
        if (operatingStatus == 'Subsidiary' || operatingStatus == 'Acquired') {
          $('#org-parent .entity-selector input').prop('disabled', false);
          $('#org-parent .entity-selector input').val('');
        } else {
          $('#org-parent .entity-selector input').prop('disabled', true);
          $('#org-parent .entity-selector input').val('None');
        }
      },
      setOrganizationType: function setOrganizationType(type) {
        if (type == 'Government Entity') {
          this.set('model.organization.type', 'MUNICIPALITY');
        } else {
          this.set('model.organization.type', 'SERVICE_PROVIDER');
        }
        this.set('model.organization.industry', type);
        this.set('model.organization.category', null);
        this.set('model.organization.subCategory', null);
      },
      setOrganizationCategory: function setOrganizationCategory(category) {
        this.set('model.organization.category', category);
        this.set('model.organization.subCategory', null);
      },
      setOrganizationSubCategory: function setOrganizationSubCategory(subCategory) {
        if (subCategory == 'None') {
          this.set('model.organization.subCategory', this.get('model.organization.category'));
        } else if (subCategory == 'Other') {
          this.set('model.organization.subCategory', null);
          this.set('defaultSubCategories', false);
        } else {
          this.set('model.organization.subCategory', subCategory);
        }
      },
      showSubCategoryDefaults: function showSubCategoryDefaults() {
        this.set('model.organization.subCategory', null);
        this.set('defaultSubCategories', true);
      }
    }
  });
});