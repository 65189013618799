define('pretty-nuts/components/reocurrence-frequency', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		frequencyUnits: ['Days', 'Business Days', 'Weeks', 'Months', 'Years'],
		monthlyDays: ['Day 9', '2nd Tuesday', '7th Business Day'],
		weekdays: [],
		endDate: '',
		actions: {
			endEvent: function endEvent(val) {
				console.log('val', val);
				switch (val) {
					case '0':
						this.set('endDate', this.get('finalObligationMaturity'));
						console.log('case0:', this.get('finalObligationMaturity'));
						break;
					case '1':
						break;
					default:
						this.set('endDate', val);
						console.log('case1:', this.get('endDate'));
						break;
				}
			},
			setMonthlyDay: function setMonthlyDay(val) {
				this.set('monthlyDay', val);
			},
			setFrequencyUnit: function setFrequencyUnit(val) {
				this.set('frequencyUnit', val);
			},
			selectedWeekdays: function selectedWeekdays(val) {
				var weekdays = this.get('weekdays');
				var match = false;
				for (var i in weekdays) {
					if (weekdays[i] == val) {
						match = true;
						var index = weekdays.indexOf(val);
						weekdays.splice(index, 1);
						break;
					}
				}
				if (match == false) {
					weekdays.push(val);
				}
				this.set('weekdays', weekdays);
			}
		}
	});
});