define('pretty-nuts/controllers/notifications', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notificationService: Ember.inject.service(),
    notifications: [],
    checkedNotifications: {},
    openNotification: null,
    anyCheckedNotifications: false,
    fullName: Ember.computed('openNotification', function (n) {
      var fullName = n.jsonObject.FIRST_NAME + " " + n.jsonObject.LAST_NAME;
      return fullName;
    }),
    checkCheckedNotifications: function () {
      var _this = this;

      console.log('checking checkedNotifications');
      var cn = this.get('checkedNotifications');
      this.set('anyCheckedNotifications', false);
      var n = Object.keys(cn);
      n.forEach(function (key) {
        console.log('key', key, cn[key]);
        if (cn[key]) {
          _this.set('anyCheckedNotifications', true);
        }
      });
    }.observes('checkedNotifications'),

    notificationModelConverter: function () {
      var notifications = [];
      this.get('model.notifications').forEach(function (notification) {
        var properties = notification.getProperties(['notificationId', 'createdOn', 'type', 'seen', 'jsonObject']);
        properties.jsonObject = JSON.parse(properties.jsonObject);
        notifications.push(properties);
      });
      this.set('notifications', notifications);
      $('.fa-badge').attr('data-after', notifications.length);
    }.observes('model.notifications'),

    actions: {
      logout: function logout() {
        this.get('onLogout')();
      },
      markAll: function markAll() {
        // this.get('notificationService').updateNotificationSeen(notifications[i].notificationId, true);
        var notifications = this.get('notifications');
        var cn = this.get('checkedNotifications');
        for (var i = 0; i < notifications.length - 1; i++) {
          cn[notifications[i].notificationId] = true;
        }
        console.log('checkedNotifications', cn);
        this.set('checkedNotifications', cn);
        this.send('markSelected');
      },
      markSelected: function markSelected() {
        var that = this;
        var count = 0;
        var notifications = this.get('checkedNotifications');
        var n = Object.keys(notifications);
        this.set('anyCheckedNotifications', false);
        n.forEach(function (key) {
          count++;
          if (notifications[key]) {
            that.get('notificationService').updateNotificationSeen(key, true).then(function () {
              if (count == n.length) {
                that.send('reloadModel');
              }
            });
          }
        });
      },
      revealNotification: function revealNotification(notification) {
        this.set('openNotification', notification);
        console.log('current notification', notification);
        $('#notification').foundation('open');
      },
      markChecked: function markChecked(notificationId) {
        var cn = this.get('checkedNotifications');
        if (cn[notificationId]) {
          cn[notificationId] = false;
        } else {
          cn[notificationId] = true;
        }
        this.set('checkedNotifications', cn);
        this.checkCheckedNotifications();
        console.log('CHECKED for ', notificationId, 'is ', cn[notificationId], '-->', this.get('checkedNotifications'));
      },
      markSeen: function markSeen(notificationId, index) {
        var that = this;
        this.get('notificationService').updateNotificationSeen(notificationId, true).then(function () {
          that.send('reloadModel');
        });
      }
    }
  });
});