define('pretty-nuts/models/obligation', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    legalEntity: _emberData.default.belongsTo('legal-unit'),

    version: _emberData.default.attr(),

    status: _emberData.default.attr('enum', { type: _constants.Constants.ObligationStatus }),
    aquornId: _emberData.default.attr(),
    creator: _emberData.default.belongsTo('user'),
    lastUpdatedBy: _emberData.default.belongsTo('user'),
    lastUpdatedDate: _emberData.default.attr(),

    shortName: _emberData.default.attr(),
    series: _emberData.default.attr(),

    longName1: _emberData.default.attr(),
    longName2: _emberData.default.attr(),
    longName3: _emberData.default.attr(),
    longName4: _emberData.default.attr(),
    projectName: _emberData.default.attr(),

    securityPledge: _emberData.default.attr(),

    federalTaxStatus: _emberData.default.attr(),
    stateTaxStatus: _emberData.default.attr(),
    amtStatus: _emberData.default.attr(),

    bankQualified: _emberData.default.attr(),
    purpose: _emberData.default.attr('enum', { type: _constants.Constants.ObligationPurpose }),
    seniority: _emberData.default.attr(),
    saleType: _emberData.default.attr(),
    offeringType: _emberData.default.attr(),
    structureType: _emberData.default.attr('enum', { type: _constants.Constants.ObligationStructure }),

    creditEnhancement: _emberData.default.attr(),

    mainUseOfProceeds: _emberData.default.attr(),
    generalUseOfProceeds: _emberData.default.attr(),

    currency: _emberData.default.attr(),

    plannedSaleDate: _emberData.default.attr(),
    finalSaleDate: _emberData.default.attr(),
    finalSaleTime: _emberData.default.attr(),
    finalMaturityDate: _emberData.default.attr(),

    datedDate: _emberData.default.attr(),
    deliveryDate: _emberData.default.attr(),
    firstInterestDate: _emberData.default.attr(),

    numBonds: _emberData.default.attr(),

    denomination: _emberData.default.attr('decimal', { precision: 2 }),

    plannedSaleAmount: _emberData.default.attr('decimal', { precision: 2 }),
    // parIssued: DS.attr('decimal', {precision: 2}),
    parIssued: _emberData.default.attr(),

    // premium: DS.attr('decimal', {precision: 2}),
    premium: _emberData.default.attr(),
    // discount: DS.attr('decimal', {precision: 2}),
    discount: _emberData.default.attr(),

    totalSourceOfFunds: _emberData.default.attr('decimal', { precision: 2 }),
    costOfIssuance: _emberData.default.attr('decimal', { precision: 2 }),
    underwritersDiscount: _emberData.default.attr('decimal', { precision: 2 }),
    underwriterManagementFee: _emberData.default.attr('decimal', { precision: 2 }),
    underwriterTakedown: _emberData.default.attr('decimal', { precision: 2 }),
    underwriterRisk: _emberData.default.attr('decimal', { precision: 2 }),
    contingency: _emberData.default.attr(),

    totalUseOfFunds: _emberData.default.attr(),

    averageCoupon: _emberData.default.attr('decimal', { precision: 3 }),
    trueInterestCost: _emberData.default.attr('decimal', { precision: 6 }),
    netInterestCost: _emberData.default.attr('decimal', { precision: 6 }),
    allInTrueInterestCost: _emberData.default.attr('decimal', { precision: 6 }),
    arbitrageYield: _emberData.default.attr('decimal', { precision: 6 }),
    // averageLife: DS.attr('decimal', {precision: 3}),
    averageLife: _emberData.default.attr(),
    durationOfIssue: _emberData.default.attr(),
    bondProceeds: _emberData.default.attr(),

    // accruedInterest: DS.attr('decimal', {precision: 2}),
    accruedInterest: _emberData.default.attr(),
    totalInterest: _emberData.default.attr(),
    netInterest: _emberData.default.attr(),
    totalDebtService: _emberData.default.attr(),
    maxAnnualDebtService: _emberData.default.attr(),
    averageAnnualDebtService: _emberData.default.attr(),

    moodysRating: _emberData.default.attr(),
    spRating: _emberData.default.attr(),
    fitchRating: _emberData.default.attr(),
    kbraRating: _emberData.default.attr(),

    otherInfo: _emberData.default.attr('embedded-model', { modelName: 'obligation-other-info', generateId: true }),

    debtService: _emberData.default.attr(),

    hasDraft: null,

    // otherInfo: DS.a`ttr('json-blob', {
    //   defaultValue() {
    //     return {
    //       uiSteps: {}
    //     };
    //   }
    // }),

    /////////////////////////////////////////////////////////
    // Computed values for compatibility with entity-selector

    name: Ember.computed('shortName', function () {
      return this.get('shortName');
    }),

    isPending: Ember.computed('status', function () {
      return this.get('status') === _constants.Constants.ObligationStatus.PENDING;
    })

    // Computed values for compatibility with entity-selector
    /////////////////////////////////////////////////////////

  });
});