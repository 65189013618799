define('pretty-nuts/routes/obligation/events', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    eventService: Ember.inject.service(),

    queryParams: {
      selectedStartDate: {
        refreshModel: true
      },
      selectedEndDate: {
        refreshModel: true
      }
    },
    finalObligationMaturity: Ember.computed('model', function () {
      var date = moment().format('YYYY/MM/DD, HH:MM:SS');
      return date;
    }),
    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    },
    model: function model(params) {
      var obligationModel = this.modelFor('obligation');
      var obligation = obligationModel.obligation;
      var eventService = this.get('eventService');

      // for obligation onboarding events list ---> eventService.getEventMasterDrafts(obligationId)
      return Ember.RSVP.hash({
        obligation: obligation,
        events: eventService.getEvents(params.selectedStartDate, params.selectedEndDate, '', '', obligation.get('id')),
        obligationId: obligation.get('id')
      }).then(function (model) {
        console.log('UGLY EVENTS', model);
        // model.events = model.events.content;
        return model;
      });
    }
  });
});