define('pretty-nuts/routes/onboarding/events', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
		eventService: Ember.inject.service(),

		model: function model() {
			var model = this.modelFor('onboarding');
			var eventService = this.get('eventService');

			if (model.events && model.eventObligationId === model.obligationId) {
				return model;
			} else {
				model.events = undefined;
			}
			// for obligation onboarding events list ---> eventService.getEventMasterDrafts(obligationId)
			return Ember.RSVP.hash({
				events: eventService.getEventMasterDrafts(model.obligationId)
			}).then(function (eventModel) {
				model.events = eventModel.events;
				model.eventObligationId = model.obligationId;
				return model;
			});
		},


		afterModel: function afterModel(model) {
			this.controllerFor('onboarding').updateProgress(model, 'events');
		},

		actions: {
			reloadModel: function reloadModel() {
				this.refresh();
			}
		}
	});
});