define('pretty-nuts/controllers/governments', ['exports', 'pretty-nuts/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Controller.extend((_Ember$Controller$ext = {
    application: Ember.inject.controller(),
    query: null,
    activeColumn: 'name',
    desc: false,

    runEveryTime: function () {
      var count = 0;
      Ember.run.schedule('afterRender', this, function () {
        $('.obligations-table').floatThead({ top: 66 });
        var controller = this;
        console.log('every time');
        window.setTimeout(function () {
          window.scrollTo(0, 1);
        }, 100);
      });
      this.set('entered', false);
      // this.set('model.accumulatedUsers',null);
    }.observes('entered')
  }, _defineProperty(_Ember$Controller$ext, 'query', function () {
    console.log('observed a change in query or activeColumn or desc');
    this.send('actionReloadFilter', this.get('application.query'), this.get('activeColumn'), this.get('desc'));
  }.observes('application.query', 'activeColumn', 'desc')), _defineProperty(_Ember$Controller$ext, 'actions', {
    clickRow: function clickRow(id) {
      this.transitionToRoute('government', id);
    },
    actionMore: function actionMore() {
      this.get('model.accumulatedGovernments').more();
    },
    sortColumn: function sortColumn(prop) {
      // console.log('sort column by',prop);
      this.set('desc', !this.get('desc'));
      this.set('activeColumn', prop);
    }
  }), _Ember$Controller$ext));
});