define('pretty-nuts/controllers/reporting/legal-entity/period', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    reporting: Ember.inject.controller(),
    groups: ['None', 'Legal Units', 'Obligations'],
    groupedBy: 'None',
    currentReport: { value: 'period', name: 'Period Debt Service' },
    periodStart: moment().format("YYYY-MM-DD"), //first day of month
    periodEnd: moment().add(1, 'y').format('YYYY-MM-DD'), // defaults 1 year later

    groupings: [_constants.Constants.Grouping.OBLIGATION, _constants.Constants.Grouping.AMT_STATUS,
    // Constants.Grouping.CALL_STATUS,
    _constants.Constants.Grouping.FEDERAL_TAX_STATUS, _constants.Constants.Grouping.OFFERING_TYPE, _constants.Constants.Grouping.PURPOSE,
    // Constants.Grouping.RATE_TYPE,
    _constants.Constants.Grouping.SALE_METHOD, _constants.Constants.Grouping.SECURITY_PLEDGE, _constants.Constants.Grouping.STATE_TAX_STATUS, _constants.Constants.Grouping.STRUCTURE_TYPE],

    groupingToEnumMap: {
      Obligation: null,
      AMTStatus: _constants.Constants.AMTStatus,
      // Constants.Grouping.CALL_STATUS: null,
      FederalTaxStatus: _constants.Constants.TaxStatus,
      OfferingType: _constants.Constants.OfferingType,
      Purpose: _constants.Constants.ObligationPurpose,
      // Constants.Grouping.RATE_TYPE:, null,
      SaleMethod: _constants.Constants.SaleType,
      SecurityPledge: _constants.Constants.SecurityPledge,
      StateTaxStatus: _constants.Constants.TaxStatus,
      StructureType: _constants.Constants.ObligationStructure
    },

    overlayObserver: function () {
      var running = this.get('model.report.task.isRunning');

      if (running) {
        console.log('hiding overlay');
        $('.report').addClass('calculating');
      } else {
        console.log('showing overlay');
        $('.report').removeClass('calculating');
      }
    }.observes('model.report.task.isRunning'),
    enumType: Ember.computed('model.grouping', function () {
      var grouping = this.get('model.grouping');

      var map = this.get('groupingToEnumMap');
      var et = map[grouping.jsonValues[0]];

      console.log('enum type', grouping.jsonValues[0], et);

      return et;
    }),

    onGroupedBy: function () {
      console.log('Grouped By', this.get('groupedBy'));
      if (this.get('groupedBy') == 'None') {
        console.log(':-(');
      }
    }.observes("groupedBy"),
    period: function () {
      console.log('Date Change', this.get('periodStart'), this.get('periodEnd'));
      if (this.get('groupedBy') == 'None') {}
      return;
    }.observes("periodStart", "periodEnd"),
    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        console.log('landscape foo');
        this.set('landscape', true);
        this.get('reporting').send('switchToLandscape', true);
      });
      this.set('entered', false);
    }.observes('entered'),

    actions: {
      revert: function revert(item) {
        this.send('display', item);
      },
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      },
      actionRefreshReport: function actionRefreshReport() {
        var grouping = this.get('model.grouping');
        var start = this.get('model.start');
        var end = this.get('model.end');

        var report = this.get('model.report');

        // report.setGrouping(grouping);
        // report.setStart(start);
        report.update(start, end);
        this.set('model.templateGrouping', grouping);
        this.send('display', 'editable');
      },
      actionSetGrouping: function actionSetGrouping(grouping) {
        this.get('model.report').setGrouping(grouping);
      },
      actionSetStartDate: function actionSetStartDate(evt) {
        this.get('model.report').setStartDate(evt.target.value);
      },
      actionSetEndDate: function actionSetEndDate(evt) {
        this.get('model.report').setEndDate(evt.target.value);
      }
    }
  });
});