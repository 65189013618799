define('pretty-nuts/routes/onboarding/cashflow', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/constants'], function (exports, _aquornAuthenticatedRouteMixin, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    operationService: Ember.inject.service(),

    model: function model() {

      var operationService = this.get('operationService');

      var model = this.modelFor('onboarding');

      // console.log('model obligation id', model.obligationId);
      // console.log('model obligation date', model.perspectiveDate);

      return model;

      // return model.integratedObligation.then((integratedObligation) => {
      //   Ember.set(model, 'parameters', {
      //     perspectiveDate: integratedObligation.get('value.obligation.perspectiveDate')
      //   });
      // });

      // Ember.set(model, 'parameters', {
      //   perspectiveDate: Ember.get(model, 'integratedObligation.value.obligation.perspectiveDate')
      // });

      // return model;

      // return Ember.RSVP.hash({
      //   stats: this.get('store').queryRecord(
      //     'domain-stats-cashflow', {
      //       perspectiveDate: model.perspectiveDate,
      //       obligationIds: model.obligationId,
      //       resolution: Constants.Frequency.ACTUAL.queryValue
      //     })
      // }).then((m) => {
      //   Ember.set(model, 'stats', {'ACTUAL': m.stats, 'SEMI-ANNUAL': null, 'ANNUAL': null});
      //   return model;
      // });

      // model.debtService.reload();

      // model.uiSteps.set('cashflow',  true);
      // model.uiSteps.save();

      // return model;
    },


    afterModel: function afterModel(model) {
      this.controllerFor('onboarding').updateProgress(model, 'cashflow');
    }
  });
});