define('pretty-nuts/controllers/aquorn-admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    permissions: [{ role: 'Guest', type: 'option' }, { role: 'Member', type: 'option' }, { role: 'Submitter', type: 'option' }, { role: 'Approver', type: 'option' }, { role: 'Admin', type: 'option' }, { role: 'Owner', type: 'option' }],
    actions: {
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      remove: function remove(item) {
        $('#' + item).hide();
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      },
      addAction: function addAction() {
        $('#new_action').show();
      },
      saveNewAction: function saveNewAction() {
        $('#new_action').hide();
      },
      setPermission: function setPermission(item, where) {
        console.log('setPermission', 'where:', where);
        console.log('item:', item.role);
        this.set(where, item.role);
      }
    }
  });
});