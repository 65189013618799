define('pretty-nuts/routes/obligations', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    obligationService: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    model: function model(params) {

      var obligationService = this.get('obligationService');
      var currentEmployment = this.get('currentUser').getCurrentEmployment();

      return Ember.RSVP.hash({
        obligations: obligationService.getObligations(currentEmployment.get('organization').get('id'))
      });

      //let currentEmployment = this.get('currentUser').getCurrentEmployment();

      //return obligationService.getObligations(currentEmployment.get('organization').get('id'));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    }
  });
});