define('pretty-nuts/services/orgsapi', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    /**
     * Backend request wrapper
     */
    backend: Ember.inject.service("Backend"),

    session: Ember.inject.service(),

    getOrganizationProfiles: function getOrganizationProfiles(orgIds, cb) {
      console.log('getorgprofiles');
      var stringOfOrgs = orgIds.join();
      // $.get("/api/org/orgProfiles", "organizationIds="+stringOfOrgs).done(data => {
      //   console.log('cbnull');
      //   console.log('orgprofiles',JSON.stringify(data));
      //   cb(null,data);
      // }).fail(data => {
      //   console.log('cbtrue');
      //   cb(true, data);
      // })
      return this.get('backend').get({
        url: "/api/org/orgProfiles",
        data: { organizationIds: stringOfOrgs },
        // url: "/api/org/orgProfiles",
        // data: "organizationIds="+stringOfOrgs,
        cb: cb
      });
    },
    getOrganizationProfile: function getOrganizationProfile(orgId, cb) {
      return this.get('backend').get({
        url: "/api/org/" + orgId,
        cb: cb
      });
    },
    getOrganizationLEs: function getOrganizationLEs(orgId, cb) {
      return this.get('backend').get({
        url: "/api/org/" + orgId + "/entities",
        cb: cb
      });
    },
    getOrganizationMembers: function getOrganizationMembers(orgId, cb) {
      return this.get('backend').get({
        url: "/api/org/" + orgId + "/members",
        cb: cb
      });
    },
    getOrganizationPendingMemberRequests: function getOrganizationPendingMemberRequests(orgId, cb) {
      return this.get('backend').get({
        url: "/api/org/" + orgId + "/members/pending",
        cb: cb
      });
    },
    getLegalUnitProfile: function getLegalUnitProfile(legalUnitId, cb) {
      return this.get('backend').get({
        url: "/api/legalUnit/" + legalUnitId,
        cb: cb
      });
    },
    getLegalUnitObligations: function getLegalUnitObligations(legalUnitId, cb) {
      return this.get('backend').get({
        url: "/api/org/" + legalUnitId + "/obligations",
        cb: cb
      });
    },
    getLegalUnitObligationDrafts: function getLegalUnitObligationDrafts(legalUnitId, cb) {
      return this.get('backend').get({
        url: "/api/org/" + legalUnitId + "/obligations/drafts",
        cb: cb
      });
    },
    userJoinRequest: function userJoinRequest(orgId, cb) {
      return this.get('backend').put({
        url: "/api/org/" + orgId + "/members/join",
        data: JSON.stringify({
          requestorType: "User",
          objectType: "Organization",
          role: "Member"
        }),
        cb: cb
      });
    },
    userJoinNow: function userJoinNow(orgId, cb) {
      console.log('session', this.get('session').get('data').authenticated);
      return this.get('backend').post({
        url: "/joinorg/" + orgId,
        cb: cb
      });
    },
    rejectJoinRequest: function rejectJoinRequest(requestId, cb) {
      return this.get('backend').post({
        url: "/api/roleRequests/" + requestId + "/decline",
        data: {},
        cb: cb
      });
    },
    acceptJoinRequest: function acceptJoinRequest(requestId, cb) {
      return this.get('backend').post({
        url: "/api/roleRequests/" + requestId + "/accept",
        data: {},
        cb: cb
      });
    },
    removeOrganizationUser: function removeOrganizationUser(orgId, userId, cb) {
      return this.get('backend').delete({
        url: "/api/org/" + orgId + "/members",
        data: JSON.stringify({
          granteeType: "USER",
          granteeId: userId
        }),
        cb: cb
      });
    }
  });
});