define('pretty-nuts/routes/reporting/legal-entity/cashflow', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    obligationService: Ember.inject.service(),

    // model(params) {
    //   let model = this.modelFor('reporting.legal-entity');
    //
    //   console.log('cashflow model', model);
    //
    //   return model;
    // },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    },

    actions: {
      didTransition: function didTransition() {
        this._super();
        this.controller.get('chartRebuild');
      },
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});