define('pretty-nuts/utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function compare(a, b) {
    return a > b ? 1 : a < b ? -1 : 0;
  }

  var Sort = exports.Sort = {
    asc: false,
    desc: true
  };

  var ArrayComparer = exports.ArrayComparer = {
    mapper: function mapper(mapping, desc) {
      return function (a, b) {
        var res = compare(mapping(a), mapping(b));
        return desc ? -res : res;
      };
    },
    property: function property(prop, desc) {
      return this.mapper(function (x) {
        return x[prop];
      }, desc);
    }
  };

  var Backend = exports.Backend = {

    // Some credentials that you will need frequently when talking to backend
    userId: function userId() {
      var login = localStorage.getItem('login-session');
      console.log('LOGIN', login);
      return JSON.parse(login).user.userId;
    },

    sessionId: '',
    documentToken: '',

    /**
     * @param {object} obligation - {
     *   obligationOwner: {
     *     legalUnitId,
     *     creator
     *   },
     *   obligationInformation: {
     *     obligationShortName,
     *     obligationLongName1,
     *     obligationLongName2,
     *     obligationLongName3,
     *     obligationLongName4,
     *     projectName,
     *
     *     securityPledge,
     *     federalTaxStatus,
     *     stateTaxStatus,
     *     amtStatus,
     *     bankQualified,
     *     purpose,
     *     seniority,
     *     saleType,
     *     offeringType,
     *     structureType,
     *     creditEnhancement,
     *     generalUseOfProceeds,
     *     mainUserOfProceeds,
     *
     *     plannedSaleDate,
     *     finalSaleDate,
     *     datedDate,
     *     deliveryDate,
     *     firstInterestDate,
     *
     *     currency,
     *     plannedSaleAmount,
     *     finalSaleAmount,
     *     denomination,
     *     numberOfBonds,
     *     parIssued,
     *     premium,
     *     discount,
     *     costOfIssuance,
     *     underwriterDiscount,
     *     contingency,
     *
     *     totalSourceOfFunds,
     *     otherSources,
     *     userOfFunds,
     *     totalUserOfFunds,
     *
     *     averageCoupon,
     *     trueInterestCost,
     *     netInterestCost,
     *     allInTrueInterestCost,
     *     arbitrageYield,
     *     averageLife,
     *     durationOfIssue,
     *     bondProceeds,
     *     totalInterest,
     *     netInterest,
     *     totalDebtService,
     *     maximumAnnualDebtService,
     *     averageAnnualDebtService,
     *     serviceProviders
     *   },
     *   securitiesList: [
     *   ]
     * }
     * @param {function} cb - Callback
     */
    createObligation: function createObligation(obligation, cb) {
      $.ajax('/api/obligation', {
        type: 'PUT',
        data: obligation
      }).done(cb);
    },
    createOrg: function createOrg(org, cb) {
      $.ajax('/api/org', {
        type: 'PUT',
        data: org
      }).done(cb);
    },
    createLE: function createLE(le, cb) {
      $.ajax('/api/legalUnit', {
        type: 'PUT',
        data: le
      }).done(cb);
    },


    /**
     * @param {string} text - Search text
     * @param {string} type - Search type, like "LegelUnits", "Users", "Organizations", "Documents", "Obligations"
     * @param {function} cb - Callback
     */
    doSearch: function doSearch(text, type, cb) {
      console.log("Searching a: " + type);
      var url = '/api/search?searchText=' + text + '&searchType=wild_card&search' + type + '=yes';
      $.get(url).done(cb);
    },

    /**
     * @param {string} text - Search text
     * @param {string} type - Search type, like "LegelUnits", "Users", "Organizations", "Documents", "Obligations"
     * @param {function} cb - Callback
     */
    doSearchAll: function doSearchAll(text, type, cb) {
      console.log("Searching all: " + type);
      var url = '/api/search?searchText=' + text + '&searchType=wild_card&searchAll' + type + '=yes';
      $.get(url).done(cb);
    },
    verifyDocumentAccess: function verifyDocumentAccess(id, type, cb) {
      var _this = this;

      var url = '/api/upload/verifyaccess';
      $.post(url, {
        id: id,
        type: type
      }).done(function (data) {
        _this.documentToken = data;
        cb(data);
      });
    },
    uploadFile: function uploadFile($form, id, type, cb) {
      var url = '/api/upload/uploadFile';
      var formData = new FormData($form.get(0));
      formData.set('id', id);
      formData.set('type', type);
      formData.set('token', this.documentToken);
      $.ajax(url, {
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false
      }).done(cb);
    }
  };
});