define('pretty-nuts/models/obligation-other-info', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    dayBasis: _emberData.default.attr('enum', { type: _constants.Constants.DayBasis }),
    interestFrequency: _emberData.default.attr('enum', { type: _constants.Constants.Frequency }),
    resetFrequency: _emberData.default.attr('enum', { type: _constants.Constants.Frequency }),
    securityType: _emberData.default.attr(),

    firstMaturityDate: _emberData.default.attr(),
    finalMaturityDate: _emberData.default.attr(),

    totalSecuritiesPrincipal: _emberData.default.attr(),

    ////////////////////////
    // General Attributes //
    ////////////////////////

    notes: _emberData.default.attr(),

    ///////////////
    // Uses info //
    ///////////////

    underwriterManagementFeePricePerBond: _emberData.default.attr(),
    underwriterManagementFeePercentPar: _emberData.default.attr(),

    underwriterTakedownPricePerBond: _emberData.default.attr(),
    underwriterTakedownPercentPar: _emberData.default.attr(),

    underwriterRiskPricePerBond: _emberData.default.attr(),
    underwriterRiskPercentPar: _emberData.default.attr(),

    totalUnderwriterDetailPricePerBond: _emberData.default.attr(),
    totalUnderwriterDetailParAmount: _emberData.default.attr(),
    totalUnderwriterDetailPrice: _emberData.default.attr(),

    totalUnderwriterExpensePricePerBond: _emberData.default.attr(),
    totalUnderwriterExpenseParAmount: _emberData.default.attr(),
    totalUnderwriterExpensePrice: _emberData.default.attr(),

    totalUnderwriterPricePerBond: _emberData.default.attr(),
    totalUnderwriterParAmount: _emberData.default.attr(),

    totalPrimaryUses: _emberData.default.attr(),
    totalUsesOfFunds: _emberData.default.attr(),

    totalOtherSourcesOfFunds: _emberData.default.attr(),

    uiSteps: _emberData.default.attr()
  });
});