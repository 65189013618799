define('pretty-nuts/models/domain-obligation-refunding', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    perspectiveDate: _emberData.default.attr(),
    refundingObligationId: _emberData.default.attr(),
    refundedObligationId: _emberData.default.attr(),
    refundedSecurities: _emberData.default.attr('embedded-models', { modelName: 'domain-security-refunding', generateId: true }),
    // refundedSecurities: DS.attr(),

    obligation: Ember.computed('perspectiveDate', function () {
      var obligationId = this.get('refundedObligationId');

      if (!obligationId) {
        return null;
      }

      return this.get('store').findRecord('domain-obligation', obligationId);
    })
  });
});