define('pretty-nuts/services/current-user', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    session: Ember.inject.service(),

    operationService: Ember.inject.service(),
    resourceService: Ember.inject.service(),

    /**
     * The Ember data store doesn't get automatically injected into services like
     * routes and controllers.
     */
    store: Ember.inject.service(),

    backend: Ember.inject.service(),

    isAquornAdmin: false,

    _load: function _load(userId) {
      var _this = this;

      var that = this;
      var store = this.get('store');

      console.log('currentUser._load()');

      return Ember.RSVP.hash({
        user: store.findRecord('user', userId),
        employments: store.query('employment', { userId: userId }),
        phones: store.query('phone', { userId: userId }),
        emails: store.query('email', { userId: userId }),
        userProfile: store.findRecord('integrated-user-profile', userId),
        userAccess: store.query('user-access', {})
      }).then(function (m) {
        return store.query('organization-user-role', { userId: m.user.get('id') }).then(function (our) {
          m.roles = our;
          return m;
        });
      }).then(function (model) {

        console.log('currentUser.then()', model);

        //
        // User
        //
        that.set('user', model.user);
        console.log('service.user', _this.get('user'));

        that.set('userProfile', model.userProfile);

        //
        // Employments
        //
        var employments = [];

        // Transfer to mutable array
        model.employments.forEach(function (employment) {
          employments.pushObject(employment);
        });

        //that.set('employments', employments);
        that.set('employments', model.employments);

        //
        // Phones
        //
        var phones = [];

        // Transfer for mutable array
        model.phones.forEach(function (phone) {
          phones.pushObject(phone);
        });

        //that.set('phones', phones);
        that.set('phones', model.phones);

        //
        // Emails
        //
        var emails = [];

        // Transfer to mutable array
        model.emails.forEach(function (email) {
          emails.pushObject(email);
        });

        //that.set('emails', emails);
        that.set('emails', model.emails);
        //
        // User roles
        //
        var roles = [];

        // Transfer to mutable array
        model.roles.forEach(function (role) {
          roles.pushObject(role);
        });

        //that.set('roles', roles);
        that.set('roles', model.roles);

        that.set('accessStructures', model.userAccess);

        employments.forEach(function (employment) {
          employment.get('organization').then(function (organization) {
            employment.set('role', _this.getOrganizationRole(organization));
          });
        });
      }).then(function () {
        // console.log('currentUser.then.then()');
        var fullName = that.get('user.fullName');
        var email = that.get('userProfile.email.address');
        var id = that.get('user.id');
        var company = that.get('userProfile.primaryEmployment.organizationName');
        var companyId = that.get('userProfile.primaryEmployment.organizationId');
        var link = 'http://1812.io/person/' + id;
      }).catch(function (reason) {
        console.log('An error occurred getting the user information:');
        console.log(reason);
        that.unload();
      });
    },


    /**
     * Load or reload the current user session state
     *
     * @returns {*}
     */
    load: function load() {
      var session = this.get('session');
      var userId = session.get('data.authenticated.userId');
      var isAquornAdmin = session.get('data.authenticated.isAquornAdmin');

      this.set('isAquornAdmin', isAquornAdmin);

      if (userId) {
        // console.log('Calling load user', userId);

        return this._load(userId);
      } else {
        // console.log('Skipping load user', userId);
        // Not authenticated or not user id
        return Ember.RSVP.resolve();
      }
    },
    reload: function reload() {
      return Ember.RSVP.hash({
        user: this.get('user').reload(),
        employments: this.get('employments').update(),
        phones: this.get('phones').update(),
        emails: this.get('emails').update(),
        roles: this.get('roles').update(),
        accessStructures: this.get('accessStructures').update()
      });
    },


    /**
     * Clear the current user session state and information. This should occur on logout or
     * session expiration.
     */
    unload: function unload() {
      this.set('user', null);
    },


    /**
     * Save the current user's profile state
     */
    save: function save() {
      return this.get('user').save();
    },


    /**
     * Set the user's profile setup complete flag. Setting to true will allow the user
     * to proceed beyond the complete-signup page, while setting it to false will restrict
     * the user to the complete-signup page until it has been completed.
     *
     * @param state The state to set
     */
    // setSetupComplete(state) {
    //   var user = this.get('user');
    //
    //   if (!Ember.isNone(user)) {
    //     user.set('otherInfo.initialSetup.complete', state);
    //     return user.save();
    //   }
    //
    //   return Ember.RSVP.resolve();
    // },

    completeSignup: function completeSignup(completionData) {
      var that = this;

      return this.get('backend').post({
        url: '/completeSignup',
        data: JSON.stringify({
          data: completionData
        })
      }).then(function () {
        return that.reload();
      });
    },


    /**
     * Determine whether the user's profile has completed the mandatory profile setup
     *
     * @returns {boolean} True if the user has complete the signup and can progress, false if not
     */
    isSetupComplete: function isSetupComplete() {
      return this.get('user.otherInfo.initialSetup.complete') === true;
    },
    isLicenseAccepted: function isLicenseAccepted() {
      return this.get('user.otherInfo.betaAcknowledgement.complete') === true;
    },
    acceptLicense: function acceptLicense() {

      var that = this;

      return this.get('backend').post({
        url: '/acceptLicense'
      }).then(function () {
        return that.get('user').reload();
      });
    },


    /**
     * Determine if the data in the service is valid (current active session
     * with valid user record)
     *
     * @returns {boolean} True if current user represents active session with valid data, false otherwise
     */
    isValid: function isValid() {
      return !Ember.isNone(this.get('user'));
    },


    /**
     * Retrieve the current user profile details
     *
     * @returns {*} User model
     */
    getProfile: function getProfile() {
      return this.get('user');
    },


    /**
     * Retrieve the current user's ID
     *
     * @returns {string} the user's ID
     */
    getUserId: function getUserId() {
      return this.getProfile().get('userId');
    },
    getPrimaryPhone: function getPrimaryPhone() {
      var phones = this.get('phones');
      var currentPhone = null;

      if (!Ember.isEmpty(phones)) {
        currentPhone = phones.objectAt(0);
      }

      return currentPhone;
    },
    getCurrentEmployment: function getCurrentEmployment() {
      var currentEmploymentId = this.get('user.otherInfo.profile.currentEmploymentId');

      if (!currentEmploymentId) {
        return null;
      }

      return this.get('store').findRecord('employment', currentEmploymentId);
    },
    getPrimaryEmail: function getPrimaryEmail() {
      var emails = this.get('emails');
      var primaryEmail = null;

      if (!Ember.isEmpty(emails)) {
        primaryEmail = emails.objectAt(0);
      }

      return primaryEmail;
    },


    /**
     * Add employment details to this user's work history
     *
     * @param employment the employment details to add
     * @returns {Promise}
     */
    addEmployment: function addEmployment(employment) {
      var that = this;

      employment.set('userId', this.getUserId());

      return employment.save().then(function () {
        return that.get('employments').update().then(function (employments) {
          return employment;
        });
      });
    },
    updateEmployment: function updateEmployment(employment) {
      var that = this;

      return employment.save().then(function () {
        return that.get('employments').update().then(function () {
          return employment;
        });
      });
    },


    /**
     * Remove the specified employment from the user's work history
     *
     * @param employment the employment instance to remove
     * @returns {*}
     */
    removeEmployment: function removeEmployment(employment) {
      var that = this;

      return employment.destroyRecord().then(function () {
        that.get('employments').update();
      });
    },


    /**
     * Set the specified employment as the user's primary employment
     *
     * @param employment details to make primary
     */
    setCurrentEmployment: function setCurrentEmployment(employment) {
      this.set('user.otherInfo.profile.currentEmploymentId', employment.get('id'));
      //return this.get('user').save();
      // this.get('user').set('otherInfo.currentEmployment', {
      //   employmentId: employment.get('id'),
      //   organizationId: employment.get('organization.id'),
      //   organizationName: employment.get('organization.name'),
      //   position: employment.get('position')
      // });
    },


    /**
     * Use the provided file to upload it to the backend for the user's profile
     *
     * @param file selected file from the user from ember-file-upload
     * @param userId [optional] user for for which to upload the avatar, otherwise to the current user
     */
    uploadAvatar: function uploadAvatar(file, userId) {

      return this.get('resourceService').uploadUserAvatar(file, userId || this.get('user.id'));
    },
    getRoles: function getRoles() {
      return this.get('roles');
    },
    getUserAccessStructures: function getUserAccessStructures() {
      return this.get('accessStructures');
    },
    hasAccess: function hasAccess(organizationId, structureName) {
      var structures = null;

      if (this.get('isAquornAdmin')) {
        return true;
      }

      this.get('accessStructures').forEach(function (object) {
        if (object.get('objectAquornId').get('id') === organizationId) {
          structures = object.get('structureMap');
        }
      });

      if (structures && structures[structureName] !== undefined) {
        return true;
      } else {
        return false;
      }
    },
    getMyOrganization: function getMyOrganization() {
      var roles = this.get('roles');
      var organization = null;

      if (Ember.isEmpty(roles)) {
        return null;
      }

      // console.log('roles', roles);

      return roles.objectAt(0).get('organization');
    },


    /**
     * Get the role the current user has on the specified organization
     *
     * @param organization the specified organization
     * @returns {*} the organization role, if present, otherwise null
     */
    getOrganizationRole: function getOrganizationRole(organization) {
      var organizationRole = null;

      // Check the user's current roles for the organization id
      this.get('roles').forEach(function (role) {
        if (role.get('organization.id') === organization.get('id')) {
          organizationRole = role;
        }
      });

      return organizationRole;
    },


    /**
    * Sets is aquorn admin is current user
    *
    *@param value boolean value
    *@returns void
    */
    setIsAquornAdmin: function setIsAquornAdmin(value) {
      this.set('isAquornAdmin', value);
    }
  });
});