define('pretty-nuts/routes/onboarding/index', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      console.log('setting onboarding data structure');
      var controller = this.controllerFor("onboarding");
      controller.resetObligation();
    }
  });
});