define('pretty-nuts/routes/governments', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/paginated-result', 'pretty-nuts/accumulated-result'], function (exports, _aquornAuthenticatedRouteMixin, _paginatedResult, _accumulatedResult) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    organizationService: Ember.inject.service(),
    query: null,
    sortBy: '',

    model: function model() {
      return Ember.RSVP.hash({
        governments: this.store.query('organization', {
          filter: this.get('query'),
          type: 'MUNICIPALITY',
          searchType: 'WILD_CARD',
          sortBy: this.get('sortBy'),
          isAsc: !this.get('desc'),
          pageSize: 50,
          pageNo: 1
        }),
        infiniteGovernments: this.store.query('organization', {
          filter: this.get('query'),
          type: 'MUNICIPALITY',
          searchType: 'WILD_CARD',
          sortBy: this.get('sortBy'),
          isAsc: !this.get('desc'),
          pageSize: 50,
          pageNo: 1
        })
      }).then(function (model) {
        var paginatedGovernments = _paginatedResult.default.create();
        paginatedGovernments.initData(model.governments);

        var accumulatedGovernments = _accumulatedResult.default.create();
        accumulatedGovernments.initData(model.infiniteGovernments);
        return {
          paginatedGovernments: paginatedGovernments,
          accumulatedGovernments: accumulatedGovernments
        };
      });
    },
    setupController: function setupController(controller, model) {
      this.set('query', '');
      this._super(controller, model);
      controller.set('entered', true);
    },

    actions: {
      actionReloadFilter: function actionReloadFilter(query, sortBy, desc) {
        this.set('query', query);
        this.set('sortBy', sortBy);
        this.set('desc', desc);
        this.refresh();
      }
    }
  });
});