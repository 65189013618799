define('pretty-nuts/models/domain-security-cashflow', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    accrualPeriods: _emberData.default.attr(),
    interestPeriods: _emberData.default.attr(),
    interestPayments: _emberData.default.attr(),
    principalPayments: _emberData.default.attr(),
    orderedPrincipalPayments: _emberData.default.attr(),
    principalSchedule: _emberData.default.attr(),
    refundPrincipalMap: _emberData.default.attr(),
    refundedPrincipalSchedule: _emberData.default.attr(),
    outstandingPrincipal: _emberData.default.attr(),
    principalAmounts: _emberData.default.attr(),
    rateResets: _emberData.default.attr(),
    forecastResets: _emberData.default.attr()

  });
});