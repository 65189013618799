define('pretty-nuts/models/obligation-refund-determination', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    aquornId: _emberData.default.attr(),
    obligationAquornId: _emberData.default.attr(),
    outstandingPrincipal: _emberData.default.attr(),
    maturityRangeBegin: _emberData.default.attr(),
    maturityRangeEnd: _emberData.default.attr(),
    totalRefundPrincipal: _emberData.default.attr(),
    securityRefundDeterminations: _emberData.default.attr('embedded-models', { modelName: 'security-refund-determination' })

  });
});