define("pretty-nuts/components/calendar-widget", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function convertType(txt) {
    return txt.toLowerCase().split('').map(function (c) {
      return 'abcdefghijklmnopqrstuvwxyz'.indexOf(c) + 1;
    }).reduce(function (a, b) {
      return a + b;
    });
  }

  exports.default = Ember.Component.extend({
    routing: Ember.inject.service("-routing"),
    eventService: Ember.inject.service(),
    title: '',
    weekdays: [],
    header: {
      left: 'prev,next today title',
      right: 'month,listYear'
    },
    listHeader: {
      left: '',
      center: 'title',
      right: ''
    },
    needs: ['application'],
    categories: ['Debt Service', 'Other'],
    advanceUnits: ['Days', 'Weeks', 'Months', 'Years'],
    widgetEvent: Ember.computed('viewEvent', function () {
      console.log("in widgetEvent observed", this.get('viewEvent'));
      return this.get('viewEvent');
    }),

    events: Ember.computed('modelEvents', function () {
      var events = [];
      var color = void 0;
      var data = this.get('modelEvents');
      var allday = void 0;
      var title = void 0;
      if (data.get('content').length > 0) {
        data.forEach(function (event) {
          title = event.get('title');
          if (title == 'Debt service events view') {
            title = 'Debt Service for ' + event.get('eventsView').obligations.length + ' Obligations Due';
          }
          // console.log(event)
          if (event) {
            if (event.get('isAllDay') === 'No') {
              allday = false;
            } else {
              allday = true;
            }
            if (event.get('metaEventType')) {
              color = event.get('metaEventType');
            } else {
              color = 'color';
            }
            events.push({
              color: ('#' + Math.floor(convertType(color)) * 1777).toString(16),
              colorbg: ('#' + Math.floor(convertType(color)) * 1777 + '33').toString(16),
              title: title,
              allDay: allday,
              start: moment(event.get('startDateTime')).format(),
              end: moment(event.get('endDateTime')).format(),
              category: event.get('metaEventType'),
              description: event.get('description'),
              masterEventId: event.get('masterEventId'),
              eventsView: event.get('eventsView'),
              id: event.get('eventId')
            });
          }
        });
      }
      return events;
    }),

    dayClick: function dayClick(date, jsEvent, view) {
      console.log('dayclick jsEvent', jsEvent);
      $('#event').foundation('open');
      $(".fc-state-highlight").removeClass("fc-state-highlight");
      $("td[data-date=" + date.format('YYYY-MM-DD') + "]").addClass("fc-state-highlight");
    },

    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        $('.obligations-table').floatThead({ top: 66 });
      });
      $('#calendar').fullCalendar('option', 'height', 'auto');
      this.set('entered', false);
    }.observes('entered'),

    didRender: function didRender() {
      $('#event_' + this.get('masterEventId')).click();
    },


    actions: {
      updateMonthly: function updateMonthly(value) {
        var _this = this;

        this.set('startDate', value);
        var eventService = this.get('eventService');

        eventService.getDateContext(value).then(function (response) {
          _this.set('monthlyDays', eventService.getMonthlyDays(value, response.get('dayOfWeek'), response.get('businessDayNumber')));
        });
      },
      refreshCurrentRoute: function refreshCurrentRoute() {
        var currentRouteName = this.get("routing.currentRouteName");
        var currentRouteInstance = Ember.getOwner(this).lookup("route:" + currentRouteName);
        currentRouteInstance.refresh();
      },
      reloadModel: function reloadModel() {
        this.send('reloadModel');
      },
      showAddEvent: function showAddEvent() {
        $('#newEvent').foundation('open');
      },
      recurrenceClicked: function recurrenceClicked() {},
      setAdvanceUnit: function setAdvanceUnit(val) {
        this.set('advanceUnit', val);
      },
      setEventCategory: function setEventCategory(val) {
        this.set('category', val);
      },
      closePanel: function closePanel() {
        $('.simple-panel').hide();
        $('#detail-panel').removeClass('is-active');
        if ($('#calendar').hasClass('is-active')) {
          $('#calendar').removeClass('is-active');
        }
      },
      viewRender: function viewRender(view, element) {
        console.log('VIEW RENDER', view, element);
        this.set('selectedStartDate', view.intervalStart.format("YYYY-MM-DD"));
        this.set('selectedEndDate', view.intervalStart.add('years', 1).format("YYYY-MM-DD"));
      },
      eventRender: function eventRender(event, element, view) {
        element.attr('id', 'event_' + event.masterEventId);
      },
      clicked: function clicked(event, jsEvent, view) {
        var eventService = this.get('eventService');
        var xpos = void 0,
            ypos = void 0;
        if (jsEvent.clientY <= $(window).height() - 300) {
          ypos = jsEvent.clientY + 20;
        } else {
          ypos = jsEvent.clientY - 200;
        }
        if (jsEvent.clientX <= $(window).width() - 400) {
          xpos = jsEvent.clientX + 20;
        } else {
          xpos = jsEvent.clientX - 420;
        }
        // console.log(xpos,ypos)
        $('.simple-panel').show().offset({ top: ypos, left: xpos });
        $('#event').foundation('open');

        $(".fc-state-highlight").removeClass("fc-state-highlight");
        $("td[data-date=" + event.start.format('YYYY-MM-DD') + "]").addClass("fc-state-highlight");

        var viewEvent = event;
        viewEvent.start = event.start.format('MMMM Do YYYY');
        //z viewEvent.time = event.start.format('h:mm');
        this.set('viewEvent', viewEvent);
        // this.set('master',eventService.getEventMaster(Number(event.master.get('id'))));
      },
      createEvent: function createEvent() {
        var _this2 = this;

        var eventService = this.get('eventService');
        var that = this;
        //   createCustomEvent(title, description, startDateTime, endDateTime, objectId, location)
        /*
          event-service.createEventMasterDraft
          event-service.createCustomEvent
           period{
            by: '', //{'BusinessDays','Days','Weeks','Months','Years'}
            byFrequency: 1, //{Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday}
            daysOfWeek: [],
            daysOfMonth: [],
            businessDaysOfMonth: [],
            daysOfWeekMonths: [], //{dayOfWeek, weekNo}
          }
        */
        var periodUnit = {};
        if (this.get('recurringEvent')) {
          periodUnit = {
            by: this.get('frequencyUnit'),
            byFrequency: this.get('frequencyNumber'),
            daysOfWeek: this.get('weekdays'),
            daysOfMonth: [],
            businessDaysOfMonth: [],
            daysOfWeekMonths: []
          };
        }
        if (!this.get('endDate')) {
          this.set('endDate', this.get('startDate'));
        }
        eventService.createCustomEvent(this.get('title'), this.get('description'), moment(this.get('startDate')).utc().format('YYYY-MM-DD' + 'T' + 'HH:mm:ss'), moment(this.get('endDate')).utc().format('YYYY-MM-DD' + 'T' + 'HH:mm:ss'), this.get('obligationId'), null, periodUnit).then(function (model) {
          $('#newEvent').foundation('close');
          _this2.send('refreshCurrentRoute');
          $('#calendar').fullCalendar('refetchEvents');
          console.log('REFETCH in createEvent');
        });
      },
      deleteEvent: function deleteEvent(eventId) {
        var _this3 = this;

        console.log('eventId to delete:', eventId);
        var that = this;
        var eventService = this.get('eventService');
        eventService.deleteCustomEvent(this.get('eventId')).then(function (model) {
          $('#event').foundation('close');
          _this3.send('refreshCurrentRoute');
          $('#calendar').fullCalendar('refetchEvents');
        });
      },
      deleteEventMaster: function deleteEventMaster(masterEventId) {
        var _this4 = this;

        console.log('masterEventId to delete:', this.get('masterEventId'));
        var that = this;
        var eventService = this.get('eventService');
        eventService.deleteEventMaster(masterEventId).then(function (model) {
          $('#event').foundation('close');
          _this4.send('refreshCurrentRoute');
          $('#calendar').fullCalendar('refetchEvents');
        });
      },
      closeEvent: function closeEvent() {
        $('#event').foundation('close');
        $('#newEvent').foundation('close');
      },
      weekView: function weekView() {
        $('#calendar').fullCalendar('changeView', 'basicWeek');
        $('#calendar').fullCalendar('gotoDate', date.getFullYear(), date.getMonth(), date.getDate());
      },
      monthView: function monthView() {
        $('#calendar').fullCalendar('changeView', 'month');
        $('#calendar').fullCalendar('gotoDate', date.getFullYear(), date.getMonth(), date.getDate());
      },
      dayView: function dayView() {
        $('#calendar').fullCalendar('changeView', 'basicDay');
        $('#calendar').fullCalendar('gotoDate', date.getFullYear(), date.getMonth(), date.getDate());
      }
    }
  });
});