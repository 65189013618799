define('pretty-nuts/controllers/aquorn-admin/api-docs', ['exports', 'swagger-ui'], function (exports, _swaggerUi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SwaggerUIBundle = _swaggerUi.default.SwaggerUIBundle,
      SwaggerUIStandalonePreset = _swaggerUi.default.SwaggerUIStandalonePreset;
  exports.default = Ember.Controller.extend({
    swaggerConfig: {
      spec: {},
      deepLinking: false,
      presets: [SwaggerUIStandalonePreset, SwaggerUIBundle.presets.apis, SwaggerUIBundle.plugins.DownloadUrl],
      layout: "StandaloneLayout",
      docExpansion: 'none',
      tagsSorter: 'alpha',
      operationsSorter: 'alpha',
      defaultModelsExpandDepth: -1,
      defaultModelExpandDepth: 1
    },

    addMoreConfig: function () {
      var apiDocs = this.get('model.apiDocsObject');
      this.set('swaggerConfig', {
        spec: apiDocs,
        deepLinking: false,
        presets: [SwaggerUIStandalonePreset, SwaggerUIBundle.presets.apis, SwaggerUIBundle.plugins.DownloadUrl],
        layout: "StandaloneLayout",
        docExpansion: 'none',
        tagsSorter: 'alpha',
        operationsSorter: 'alpha',
        defaultModelsExpandDepth: -1,
        defaultModelExpandDepth: 1
      });
    }.observes('model.apiDocsObject').on('init')
  });
});