define('pretty-nuts/serializers/domain-security-refunding', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      refundedSecurity: 'refundedSecurityId'
    }
  });
});