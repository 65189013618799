define('pretty-nuts/controllers/orgsettings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var listShow = false;

  $('#employer1').on('focus', function () {
    $('#list').show();
  }).on('blur', function () {
    $('#list').hide();
  });

  exports.default = Ember.Controller.extend((_Ember$Controller$ext = {
    queryParams: ['currentOrganizationId'],
    organizationService: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    application: Ember.inject.controller(),
    modalService: Ember.inject.service(),
    orgsapi: Ember.inject.service(),
    obligationService: Ember.inject.service(),
    isAquornAdmin: Ember.computed.reads('application.isAquornAdmin'),

    leToAdd: null,
    legalUnitToDelete: null,
    userToAdd: null,
    selectedUser: null,
    newRole: null,
    selectedUserRoles: [],
    selectedUserRoleRequests: [],
    newRoleRequests: []
  }, _defineProperty(_Ember$Controller$ext, 'newRole', null), _defineProperty(_Ember$Controller$ext, 'pendingRoles', []), _defineProperty(_Ember$Controller$ext, 'outstanding', []), _defineProperty(_Ember$Controller$ext, 'pending', []), _defineProperty(_Ember$Controller$ext, 'retired', []), _defineProperty(_Ember$Controller$ext, 'buttons', {
    'employment': true
  }), _defineProperty(_Ember$Controller$ext, 'permissions', [{ role: 'Guest', actionPermissions: { 'viewUserProfile': true, 'viewServiceProviders': true, 'editUserProfile': true }, type: 'option' }, { role: 'Member', type: 'option' }, { role: 'Submitter', type: 'option' }, { role: 'Approver', type: 'option' }, { role: 'Admin', type: 'option' }, { role: 'Aquorn Admin', type: 'option' }, { role: 'Owner', type: 'option' }]), _defineProperty(_Ember$Controller$ext, 'availableRoles', function () {
    var permissions = this.get('permissions').map(function (permissions) {
      return permissions.role;
    });
    var isAquornAdmin = this.get("application.currentUser.isAquornAdmin");
    return permissions.filter(function (role) {
      return isAquornAdmin || role != 'Aquorn Admin';
    });
  }.property('selectedUserRoles.@each', 'selectedUserRoleRequests.@each')), _defineProperty(_Ember$Controller$ext, 'LEStatuses', ['Approved', 'Declined']), _defineProperty(_Ember$Controller$ext, 'selectedPermission', ''), _defineProperty(_Ember$Controller$ext, 'selectedLEStatus', ''), _defineProperty(_Ember$Controller$ext, 'newPhone', {
    countryCode: '+1',
    number: '(000)000-0000',
    type: 'Work'
  }), _defineProperty(_Ember$Controller$ext, 'newEmail', {
    source: 'ABC Inc.',
    address: 'sample@aquorn.com',
    isPrimary: false,
    isVerified: false
  }), _defineProperty(_Ember$Controller$ext, 'profile', null), _defineProperty(_Ember$Controller$ext, 'reloadObligationOverviews', false), _defineProperty(_Ember$Controller$ext, 'orgId', Ember.computed('application.currentOrganization', function () {
    console.log("-ds-Updating currentOrganization id");
    var orgId = this.get('application.currentOrganization.id');
    this.profileSwitcher(orgId);
    return orgId;
  })), _defineProperty(_Ember$Controller$ext, 'legalEntitySorter', function () {
    var outstanding = [];
    var pending = [];
    var retired = [];
    var documents = [];
    var total = 0;
    var obligations = this.get('model.obligations');
    if (obligations) {
      obligations.forEach(function (obligation) {
        if (obligation.get('documents')) {
          // I have no idea where I would even look for these... in the org?
          console.log('documents', obligation.this.get('documents'));
        }
        switch (obligation.get('status')) {
          case 2:
            {
              outstanding.push(obligation);
              console.log('parIssued', obligation.get('parIssued'));
              if (obligation.get('parIssued')) {
                total = new BigNumber(obligation.get('parIssued').replace(/,/g, '')).plus(total);
              }
              break;
            }

          case 0:
            {
              pending.push(obligation);
              break;
            }

          default:
            {
              retired.push(obligation);
            }
        }
      });
    }
    this.set('outstanding', outstanding);
    this.set('pending', pending);
    this.set('retired', retired);
    this.set('total', total);
  }.observes('model.obligations').on('init')), _defineProperty(_Ember$Controller$ext, 'orgLEs', []), _defineProperty(_Ember$Controller$ext, 'profileSwitcher', function profileSwitcher(orgId) {
    var _this = this;

    var that = this;
    var orgsapi = this.get('orgsapi');

    orgsapi.getOrganizationProfile(orgId, function (err, data) {
      if (!err) {
        that.set('profile', data);

        _this.getChildLEs();
      } else {
        console.log("GOT ERROR");
        that.get('notifications').error(data);
      }
    });
  }), _defineProperty(_Ember$Controller$ext, 'getChildLEs', function getChildLEs() {
    var that = this;
    this.set('orgLEs', this.get('model.legalUnits'));
  }), _defineProperty(_Ember$Controller$ext, 'reloadObligationOverviewsObserver', function () {
    if (this.get('reloadObligationOverviews')) {
      var that = this;
      var organizationId = this.get('currentOrganizationId');
      this.get('store').unloadAll('obligation-overview');
      return this.get('store').query('obligation-overview', { organizationId: organizationId }).then(function (obligationOverviews) {
        that.set('model.obligationOverviews', obligationOverviews);
        that.set('reloadObligationOverviews', false);
      });
    }
  }.observes('reloadObligationOverviews')), _defineProperty(_Ember$Controller$ext, 'isAdmin', Ember.computed('currentOrganizationId', 'isAquornAdmin', function () {
    var isCompanyAdmin = this.get('application.currentUser').hasAccess(this.get('currentOrganizationId'), 'springapp/organizations/roles/approveRequest');
    var isAquornAdmin = this.get('isAquornAdmin');
    return isCompanyAdmin || isAquornAdmin;
  })), _defineProperty(_Ember$Controller$ext, 'actions', {
    openOrgForAquornAdmin: function openOrgForAquornAdmin() {
      this.send('reloadModel');
    },
    actionLoadOrg: function actionLoadOrg() {
      this.set('currentOrganizationId', this.get('currentOrganization.id'));
    },
    approve: function approve(data) {
      data.roles.set('status', 'Approved');
      data.roles.set('approver', this.get("application.currentUser.user"));
      data.roles.save();
    },
    reject: function reject(data) {
      console.log(data);
      data.roles.set('status', 'Denied');
    },
    addUserToOrg: function addUserToOrg(user) {
      var that = this;
      var organization = this.get('model.organization');
      this.get('organizationService').inviteUserOrganizationRole(user, organization, 'Member').then(function (c) {
        that.send('reloadModel');
      });
    },
    addChildLEToOrg: function addChildLEToOrg(org, le) {
      var that = this;
      this.get('organizationService').addLegalUnitToOrganization(org, le).then(function (c) {
        that.get('model.legalUnits').update();
      });
    },
    actionCreateUser: function actionCreateUser(entityType, user, searchTerm) {
      var organization = this.get('model.organization');

      var _searchTerm$split = searchTerm.split(" "),
          _searchTerm$split2 = _slicedToArray(_searchTerm$split, 2),
          firstName = _searchTerm$split2[0],
          lastName = _searchTerm$split2[1];

      var newUser = this.get('store').createRecord('new-user', { firstName: firstName, lastName: lastName });
      var that = this;
      this.get('application').showUserCreation(newUser, organization, function (createdUser) {
        that.set('userToAdd', null);
        that.send('reloadModel');
        console.log('callback:', createdUser);
      }, function (error) {
        console.log('error:', error);
      });
    },
    actionCreateLegalUnit: function actionCreateLegalUnit(entityType, legalUnit) {
      var organization = this.get('model.organization');
      var that = this;

      legalUnit.set('organization', organization);

      this.get('application').showLECreation(legalUnit, function (createdLE) {
        console.log('callback:', createdLE);
      }, function (error) {
        console.log('error:', error);
      });
    },
    showDeleteLegalUnit: function showDeleteLegalUnit(legalUnit) {
      var that = this;
      var args = {
        prompt: 'are you sure you want to delete?',
        confirmWord: 'Yes',
        cancelWord: 'No',
        callbacks: {
          success: function success() {
            legalUnit.destroyRecord();
          }
        }
      };
      this.get('modalService').showModal('confirm', args);
    },
    deleteLegalUnit: function deleteLegalUnit() {
      var _this2 = this;

      var legalUnit = this.get('legalUnitToDelete');
      var that = this;
      legalUnit.set('organization', null);
      legalUnit.save().then(function () {
        _this2.send('reloadModel');
        that.get('notifications').success('legal unit removed', { autoClear: true });
      }).catch(function (error) {
        that.get('notifications').error("failed: " + error);
      });
      this.send('cancelDeleteLegalUnit');
    },
    setLEStatus: function setLEStatus(term) {
      this.set('selectedLEStatus', term);
    },
    setPermission: function setPermission(role, user) {
      user.set(user, role);
      console.log('setPermission', user);
    },
    logout: function logout() {
      this.get('controllers.application').send('logout');
    },
    edit_text: function edit_text(f) {
      $(f + ' .text-content').addClass('editable').attr('contenteditable', 'true');
      $(f + ' .pencil').addClass('hide');
      $(f + ' .x').removeClass('hide');
    },
    stop_edit: function stop_edit(f) {
      $(f + ' .text-content').removeClass('editable').attr('contenteditable', 'false');
      $(f + ' .pencil').removeClass('hide');
      $(f + ' .x').addClass('hide');
    },
    browse_file: function browse_file() {
      document.getElementById('file').click();
      return false;
    },
    list: function list() {
      listShow = !listShow;
      if (listShow) $('#list').show();else $('#list').hide();
    },
    provider_directory: function provider_directory() {
      listShow = !listShow;
    },
    sortColumn: function sortColumn(prop) {
      this.set('desc', !this.get('desc'));
      this.set('activeColumn', prop);
      var model = this.get('model').sort(ArrayComparer.property(prop, this.get('desc')));
      this.set('model', model.slice());
    },
    goToItem: function goToItem(item) {
      alert('single click is details sidebar, double click to drill in to ' + item + ' page');
    },
    secondaryActions: function secondaryActions(item) {
      alert('view, share, view details, copy, delete to ' + item + ' item');
    },
    edit: function edit(item) {
      $('#' + item + ' .display').hide();
      $('#' + item + ' .edit').show();
      $('#' + item + ' .times').hide();
      this.set('buttons.' + item, false);
    },
    remove: function remove(item) {
      $('#' + item).hide();
    },
    display: function display(item) {
      $('#' + item + ' .display').show();
      $('#' + item + ' .edit').hide();
      $('#' + item + ' .times').show();
      this.set('buttons.' + item, true);
    },
    manageRoles: function manageRoles(user, roles) {
      $('#edit_user_roles').foundation('open');
      this.set('selectedUser', user);
      this.set('selectedUserRoles', roles);
      var roleRequests = this.get('model.roleRequestsByUser').find(function (rr) {
        return rr.user && rr.user.get('id') == user.get('id');
      });
      if (roleRequests) {
        this.set('selectedUserRoleRequests', roleRequests.roleRequests);
      }
    },
    updateUserRoles: function updateUserRoles(userRole, role) {
      var that = this;
      var user = userRole.user;
      var organization = this.get('model.organization');
      var organizationService = this.get('organizationService');
      if (userRole.roleRequests.length > 0) {
        userRole.roleRequests.forEach(function (roleRequest) {
          organizationService.rejectRoleRequest(roleRequest);
        });
      }
      if (userRole.roles.length > 0) {
        userRole.roles.forEach(function (role) {
          organizationService.removeUserOrganizationRole(role);
        });
      }
      var roleRequest = organizationService.requestUserOrganizationRole(user, organization, role).then(function (roleRequest) {
        that.send('reloadModel');
      });
    },
    deleteRole: function deleteRole(role) {
      var that = this;
      var args = {
        prompt: 'are you sure you want to remove this employee?',
        confirmWord: 'Yes',
        cancelWord: 'No',
        callbacks: {
          success: function success() {
            that.get('organizationService').removeUserOrganizationRole(role).then(function () {
              that.send('reloadModel');
            });
          }
        }
      };
      this.get('modalService').showModal('confirm', args);
    },
    approveRoleRequest: function approveRoleRequest(roleRequest) {
      var that = this;
      var organizationService = this.get('organizationService');
      organizationService.approveRoleRequest(roleRequest).then(function (roleRequest) {
        that.send('reloadModel');
      });
    },
    deleteRoleRequest: function deleteRoleRequest(roleRequest) {
      var that = this;
      var args = {
        prompt: 'are you sure you want to remove this employee?',
        confirmWord: 'Yes',
        cancelWord: 'No',
        callbacks: {
          success: function success() {
            that.get('organizationService').rejectRoleRequest(roleRequest).then(function () {
              that.send('reloadModel');
            });
          }
        }
      };
      this.get('modalService').showModal('confirm', args);
    }
  }), _Ember$Controller$ext));
});