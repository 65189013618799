define('pretty-nuts/routes/index', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/constants'], function (exports, _aquornAuthenticatedRouteMixin, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    eventService: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    organizationService: Ember.inject.service(),

    model: function model() {
      var currentUser = this.get('currentUser');
      var organization = currentUser.getMyOrganization();
      var organizationService = this.get('organizationService');
      var eventService = this.get('eventService');

      var legalUnits = [];

      if (organization) {
        legalUnits = organizationService.getOrganizationLegalUnits(organization.get('id'));
      }

      return Ember.RSVP.hash({
        user: currentUser.user,
        organization: Ember.RSVP.resolve(organization),
        legalUnits: legalUnits
        // events: eventService.getEvents(moment().format('YYYY-MM-DD'),moment().add(1,'years').format('YYYY-MM-DD'),organization.get('id'),'',''),
      }).then(function (model) {
        model.legalEntityIds = model.legalUnits.map(function (legalUnit) {
          return legalUnit.get('id');
        });
        var today = new Date();

        var startDay = today.getDate();
        var startMonth = today.getMonth() + 1;
        var startYear = today.getFullYear();

        var endDate = new Date(startYear, startMonth + 19, 0);
        var endMonth = endDate.getMonth() + 1;

        if (startMonth < 10) {
          startMonth = '0' + startMonth;
        }
        if (endMonth < 10) {
          endMonth = '0' + endMonth;
        }

        model.query = {
          resolution: _constants.Constants.Frequency.MONTHLY,
          fromDate: startYear + '-' + startMonth + '-01',
          toDate: endDate.getFullYear() + '-' + endMonth + '-' + endDate.getDate()
        };
        return model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    },

    actions: {
      didTransition: function didTransition() {
        Ember.run.scheduleOnce('afterRender', this, function () {
          this.controller.get('chartRebuild');
        });
      }
    }
  });
});