define('pretty-nuts/controllers/issuer/documents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    issuer: Ember.inject.controller()

  });
});