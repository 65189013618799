define('pretty-nuts/models/domain-call-option', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('obligation'),
    callDate: _emberData.default.attr(),
    price: _emberData.default.attr(),
    type: _emberData.default.attr('enum', { type: _constants.Constants.CallOptionType }),
    availability: _emberData.default.attr('enum', { type: _constants.Constants.CallOptionAvailability })
  });
});