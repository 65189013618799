define('pretty-nuts/components/noclick-currency-mask', ['exports', 'ember-inputmask/components/one-way-currency-mask'], function (exports, _oneWayCurrencyMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _oneWayCurrencyMask.default.extend({
    classNameBindings: ['classes'],
    click: function click() {
      return false;
    }
  });
});