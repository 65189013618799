define('pretty-nuts/models/integrated-user-profile', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    user: _emberData.default.attr(),
    email: _emberData.default.attr(),
    phone: _emberData.default.attr(),
    employments: _emberData.default.attr(),

    fullName: Ember.computed('user.firstName', 'user.lastName', function () {
      return this.get('user.firstName') + ' ' + this.get('user.lastName');
    }),

    name: Ember.computed('fullName', function () {
      return this.get('fullName');
    }),

    primaryEmployment: Ember.computed('employments', function () {
      var primaryEmploymentId = this.get('user.otherInfo.profile.currentEmploymentId');
      return this.get('employments.' + primaryEmploymentId);
    }),

    profileImageUrlObserver: function () {
      if (this.get('user.profileImageResourceAquornId')) {
        this.set('profileImageUrl', _constants.Constants.RESOURCE_LOCATION + '/' + this.get('user.profileImageResourceAquornId'));
      }

      // let profileResource = this.get('user.profileImageResourceId');
      // const store = this.get('store');
      // let that = this;
      //
      // if (profileResource) {
      //   store.findRecord('resource', profileResource).then((resource) => {
      //     let url = (resource ? '/' + resource.get('location') : null);
      //     that.set('profileImageUrl', url);
      //     that.set('imageUrl', url);
      //   });
      // }

    }.observes('user.profileImageResourceId').on('init')

  });
});