define('pretty-nuts/controllers/trends/state-historical', ['exports', 'pretty-nuts/db', 'accounting'], function (exports, _db, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var controller = void 0;

  exports.default = Ember.Controller.extend({
    // ---------
    // Default Settings
    // ---------

    init: function init() {
      controller = this;
    },


    pie: 'use',
    donutView: 'by Use',
    maxBarThickness: 2,
    betweenGroupPadding: 0,
    withinGroupPadding: 0,
    maxLabelHeight: 40,
    stackBars: true,

    graph_salemethod: {
      x: 'x',
      columns: [['x', '2015-08-01', '2015-09-01', '2015-10-01', '2015-11-01', '2015-12-01', '2016-01-01', '2016-02-01', '2016-03-01', '2016-04-01', '2016-05-01', '2016-06-01', '2016-07-01', '2028-01-01', '2029-01-01', '2030-01-01', '2031-01-01', '2032-01-01', '2033-01-01', '2034-01-01', '2035-01-01', '2036-01-01', '2037-01-01', '2038-01-01', '2039-01-01', '2040-01-01', '2041-01-01'], ['Competitive Amount', 188.22, 57.09, 166.28, 1572.55, 319.81, 77.13, 175.81, 211.67, 550.9, 1869.54, 1064.49, 48.5], ['Negotiated Amount', 5439.54, 5619.41, 3861.46, 3744.35, 4565.77, 2532.6, 3696.91, 2210.94, 9600.02, 5390.47, 3310.13, 741.19], ['Competitive Issues', 5, 4, 6, 36, 4, 6, 6, 3, 8, 39, 15, 1], ['Negotiated Issues', 286, 273, 216, 219, 207, 202, 187, 175, 273, 194, 165, 79]],
      types: {
        'Competitive Issues': 'spline',
        'Negotiated Issues': 'spline',
        'Competitive Amount': 'bar',
        'Negotiated Amount': 'bar'
      },

      groups: [['Competitive Amount', 'Negotiated Amount']],
      axes: {
        'Competitive Amount': 'y2',
        'Negotiated Amount': 'y2',
        'Competitive Issues': 'y',
        'Negotiated Issues': 'y,'
      }

    },

    graph_historical: Ember.computed('model', function () {
      var data = _db.default.getTable('state-overview-7'); //this.get('model.consolidated');
      var date = [];
      var obligations = [];
      var proceeds = [];
      date.push('x');
      obligations.push('Obligations');
      proceeds.push('Proceeds');
      for (var i = 0; i < data.length; i++) {
        date.push(data[i].Year + '-' + data[i].Month + '-01');
        obligations.push(data[i].NumberofObligations);
        proceeds.push(_accounting.default.unformat(data[i].Proceeds));
      }
      var thing = {
        x: 'x',
        columns: [date, obligations, proceeds],
        types: {
          Obligations: 'spline',
          Proceeds: 'bar'
        },
        axes: {
          Proceeds: 'y2'
        }
      };
      console.log(thing);
      return thing;
    }),

    tooltip: {
      format: {
        title: function title(d) {
          return 'Data ' + d;
        },
        value: function value(_value, ratio, id) {
          var format = id === 'Competitive Issues' || id === 'Negotiated Issues' ? d3.format(',') : d3.format('$');
          return format(_value);
        }
        //            value: d3.format(',') // apply this format to both y and y2
      }
    },
    tooltip_historical: {
      format: {
        title: function title(d) {
          return 'Data ' + d;
        },
        value: function value(_value2, ratio, id) {
          var format = id === 'Obligations' ? d3.format(',') : d3.format('$');
          return format(_value2);
        }
        //            value: d3.format(',') // apply this format to both y and y2
      }
    },
    color1: {
      pattern: ['rgb(249, 106, 27)', 'rgb(63, 122, 175)', 'rgb(55, 106, 153)', 'rgb(82, 140, 193)', 'rgb(95, 148, 199)', 'rgb(109, 157, 205)', 'rgb(122, 165, 211)', 'rgb(136, 174, 216)', 'rgb(150, 183, 221)', 'rgb(164, 192, 227)']
    },
    color2: {
      pattern: ['rgb(63, 122, 175)', 'rgb(230,230,230)', 'rgb(55, 106, 153)', 'rgb(180,180,180)', 'rgb(82, 140, 193)', 'rgb(95, 148, 199)', 'rgb(109, 157, 205)', 'rgb(122, 165, 211)', 'rgb(136, 174, 216)', 'rgb(150, 183, 221)', 'rgb(164, 192, 227)']
    },
    color: {
      pattern: ['rgb(249, 106, 27)', 'rgb(251, 166, 118)', 'rgb(8, 161, 217)', 'rgb(90, 206, 249)', 'rgb(124, 152, 74)', 'rgb(178, 199, 140)', 'rgb(194, 173, 141)', 'rgb(218, 206, 187)', 'rgb(121, 123, 126)', 'rgb(175, 176, 178)', 'rgb(80, 110, 148)', 'rgb(145, 176, 196)', 'rgb(91, 115, 120)', 'rgb(205, 215, 217)', 'rgb(166, 166, 166)', 'rgb(217, 217, 217)']
    },
    axis: {
      y2: {
        show: true,
        label: {
          text: 'Proceeds in $Millions',
          position: 'outer-middle'
        },
        tick: {
          format: d3.format("$,")
          //                format: function (d) { return "$" + d; }
        }

      },
      x: {
        type: 'timeseries',
        tick: {
          format: '%Y'
        }
      },
      y: {
        max: 350,
        min: 31,
        label: {
          text: 'Number of Obligations',
          position: 'outer-middle'
        }
      }
    },
    labels: {
      //            format: function (v, id, i, j) { return "Default Format"; },
      format: {
        Airport: d3.format('$')
        //                data1: function (v, id, i, j) { return "Format for data1"; },
      }
    },

    salesmethod_axis: {
      y2: {
        max: 10000,
        show: true,
        label: {
          text: 'Amount in $Millions',
          position: 'outer-middle'
        },
        tick: {
          format: d3.format("$,")
          //                format: function (d) { return "$" + d; }
        }
      },
      x: {
        type: 'timeseries',
        tick: {
          format: '%Y'
        }
      },
      y: {
        max: 300,
        label: {
          text: 'Number of Issues',
          position: 'outer-middle'
        }
      }
    },
    salesmethod_labels: {
      format: {
        'Competitive Amount': d3.format('$,')
      }
    },
    pie1: {
      // iris data from R
      columns: [['Airports', 16.28], ['General Purpose', 24.73], ['Government Buildings', 14.93], ['Mass Transit', 12.18], ['Fire Stations', 9.44], ['Parking Facilities', 4.81], ['Parks, Zoos, Beaches', 7.1], ['Solid Waste', 4.73], ['Water & Sewer Facilities', 5.81]],
      type: 'donut',
      onclick: function onclick(d, i) {
        console.log(JSON.stringify(controller.get('pie')));
        JSON.stringify(controller.set('pie', 'use'));
        JSON.stringify(controller.set('donutView', 'by Use'));
      }
    },
    legend: {
      hide: true
    },
    pieSize: {
      width: 250,
      height: 250
    },
    actions: {
      foo: function foo() {
        console.log(this);
      }
    }
  });
});