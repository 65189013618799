define('pretty-nuts/controllers/aquorn-admin/cache', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    operationService: Ember.inject.service(),
    backend: Ember.inject.service(),

    obligation: null,

    actions: {
      actionClearCache: function actionClearCache() {
        var _this = this;

        this.set('responseValue', 'Running...');

        this.get('backend').post({
          url: '/perspectiveCache/clear'
        }).then(function (response) {
          //console.log('response', response);
          _this.set('responseValue', response.data);
        });
      },
      actionReloadCache: function actionReloadCache() {
        var operationService = this.get('operationService');
        var controller = this;

        operationService.operationRequest('POST', '/perspectiveCache/reload', {}).perform(function () {
          controller.set('responseValue', 'OK');
        }, function (message) {
          controller.set('responseValue', message);
        });
      }
    }
  });
});