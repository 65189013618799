define('pretty-nuts/serializers/provider-view', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'providerId',

    attrs: {
      providerOrgResource: 'providerOrgResourceId',
      providerUserResource: 'providerUserResourceId'
    }
  });
});