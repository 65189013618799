define('pretty-nuts/components/debt-service-chart', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    /**
     * Binding for access to constants values in template
     */
    Constants: _constants.Constants,
    exportData: null,

    chartColors: {
      'PrincipalAndInterest': {
        'pattern': ['#D3D3D3', // Dark Grey
        '#FFAA59', // Orange,
        '#F3D8BF' // Faded orange

        ]
      },
      'FederalTaxStatus': {
        'pattern': ['#64A6E1', // Blue
        '#C7D8FB' // Blue Fade
        ]
      },
      'SecurityPledge': {
        'pattern': ['#EF5A5D', // Red
        '#FBCACA' // Red Fade
        ]
      },
      'StructureType': {
        'pattern': ['#9473C8', // Purple
        '#DBD0EB' //  Purple Fade
        ]
      },
      'SaleType': {
        'pattern': ['#114411', // Green
        '#B6EDB6']
      },
      'Callable': {
        'pattern': ['#2AAA2A', // Green
        '#8BE28B' // Green Fade
        ]
      },
      'default': {
        'pattern': ['#ffae00', '#1e333e', '#ec5840', '#f47c0a', '#3adb76', '#955648', '#0F6E9B']
      }
    },

    pieChartDisplayNames: {
      PrincipalAndInterest: 'Principal and Interest',
      SecurityPledge: 'Security Pledge',
      FederalTaxStatus: 'Tax Status',
      Callable: 'Call Status',
      SaleType: 'Coupon Type',
      Obligation: 'Obligation',
      LegalEntity: 'Legal Entity'
    },

    debtServiceChartGroups: [['Principal', 'Interest']],

    selectedAttribute: 'PrincipalAndInterest',

    // aggregateDebtService: {},

    buildPieCharts: true,

    //////////////
    // Services //

    obligationService: Ember.inject.service(),

    // Services //
    //////////////

    /////////////////////////////
    // Configuration and State //

    resolution: _constants.Constants.Frequency.ANNUAL,

    showChart: true,

    currentView: 'PrincipalAndInterest',

    resolutionButtonClass: Ember.computed('resolution', function () {
      var resolution = this.get('resolution');
      if (!resolution) {
        return {};
      }
      if (resolution.display === 'Actual') {
        return { actual: 'selected' };
      } else if (resolution.display === 'Semi-Annual') {
        return { semiAnnual: 'selected' };
      } else {
        return { annual: 'selected' };
      }
    }),

    // Configuration and State //
    /////////////////////////////

    // maxPieCharts: [0, 1, 2, 3, 4],

    showBarGraph: function showBarGraph(name, table, resolution) {
      var columns = [];
      var groups = [];

      var rows = Ember.get(table, 'columns');

      // for (let i = 0; i < rows.length - 1; i++) {
      for (var i = 0; i < rows.length; i++) {
        var row = rows[i];

        if (Ember.get(row, 'attributes.chart') === false) {
          console.log('skipping', row.get('name'));
          continue;
        }

        console.log('adding column', row.get('name'));

        var values = row.get('values').map(function (v) {
          return v.value;
        });

        // columns.push([row.get('name')].concat(row.get('values')));
        columns.push([row.get('name')].concat(values));

        if (i > 0) {
          groups.push(row.get('name'));
        }
      }

      this.sortColumns(columns);

      if (resolution === _constants.Constants.Frequency.MONTHLY) {
        columns = this.fillBlankDates(columns);
      }

      var g = {
        x: 'Date',
        columns: columns,
        type: 'bar',
        groups: [groups],
        order: null
      };

      var c3Build = {
        bindto: '#c3_bar',
        data: g,
        color: this.get('chartColors.' + name) || this.get('chartColors.default'),
        tooltip: {
          format: {
            title: function title(d) {
              if (resolution === _constants.Constants.Frequency.ANNUAL) {
                return moment(d).format('YYYY');
              } else {
                return moment(d).format('MMMM, YYYY');
              }
            },
            value: function value(_value) {
              return d3.format("$,.0f")(_value);
            }
          },
          contents: function contents(d, defaultTitleFormat, defaultValueFormat, color) {
            d = d.filter(function (item) {
              return item.value > 0;
            });
            if (d.length > 0) {
              var content = this.getTooltipContent(d, defaultTitleFormat, defaultValueFormat, color);
              var total = d.reduce(function (total, row) {
                return total += row.value;
              }, 0);
              total = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
              var totalRow = '<tr class=\'c3-tooltip-name--total\'><td class=\'name\'></span>Total</td><td class=\'value\'>$' + total + '</td></tr></table>';
              return content.replace('</table>', totalRow);
            }
          }
        },
        size: {
          height: this.get('graphHeight')
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%b %Y'
            }
          },
          y: {
            label: {
              text: '$ in Thousands',
              position: 'outer-middle'
            },
            tick: {
              format: function format(d) {
                return d3.format("$,.0f")(Math.round(d / 1000));
              },
              values: this.yAxisValues
            }
          }
        }
      };

      console.log(c3Build);

      c3.generate(c3Build);
    },
    displayPieChart: function displayPieChart(position_index, name, breakdown) {
      var columns = [];

      Object.keys(breakdown).forEach(function (key) {
        columns.push([key, breakdown[key]]);
      });

      this.sortColumns(columns);

      var pieData = {
        columns: columns,
        type: 'donut'
      };

      var pieChart = {
        size: {
          height: 140,
          width: 140
        },
        bindto: '#c3_pie' + position_index,
        color: this.get('chartColors.' + name) || this.get('chartColors.default'),
        data: pieData,
        legend: {
          show: false
        },
        donut: {
          label: {
            show: false
          },
          donut: {
            width: 70
          }
        }
      };

      c3.generate(pieChart);
    },


    columnOrder: {
      'Date': 0,
      'Principal': 1,
      'Interest': 2,
      'Forecasted Interest': 3
    },

    sortColumns: function sortColumns(columns) {
      var _this = this;

      columns.sort(function (a, b) {
        var av = _this.get('columnOrder')[a[0]];
        var bv = _this.get('columnOrder')[b[0]];

        return av - bv;

        // return b[0] === 'Principal' || a[0] > b[0] ? 1 : -1;
      });
    },
    fillBlankDates: function fillBlankDates(columns) {
      var i = 1;
      var currentDate = new Date(this.get('beginDate'));
      var shouldBeNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
      var nextMonth = new Date(columns[0][i]);
      var lastDate = new Date(this.get('endDate'));

      while (!!nextMonth && (shouldBeNextMonth.getFullYear() != lastDate.getFullYear() || shouldBeNextMonth.getMonth() != lastDate.getMonth())) {
        if ((shouldBeNextMonth.getFullYear() != nextMonth.getFullYear() || shouldBeNextMonth.getMonth() != nextMonth.getMonth()) && !!nextMonth) {
          columns[0].splice(i, 0, shouldBeNextMonth.getFullYear() + '-' + (shouldBeNextMonth.getMonth() + 1) + '-' + shouldBeNextMonth.getDate());
          columns[1].splice(i, 0, 0);
          columns[2].splice(i, 0, 0);
        }

        i += 1;
        currentDate = new Date(columns[0][i - 1]);
        shouldBeNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
        nextMonth = columns[0][i] ? new Date(columns[0][i]) : null;
      }

      return columns;
    },
    yAxisValues: function yAxisValues(range) {
      var max = range[1];
      var orderOfMagnitude = 1;
      var values = [];
      var interval = void 0;

      while (Math.floor(max / 10) > 0) {
        max = Math.floor(max / 10);
        orderOfMagnitude *= 10;
      }

      if (max >= 4) {
        interval = orderOfMagnitude;
      } else if (max < 4 && max > 1) {
        interval = orderOfMagnitude / 2;
      } else if (max <= 1) {
        interval = orderOfMagnitude / 4;
      }

      max = (max + 1) * orderOfMagnitude;

      for (var i = 0; i < max; i += interval) {
        values.push(i);
      }

      return values;
    },


    generate: function () {
      var table = this.get('table');
      console.log('generate table', table);

      // let chart_name = 'principal_interest';
      var chart_name = this.get('currentView');
      var resolution = this.get('resolution');
      // const selectedAttribute = this.get('selectedAttribute');

      var tables = table.get('tables');
      console.log('chart_name', chart_name);
      console.log('table', table);
      console.log('ds tables', tables);
      var chart = Ember.get(tables, chart_name);

      this.showBarGraph(chart_name, chart, resolution);

      var pieCharts = this.get('pieCharts');

      for (var i = 0; i < pieCharts.length; i++) {
        var name = pieCharts[i];
        // console.log('name', name);
        console.log(i, name);
        var breakdown = Ember.get(tables, name + '.breakdown');
        this.displayPieChart(i, name, breakdown);
      }

      // this.set('clickCharts', click_charts);
    }.observes('table', 'currentView'),

    selectGraphResolutionTab: function selectGraphResolutionTab(resolution) {
      if (resolution) {
        this.set('resolution', resolution);

        $('.selected').removeClass('selected');
        $('#graph_' + resolution.display).addClass('selected');

        this.get('selectResolution')(resolution);
      }
    },


    actions: {
      revert: function revert(item) {
        this.send('display', item);
      },
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      },
      exportCsv: function exportCsv() {
        var exportData = [];

        $("#filters thead tr.headers").each(function () {
          var arrayOfThisRow = [];
          var tableData = $(this).find('th');
          if (tableData.length > 0) {
            tableData.each(function () {
              arrayOfThisRow.push($(this).text());
            });
            exportData.push(arrayOfThisRow);
          }
        });
        $("#filters tbody tr").each(function () {
          var arrayOfThisRow = [];
          var tableData = $(this).find('td');
          if (tableData.length > 0) {
            tableData.each(function () {
              arrayOfThisRow.push($(this).text());
            });
            exportData.push(arrayOfThisRow);
          }
        });
        console.log('FILTER ARRAY', exportData);

        this.set('exportData', exportData);
        this.get('csv').export(this.get('exportData'), { fileName: 'exportData.csv' });
      },
      actionSelectResolution: function actionSelectResolution(resolution) {
        // this.selectGraphResolutionTab(resolution);
        // this.get('setQueryAttribute')('resolution', resolution);
        this.get('setResolution')(resolution);
      },
      actionPieClick: function actionPieClick(position) {
        // const click_charts = this.get('clickCharts');
        var pieCharts = this.get('pieCharts');
        var pieClickAction = this.get('pieClickAction');

        pieClickAction(pieCharts[position]);
      }
    }
  });
});