define('pretty-nuts/routes/government', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    obligationService: Ember.inject.service(),
    organizationService: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    queryParams: {
      perspectiveDate: {}
    },

    model: function model(params) {
      var _this = this;

      var obligationService = this.get('obligationService');
      var organizationService = this.get('organizationService');
      var currentEmployment = this.get('currentUser').getCurrentEmployment();
      var permissions = this.get('currentUser').getRoles();
      var organizationId = params.organizationId;
      var user = this.get('currentUser').getProfile();

      return Ember.RSVP.hash({
        organization: organizationService.getOrganization(organizationId),
        addresses: organizationService.getOrganizationAddresses(organizationId),
        legalUnits: organizationService.getOrganizationLegalUnits(organizationId)
      }).then(function (model) {
        model.legalEntityIds = model.legalUnits.map(function (lu) {
          return lu.get('id');
        });
        model.legalEntityAquornIds = model.legalUnits.map(function (lu) {
          return lu.get('id');
        });

        return Ember.RSVP.hash({
          debtProfile: obligationService.getOrganizationDebtProfile(model.organization.get('id'))
        }).then(function (model2) {
          console.log('route integratedObligations', model2.integratedObligations);
          model.debtProfile = model2.debtProfile;
          _this.set('organizationName', model.organization.get('name'));
          model.perspectiveDate = params.perspectiveDate;
          model.organizationId = organizationId;
          model.permissions = permissions;
          model.user = user;

          model.headquarters = model.addresses.find(function (address) {
            return address.get('hq');
          });
          var obligationsByLegalUnit = {};

          console.log(obligationsByLegalUnit);
          model.obligationsByLegalUnit = obligationsByLegalUnit;
          console.log('obligationOverviews', model.obligationOverviews);

          return model;
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    },


    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});