define("pretty-nuts/transforms/json-blob", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized, options) {
      var deserialized = {};

      // Check that the returned blob is valid
      /*
      if (serialized == "e30=" || Ember.isEmpty(serialized)) {
        serialized = null;
      }
      */

      if (!Ember.isNone(serialized)) {
        // There was a valid json-blob received, deserialize it
        deserialized = JSON.parse(atob(serialized));
      } else {
        // There was no blob or it was invalid, set to default json structure
        if (!Ember.isNone(options)) {
          var t = _typeof(options.defaultValue);

          if (t === "function") {
            deserialized = options.defaultValue();
          } else if (t === "undefined") {
            deserialized = null;
          } else {
            deserialized = options.defaultValue;
          }
        }
      }

      //console.log('json-blob deserialize, serialized:', serialized);
      //console.log('json-blob deserialize, deserialized:', deserialized);

      return deserialized;
    },
    serialize: function serialize(deserialized, options) {
      var j = deserialized;

      //console.log('serializing', deserialized);

      // If already a serialized string (address bug where a recursive transform occurs)
      if (typeof deserialized === 'string') {
        return deserialized;
      }

      // If no blob specified in record, set to null for transmission to server
      if (Ember.isNone(j)) {
        j = null;
      }

      var serialized = btoa(JSON.stringify(j));

      //console.log('serialized', serialized);

      return serialized;
    }
  });
});