define('pretty-nuts/models/call-structure', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('domain-obligation'),
    type: _emberData.default.attr('enum', { type: _constants.Constants.CallOptionType }),
    firstStepDate: _emberData.default.attr(),
    lastStepDate: _emberData.default.attr(),
    firstCallPrice: _emberData.default.attr(),
    basisPointsStep: _emberData.default.attr(),
    frequency: _emberData.default.attr('enum', { type: _constants.Constants.Frequency }),
    availability: _emberData.default.attr('enum', { type: _constants.Constants.CallOptionAvailability })
  });
});