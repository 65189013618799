define("pretty-nuts/helpers/changeset-sorted-each-in", ["exports", "pretty-nuts/helpers/object-sort"], function (exports, _objectSort) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.changesetSortedEachIn = changesetSortedEachIn;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function changesetSortedEachIn(_ref) {
    var _ref2 = _slicedToArray(_ref, 3),
        changeset = _ref2[0],
        attribute = _ref2[1],
        desc = _ref2[2];

    // console.log("changeset", changeset);

    if (!changeset) {
      return [];
    }

    var unwrapped = changeset._eachIn();

    var keys = Object.keys(unwrapped);

    // keys.sort();

    var sorted_content = [];

    keys.forEach(function (key) {
      sorted_content.push(unwrapped[key]);
    });

    // return params;
    return (0, _objectSort.objectSort)([sorted_content, attribute, desc]);
  }

  exports.default = Ember.Helper.helper(changesetSortedEachIn);
});