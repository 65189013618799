define('pretty-nuts/controllers/obligation/security', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isVariableRate: Ember.computed('model.obligation.obligation.structureType', function () {
      console.log('IS VARIABLE', this.get('model.obligation.obligation.structureType'));
      console.log('CONSTANT', _constants.Constants.ObligationStructure.VARIABLE_RATE);
      return _constants.Constants.ObligationStructure.VARIABLE_RATE === this.get('model.obligation.obligation.structureType');
    }),
    zoom: {
      enabled: true,
      onzoom: function onzoom(domain) {
        console.log(this, domain);
      }
    },
    graph: function () {
      var years = ['x'];
      var principal = ['Principal'];
      var interest = ['Interest'];
      var payments = this.get('model.cashflow').get('debtServicePayments');
      // let debtService = this.get('model.debtService').get('payments');
      // for (var i=0; i<debtService.length; i++){
      //   // years.push(debtService[i].paymentDate);
      // }
      for (var j = 0; j < payments.length; j++) {
        years.push(payments[j].paymentDate);
        principal.push(payments[j].principalAmount);
        interest.push(payments[j].interestAmount);
      }

      var g = {
        x: 'x',
        columns: [years, principal, interest],
        type: 'bar',
        groups: [['Principal', 'Interest']]
        // onclick(a) {
        //   // alert('Item ' + a.index + ' of value ' + a.value);
        // }
      };
      var c3Build = {
        bindto: '#c3cashflow',
        data: g,
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%Y'
            }
          },
          y: {
            label: {
              text: '$ in Thousands',
              position: 'outer-middle'
            },
            tick: {
              format: function format(d) {
                return '$' + d / 1000;
              }
            }
          }
        },
        color: {
          pattern: ['#E46C0A', // Aquorn Orange
          '#7e7e7e', // Grey
          '#F9AB6B', // Orange Fade
          '#d3d3d3', // Grey Fade
          '#50AB85', // Ocean Green
          '#3fc33f', // Green Fade
          '']
        }
      };
      this.set('c3Build', c3Build);
      c3.generate(c3Build);
      console.log('c3Data is ', g);
      console.log('c3Build is', c3Build);
    }.observes('model'),

    runEveryTime: function () {
      var $table = $('.obligations-table');
      Ember.run.schedule('afterRender', this, function () {
        $table.floatThead({ top: 66 });
        $table.floatThead('reflow');
        c3.generate(this.get('c3Build'));
      });
      this.set('entered', false);
    }.observes('entered'),

    actions: {
      toggle_variable_rates: function toggle_variable_rates(e, status) {
        if (status == 'Paid') {
          $('.fore').removeClass('cast');
        } else {
          $('.fore').addClass('cast');
        }
        $('tr.active-row').removeClass('active-row');
        $('#' + e).addClass('active-row');
        console.log('parent =', $('#variable_rates_table').parent().attr('id'));
        if ($('#variable_rates_table').parent().attr('id') === 'hidden_table') {
          console.log('check hidden passed');
          $('#variable_rates_table').insertAfter($('#' + e));
          // $('#variable_rates_table').hasClass('active-row') ? console.log('has?') : $('#variable_rates_table').addClass('active-row');
        } else {
          console.log('check hidden failed');
          if ($('#variable_rates_table').prev().attr('id') == e) {
            console.log('check eq passed');
            $('#' + e).removeClass('active-row');
            $('#variable_rates_table').appendTo($('#hidden_table'));
          } else {
            console.log('check eq failed', e);
            $('#variable_rates_table').insertAfter($('#' + e));
          }
        }
        // $('#variable_rates_table').toggleClass('hide');
      }
    }
  });
});