define('pretty-nuts/controllers/onboarding/index', ['exports', 'pretty-nuts/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // needs: 'onboarding',
    // onboarding: Ember.computed.alias('controllers.onboarding'),
    onboarding: Ember.inject.controller(),
    submissionType: '',
    //application: Ember.inject.controller(),

    luList: [],
    luSearch: '',
    luSelected: 0,
    userList: [],

    actions: {
      showMenus: function showMenus(v) {
        $('#next').show();
        if (v === 'preliminary') {
          this.get('onboarding').set('isPreliminary', true);
          this.get('onboarding').set('isFinal', false);
        } else {
          this.get('onboarding').set('isPreliminary', false);
        }
        if (v === 'final') {
          this.get('onboarding').set('isFinal', true);
          this.get('onboarding').set('isPreliminary', false);
        } else {
          this.get('onboarding').set('isFinal', false);
        }
      },
      searchLU: function searchLU() {
        var _this = this;

        console.log("Searching lu: " + this.get("luSearch"));
        console.log("data:" + data);
        _utils.Backend.doSearchAll(this.get('luSearch'), 'LegalUnits', function (data) {
          _this.set('luList', data.legalUnits);
        });
      },

      selectLU: function selectLU(selected) {
        console.log("SELECTLU: " + selected);
        this.set('luSelected', selected.id);
        this.get('onboarding').set('obligation.obligationOwner.legalUnitId', selected.id);
        //console.log(this.get('onboarding').get('obligation'));
      },
      actionSaveAndTransition: function actionSaveAndTransition(obligation) {
        this.get('onboarding.saveObligationAndTransitionTask').perform('index', obligation);
      }
    }
  });
});