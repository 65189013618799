define('pretty-nuts/routes/myprofile', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    currentUser: Ember.inject.service(),
    organizationService: Ember.inject.service(),

    model: function model() {
      var currentUser = this.get('currentUser');
      var organizationService = this.get('organizationService');

      return currentUser.reload().then(function () {
        return Ember.RSVP.hash({
          user: currentUser.user,
          emails: currentUser.emails,
          employments: currentUser.employments,
          phones: currentUser.phones,
          roleRequests: organizationService.getMyRolesRequests(currentUser.get('user.id'), 'Pending')
        }).then(function (model) {
          for (var i = 0; i < model.roleRequests.toArray().length; i++) {
            model.employments.forEach(function (e) {
              if (e.get('organization.id') == model.roleRequests.toArray()[i].get('objectId.id')) {
                e.set('pendingRequest', true);
              }
            });
          }
          return model;
        });
      });
    },

    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});