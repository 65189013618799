define('pretty-nuts/mixins/aquorn-embed-model', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    rollbackAttributes: function rollbackAttributes() {
      var model = this;

      this.eachAttribute(function (name, meta) {
        //console.log('attribute', name, meta);
        if ('embedded-model' === meta['type']) {
          model.get(name).rollbackAttributes();
        }
      });
      this._super.apply(this, arguments);
    }
  });
});