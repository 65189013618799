define('pretty-nuts/models/domain-security-refund-determination', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    identifier: _emberData.default.attr(),
    coupon: _emberData.default.attr(),
    maturityDate: _emberData.default.attr(),
    securityType: _emberData.default.attr('enum', { type: _constants.Constants.SecurityType }),
    callDate: _emberData.default.attr(),
    callPrice: _emberData.default.attr(),
    outstandingPrincipal: _emberData.default.attr(),
    refundPrincipal: _emberData.default.attr(),
    remainingPrincipal: _emberData.default.attr(),

    paymentDeterminations: _emberData.default.attr()
  });
});