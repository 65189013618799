define('pretty-nuts/controllers/providers/all-service-providers', ['exports', 'pretty-nuts/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    desc: false,
    activeColumn: '',
    previewModel: {},

    runEveryTime: function () {
      var count = 0;
      Ember.run.schedule('afterRender', this, function () {
        $('.menu.vertical a').removeClass('active');
        $('#providersNav a').addClass('active');
        $('.obligations-table').floatThead({ top: 66 });
        var controller = this;
        console.log('every time');
      });
      this.set('entered', false);
    }.observes('entered'),

    query: function () {
      console.log('observed a change in query or activeColumn or desc');
      var filterByCurrentUserOrganization = this.get('providersFilter') == 'My Service Providers';
      this.send('actionReloadFilter', this.get('application.query'), this.get('activeColumn'), this.get('desc'));
    }.observes('application.query', 'activeColumn', 'desc', 'providersFilter'),

    actions: {
      clickRow: function clickRow(providerId) {
        this.transitionToRoute('provider', providerId);
      },
      actionMore: function actionMore() {
        this.get('model.accumulatedProviders').more();
      },
      sortColumn: function sortColumn(prop) {
        console.log('sorting by service providers by ', prop);
        this.set('desc', !this.get('desc'));
        this.set('activeColumn', prop);
      }
    }
  });
});