define('pretty-nuts/services/document-service', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    store: Ember.inject.service(),
    backend: Ember.inject.service(),
    operationService: Ember.inject.service(),

    getDocumentDownloadLink: function getDocumentDownloadLink(documentId) {
      return this.get('backend').post({
        url: '/documents/' + documentId + '/download'
      });
    },
    query: function query(params) {
      return this.get('store').query('document', params);
    },
    getObligationDocuments: function getObligationDocuments(obligationId) {
      return this.query({
        obligationId: obligationId
      });
    },
    getOrganizationDocuments: function getOrganizationDocuments(organizationId) {
      return this.query({
        organizationId: organizationId
      });
    },
    getLegalUnitDocuments: function getLegalUnitDocuments(legalUnitId) {
      return this.query({
        legalUnitId: legalUnitId
      });
    },
    getDocumentCategoryGroups: function getDocumentCategoryGroups() {
      return this.get('store').query('document-category', {}).then(function (groups) {
        var result = [];
        groups.forEach(function (group) {
          return result.push(group.get('group'));
        });
        return result;
      });
    },
    getDocument: function getDocument(documentId) {
      return this.get('store').findRecord('document', documentId);
    },
    getDocumentView: function getDocumentView(documentId) {
      return this.get('store').findRecord('document-view', documentId);
    },
    getDocumentCategoriesByGroup: function getDocumentCategoriesByGroup(groupName) {
      return this.get('store').query('document-category', {}).then(function (groups) {
        var category = undefined;
        groups.forEach(function (group) {
          if (group.get('group') === groupName) {
            category = group.get('category');
          }
        });

        return category;
      });
    },
    getDocumentGroupByCategory: function getDocumentGroupByCategory(categoryId) {
      return this.get('store').query('document-category', {}).then(function (groups) {
        var documentGroup = undefined;
        groups.forEach(function (group) {
          if (group.get('category.' + categoryId)) {
            console.log(group);
            documentGroup = group;
          }
        });

        return documentGroup;
      });
    },
    uploadDocument: function uploadDocument(file, params, completion_method) {
      var operationService = this.get('operationService');

      // console.log('upload params', params);
      // console.log('upload name', file.name, file['name'], filfile.get('name'));

      var document = this.get('store').createRecord('document', {
        key: file.get('key'),
        // documentType: params.type,
        documentName: file.get('name'),
        // legalEntity: params.legalEntity,
        // obligation: params.obligation,
        // organization: params.organization,
        // documentCategory: params.documentCategory,
        categoryId: params.categoryId,
        documentType: params.documentType,
        documentDate: params.documentDate,
        preparerOrg: params.preparerOrgId,
        preparerUser: params.preparerUserId,
        description: params.description
      });

      if (params.obligation) {
        document.set('obligation', params.obligation);
      } else if (params.legalEntity) {
        document.set('legalEntity', params.legalEntity);
      } else if (params.organization) {
        document.set('organization', params.organization);
      }

      return this.get('backend').request({
        type: 'POST',
        url: '/documents',
        data: JSON.stringify({
          data: document.serialize()
        })
      }).then(function (response) {
        try {
          return operationService.handleDeferredOperationResponse2(response, _constants.Constants.OperationStatusResolver.UPLOAD_DOCUMENT, completion_method);
        } catch (e) {
          console.error(e);
        }
      });
    },
    deleteDocument: function deleteDocument(document, completion_method) {
      var operationService = this.get('operationService');

      return this.get('backend').request({
        type: 'DELETE',
        url: '/documents/' + document.id
      }).then(function (response) {
        try {
          return operationService.handleDeferredOperationResponse2(response, _constants.Constants.OperationStatusResolver.DELETE_DOCUMENT, completion_method);
        } catch (e) {
          console.error(e);
        }
      });
    }
  });
});