define('pretty-nuts/controllers/aquorn-admin/obligations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    operationService: Ember.inject.service(),

    obligationId: null,
    node: null,
    message: null,

    actions: {
      actionCalculateFromNode: function actionCalculateFromNode(node) {},
      actionCalculateNode: function actionCalculateNode(node) {
        var controller = this;
        var obligationId = this.get('obligationId');

        this.set('message', 'Working...');

        this.get('operationService').operationRequest('POST', '/calculations', {
          data: {
            obligationId: obligationId,
            nodes: [node],
            requestType: 'CALCULATE'
          }
        }).perform(function () {
          controller.set('message', 'OK');
        }, function (operation) {
          controller.set('message', operation.get('resultMessage'));
        });
      }
    }
  });
});