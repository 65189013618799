define('pretty-nuts/models/resource', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    userId: _emberData.default.attr(),
    uploadTime: _emberData.default.attr(),
    resourceType: _emberData.default.attr(),
    location: _emberData.default.attr(),
    resourceStatus: _emberData.default.attr(),
    resourceFileType: _emberData.default.attr(),
    fileSize: _emberData.default.attr(),

    legalEntityId: _emberData.default.attr(),
    organizationId: _emberData.default.attr()
  });
});