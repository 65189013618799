define('pretty-nuts/controllers/documents', ['exports', 'pretty-nuts/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),

    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        $('.menu.vertical a').removeClass('active');
        $('#documentsNav a').addClass('active');
      });
      this.set('entered', false);
    }.observes('entered'),

    actions: {}
  });
});