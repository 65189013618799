define('pretty-nuts/serializers/refund', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    attrs: {
      refundingSecurity: 'refundingSecurityId',
      callOption: 'callOptionId'
      // refundedSecurity: 'refundedSecurityAquornId'
    }
  });
});