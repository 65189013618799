define('pretty-nuts/helpers/head-title', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.headTitle = headTitle;
  function headTitle(title) {
    Ember.$('head').find('title').text(title);
  }

  exports.default = Ember.Helper.helper(headTitle);
});