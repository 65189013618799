define('pretty-nuts/models/event-draft', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    eventId: _emberData.default.attr(),
    masterEventId: _emberData.default.belongsTo('event-master'),
    metaEventId: _emberData.default.attr(),
    organizationId: _emberData.default.belongsTo('organization'),
    userId: _emberData.default.belongsTo('user'),
    eventAppliesTo: _emberData.default.attr(),
    objectId: _emberData.default.attr(),
    title: _emberData.default.attr(),
    description: _emberData.default.attr(),
    category: _emberData.default.attr(),
    location: _emberData.default.attr(),
    startDateTime: _emberData.default.attr(),
    endDateTime: _emberData.default.attr(),
    isAllDay: _emberData.default.attr(),
    notifyAfter: _emberData.default.attr()
  });
});