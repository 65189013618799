define('pretty-nuts/routes/issuer/cashflow', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    },
    model: function model() {
      return this.modelFor('issuer');
    }
  });
});