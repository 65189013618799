define('pretty-nuts/models/domain-integrated-obligation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.attr('embedded-model', { modelName: 'domain-obligation' }),
    callOptions: _emberData.default.attr('embedded-models', { modelName: 'domain-call-option' }),
    securities: _emberData.default.attr('embedded-models', { modelName: 'domain-security' }),
    usesOfFunds: _emberData.default.attr('embedded-models', { modelName: 'domain-use-of-funds' }),
    sourcesOfFunds: _emberData.default.attr('embedded-models', { modelName: 'domain-source-of-funds' }),

    obligors: _emberData.default.attr('embedded-models', { modelName: 'domain-obligor' }),

    serviceProviders: _emberData.default.attr('embedded-models', { modelName: 'domain-obligation-service-provider' }),
    serviceProviderMembers: _emberData.default.attr('embedded-models', { modelName: 'domain-service-provider-member' }),

    refundings: _emberData.default.attr('embedded-models', { modelName: 'domain-obligation-refunding' }),
    refunds: _emberData.default.attr('embedded-models', { modelName: 'domain-obligation-refunding' }),

    debtService: _emberData.default.attr()
  });
});