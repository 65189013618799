define('pretty-nuts/serializers/application', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.JSONSerializer.extend({
    //primaryKey: 'userId',

    /**
     * Do not transform the key names for model attributes. Use them as specified.
     *
     * @param attr
     * @param method
     * @returns {*}
     */
    keyForAttribute: function keyForAttribute(attr, method) {
      return attr;
    },

    /**
     * Intercept the serialization to nest the serialized model data into a 'data' sub object
     * in the serialized hash so that other meta-data may come along with the request data, and live
     * outside (parallel to) the model data.
     *
     * @param data The incoming data hash to use to hold the serialized data
     * @param type type of model
     * @param snapshot snapshot of model instance
     */
    serializeIntoHash: function serializeIntoHash(data, type, snapshot) {
      var d = {};
      this._super(d, type, snapshot);

      // Attach serialized model data into 'data' sub-attributes
      data.data = d;
    }
  });
});