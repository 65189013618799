define('pretty-nuts/services/event-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    store: Ember.inject.service(),
    backend: Ember.inject.service(),

    getDateContext: function getDateContext(date) {
      return this.get('store').findRecord('date-context', date);
    },
    getMonthlyDays: function getMonthlyDays(date, day, businessDay) {
      var b = date.split(/\D/);
      var startDate = new Date(b[0], b[1] - 1, b[2], 0, 0, 0);
      var firstDayOfMonth = new Date(b[0], b[1] - 1, 1, 0, 0, 0);
      var firstDayNum = firstDayOfMonth.getDay();
      var todayDayNum = startDate.getDay();

      var firstOccurance = firstDayNum;
      if (todayDayNum > firstDayNum) {
        firstOccurance = todayDayNum - firstDayNum + 1;
      } else if (todayDayNum < firstDayNum) {
        firstOccurance = 8 - firstDayNum + todayDayNum;
      } else {
        firstOccurance = 1;
      }

      var weekNum = (startDate.getDate() - firstOccurance) / 7 + 1;

      var businessDayStr = businessDay > 0 ? this.getStringFromDay(businessDay) + " business day" : "";
      return ['Day ' + startDate.getDate(), this.getStringFromDay(weekNum) + " " + day, businessDayStr];
    },
    getStringFromDay: function getStringFromDay(day) {
      var unitsPlace = day % 10;

      if (unitsPlace == 1) {
        return day + "st";
      } else if (unitsPlace == 2) {
        return day + "nd";
      } else if (unitsPlace == 3) {
        return day + "rd";
      } else {
        return day + "th";
      }
    },
    getEventDrafts: function getEventDrafts(obligationId) {
      return this.get('store').query('event-draft', { 'obligationId': obligationId });
    },
    getEventMasterDrafts: function getEventMasterDrafts(obligationId) {
      return this.get('store').query('event-draft-master', { 'obligationId': obligationId });
    },
    getEvents: function getEvents(startDate, endDate, organizationId, eventMasterId, objectId, legalUnitId) {
      var json = {};

      if (startDate !== undefined) {
        json['startDate'] = startDate;
      }

      if (endDate !== undefined) {
        json['endDate'] = endDate;
      }

      if (organizationId !== undefined) {
        json['organizationId'] = organizationId;
      }

      if (legalUnitId !== undefined) {
        json['legalUnitId'] = legalUnitId;
      }

      if (eventMasterId !== undefined) {
        json['eventMasterId'] = eventMasterId;
      }

      if (objectId !== undefined) {
        json['objectId'] = objectId;
      }

      return this.get('store').query('event', json);
    },
    getEvent: function getEvent(eventId) {
      return this.get('store').findRecord('event', eventId);
    },
    getEventMaster: function getEventMaster(eventMasterId) {
      return this.get('store').findRecord('event-master', eventMasterId);
    },
    getEventMetadata: function getEventMetadata() {
      return this.get('store').query('event-metadata', {});
    },
    getEventMetadataById: function getEventMetadataById(eventMetadataId) {
      return this.get('store').findRecord('event-metadata', eventMetadataId);
    },


    /**
    * Period JSON
    * by {BusinessDays, Days, Weeks, Months, Years}
    * byFrequency - Integer (By default 1)
    * daysOfWeek - List[] {Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday}
    * daysOfMonth - List[] Integer
    * businessDaysOfMonth - List[] Integer
    * daysOfWeekMonths - List[] Integer
    */
    createEventMasterDraft: function createEventMasterDraft(title, description, startDate, endDate, obligationId, location, period) {
      var eventDraftMaster = this.get('store').createRecord('event-draft-master', {
        'title': title,
        'description': description,
        'startDate': startDate,
        'endDate': endDate,
        'objectId': obligationId,
        'location': location,
        'periodUnit': JSON.stringify(period)
      });

      return eventDraftMaster.save().then(function () {
        return eventDraftMaster;
      });
    },
    getCachedEventMasterDrafts: function getCachedEventMasterDrafts() {
      var records = this.get('store').peekAll('event-draft-master');

      var nonDeletedRecords = [];
      records.forEach(function (record) {
        if (!record.get('isDeleted')) {
          nonDeletedRecords.push(record);
        }
      });

      return nonDeletedRecords;
    },
    updateEventMasterDraft: function updateEventMasterDraft(title, description, startDate, endDate, location, eventMasterDraftId) {
      return this.get('store').findRecord('event-draft-master', eventMasterDraftId).then(function (eventDraftMaster) {
        eventDraftMaster.set('title', title);
        eventDraftMaster.set('description', description);
        eventDraftMaster.set('startDate', startDate);
        eventDraftMaster.set('endDate', endDate);
        eventDraftMaster.set('location', location);

        return eventDraftMaster.save().then(function () {
          return eventDraftMaster;
        });
      });
    },
    deleteEventMasterDraft: function deleteEventMasterDraft(eventMasterDraftId, obligationId) {
      var draft = this.get('store').peekRecord('event-draft-master', eventMasterDraftId);
      draft.deleteRecord();
      return this.get('backend').delete({
        'url': '/eventDraftMasters/' + eventMasterDraftId,
        'data': JSON.stringify({
          'data': { 'objectAquornId': obligationId }
        }),
        'dataType': 'json'
      });
    },
    deleteEventMaster: function deleteEventMaster(masterEventId) {
      return this.get('store').findRecord('event-master', masterEventId).then(function (event) {
        event.deleteRecord();
        return event.save().then(function () {
          return;
        });
      });
    },
    createCustomEvent: function createCustomEvent(title, description, startDate, endDate, obligationId, location, periodUnit, notifyAfter) {
      var eventObject = {
        'title': title,
        'description': description,
        'startDateTime': startDate,
        'endDateTime': endDate,
        'objectId': obligationId,
        'location': location,
        'periodUnit': JSON.stringify(periodUnit),
        'notifyAfter': notifyAfter //in days
      };
      console.log('eventObject:', eventObject);
      var event = this.get('store').createRecord('event', eventObject);

      return event.save().then(function () {
        return event;
      });
    },
    updateCustomEvent: function updateCustomEvent(title, description, startDate, endDate, location, eventId) {
      return this.get('store').findRecord('event', eventId).then(function (event) {
        event.set('title', title);
        event.set('description', description);
        event.set('startDate', startDate);
        event.set('endDate', endDate);
        event.set('location', location);

        return event.save().then(function () {
          return event;
        });
      });
    },
    deleteCustomEvent: function deleteCustomEvent(eventId) {
      return this.get('store').findRecord('event', eventId).then(function (event) {
        event.deleteRecord();
        return event.save().then(function () {
          return;
        });
      });
    },
    createEventMetadata: function createEventMetadata(parentId, name, defaultNotifyTime, notificationType, period, periodUnit, canDelete) {
      var eventMetadata = this.get('store').createRecord('event-metadata', {
        'parentId': parentId,
        'type': name,
        'defaultNotifyAfter': defaultNotifyTime,
        'notificationType': notificationType,
        'period': period,
        'periodUnit': periodUnit,
        'canDelete': canDelete
      });

      return eventMetadata.save().then(function () {
        return eventMetadata;
      });
    },
    updateEventMetadata: function updateEventMetadata(parentId, name, defaultNotifyTime, notificationType, period, periodUnit, canDelete, eventMetadataId) {
      return this.get('store').findRecord('event-metadata', eventMetadataId).then(function (eventMetadata) {
        eventMetadata.set('parentId', parentId);
        eventMetadata.set('type', name);
        eventMetadata.set('defaultNotifyAfter', defaultNotifyTime);
        eventMetadata.set('notificationType', notificationType);
        eventMetadata.set('period', period);
        eventMetadata.set('periodUnit', periodUnit);
        eventMetadata.set('canDelete', canDelete);

        return eventMetadata.save().then(function () {
          return eventMetadata;
        });
      });
    },
    deleteEventMetadata: function deleteEventMetadata(eventMetadataId) {
      return this.get('store').findRecord('event-metadata', eventMetadataId).then(function (eventMetadata) {
        eventMetadata.deleteRecord();
        return eventMetadata.save().then(function () {
          return;
        });
      });
    }
  });
});