define('pretty-nuts/serializers/document', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    // primaryKey: 'documentId',
    primaryKey: 'aquornId',

    attrs: {
      resource: 'resourceAquornId',
      obligation: 'obligationAquornId',
      user: 'userId',
      organization: 'organizationAquornId',
      legalEntity: 'legalEntityAquornId',
      preparerOrg: 'preparerOrgAquornId',
      preparerUser: 'preparerUserAquornId'
    }
  });
});