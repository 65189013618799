define('pretty-nuts/controllers/reporting/obligation/index', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function init_filter() {
    return {
      cashflowView: _constants.Constants.CashflowView.OUTSTANDING
    };
  }

  exports.default = Ember.Controller.extend({
    Constants: _constants.Constants,
    government: Ember.inject.controller(),
    application: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    organizationService: Ember.inject.service(),
    currentUser: Ember.inject.service('current-user'),
    issuer: Ember.inject.controller(),
    resolution: _constants.Constants.Frequency.ANNUAL,
    reloadObligationOverviews: false,
    description: "This is where you'll find a description of the thing your mouse is over",

    applicationController: Ember.inject.controller('application'),
    isAquornAdmin: Ember.computed.reads('applicationController.isAquornAdmin'),

    hasAccess: Ember.computed('currentUser', function () {
      var currentUser = this.get('currentUser');
      return currentUser.hasAccess(this.get('model.organization.id'), 'springapp/organizations/roles/approveRequest');
    }),
    displayDescription: Ember.computed('government.permissionsString', function () {
      return this.get('model.organization.description') || this.get('hasAccess');
    }),
    displayContact: Ember.computed('government.permissionsString', function () {
      var hasContactInfo = this.get('phoneNumber') || this.get('email');
      return this.get('model.organization.description') || this.get('hasAccess');
    }),

    query: {
      resolution: _constants.Constants.Frequency.ANNUAL
    },
    filter: init_filter(),

    pieCharts: ['principalAndInterest', 'federalTaxStatus', 'securityPledge', 'callable', 'obligation', 'legalEntity'],
    currentChartView: 'obligation',

    reloadModel: function reloadModel() {
      this.send('reloadModel');
    },

    outstandingObligations: Ember.computed('profile', function () {
      var outstandingObligations = [];
      console.log('PROFILE', this.get('profile'));

      this.get('profile').get('outstandingObligationsSummary').forEach(function (summary) {
        //console.log('summary obligation', summary.get('obligation'));
        outstandingObligations.push(summary.get('obligation'));
      });

      return outstandingObligations;
    }),
    reloadObligationOverviewsObserver: function () {
      if (this.get('reloadObligationOverviews')) {
        var that = this;
        var organizationId = this.get('model.organization.id');
        this.get('store').unloadAll('obligation-overview');
        return this.get('store').query('obligation-overview', { organizationId: organizationId }).then(function (obligationOverviews) {
          that.set('model.obligationOverviews', obligationOverviews);
          that.set('reloadObligationOverviews', false);
        });
      }
    }.observes('reloadObligationOverviews'),
    actions: {
      describe: function describe(item) {
        var descriptions = {
          'default': "This is where you'll find a description of the thing your mouse is over",
          'le': 'This is the description for the Legal Entity Reports.  I have no idea what to write here but I know it should change',
          'obligation': 'Here I have the obligation description. I assume all this will come from the backend eventually.  Right now it is all hardcoded',
          'cashflow': 'description of cashflow thingie',
          '30-year': 'This is how 30 year reports do',
          'period': 'I like to go swimming with bowlegged women and swim between their legs, swim between their legs, swim between their legs'
        };
        this.set('description', descriptions[item]);
      },
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      },
      revert: function revert(item) {
        this.get('model').organization.rollbackAttributes();
        this.send('display', item);
      },
      saveSection: function saveSection(section) {
        var that = this;
        this.get('government.model.organization').save().then(function () {
          that.get('government.model.addresses').forEach(function (address) {
            if (address.hasDirtyAttributes) {
              address.save().then(function () {
                that.reloadModel();
              });
            }
          });
          if (that.get('newAddress')) {
            that.get('newAddress').save().then(function () {
              that.set('newAddress', null);
              that.reloadModel();
            });
          }
          that.send('display', section);
          that.get('notifications').success('organization saved', { autoClear: true });
        }).catch(function (error) {
          that.send('display', section);
          that.get('notifications').error("failed: " + error);
        });
      },
      actionRefreshDebtProfile: function actionRefreshDebtProfile(debtProfile, obligation) {
        // console.log('Deleted pending obligation', obligation);
        debtProfile.reload();
      }
    }
  });
});