define('pretty-nuts/controllers/obligation/service-providers', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    isAquornAdmin: Ember.computed.reads('applicationController.isAquornAdmin'),

    //////////////////////////////////
    // Constants mapping for local use

    OBLIGOR: _constants.Constants.OBLIGOR,
    ISSUER: _constants.Constants.ISSUER,
    FINANCE_TEAM: _constants.Constants.FINANCIAL_ADVISOR,
    UNDERWRITER: _constants.Constants.LEAD_UNDERWRITER,
    OTHER_PARTY: _constants.Constants.OTHER,
    isNull: null,
    serviceProviderArgs: { type: 'SERVICE_PROVIDER' },
    serviceProviderToAdd: null,

    financeTeamTypes: [_constants.Constants.BOND_COUNSEL, _constants.Constants.FINANCIAL_ADVISOR, _constants.Constants.UNDERWRITERS_COUNSEL, _constants.Constants.DISCLOSURE_COUNSEL, _constants.Constants.SPECIAL_TAX_COUNSEL, _constants.Constants.VERIFICATION_AGENT, _constants.Constants.CREDIT_ENHANCER, _constants.Constants.TRUSTEE, _constants.Constants.PAYING_AGENT, _constants.Constants.ESCROW_AGENT, _constants.Constants.AUDITOR, _constants.Constants.GENERAL_COUNCIL, _constants.Constants.FEASIBILITY_CONSULTANT, _constants.Constants.FISCAL_CONSULTANT, _constants.Constants.DISSEMINATION_AGENT],

    underwriterTeamTypes: [_constants.Constants.LEAD_UNDERWRITER, _constants.Constants.UNDERWRITER_MEMBER],

    // Constants mapping for local use
    //////////////////////////////////

    ///////////////////////////
    // Services and Controllers

    onboarding: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    application: Ember.inject.controller(),
    applicationController: Ember.inject.controller('application'),

    // Services and Controllers
    ///////////////////////////

    ///////
    // Data

    issuers: [],
    obligors: [],
    financingTeam: [],
    underwriters: [],
    otherParties: [],

    // Data
    ///////


    /**
     * Financing Team (Parties involved at issuance but not necessarily ongoing)
     1.  Bond Counsel
     2.  Financial Advisor
     3.  Underwriter\'s Counsel
     4.  Disclosure Counsel
     5.  Special Tax Counsel
     6.  Verification Agent
     7.  Credit Enhancer
     8.  Trustee/Paying Agent (entity can be either or both depending on deal type)
     9.  Maybe a few others...
      Underwriter:   Pretty straight forward.
      Other Parties.  Anyone else.
     */
    serviceProvidersObserver: function () {
      var _this = this;

      var serviceProviders = this.get('model.integratedObligation.serviceProviders');
      var issuers = [];
      var financingTeam = [];
      var underwriters = [];
      var obligors = [];
      var otherParties = [];

      if (!Ember.isEmpty(serviceProviders)) {

        // Split provider orgs and members
        Object.keys(serviceProviders).forEach(function (service_provider_id) {
          var serviceProvider = serviceProviders[service_provider_id];

          console.log('serviceProvider', serviceProvider);

          // if (!Ember.isNone(serviceProvider.get('organization')) && Ember.isNone(serviceProvider.get('user').get('content'))) {

          switch (_this.getTypeCategory(serviceProvider.get('type'))) {

            case _constants.Constants.FINANCE_TEAM:
              {
                financingTeam.push(serviceProvider);
                break;
              }

            case _constants.Constants.UNDERWRITER:
              {
                underwriters.push(serviceProvider);
                break;
              }
            // Obligors are now separate entities
            /*
            case Constants.OBLIGOR: {
              obligors.push(serviceProvider);
              break;
            }
            */
            case _constants.Constants.OTHER:
              {
                otherParties.push(serviceProvider);
                break;
              }

            default:
              {
                console.log('unknown type:', serviceProvider.get('type'));
              }
          }
          // } else {
          //   if (serviceProvider.get('type') == Constants.ISSUER) {
          //     issuers.push(serviceProvider);
          //   }
          // }
        });
      }

      this.set('issuers', issuers);
      this.set('financingTeam', financingTeam);
      this.set('underwriters', underwriters);
      this.set('obligors', obligors);
      this.set('otherParties', otherParties);
    }.observes('model.integratedObligations.serviceProviders.@each'),

    getTypeCategory: function getTypeCategory(type) {
      if (this.financeTeamTypes.includes(type)) {
        return _constants.Constants.FINANCE_TEAM;
      } else if (this.underwriterTeamTypes.includes(type)) {
        return _constants.Constants.UNDERWRITER;
      } else {
        return _constants.Constants.OTHER;
      }
    },

    actions: {
      activeMenu: function activeMenu(id) {
        $('.active-icon-menu').removeClass('active-icon-menu');
        $('#' + id + ' i').addClass('active-icon-menu');
      }
    }
  });
});