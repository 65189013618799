define('pretty-nuts/routes/aquorn-admin/whitelist', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    adminService: Ember.inject.service(),
    model: function model(params) {
      var adminService = this.get('adminService');
      return Ember.RSVP.hash({
        lists: adminService.getDomainWhitelists()
      }).then(function (model) {
        //   	console.log('WHITELISTS',model)
        return model;
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },


    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});