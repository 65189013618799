define('pretty-nuts/helpers/maturity-status', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.maturityStatus = maturityStatus;
  function maturityStatus(params /*, hash*/) {
    var now = new Date();
    if (moment(params).isBefore(now)) {
      return 'Matured';
    } else {
      return 'Outstanding';
    }
  }

  exports.default = Ember.Helper.helper(maturityStatus);
});