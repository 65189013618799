define("pretty-nuts/helpers/get-json-key", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getJsonKey = getJsonKey;
  function getJsonKey(params /*, hash*/) {
    if (params[0] !== undefined && params[1] !== undefined) {
      return JSON.parse(params[0])[params[1]];
    } else {
      return "";
    }
  }

  exports.default = Ember.Helper.helper(getJsonKey);
});