define('pretty-nuts/controllers/onboarding/resume', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    onboarding: Ember.inject.controller(),

    actions: {
      setupResume: function setupResume(obligation) {
        console.log("setupResume!!!");
        //this.get('onboarding').set('obligation', obligation);
        this.get('onboarding').set('obligation', obligation);
        this.transitionToRoute('onboarding.overview');
      }
    }
  });
});