define('pretty-nuts/routes/obligation/refunding', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor('obligation');

      return Ember.RSVP.hash({
        refunds: this.store.query('refund', { obligationId: model.obligation.get('id') })
      }).then(function (result) {
        Ember.set(model, 'refunds', result.refunds);

        return model;
      });
    }
  });
});