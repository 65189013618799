define('pretty-nuts/models/service-provider-summary', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    organization: _emberData.default.belongsTo('organization'),
    users: _emberData.default.hasMany('integrated-user-profile'),
    obligations: _emberData.default.hasMany('obligation'),
    types: _emberData.default.attr()

    // us: Ember.computed('users', function () {
    //   const users = this.get('users');
    //
    //   let us = [];
    //
    //   console.log('us computed');
    //
    //   if (!Ember.isEmpty(users)) {
    //     users.forEach((user) => {
    //       console.log('user profile', user);
    //       us.push(this.store.findRecord('user', user.get('id')));
    //     })
    //   }
    //
    //   return us;
    // })
  });
});