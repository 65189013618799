define('pretty-nuts/serializers/my-role-request', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'requestAquornId',

    atts: {
      requestorId: 'requestorAquornId',
      objectId: 'objectAquornId',
      approver: 'approverAquorn'
    }
  });
});