define('pretty-nuts/helpers/times', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.times = times;
  function times(params /*, hash*/) {
    var accum = [];
    for (var i = 0; i < params; ++i) {
      accum.push(i);
    }
    return accum;
  }

  exports.default = Ember.Helper.helper(times);
});