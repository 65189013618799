define('pretty-nuts/controllers/obligation/providers', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    obligation: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    application: Ember.inject.controller(),
    addSyndicateTeam: false,
    leToAdd: null,
    orgToAdd: null,

    serviceProviders: Ember.computed('obligation.model', function () {
      console.log('sp', this.get('obligation').get('model.serviceProviders'));
      return this.get('model').get('serviceProviders');
    }),
    showElement: function showElement(name) {
      $(name).show();
    },
    hideElement: function hideElement(name) {
      $(name).hide();
    },
    addServiceProviderOrg: function addServiceProviderOrg(organization, type) {
      var that = this;
      var obligation = this.get('onboarding.obligation');
      var obligationService = this.get('obligationService');

      return obligationService.addServiceProviderOrg(obligation, organization, type).then(function (serviceProvider) {
        that.get('onboarding.serviceProviders').update();
        that.get('onboarding.notifications').success("Service provider added", { autoClear: true });
      });
    },
    saveServiceProvider: function saveServiceProvider(serviceProvider) {
      var that = this;
      var obligationService = this.get('obligationService');

      obligationService.updateServiceProvider(serviceProvider).then(function (serviceProvider) {
        that.get('onboarding.notifications').success("Service provider updated", { autoClear: true });
      });
    },


    actions: {
      actionAddIssuer: function actionAddIssuer(issuer) {
        var that = this;
        var obligation = this.get('onboarding.obligation');
        var obligationService = this.get('obligationService');

        console.log('seleted', issuer);

        obligationService.addIssuer(obligation, issuer).then(function (serviceProvider) {
          that.get('onboarding.serviceProviders').update();
          that.hideElement("#add_issuer");
          that.get('onboarding.notifications').success("Service provider added", { autoClear: true });
        });
      },
      actionCreateOrganization: function actionCreateOrganization(organization) {
        var that = this;

        console.log('create org:', name);

        this.get('application').showOrgCreation(organization, function (organization) {
          console.log('callback:', that.get('orgToAdd'));

          that.set('orgToAdd', null);
          //setValue(null);
          that.send('reloadModel');
        });
      },
      actionCreateLegalUnit: function actionCreateLegalUnit(legalUnit) {
        var organization = this.get('model.organization');
        var that = this;

        legalUnit.set('organization', organization);

        console.log('create lu:', name);

        this.get('application').showLECreation(legalUnit, function (legalUnit) {
          console.log('callback:', that.get('leToAdd'));

          that.set('leToAdd', null);
          //setValue(null);
          that.send('reloadModel');
        });
      },
      actionSaveServiceProvider: function actionSaveServiceProvider(serviceProvider) {
        this.saveServiceProvider(serviceProvider);
      },
      actionSetProviderType: function actionSetProviderType(serviceProvider, type) {
        console.log('serviceProvider', serviceProvider);
        console.log('type', type);
        serviceProvider.set('type', type);
        this.saveServiceProvider(serviceProvider);
      },
      actionAddObligor: function actionAddObligor(entityType, organization) {
        this.addServiceProviderOrg(organization, _constants.Constants.OBLIGOR);
        this.hideElement("#add_obligor");
      },
      actionAddFinanceTeam: function actionAddFinanceTeam(entityType, organization) {
        this.addServiceProviderOrg(organization, _constants.Constants.FINANCIAL_ADVISOR);
        this.hideElement("#add_finance_team");
      },
      actionAddFinanceMember: function actionAddFinanceMember(serviceProviderOrg, user) {
        var that = this;
        var obligation = this.get('onboarding.obligation');
        var obligationService = this.get('obligationService');

        obligationService.addServiceProviderMember(obligation, serviceProviderOrg, user).then(function (serviceProviderMember) {
          that.get('onboarding.serviceProviders').update();
          that.hideElement('#add_finance_member_' + serviceProviderOrg.get('id'));
        });
      },
      actionAddSyndicateTeam: function actionAddSyndicateTeam(entityType, organization) {
        this.addServiceProviderOrg(organization, _constants.Constants.LEAD_UNDERWRITER);
        this.hideElement("#add_syndicate_team");
        this.set('addSyndicateTeam', true);
      },
      actionAddSyndicateMember: function actionAddSyndicateMember(serviceProviderOrg, user) {
        var that = this;
        var obligation = this.get('onboarding.obligation');
        var obligationService = this.get('obligationService');

        obligationService.addServiceProviderMember(obligation, serviceProviderOrg, user).then(function (serviceProviderMember) {
          that.get('onboarding.serviceProviders').update();
          that.hideElement('#add_syndicate_member_' + serviceProviderOrg.get('id'));
        });
      },
      actionAddOtherTeam: function actionAddOtherTeam(entityType, organization) {
        this.addServiceProviderOrg(organization, "Other");
        this.hideElement("#add_other_team");
      },
      actionAddOtherMember: function actionAddOtherMember(serviceProviderOrg, user) {
        var that = this;
        var obligation = this.get('onboarding.obligation');
        var obligationService = this.get('obligationService');

        obligationService.addServiceProviderMember(obligation, serviceProviderOrg, user).then(function (serviceProviderMember) {
          that.get('onboarding.serviceProviders').update();
          that.hideElement('#add_other_member_' + serviceProviderOrg.get('id'));
        });
      },
      actionRemoveServiceProvider: function actionRemoveServiceProvider(serviceProvider) {
        var that = this;
        var obligationService = this.get('onboarding.obligationService');

        obligationService.removeServiceProvider(serviceProvider).then(function () {
          that.get('onboarding.serviceProviders').update();
        });
      },
      actionShowElement: function actionShowElement(element, index) {
        var modifier = index ? "_" + index : "";
        this.showElement(element + modifier);
      },
      actionHideElement: function actionHideElement(element, index) {
        var modifier = index ? "_" + index : "";
        this.hidElement(element + modifier);
      }
    }
  });
});