define('pretty-nuts/routes/onboarding/export', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var STEPS = {
    OBLIGATION_CREATE: 'create',
    OBLIGATION_UPDATE: 'obligation_update',
    GENERATE_CALL_STRUCTURE: 'generate_call_structure',
    GENERATE_SECURITIES: 'generate_securities',
    UPDATE_SECURITY: 'update_security',
    OBLIGATION_VERIFY: 'obligation_verify',
    OBLIGATION_REFUND_DETERMINATION: 'obligation_refund_determination',
    SOURCE_OF_FUNDS_CREATE: 'source_of_funds_create',
    SOURCE_OF_FUNDS_EDIT: 'source_of_funds_edit',
    USE_OF_FUNDS_CREATE: 'use_of_funds_create',
    USE_OF_FUNDS_EDIT: 'use_of_funds_edit',
    OBLIGATION_SUBMIT: 'obligation_submit'
  };

  var GenerateSecuritiesStepBuilder = function () {
    function GenerateSecuritiesStepBuilder(obligation, securities) {
      _classCallCheck(this, GenerateSecuritiesStepBuilder);

      securities = securities.slice(0);

      securities.sort(function (a, b) {
        return compare(Ember.get(a, 'maturityDate'), Ember.get(b, 'maturityDate'));
      });

      this.obligation = obligation;
      this.securities = securities;
      this.serialSecurities = [];
      this.termSecurities = [];
      this.bulletSecurities = [];
      this.steps = [];

      this.securitiesMap = {};
      this.identifiersMap = {};

      this.id_counter = 0;
      this.identifiers = [];

      this.generateResultsAttributes = ['maturityDate', 'securityType', 'rateType', 'couponPeriod', 'callable'];

      this.securitySetAttributes = ['identifier', 'coupon', 'initialOfferYield',
      // 'maturityDate',
      'cashflow.principalPayments.#.#.amount', 'otherInfo.totalCurrentRefundingAmount', 'otherInfo.totalAdvanceRefundingAmount'];

      this.generateSecurityResults = [];
    }

    _createClass(GenerateSecuritiesStepBuilder, [{
      key: '_getIdentifier',
      value: function _getIdentifier() {
        var sid = String(this.id_counter++);

        while (sid.length < 4) {
          sid = '0' + sid;
        }

        return sid;
      }
    }, {
      key: '_splitByType',
      value: function _splitByType() {
        var _this = this;

        this.securities.forEach(function (security) {
          switch (security.get('securityType')) {
            case _constants.Constants.SecurityType.SERIAL:
              {
                _this.serialSecurities.push(security.toJSON());
                break;
              }

            case _constants.Constants.SecurityType.TERM:
              {
                _this.termSecurities.push(security.toJSON());
                break;
              }

            case _constants.Constants.SecurityType.BULLET:
              {
                _this.bulletSecurities.push(security.toJSON());
                break;
              }

            default:
              {
                throw 'Unknown security type: ' + security.get('securityType');
              }
          }
        });
      }
    }, {
      key: '_addSecurityResults',
      value: function _addSecurityResults(securities) {
        var _this2 = this;

        securities.forEach(function (security) {
          _this2.generateSecurityResults.push(carve_object(security, _this2.generateResultsAttributes));

          var identifier = _this2._getIdentifier();

          // console.log('this.identifiers', this.identifiers);

          _this2.identifiers.push(identifier);
          _this2.securitiesMap[identifier] = security;
          _this2.identifiersMap[security.aquornId] = identifier;
        });
      }
    }, {
      key: 'getSecurityIdentifier',
      value: function getSecurityIdentifier(security) {
        var _this3 = this;

        Object.keys(this.securitiesMap).forEach(function (key) {
          if (_this3.securitiesMap[key] === security) {
            return key;
          }

          return null;
        });
      }
    }, {
      key: 'buildSerialGeneration',
      value: function buildSerialGeneration() {
        var securities = this.serialSecurities;

        // let identifier_base = Object.keys(this.securitiesMap).length;

        this._addSecurityResults(securities);

        var firstSecurity = securities[0];
        var finalSecurity = securities[securities.length - 1];

        return {
          operation: STEPS.GENERATE_SECURITIES,
          name: 'Generate Serial Securities',
          config: {
            type: Ember.get(firstSecurity, 'securityType'),
            firstMaturityDate: Ember.get(firstSecurity, 'maturityDate'),
            finalMaturityDate: Ember.get(finalSecurity, 'maturityDate'),
            maturityFrequency: _constants.Constants.Frequency.ANNUAL.jsonValues[0]
          },
          identifiers: this.identifiers.slice(0),
          results: {
            data: this.generateSecurityResults.slice(0)
          }
        };
      }
    }, {
      key: 'buildTermGeneration',
      value: function buildTermGeneration() {
        var _this4 = this;

        var securities = this.termSecurities;

        var steps = [];

        securities.forEach(function (termSecurity) {
          // let identifier_base = Object.keys(this.securitiesMap).length;

          _this4._addSecurityResults([termSecurity]);

          steps.push({
            operation: STEPS.GENERATE_SECURITIES,
            name: 'Generate Term Securities',
            config: {
              type: Ember.get(termSecurity, 'securityType'),
              firstMaturityDate: Ember.get(termSecurity, 'maturityDate'),
              finalMaturityDate: Ember.get(termSecurity, 'maturityDate'),
              firstPrincipalDate: Ember.get(termSecurity, 'otherInfo.firstPrincipalDate'),
              finalPrincipalDate: Ember.get(termSecurity, 'otherInfo.finalPrincipalDate'),
              maturityFrequency: _constants.Constants.Frequency.ANNUAL.jsonValues[0],
              principalFrequency: _constants.Constants.Frequency.ANNUAL.jsonValues[0]
            },
            identifiers: _this4.identifiers.slice(0),
            results: {
              data: _this4.generateSecurityResults.slice(0)
            }
          });
        });

        return steps;
      }
    }, {
      key: 'buildBulletGeneration',
      value: function buildBulletGeneration() {
        var _this5 = this;

        var steps = [];

        this.bulletSecurities.forEach(function (bulletSecurity) {

          _this5._addSecurityResults([bulletSecurity]);

          steps.push({
            operation: STEPS.GENERATE_SECURITIES,
            name: 'Generate bullet security',
            config: {
              type: Ember.get(bulletSecurity, 'securityType'),
              firstMaturityDate: Ember.get(bulletSecurity, 'maturityDate'),
              finalMaturityDate: Ember.get(bulletSecurity, 'maturityDate'),
              firstPrincipalDate: Ember.get(bulletSecurity, 'otherInfo.firstPrincipalDate'),
              finalPrincipalDate: Ember.get(bulletSecurity, 'otherInfo.finalPrincipalDate'),
              maturityFrequency: _constants.Constants.Frequency.ANNUAL.jsonValues[0],
              principalFrequency: _constants.Constants.Frequency.ANNUAL.jsonValues[0]
            },
            identifiers: _this5.identifiers.slice(0),
            results: {
              data: _this5.generateSecurityResults.slice(0)
            }
          });
        });

        return steps;
      }
    }, {
      key: 'buildSecuritySetValuesStep',
      value: function buildSecuritySetValuesStep(securities) {
        var _this6 = this;

        var steps = [];

        securities.forEach(function (security) {
          var attributes = _this6.securitySetAttributes.slice(0);

          // let principalPayments = security.cashflow.principalPayments;

          // Object.keys(principalPayments).forEach((paymentDate) => {
          //   principalPayments[paymentDate].forEach((principalPayment, index) => {
          //     attributes.push(`cashflow.principalPayments.${paymentDate}.${index}.amount`);
          //   });
          // });

          var resultsAttributes = attributes.slice(0).concat(['initialOfferPrice', 'principalAmount',
          // 'coupon',
          'maturityDate', 'otherInfo.premium', 'otherInfo.discount', 'otherInfo.accruedInterest', 'otherInfo.bondYears', 'otherInfo.bondYearsCoupon', 'otherInfo.firstPrincipalDate', 'otherInfo.finalPrincipalDate', 'otherInfo.totalCurrentRefundingAmount', 'otherInfo.totalAdvanceRefundingAmount', 'cashflow.outstandingPrincipal', 'cashflow.principalSchedule', 'cashflow.accrualPeriods.#.startDate', 'cashflow.accrualPeriods.#.endDate', 'cashflow.interestPeriods.#.startDate', 'cashflow.interestPeriods.#.endDate', 'cashflow.interestPeriods.#.actualRate', 'cashflow.interestPeriods.#.forecastRate', 'cashflow.interestPeriods.#.paymentDate', 'cashflow.interestPeriods.#.resetDate', 'cashflow.interestPeriods.#.countedDays', 'cashflow.interestPeriods.#.principalAmount', 'cashflow.interestPeriods.#.interestAmount', 'cashflow.interestPeriods.#.forecastInterestAmount', 'cashflow.interestPayments.#.#.accrualPeriod.startDate', 'cashflow.interestPayments.#.#.accrualPeriod.endDate', 'cashflow.interestPayments.#.#.outstandingPrincipal', 'cashflow.interestPayments.#.#.paymentDate', 'cashflow.interestPayments.#.#.amount', 'cashflow.principalPayments.#.#.amount', 'cashflow.principalPayments.#.#.netAmount', 'cashflow.principalPayments.#.#.paymentDate', 'cashflow.principalPayments.#.#.refundAmount', 'cashflow.principalPayments.#.#.isMaturity']);

          steps.push({
            operation: STEPS.UPDATE_SECURITY,
            name: 'Set security attributes: ' + security.identifier,
            // identifier: this.identifiers[index],
            // identifier: this.getSecurityIdentifier(security),
            identifier: _this6.identifiersMap[security.aquornId],
            changes: build_changes_deep(security, attributes),
            results: {
              data: carve_object(security, resultsAttributes),
              refundingDetermination: carve_object(security, ['otherInfo.refundingDetermination']).otherInfo.refundingDetermination
            }
          });
        });

        return steps;
      }
    }, {
      key: 'build',
      value: function build() {
        this._splitByType();

        this.steps.push(this.buildSerialGeneration());
        this.steps = this.steps.concat(this.buildSecuritySetValuesStep(this.serialSecurities));
        this.steps = this.steps.concat(this.buildTermGeneration());
        this.steps = this.steps.concat(this.buildSecuritySetValuesStep(this.termSecurities));
        this.steps = this.steps.concat(this.buildBulletGeneration());
        this.steps = this.steps.concat(this.buildSecuritySetValuesStep(this.bulletSecurities));

        this.steps.push({
          operation: STEPS.OBLIGATION_VERIFY,
          name: 'Verify security totals',
          results: {
            data: carve_object(this.obligation, ['parIssued', 'otherInfo.totalSecuritiesPrincipal'])
          }
        });

        return this.steps;
      }
    }]);

    return GenerateSecuritiesStepBuilder;
  }();

  function _carve_object(dest, object, keys) {
    var current_key = keys[0];

    if (keys.length > 1) {

      if (current_key === '#') {
        var current_type = typeof object === 'undefined' ? 'undefined' : _typeof(object);

        if (Array.isArray(object)) {

          object.forEach(function (item, index) {
            var next_keys = [index].concat(keys.slice(1));
            _carve_object(dest, object, next_keys);
          });
        } else if (current_type === 'object') {

          Object.keys(object).forEach(function (item_key) {
            // let next_object = object[item_key];
            var next_object = object;
            var next_keys = [item_key].concat(keys.slice(1));
            // console.log('wildcard object key: ', item_key, next_keys, next_object);
            _carve_object(dest, next_object, next_keys);
          });
        } else {
          throw 'Cannot use wildcard with type that is not object or array';
        }
      } else {

        var next_object = object[current_key];

        var next_type = Array.isArray(next_object) ? 'array' : typeof next_object === 'undefined' ? 'undefined' : _typeof(next_object);

        switch (next_type) {
          case 'instance':
          case 'object':
            {

              if (!dest[current_key]) {
                dest[current_key] = {};
              }

              _carve_object(dest[current_key], next_object, keys.slice(1));

              break;
            }

          case 'array':
            {

              if (!dest[current_key]) {
                // dest[current_key] = next_object;
                dest[current_key] = new Array(next_object.length);
              }

              _carve_object(dest[current_key], next_object, keys.slice(1));

              break;
            }

        }
      }
    } else {
      dest[current_key] = object[current_key];
    }
  }

  function carve_object(object, attributes) {
    var carved = {};

    attributes.forEach(function (attribute) {
      _carve_object(carved, object, attribute.split('.'));
    });

    return carved;
  }

  function _build_changes_deep(changes, object, path, splitKeys) {

    // console.log('_build_changes_deep path', path);
    // console.log('_build_changes_deep splitKeys', splitKeys);

    if (splitKeys.length === 0) {
      // console.log('Setting path', path);
      // console.log('Setting object', object);
      changes[path.join('.')] = object;
    } else {
      var current_key = splitKeys[0];
      var remainingKeys = splitKeys.slice(1);
      // let current_path = path.slice(0).push(current_key);


      if (current_key === '#') {
        // console.log('current_key is hash');
        if (Array.isArray(object)) {
          object.forEach(function (item, index) {
            current_key = index;
            var current_path = path.slice(0);
            current_path.push(current_key);

            _build_changes_deep(changes, object[current_key], current_path, remainingKeys);
          });
        } else {
          Object.keys(object).forEach(function (key) {
            current_key = key;
            var current_path = path.slice(0);
            current_path.push(current_key);

            _build_changes_deep(changes, object[current_key], current_path, remainingKeys);
          });
        }
      } else {

        var current_path = path.slice(0);
        current_path.push(current_key);

        // console.log('pushing current_path', current_path);

        _build_changes_deep(changes, object[current_key], current_path, remainingKeys);
      }
    }
  }

  function build_changes_deep(object, attributes) {
    var changes = {};

    attributes.forEach(function (attribute) {
      _build_changes_deep(changes, object, [], attribute.split('.'));
    });

    return changes;
  }

  function build_changes(obligation, attributes) {
    var changes = {};

    attributes.forEach(function (attribute) {
      changes[attribute] = Ember.get(obligation, attribute);
    });

    return changes;
  }

  function compare(a, b) {
    if (a > b) {
      return 1;
    } else if (a < b) {
      return -1;
    }

    return 0;
  }

  exports.default = Ember.Route.extend({

    securities: [],

    model: function model() {
      var model = this.modelFor('onboarding');

      Ember.set(model, 'export', this.createTestSteps(model));

      return model;
    },
    createTestSteps: function createTestSteps(model) {

      var jsonObligation = Ember.get(model, 'obligation').toJSON();

      var tests = {
        name: jsonObligation.shortName,
        organization: {
          name: 'Los Vages Valley Water District',
          type: 'MUNICIPALITY'
        },
        legalEntity: {
          name: 'Los Vages Valley Water District'
        },
        onboarding_steps: []
      };

      tests.onboarding_steps.push(this.createObligationStep(jsonObligation, ['shortName', 'purpose', 'structureType']));

      var overviewAttributes = ['longName1', 'longName2', 'longName3', 'longName4', 'finalSaleDate', 'datedDate', 'deliveryDate', 'firstInterestDate', 'denomination', 'otherInfo.dayBasis', 'otherInfo.interestFrequency'];

      tests.onboarding_steps.push(this.createObligationUpdateStep(jsonObligation, overviewAttributes, overviewAttributes, 'Edit Obligation: Overview'));

      var attributes = ['federalTaxStatus', 'stateTaxStatus', 'saleType', 'offeringType', 'securityPledge', 'amtStatus', 'generalUseOfProceeds', 'mainUseOfProceeds', 'moodysRating', 'spRating', 'fitchRating', 'kbraRating'];

      tests.onboarding_steps.push({
        operation: STEPS.OBLIGATION_UPDATE,
        name: 'Edit Obligation: Attributes',
        changes: build_changes_deep(jsonObligation, attributes),
        results: {
          data: carve_object(jsonObligation, attributes)
        }
      });

      tests.onboarding_steps = tests.onboarding_steps.concat(this.createRefundedDebtDeterminationStep(jsonObligation, Ember.get(model, 'refundedDebtDetermination')));

      tests.onboarding_steps = tests.onboarding_steps.concat(this.createGenerateCallStructureSteps(Ember.get(model, 'callOptions')));
      tests.onboarding_steps = tests.onboarding_steps.concat(this.createGenerateSecuritiesSteps(jsonObligation, Ember.get(model, 'securities')));

      tests.onboarding_steps.push(this.createObligationDebtServiceStep(jsonObligation));

      tests.onboarding_steps = tests.onboarding_steps.concat(this.createSourceOfFundsStep(Ember.get(model, 'sourcesOfFunds')));
      tests.onboarding_steps = tests.onboarding_steps.concat(this.createUsesOfFundsStep(jsonObligation, Ember.get(model, 'usesOfFunds')));

      tests.onboarding_steps.push(this.createReviewVerificationStep(jsonObligation));

      tests.onboarding_steps.push(this.createObligationSubmitStep(Ember.get(model, 'obligation')));

      return tests;
    },
    createObligationStep: function createObligationStep(obligation, attributes) {

      var step = {
        operation: STEPS.OBLIGATION_CREATE,
        name: "Create Obligation: " + obligation.shortName,
        legalEntity: '{{LEGAL_ENTITY}}',
        data: build_changes(obligation, attributes),

        results: {
          status: 200,
          data: carve_object(obligation, attributes)
        }
      };

      step.results.data.draft = true;

      return step;
    },
    createObligationUpdateStep: function createObligationUpdateStep(obligation, attributes, results_attributes, name) {

      var step = {
        operation: STEPS.OBLIGATION_UPDATE,
        name: name ? name : 'Edit obligation',
        changes: build_changes(obligation, attributes),
        results: {
          data: carve_object(obligation, results_attributes ? results_attributes : attributes)
        }
      };

      return step;
    },
    createCallOptionStep: function createCallOptionStep(calls) {

      calls.sort(function (a, b) {
        return compare(Ember.get(a, 'callDate'), Ember.get(b, 'callDate'));
      });

      var callResults = [];

      calls.forEach(function (call) {
        callResults.push(carve_object(call, ['callDate', 'price', 'type', 'availability']));
      });

      var call = calls[0];

      return {
        operation: 'generate_call_options',
        name: 'Generate ' + call.type + ' Structure',
        callStructure: {
          type: Ember.get(call, 'type'),
          availability: Ember.get(call, 'availability'),
          firstStepDate: Ember.get(call, 'callDate'),
          firstCallPrice: Ember.get(call, 'price')
        },
        results: {
          data: callResults
        }
      };
    },
    createGenerateCallStructureSteps: function createGenerateCallStructureSteps(callOptions) {

      var optionalCalls = [];
      var mandatoryCalls = [];

      callOptions.forEach(function (callOption) {
        switch (callOption.get('type')) {
          case _constants.Constants.CallOptionType.OPTIONAL_CALL.jsonValues[0]:
            {

              optionalCalls.push(callOption.toJSON());

              break;
            }

          case _constants.Constants.CallOptionType.MANDATORY_CALL.jsonValues[0]:
            {

              mandatoryCalls.push(callOption.toJSON());

              break;
            }

          default:
            {
              throw 'Unknown call type: ' + callOption.get('type');
            }
        }
      });

      var steps = [];

      if (optionalCalls.length > 0) {
        steps.push(this.createCallOptionStep(optionalCalls));
      }

      if (mandatoryCalls.length > 0) {
        steps.push(this.createCallOptionStep(mandatoryCalls));
      }

      return steps;
    },
    createGenerateSecuritiesSteps: function createGenerateSecuritiesSteps(obligation, securities) {

      var generator = new GenerateSecuritiesStepBuilder(obligation, securities);
      return generator.build();
    },
    createObligationDebtServiceStep: function createObligationDebtServiceStep(obligation) {
      var attributes = ['otherInfo.totalSecuritiesPrincipal', 'otherInfo.firstMaturityDate', 'otherInfo.finalMaturityDate', 'debtService.totalDebtService', 'debtService.totalPrincipal', 'debtService.totalInterest', 'debtService.debtServicePayments.#.paymentDate', 'debtService.debtServicePayments.#.principalAmount', 'debtService.debtServicePayments.#.interestAmount', 'debtService.debtServicePayments.#.coupons'];

      var step = {
        operation: STEPS.OBLIGATION_VERIFY,
        name: 'Verify obligation debt service',
        results: {
          data: carve_object(obligation, attributes)
        }
      };

      return step;
    },
    createSourceOfFundsStep: function createSourceOfFundsStep(sourcesOfFunds) {
      var steps = [];

      return steps;
    },
    createUsesOfFundsStep: function createUsesOfFundsStep(obligation, usesOfFunds) {
      var steps = [];

      var identifier = 0;

      var generate_results = [];
      var identifiers = [];

      usesOfFunds.forEach(function (useOfFunds) {
        var jsonUseOfFunds = useOfFunds.toJSON();
        var current_identifier = identifier++;

        generate_results.push(carve_object(jsonUseOfFunds, ['type', 'subType']));

        identifiers.push(current_identifier);

        steps.push({
          operation: STEPS.USE_OF_FUNDS_CREATE,
          name: 'Create use-of-funds ' + jsonUseOfFunds.type + ' - ' + jsonUseOfFunds.name,
          identifiers: identifiers.slice(0),
          data: build_changes_deep(jsonUseOfFunds, ['type', 'subType']),
          results: {
            data: generate_results.slice(0)
          }
        });

        steps.push({
          operation: STEPS.USE_OF_FUNDS_EDIT,
          name: 'Edit use-of-funds: ' + jsonUseOfFunds.name,
          identifier: current_identifier,
          changes: build_changes_deep(jsonUseOfFunds, ['type', 'subType', 'name', 'role', 'price']),
          results: {
            data: carve_object(jsonUseOfFunds, ['type', 'subType', 'name', 'role', 'price'])
          }
        });
      });

      steps.push({
        operation: STEPS.OBLIGATION_UPDATE,
        name: "Set underwriter's discount",
        changes: build_changes_deep(obligation, ['underwriterManagementFee', 'underwriterTakedown', 'underwriterRisk']),
        results: {
          data: carve_object(obligation, ['underwriterManagementFee', 'underwriterTakedown', 'underwriterRisk'])
        }
      });

      steps.push({
        operation: STEPS.OBLIGATION_VERIFY,
        name: 'Verify use of funds totals',
        results: {
          data: carve_object(obligation, ['otherInfo.underwriterManagementFeePricePerBond', 'underwriterManagementFeePercentPar', 'underwriterTakedownPricePerBond', 'underwriterTakedownPercentPar', 'underwriterRiskPricePerBond', 'underwriterRiskPercentPar', 'totalUnderwriterDetailPricePerBond', 'totalUnderwriterDetailParAmount', 'totalUnderwriterDetailPrice', 'totalUnderwriterExpensePricePerBond', 'totalUnderwriterExpenseParAmount', 'totalUnderwriterExpensePrice', 'totalUnderwriterPricePerBond', 'totalUnderwriterParAmount', 'totalPrimaryUses', 'totalUsesOfFunds'])
        }
      });

      return steps;
    },
    createReviewVerificationStep: function createReviewVerificationStep(obligation) {
      return {
        operation: STEPS.OBLIGATION_VERIFY,
        name: 'Verify obligation statistics',
        results: {
          data: carve_object(obligation, ['premium', 'discount', 'totalInterest', 'netInterest', 'totalDebtService', 'maxAnnualDebtService', 'averageAnnualDebtService', 'allInTrueInterestCost', 'arbitrageYield', 'averageLife', 'duartionOfIssue', 'bondProceeds', 'accruedInterest'])
        }
      };
    },
    createRefundedDebtDeterminationStep: function createRefundedDebtDeterminationStep(obligation, refundedDebtDetermination) {
      var steps = [];

      var obligationMap = {};
      var securityMap = {};

      var obligationRefundDeterminations = refundedDebtDetermination.get('obligationRefundDeterminations');

      console.log(refundedDebtDetermination.toJSON());

      var refundSpecifications = [];

      Object.keys(obligationRefundDeterminations).forEach(function (obligationId) {

        var obligationRefundDetermination = obligationRefundDeterminations[obligationId];

        var securityRefundDeterminations = obligationRefundDetermination.get('securityRefundDeterminations');

        // obligationMap[obligationId] = obligationRefundDetermination.get('obligation.shortName');

        Object.keys(securityRefundDeterminations).forEach(function (securityId) {
          var securityRefundDetermination = securityRefundDeterminations[securityId];

          var paymentRefunds = securityRefundDetermination.get('paymentDeterminations');

          // securityMap[securityRefundDetermination.get('security.id')] = securityRefundDetermination.get('security.identifier');

          // let change_attributes = [
          //   `obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.callDate`,
          //   `obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.callPrice`
          // ];
          //
          // let verify_attributes = [
          //   `obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.callDate`,
          //   `obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.callPrice`,
          //   `obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.outstandingPrincipal`,
          //   `obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.refundPrincipal`,
          //   `obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.remainingPrincipal`,
          // ];

          var zero = new BigNumber("0");

          Object.keys(paymentRefunds).forEach(function (paymentDate) {

            var payment = paymentRefunds[paymentDate];

            if (payment.refundedPrincipalAmount) {
              var bdRefundAmount = new BigNumber(payment.get('refundedPrincipalAmount'));

              if (bdRefundAmount.greaterThan(zero)) {
                refundSpecifications.push({
                  payment: payment,
                  securityRefundDetermination: securityRefundDetermination,
                  obligationRefundDetermination: obligationRefundDetermination
                });

                obligationMap[obligationId] = obligationRefundDetermination.get('obligation.shortName');
                securityMap[securityRefundDetermination.get('security.aquornId')] = securityRefundDetermination.get('security.identifier');
              }
            }

            // change_attributes.push(`obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.paymentDeterminations.${paymentDate}.paymentDate`);
            // verify_attributes.push(`obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.paymentDeterminations.${paymentDate}.callDate`);
            // verify_attributes.push(`obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.paymentDeterminations.${paymentDate}.callPrice`);
            // verify_attributes.push(`obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.paymentDeterminations.${paymentDate}.totalPrincipalAmount`);
            // verify_attributes.push(`obligationRefundDeterminations.${obligationId}.securityRefundDeterminations.${securityId}.paymentDeterminations.${paymentDate}.remainingPrincipalAmount`);
          });

          // steps.push({
          //   operation: STEPS.OBLIGATION_REFUND_DETERMINATION,
          //   name: `Set refunded debt determination: ${obligationRefundDetermination.obligation.shortName}`,
          //   changes: build_changes_deep(refundedDebtDetermination.toJSON(), change_attributes),
          //   results: {
          //     data: carve_object(refundedDebtDetermination.toJSON(), verify_attributes)
          //   }
          // });
        });

        // steps.push({
        //   operation: STEPS.OBLIGATION_VERIFY,
        //   name: 'Verify refunded debt summary',
        //   results: {
        //     data: carve_object(obligation, [
        //       `refundedDebtDetermination.obligationRefundDeterminations.${obligationId}.outstandingPrincipal`,
        //       `refundedDebtDetermination.obligationRefundDeterminations.${obligationId}.maturityRangeBegin`,
        //       `refundedDebtDetermination.obligationRefundDeterminations.${obligationId}.maturityRangeEnd`,
        //       `refundedDebtDetermination.obligationRefundDeterminations.${obligationId}.totalRefundPrincipal`,
        //     ])
        //   }
        // });
      });

      var determinations = [];

      refundSpecifications.forEach(function (refundSpecification) {
        var determination = {
          obligation: refundSpecification.obligationRefundDetermination.toJSON(),
          security: refundSpecification.securityRefundDetermination.toJSON(),
          payment: refundSpecification.payment.toJSON()
        };

        console.log('determination', determination);

        Ember.set(determination, 'obligation.obligation', Ember.get(determination, 'obligation.obligation.aquornId'));
        Ember.set(determination, 'obligation.securityRefundDeterminations', null);
        Ember.set(determination, 'security.security', Ember.get(determination, 'security.security.aquornId'));

        Ember.set(determination, 'securityId', Ember.get(refundSpecification, 'securityRefundDetermination.security.id'));
        Ember.set(determination, 'obligationId', Ember.get(refundSpecification, 'obligationRefundDetermination.obligation.aquornId'));

        determinations.push(determination);
      });

      steps.push({
        operation: STEPS.OBLIGATION_REFUND_DETERMINATION,
        name: 'Refunding Determination',
        obligationMap: obligationMap,
        securityMap: securityMap,
        determinations: determinations,
        data: carve_object(refundedDebtDetermination.toJSON(), ['totalRefundPrincipal'])
      });

      return steps;
    },
    createObligationSubmitStep: function createObligationSubmitStep(obligation) {
      return {
        operation: STEPS.OBLIGATION_SUBMIT,
        name: 'Submit obligation',
        results: {
          data: {
            version: 1,
            draft: false
          }
        }
      };
    }
  });
});