define('pretty-nuts/relay', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    __changeset__: true,

    /////////////
    // Proxy data

    parent: null,
    key: null,

    // Proxy data
    /////////////

    _eachIn: function _eachIn() {
      var _this = this;

      var source = this._getSource(this.key);

      var obj = {};

      Object.keys(source).forEach(function (key) {
        obj[key] = _this.getValue(key);
      });

      return obj;
    },


    /**
     * Route all unknown property lookups to the getValue() method, which will proxy the lookups
     * to the changeset and source instance appropriately.
     *
     * @param key name of the field/property requested
     * @returns {*} the requested value (or undefined if not present in source or changeset)
     */
    unknownProperty: function unknownProperty(key) {
      //console.log('relay unknown property', key);
      return this.getValue(key);
    },


    /**
     * Route all property assignments for unknown properties to the setValue method which will proxy
     * the assignments to the changeset and source instance appropriately.
     *
     * @param key name of the field/property to which the value should be assigned
     * @param value the value to assign to the property
     * @returns {*} the assigned value
     */
    setUnknownProperty: function setUnknownProperty(key, value) {
      this.setValue(key, value);
      return value;
    },


    /**
     * Proxy lookups to the changeset parent object
     *
     * @param key name of the field/index to retrieve
     */
    getValue: function getValue(key) {
      return this.parent.getValue(this.key + "." + key);
    },


    /**
     * Proxy raw lookups with no wrapping to the parent changeset object
     *
     * @param key name of the field, including sub-object path, to retrieve
     * @private
     */
    _getValue: function _getValue(key) {
      return this.parent._getValue(key);
    },
    _getSource: function _getSource(key) {
      return this._getValue(this.key);
    },


    /**
     * Proxy value assignments to the parent changeset object
     *
     * @param key name of the field/index to assign the value
     * @param value is the value to assign
     */
    setValue: function setValue(key, value) {
      this.parent.setValue(this.key + '.' + key, value);
    },


    /**
     * Intercept object comparator to compare based on the wrapped arrays
     *
     * @param other
     * @returns {boolean}
     */
    isEqual: function isEqual(other) {
      if (!other) {
        return false;
      }
      var a = this._getValue(this.key);
      var b = Ember.get(other, "__changeset__") ? other._getValue(other.key) : other;
      //let b = other._getValue(other.key);

      return Ember.isEqual(a, b);
    }
  });
});