define('pretty-nuts/helpers/aquorn-file-icon', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.aquornFileIcon = aquornFileIcon;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  /*
  WORD,
      EXCEL,
      POWERPOINT,
      PDF,
      TEXT,
      ARCHIVE,
      FILE
   */

  var FILE_ICON_TYPES = {
    WORD: 'file-word-o',
    EXCEL: 'file-excel-o',
    POWERPOINT: 'file-powerpoint-o',
    PDF: 'file-pdf-o',
    TEXT: 'file-text-o',
    ARCHIVE: 'file-archive-o',
    IMAGE: 'file-image-o',
    SOUND: 'file-sound-o',
    VIDEO: 'file-video-o',
    FILE: 'file-o'
  };

  function aquornFileIcon(_ref) /*, hash*/{
    var _ref2 = _slicedToArray(_ref, 1),
        fileType = _ref2[0];

    var iconName = FILE_ICON_TYPES[fileType];

    // console.log('fileType', fileType);
    // console.log('iconName', iconName);

    if (iconName) {
      return iconName;
    }

    return FILE_ICON_TYPES.FILE;
  }

  exports.default = Ember.Helper.helper(aquornFileIcon);
});