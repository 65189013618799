define('pretty-nuts/models/domain-security', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('domain-obligation'),
    securityType: _emberData.default.attr('enum', { type: _constants.Constants.SecurityType }),
    // aquornId: DS.attr(),
    identifier: _emberData.default.attr(),
    maturityDate: _emberData.default.attr(),
    principalAmount: _emberData.default.attr('decimal', { precision: 2 }),
    // coupon: DS.attr('decimal'),
    coupon: _emberData.default.attr(),
    // initialOfferYield: DS.attr('decimal', {precision: 3}),
    initialOfferYield: _emberData.default.attr('decimal', { precision: 6 }),
    // initialOfferPrice: DS.attr('decimal', {precision: 3}),
    initialOfferPrice: _emberData.default.attr('decimal', { precision: 6 }),
    securityDescription: _emberData.default.attr(),
    creditEnchancer: _emberData.default.attr(),
    fitchLongTermRating: _emberData.default.attr(),
    fitchShortTermRating: _emberData.default.attr(),
    kbraLongTermRating: _emberData.default.attr(),
    moodysLongTermRating: _emberData.default.attr(),
    spLongTermRating: _emberData.default.attr(),
    dayBasis: _emberData.default.attr('enum', { type: _constants.Constants.DayBasis }),
    resetFrequency: _emberData.default.attr('enum', { type: _constants.Constants.Frequency }),
    couponPeriod: _emberData.default.attr('enum', { type: _constants.Constants.Frequency }),
    rateType: _emberData.default.attr(),
    forecastRate: _emberData.default.attr(),
    newMoney: _emberData.default.attr(),
    currentRefund: _emberData.default.attr('decimal', { precision: 2 }),
    advanceRefund: _emberData.default.attr('decimal', { precision: 2 }),
    //callOptions: DS.attr(),
    callable: _emberData.default.attr(),
    pricedCall: _emberData.default.belongsTo('domain-call-option'),
    calledOption: _emberData.default.belongsTo('domain-call-option'),
    callDate: _emberData.default.attr(),
    cashflow: _emberData.default.attr('embedded-model', { modelName: 'domain-security-cashflow', generateId: true }),
    // otherInfo: DS.attr()
    otherInfo: _emberData.default.attr('embedded-model', { modelName: 'security-other-info', generateId: true })
  });
});