define('pretty-nuts/controllers/issuer/service-providers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    multipleTypes: Ember.computed('model.serviceProviderSummaries@each', function () {}),
    actions: {
      activeMenu: function activeMenu(id) {
        $('.active-icon-menu').removeClass('active-icon-menu');
        $('#' + id + ' i').addClass('active-icon-menu');
      }
    }
  });
});