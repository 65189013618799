define('pretty-nuts/routes/obligation/version-history', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    obligationService: Ember.inject.service(),

    model: function model(params) {
      var obligationService = this.get('obligationService');
      var obligationModel = this.modelFor('obligation');
      var obligation = obligationModel.obligation;

      return Ember.RSVP.hash({
        obligationArchives: obligationService.getObligationArchives(obligation.get('aquornId'))
      });
    }
  });
});