define('pretty-nuts/controllers/aquorn-admin/whitelist', ['exports'], function (exports) {
		'use strict';

		Object.defineProperty(exports, "__esModule", {
				value: true
		});
		exports.default = Ember.Controller.extend({
				adminService: Ember.inject.service(),
				application: Ember.inject.controller(),
				newWhitelist: {},
				whitelistOrgToAdd: null,

				actions: {
						actionCreateOrganization: function actionCreateOrganization(entityType, organization) {
								var that = this;

								var address = this.get('store').createRecord('address', { organization: organization, hq: true });

								this.get('application').showOrgCreation(organization, address, function (organization) {});
						},
						actionAddWhitelistOrg: function actionAddWhitelistOrg(entityType, organization) {},
						revealWhitelistAddModal: function revealWhitelistAddModal() {
								$('#addWhitelistModal').foundation('open');
						},
						setWhitelistOrganizationType: function setWhitelistOrganizationType(item) {
								this.set('newWhitelist.orgType', item);
						},
						deleteWhitelist: function deleteWhitelist(id) {
								if (confirm("Are you sure you want to delete whitelisting?")) {
										this.get('adminService').deleteDomainWhitelist(id);
								}
						},
						addToWhitelist: function addToWhitelist() {
								var _this = this;

								this.set('newWhitelist.orgId', this.get('whitelistOrgToAdd.id'));
								this.set('newWhitelist.orgType', this.get('whitelistOrgToAdd.type'));
								this.get('adminService').createDomainWhitelist(this.get('newWhitelist')).then(function (response) {
										_this.send('reloadModel');
								});
						},
						cancelAddToWhitelist: function cancelAddToWhitelist() {
								$('#addWhitelistModal').foundation('close');
						}
				}
		});
});