define('pretty-nuts/helpers/table-date', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tableDate = tableDate;
  function tableDate(params /*, hash*/) {
    return moment(params).format('MM/DD/YYYY');
  }

  exports.default = Ember.Helper.helper(tableDate);
});