define('pretty-nuts/services/network-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    store: Ember.inject.service(),

    getOrgNetwork: function getOrgNetwork(organizationId) {
      var orgId = organizationId;
      return this.get('store').query('service-provider', { organizationId: orgId });
    }
  });
});