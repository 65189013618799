define('pretty-nuts/models/stats-cashflow-table', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    columns: _emberData.default.attr('embedded-models', { modelName: 'stats-cashflow-table-column', generateId: true }),
    breakdown: _emberData.default.attr()
  });
});