define('pretty-nuts/controllers/onboarding/sources-uses', ['exports', 'pretty-nuts/constants', 'accounting'], function (exports, _constants, _accounting) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    onboarding: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    sumOtherSources: 0,

    financeTeamTypes: [_constants.Constants.BOND_COUNSEL, _constants.Constants.FINANCIAL_ADVISOR, _constants.Constants.UNDERWRITERS_COUNSEL, _constants.Constants.DISCLOSURE_COUNSEL, _constants.Constants.SPECIAL_TAX_COUNSEL, _constants.Constants.VERIFICATION_AGENT, _constants.Constants.CREDIT_ENHANCER, _constants.Constants.TRUSTEE, _constants.Constants.PAYING_AGENT, _constants.Constants.ESCROW_AGENT, _constants.Constants.AUDITOR, _constants.Constants.GENERAL_COUNCEL, _constants.Constants.FEASIBILITY_CONSULTANT, _constants.Constants.FISCAL_CONSULTANT, _constants.Constants.DISSEMINATION_AGENT],

    sourceOfFundsTypes: ["Type A", "Type B"],

    sourceOfFundsSubTypes: ["Sub Type A", "Sub Type B"],

    underwriterExpenses: [],
    underwriterDetails: [],
    costsOfIssuance: [],
    primaryUses: [],

    //totalSourcesOfFunds: null,
    totalUsesOfFunds: null,

    refreshOtherSources: function refreshOtherSources() {
      var sourcesOfFunds = this.get('model.integratedObligation.value.sourcesOfFunds');

      var otherSources = [];

      if (sourcesOfFunds) {
        Object.keys(sourcesOfFunds).forEach(function (sourceOfFundsId) {
          var sourceOfFunds = sourcesOfFunds[sourceOfFundsId];

          if (sourceOfFunds) {
            otherSources.push(sourceOfFunds);
          }
        });
      }

      this.set('otherSourcesOfFunds', otherSources);
    },


    sourceOfFundsObserver: function () {
      this.refreshOtherSources();
    }.observes('model.integratedObligation.value.sourcesOfFunds'),

    refreshUses: function refreshUses() {

      var usesOfFunds = this.get('model.integratedObligation.value.usesOfFunds');
      var serviceProviders = this.get('model.integratedObligation.value.serviceProviders');
      var underwriterExpenses = [];
      var underwriterDetails = [];
      var costsOfIssuance = [];
      var primaryUses = [];
      var providerCostOfIssuance = {};

      if (usesOfFunds) {

        Object.keys(usesOfFunds).forEach(function (useOfFundsId) {

          var useOfFunds = usesOfFunds[useOfFundsId];

          if (useOfFunds) {
            var type = useOfFunds.get('type');
            var subType = useOfFunds.get('subType');

            switch (type) {

              case _constants.Constants.UseOfFundsType.UNDERWRITERS_DISCOUNT:
                {

                  switch (subType) {
                    case _constants.Constants.UseOfFundsSubType.UNDERWRITERS_EXPENSES:
                      {
                        underwriterExpenses.push(useOfFunds);
                        break;
                      }

                    case _constants.Constants.UseOfFundsSubType.UNDERWRITERS_DETAILS:
                      {
                        underwriterDetails.push(useOfFunds);
                        break;
                      }

                    default:
                      {
                        console.log("Unknown underwriter discount:", useOfFunds.toJSON());
                      }
                  }

                  break;
                }

              case _constants.Constants.UseOfFundsType.COST_OF_ISSUANCE:
                {
                  if (_constants.Constants.UseOfFundsSubType.PROVIDER_COST_OF_ISSUANCE === useOfFunds.get('subType')) {
                    var serviceProviderId = useOfFunds.get('serviceProviderId') ? useOfFunds.get('serviceProviderId') : useOfFunds.get('provider.id');
                    providerCostOfIssuance[serviceProviderId] = useOfFunds;
                  } else {
                    costsOfIssuance.push(useOfFunds);
                  }

                  break;
                }

              case _constants.Constants.UseOfFundsType.PRIMARY_USE:
                {
                  primaryUses.push(useOfFunds);
                  break;
                }

              default:
                {
                  console.log('Unknown use of funds:', useOfFunds);
                }

            }
          }
        });

        var financingTeam = [];

        // console.log('serviceProviders', serviceProviders);

        if (serviceProviders) {
          var financingTeamTypes = this.get('financeTeamTypes');

          Object.keys(serviceProviders).forEach(function (serviceProviderId) {
            var serviceProvider = serviceProviders[serviceProviderId];

            if (serviceProvider) {
              if (financingTeamTypes.includes(serviceProvider.get('type'))) {
                var _costsOfIssuance = providerCostOfIssuance[serviceProviderId];

                financingTeam.push({
                  costOfIssuance: _costsOfIssuance,
                  serviceProvider: serviceProvider
                });
              }
            }
          });
        }

        console.log('financing team', financingTeam);

        this.set('primaryUses', primaryUses);
        this.set('costsOfIssuance', costsOfIssuance);
        this.set('underwriterDetails', underwriterDetails);
        this.set('underwriterExpenses', underwriterExpenses);
        this.set('financingTeam', financingTeam);
      }
    },


    useOfFundsObserver: function () {
      //console.log("Firing useOfFunds Observer");
      this.refreshUses();
    }.observes('model.integratedObligation.value.usesOfFunds', 'model.integratedObligation.value.serviceProviders'),

    showElement: function showElement(name) {
      console.log("Showing element:", name);
      $(name).show();
    },
    hideElement: function hideElement(name) {
      $(name).hide();
    },
    setObligationValue: function setObligationValue(path, value) {
      var integratedObligation = this.get('model.integratedObligation');

      integratedObligation.set('value.' + path, value);

      return this.get('model.integratedObligation').saveAttributes([path]);
    },
    addUseOfFunds: function addUseOfFunds(useOfFunds) {
      var controller = this;

      return this.get('model.integratedObligation').addUseOfFunds(useOfFunds).then(function () {
        controller.refreshUses();
      });
    },


    actions: {
      actionAddUnderwriterExpense: function actionAddUnderwriterExpense() {
        var useOfFunds = this.get('store').createRecord('domain-use-of-funds');
        useOfFunds.set('type', _constants.Constants.UseOfFundsType.UNDERWRITERS_DISCOUNT);
        useOfFunds.set('subType', _constants.Constants.UseOfFundsSubType.UNDERWRITERS_EXPENSES);

        this.addUseOfFunds(useOfFunds);
      },
      actionAddCostOfIssuance: function actionAddCostOfIssuance() {
        var useOfFunds = this.get('store').createRecord('domain-use-of-funds');
        useOfFunds.set('type', _constants.Constants.UseOfFundsType.COST_OF_ISSUANCE);
        useOfFunds.set('subType', _constants.Constants.UseOfFundsSubType.NONE);

        this.addUseOfFunds(useOfFunds);
      },
      actionAddPrimaryUse: function actionAddPrimaryUse() {
        var useOfFunds = this.get('store').createRecord('domain-use-of-funds');
        useOfFunds.set('type', _constants.Constants.UseOfFundsType.PRIMARY_USE);
        useOfFunds.set('subType', _constants.Constants.UseOfFundsSubType.NONE);

        this.addUseOfFunds(useOfFunds);
      },
      actionDeleteSourceOfFunds: function actionDeleteSourceOfFunds(sourceOfFunds) {
        var controller = this;
        return this.get('model.integratedObligation').removeSourceOfFunds(sourceOfFunds.get('id')).then(function () {
          controller.refreshOtherSources();
        });
      },
      actionDeleteUseOfFunds: function actionDeleteUseOfFunds(useOfFunds) {
        var controller = this;

        return this.get('model.integratedObligation').removeUseOfFunds(useOfFunds.get('id')).then(function () {
          controller.refreshUses();
        });
      },
      actionAddSourceOfFunds: function actionAddSourceOfFunds() {
        var controller = this;
        return this.get('model.integratedObligation').addSourceOfFunds('new source', "0.00").then(function () {
          controller.refreshOtherSources();
        });
      },
      actionSetUseOfFundsMoneyValue: function actionSetUseOfFundsMoneyValue(useOfFunds, attribute, event) {
        var path = 'usesOfFunds.' + useOfFunds.id + '.' + attribute;
        var value = _accounting.default.unformat(event.target.value, '.');

        this.setObligationValue(path, value);
      },
      actionSetUseOfFundsAttribute: function actionSetUseOfFundsAttribute(useOfFunds, attribute, event) {
        var path = 'usesOfFunds.' + useOfFunds.id + '.' + attribute;
        var value = event.target.value;

        console.log('setting ', path, value);

        this.setObligationValue(path, value);
      },
      actionSetUseOfFundsProvider: function actionSetUseOfFundsProvider(useOfFunds, organization) {
        var path = 'usesOfFunds.' + useOfFunds.id + '.providerId';

        this.setObligationValue(path, organization);
      },
      actionSetUseOfFundsPrice: function actionSetUseOfFundsPrice(useOfFunds, event) {
        var path = 'usesOfFunds.' + useOfFunds.id + '.price';
        var value = _accounting.default.unformat(event.target.value, '.');
        this.setObligationValue(path, value);
      },
      actionSetProviderCostOfIssuanceAmount: function actionSetProviderCostOfIssuanceAmount(serviceProvider, event) {
        var value = _accounting.default.unformat(event.target.value, '.');

        this.get('model.integratedObligation').setServiceProviderCostOfIssuance(serviceProvider.get('id'), value);
      },
      actionSetSourceOfFundsMoneyValue: function actionSetSourceOfFundsMoneyValue(sourceOfFunds, attribute, event) {
        var path = 'sourcesOfFunds.' + sourceOfFunds.id + '.' + attribute;
        var value = _accounting.default.unformat(event.target.value, '.');

        this.setObligationValue(path, value);
      },
      actionSetSourceOfFundsAttribute: function actionSetSourceOfFundsAttribute(sourceOfFunds, attribute, event) {
        var path = 'sourcesOfFunds.' + sourceOfFunds.id + '.' + attribute;
        var value = event.target.value;

        this.setObligationValue(path, value);
      },
      actionSetObligationMoneyValue: function actionSetObligationMoneyValue(attribute, event) {
        var path = 'obligation.' + attribute;
        var value = _accounting.default.unformat(event.target.value, '.');

        this.setObligationValue(path, value);
      },
      actionSaveAndTransition: function actionSaveAndTransition() {
        this.get('onboarding').next('sources-uses');
      },
      deleteObligation: function deleteObligation(obligation) {
        this.get('onboarding').send('deleteObligation', obligation);
      }
    }
  });
});