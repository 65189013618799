define('pretty-nuts/controllers/obligation/calloptions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    obligation: Ember.inject.controller(),
    init: function init() {
      console.log(this.get('obligation').get('model.callOptions'));
    }
  });
});