define('pretty-nuts/models/domain-obligation', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    legalEntity: _emberData.default.belongsTo('legal-unit'),

    status: _emberData.default.attr('enum', { type: _constants.Constants.ObligationStatus }),
    active: _emberData.default.attr(),
    version: _emberData.default.attr(),

    creator: _emberData.default.attr(),
    createdDate: _emberData.default.attr(),

    lastUpdatedBy: _emberData.default.attr(),
    lastUpdatedDate: _emberData.default.attr(),

    perspectiveDate: _emberData.default.attr(),

    aquornShortName: _emberData.default.attr(),
    shortName: _emberData.default.attr(),
    series: _emberData.default.attr(),
    longName1: _emberData.default.attr(),
    longName2: _emberData.default.attr(),
    longName3: _emberData.default.attr(),
    longName4: _emberData.default.attr(),

    securityPledge: _emberData.default.attr('enum', { type: _constants.Constants.SecurityPledge }),

    federalTaxStatus: _emberData.default.attr('enum', { type: _constants.Constants.TaxStatus }),
    stateTaxStatus: _emberData.default.attr('enum', { type: _constants.Constants.TaxStatus }),
    amtStatus: _emberData.default.attr('enum', { type: _constants.Constants.AMTStatus }),

    bankQualified: _emberData.default.attr('enum', { type: _constants.Constants.Yes_No }),
    purpose: _emberData.default.attr('enum', { type: _constants.Constants.ObligationPurpose }),
    seniority: _emberData.default.attr(),
    saleType: _emberData.default.attr('enum', { type: _constants.Constants.SaleType }),
    offeringType: _emberData.default.attr('enum', { type: _constants.Constants.OfferingType }),
    structureType: _emberData.default.attr('enum', { type: _constants.Constants.ObligationStructure }),

    creditEnhancement: _emberData.default.attr(),

    generalUseOfProceeds: _emberData.default.attr(),
    mainUseOfProceeds: _emberData.default.attr(),

    currency: _emberData.default.attr(),

    plannedSaleDate: _emberData.default.attr(),
    finalSaleDate: _emberData.default.attr(),
    finalSaleTime: _emberData.default.attr(),

    datedDate: _emberData.default.attr(),
    deliveryDate: _emberData.default.attr(),
    firstInterestDate: _emberData.default.attr(),
    maturityDate: _emberData.default.attr(),

    numBonds: _emberData.default.attr(),
    denomination: _emberData.default.attr(),
    plannedSaleAmount: _emberData.default.attr(),
    parIssued: _emberData.default.attr(),
    premium: _emberData.default.attr(),
    discount: _emberData.default.attr(),

    totalSourceOfFunds: _emberData.default.attr(),
    costOfIssuance: _emberData.default.attr(),
    underwritersDiscount: _emberData.default.attr(),
    underwriterManagementFee: _emberData.default.attr(),
    underwriterTakedown: _emberData.default.attr(),
    underwriterRisk: _emberData.default.attr(),
    contingency: _emberData.default.attr(),

    totalUseOfFunds: _emberData.default.attr(),

    averageCoupon: _emberData.default.attr(),
    trueInterestCost: _emberData.default.attr(),
    netInterestCost: _emberData.default.attr(),
    allInTrueInterestCost: _emberData.default.attr(),
    arbitrageYield: _emberData.default.attr(),
    averageLife: _emberData.default.attr(),
    durationOfIssue: _emberData.default.attr(),
    bondProceeds: _emberData.default.attr(),

    totalInterest: _emberData.default.attr(),
    netInterest: _emberData.default.attr(),
    totalDebtService: _emberData.default.attr(),
    maxAnnualDebtService: _emberData.default.attr(),
    averageAnnualDebtService: _emberData.default.attr(),
    accruedInterest: _emberData.default.attr(),

    fitchRating: _emberData.default.attr(),
    kbraRating: _emberData.default.attr(),
    moodysRating: _emberData.default.attr(),
    spRating: _emberData.default.attr(),

    otherInfo: _emberData.default.attr('embedded-model', { modelName: 'obligation-other-info', generateId: true })
  });
});