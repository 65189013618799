define("pretty-nuts/helpers/object-sort", ["exports", "pretty-nuts/constants"], function (exports, _constants) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.objectSort = objectSort;

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function as_type(value, type) {
    if (_constants.Constants.ValueType.DECIMAL === type || _constants.Constants.ValueType.MONEY === type) {
      return new BigNumber(value);
    }

    return value;
  }

  function compare_type(va, vb, desc, type) {
    if (type) {
      if (_constants.Constants.ValueType.DECIMAL === type || _constants.Constants.ValueType.MONEY === type) {
        // console.log('Comparing as bignum', va, vb, type, desc);
        if (desc) {
          return vb.greaterThan(va) ? 1 : vb.lessThan(va) ? -1 : 0;
        } else {
          return va.greaterThan(vb) ? 1 : va.lessThan(vb) ? -1 : 0;
        }
      }
    }

    // console.log('Comparing as NOT bignum', type, desc);

    if (desc) {
      return vb > va ? 1 : vb < va ? -1 : 0;
    } else {
      return va > vb ? 1 : va < vb ? -1 : 0;
    }
  }

  function objectSort(_ref) {
    var _ref2 = _slicedToArray(_ref, 4),
        items = _ref2[0],
        attribute = _ref2[1],
        desc = _ref2[2],
        type = _ref2[3];

    if (!items) {
      return [];
    }

    if (!Ember.isArray(items)) {
      var is = [];
      Object.keys(items).forEach(function (key) {
        is.push(items[key]);
      });

      items = is;
    }

    if (!attribute) {
      return items;
    }

    var sorted = items.slice();

    sorted.sort(function (a, b) {
      a = Ember.get(a, attribute) || "";
      b = Ember.get(b, attribute) || "";

      if (type) {
        a = as_type(a, type);
        b = as_type(b, type);
      }

      return compare_type(a, b, desc, type);
    });

    // console.log('sorted', sorted);

    return sorted;
  }

  exports.default = Ember.Helper.helper(objectSort);
});