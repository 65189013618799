define('pretty-nuts/helpers/html-raw', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.htmlRaw = htmlRaw;
  function htmlRaw(params) {
    return Ember.String.htmlSafe(params[0]);
  }

  exports.default = Ember.Helper.helper(htmlRaw);
});