define('pretty-nuts/services/provider-view-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    getProviders: function getProviders(params) {
      var requestParams = {};

      if (params['searchText'] !== undefined && params['searchText'] !== '') {
        requestParams['searchText'] = params.searchText;
      }

      if (params['organizationId'] !== undefined && params['organizationId'] !== '') {
        requestParams['organizationId'] = params.organizationId;
      }

      if (params['legalEntityId'] !== undefined && params['legalEntityId'] !== '') {
        requestParams['legalEntityId'] = params.legalEntityId;
      }

      if (params['obligationId'] !== undefined && params['obligationId'] !== '') {
        requestParams['obligationId'] = params.obligationId;
      }

      if (params['isOrgSearch'] !== undefined && params['isOrgSearch'] !== '') {
        requestParams['isOrgSearch'] = params.isOrgSearch;
      }

      if (params['pageNo'] !== undefined && params['pageNo'] !== '') {
        requestParams['pageNo'] = params.pageNo;
      }

      if (params['pageSize'] !== undefined && params['pageSize'] !== '') {
        requestParams['pageSize'] = params.pageSize;
      }

      if (params['sortBy'] !== undefined && params['sortBy'] !== '') {
        requestParams['sortBy'] = params.sortBy;
      }

      if (params['isAsc'] !== undefined && params['isAsc'] !== '') {
        requestParams['isAsc'] = params.isAsc;
      }

      if (params['searchType'] !== undefined && params['searchType'] !== '') {
        requestParams['searchType'] = params.searchType;
      }

      return this.get('store').query('provider-view', requestParams);
    }
  });
});