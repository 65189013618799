define('pretty-nuts/components/employees-table', ['exports', 'pretty-nuts/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    organizationService: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    desc: null,

    compareValues: function compareValues(key) {
      var desc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

      return function (a, b) {
        var varA = 0;
        var varB = 0;

        if (a.get(key)) {
          varA = a.get(key).match(/^\d+$/) ? parseInt(a.get(key)) : a.get(key).toUpperCase();
        }
        if (b.get(key)) {
          varB = b.get(key).match(/^\d+$/) ? parseInt(b.get(key)) : b.get(key).toUpperCase();
        }

        var comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }

        return desc ? comparison * -1 : comparison;
      };
    },


    actions: {
      sortColumn: function sortColumn(prop) {
        var compareValues = this.get('compareValues');
        var desc = !this.get('desc');
        this.set('desc', desc);
        this.set('activeColumn', prop);
        var employeeUserProfiles = this.get('employeeUserProfiles');
        employeeUserProfiles.sort(compareValues(prop, desc));
        this.set('employeeUserProfiles', employeeUserProfiles.slice());
      },
      addUserToOrg: function addUserToOrg(user) {
        var org = this.get('organization');
        var that = this;
        this.get('organizationService').inviteUserOrganizationRole(user, org, "Member").then(function (response) {
          that.get('notifications').success("Sent role request: " + response.get('id') + " with role: " + response.get('role'));
        }).catch(function (error) {
          that.get('notifications').error("failed: " + error);
        });
      }
    }
  });
});