define('pretty-nuts/models/obligation-archive', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('obligation'),
    aquornId: _emberData.default.attr(),
    version: _emberData.default.attr(),
    creator: _emberData.default.belongsTo('user'),
    createdDate: _emberData.default.attr(),
    note: _emberData.default.attr()
  });
});