define('pretty-nuts/components/better-checkbox', ['exports', 'ember-invoke-action'], function (exports, _emberInvokeAction) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    checked: false,
    disabled: false,

    change: function (event) {
      (0, _emberInvokeAction.invokeAction)(this, 'update', this.readDOMAttr('checked'), event);
      console.log('change is happening', this.get('checked'));
    }.observes('checked'),

    click: function click(event) {
      return false;
    },


    actions: {
      toggle: function toggle() {
        this.set('checked', !this.get('checked'));
      }
    }
  });
});