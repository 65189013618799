define('pretty-nuts/helpers/paragraph-format', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.paragraphFormat = paragraphFormat;
	function paragraphFormat(params /*, hash*/) {
		//console.log('before',params);
		params = Ember.Handlebars.Utils.escapeExpression(params);
		params = params.replace(/\n\r?/g, '<br><br>');
		//return new Ember.Handlebars.SafeString(params);
		return new Ember.String.htmlSafe(params);
	}

	exports.default = Ember.Helper.helper(paragraphFormat);
});