define('pretty-nuts/models/service-provider', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('obligation'),
    copyId: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization'),
    user: _emberData.default.belongsTo('integrated-user-profile'),
    parent: _emberData.default.belongsTo('service-provider', { inverse: 'members' }),
    type: _emberData.default.attr(),
    myLegalEnity: _emberData.default.attr(),
    myOrganization: _emberData.default.attr(),

    members: _emberData.default.hasMany('service-provider'),

    otherInfo: _emberData.default.attr('json-blob', {
      defaultValue: function defaultValue() {
        return {};
      }
    })
  });
});