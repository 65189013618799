define('pretty-nuts/routes/legal-entity', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/db'], function (exports, _aquornAuthenticatedRouteMixin, _db) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model(params) {
      var _this = this;

      return this.store.findRecord('legal-unit', params.uid).then(function (legalUnit) {
        return Ember.RSVP.hash({
          legalUnit: legalUnit,
          parentOrgAddresses: _this.store.query('address', { organizationId: legalUnit.get('organization.id') })
        });
      });
    }
  });
});