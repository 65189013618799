define('pretty-nuts/models/generate-securities-config', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    obligation: _emberData.default.belongsTo('domain-obligation'),
    type: _emberData.default.attr('enum', { type: _constants.Constants.SecurityType }),

    firstMaturityDate: _emberData.default.attr(),
    finalMaturityDate: _emberData.default.attr(),
    maturityFrequency: _emberData.default.attr('enum', { type: _constants.Constants.Frequency }),

    firstPrincipalDate: _emberData.default.attr(),
    finalPrincipalDate: _emberData.default.attr(),
    principalFrequency: _emberData.default.attr('enum', { type: _constants.Constants.Frequency }),

    forecastRate: _emberData.default.attr(),
    couponPeriod: _emberData.default.attr('enum', { type: _constants.Constants.Frequency }),
    couponPaymentDateOffset: _emberData.default.attr(),
    firstCouponDate: _emberData.default.attr(),
    secondCouponBegin: _emberData.default.attr(),

    resetDateOffset: _emberData.default.attr(),
    resetFrequency: _emberData.default.attr('enum', { type: _constants.Constants.Frequency }),
    secondInterestBegin: _emberData.default.attr()

  });
});