define('pretty-nuts/components/address-modal', ['exports', 'pretty-nuts/components/aquorn-modal'], function (exports, _aquornModal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _aquornModal.default.extend({

    name: 'create_address',
    state: null,
    address: null,

    successCallback: null,
    cancelCallback: null,

    // Configuration and data
    /////////////////////////

    stateObserver: function () {
      var state = this.get('state.abbreviation');
      if (typeof state == 'string') {
        this.set('address.state', state);
      }
    }.observes('state'),

    onShow: function onShow(args) {
      this.set('address', args.address);

      this.set('successCallback', args.callbacks.success);
      this.set('cancelCallback', args.callbacks.cancel);
    },
    onHide: function onHide() {
      this.set('address', null);

      this.set('successCallback', null);
      this.set('cancelCallback', null);
    },


    actions: {
      actionContinue: function actionContinue() {
        var cb = this.get('successCallback');
        var address = this.get('address');

        this.hide();

        address.save().then(function (address) {
          cb(address);
        });
      }
    }
  });
});