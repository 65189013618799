define('pretty-nuts/components/variable-rate-chart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    interestPeriods: [],

    didInsertElement: function didInsertElement() {
      var interestPeriods = this.get('interestPeriods').filter(function (interestPeriod) {
        return interestPeriod.actualRate > 0;
      });

      var resetDates = interestPeriods.map(function (interestPeriod) {
        return interestPeriod.resetDate;
      });
      var forecastRates = interestPeriods.map(function (interestPeriod) {
        return interestPeriod.forecastRate;
      });
      var actualRates = interestPeriods.map(function (interestPeriod) {
        return interestPeriod.actualRate;
      });

      this.set('resetDates', resetDates);
      this.set('forecastRates', forecastRates);
      this.set('actualRates', actualRates);

      this.showVariableRateLineChart();
      this.showForecastRateActualRateDifferential();
    },
    showVariableRateLineChart: function showVariableRateLineChart() {
      var resetDates = this.get('resetDates').slice();
      var forecastRates = this.get('forecastRates').slice();
      var actualRates = this.get('actualRates').slice();

      resetDates.unshift('Reset Date');
      forecastRates.unshift('Forecast Rate');
      actualRates.unshift('Actual Rate');

      var columns = [resetDates, forecastRates, actualRates];

      var data = {
        columns: columns,
        groups: [['Forecast Rate'], ['Actual Rate']],
        order: null,
        type: 'line',
        x: 'Reset Date'
      };

      var axis = {
        x: {
          type: 'timeseries',
          tick: {
            format: '%b %d, %Y',
            fit: false
          }
        },
        y: {
          tick: {
            format: function format(d) {
              return d.toFixed(4) + '%';
            },
            values: this.yAxisValues
          }
        }
      };

      var c3Build = {
        axis: axis,
        bindto: '#c3_variable_rate_line_chart',
        data: data
      };
      console.log(c3Build);
      c3.generate(c3Build);
    },
    showForecastRateActualRateDifferential: function showForecastRateActualRateDifferential() {
      var resetDates = this.get('resetDates').slice();
      var forecastRates = this.get('forecastRates').slice();
      var actualRates = this.get('actualRates').slice();

      var positives = forecastRates.map(function (forecastRate, i) {
        return forecastRate >= actualRates[i] ? forecastRate - actualRates[i] : 0;
      });
      var negatives = forecastRates.map(function (forecastRate, i) {
        return forecastRate < actualRates[i] ? forecastRate - actualRates[0] : 0;
      });

      resetDates.unshift('Reset Date');
      positives.unshift('Positives');
      negatives.unshift('Negatives');

      var columns = [resetDates, positives, negatives];
      var groups = ['Positives', 'Negatives'];

      var data = {
        colors: {
          Positives: '#acd9a6',
          Negatives: '#ffa667'
        },
        columns: columns,
        groups: [groups],
        type: 'area',
        x: 'Reset Date'
      };

      var c3Build = {
        bindto: '#c3_variable_rate_differential_line_chart',
        data: data,
        legend: {
          show: false
        },
        point: {
          show: false
        },
        tooltip: {
          format: {
            value: function value(d) {
              return d.toFixed(4);
            }
          },
          contents: function contents(d, defaultTitleFormat, defaultValueFormat, color) {
            return '\n            <div class="c3-tooltip-container" style="pointer-events: none; top: 144.5px; left: 456.5px;">\n              <table class="c3-tooltip">\n                <tbody>\n                  <tr>\n                    <th colspan="2">\n                      ' + moment(d[0].x).format('MMM DD, Y') + '\n                    </th>\n                  </tr>\n                  <tr class="c3-tooltip-name--Forecast-Rate">\n                    <td class="name">Difference</td>\n                    <td class="value">' + ((d[0].value > 0 ? d[0].value : d[1].value).toFixed(4) + '%') + '</td>\n                  </tr>\n                </tbody>\n              </table>\n            </div>\n          ';
          }
        },
        size: {
          height: 150
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              format: '%b %Y'
            },
            show: false
          },
          y: {
            tick: {
              format: function format(d) {
                return d.toFixed(4) + '%';
              },
              count: 5
            }
          }
        }
      };

      c3.generate(c3Build);
    }
  });
});