define('pretty-nuts/components/list-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",
    page: 1,
    paginateBy: 10,
    ellipsis: false,
    itemsType: 'object',
    objectMap: Ember.computed('items', function () {
      if (this.get('itemsType') == 'object') {
        var result = Object.keys(this.get('items')).map(function (key) {
          return [key];
        });
        return result;
      } else {
        return false;
      }
    }),
    paginatedItems: Ember.computed('objectMap', 'page', function () {
      var i = (parseInt(this.get('page')) - 1) * parseInt(this.get('paginateBy'));
      var j = i + parseInt(this.get('paginateBy'));
      var object = {};
      if (this.get('objectMap')) {
        for (var count = i; count < j; count++) {
          if (this.get('objectMap')[count]) {
            object[this.get('objectMap')[count]] = this.get('items')[this.get('objectMap')[count]];
          }
        }
        return object;
      } else {
        return this.get('items').slice(i, j);
      }
    }),
    numberOfPages: Ember.computed('objectMap', 'page', function () {
      if (this.get('objectMap')) {
        var n = this.get('objectMap.length');
      } else {
        var n = this.get('items.length');
      }
      var c = parseInt(this.get('paginateBy'));
      var r = Math.floor(n / c);
      if (n % c > 0) {
        r += 1;
      }
      return r;
    }),
    pageNumbers: Ember.computed('numberOfPages', function () {
      var n = Array(this.get('numberOfPages'));
      for (var i = 0; i < n.length; i++) {
        n[i] = i + 1;
      }
      return n;
    }),
    showNext: Ember.computed('page', function () {
      return this.get('page') < this.get('numberOfPages');
    }),
    showPrevious: Ember.computed('page', function () {
      return this.get('page') > 1;
    }),
    nextText: 'Next page',
    previousText: 'Previous page',
    actions: {
      nextClicked: function nextClicked() {
        if (this.get('page') + 1 <= this.get('numberOfPages')) {
          this.set('page', this.get('page') + 1);
        }
      },
      previousClicked: function previousClicked() {
        if (this.get('page') > 0) {
          this.set('page', this.get('page') - 1);
        }
      },
      pageClicked: function pageClicked(pageNumber) {
        this.set('page', pageNumber);
      }
    }
  });
});