define('pretty-nuts/controllers/obligation/documents', ['exports', 'pretty-nuts/utils', 'pretty-nuts/db'], function (exports, _utils, _db) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    desc: true,
    activeColumn: '',
    previewModel: {},

    actions: {
      sortColumn: function sortColumn(prop) {
        this.set('desc', !this.get('desc'));
        this.set('activeColumn', prop);
        var model = this.get('model.documents').sort(_utils.ArrayComparer.property(prop, this.get('desc')));
        this.set('model.documents', model.slice());
      }
    }
  });
});