define('pretty-nuts/controllers/onboarding/review', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    onboarding: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    desc: false,
    isVariableRate: Ember.computed('model.integratedObligation.value.obligation.structureType', function () {
      return _constants.Constants.ObligationStructure.VARIABLE_RATE === this.get('model.integratedObligation.value.obligation.structureType');
    }),

    refundDeterminations: Ember.computed('model.integratedObligation.value.refundings', 'model.refundedDebtDetermination.value', function () {
      var refundings = this.get('model.integratedObligation.value.refundings');
      var refundedDebtDetermination = this.get('model.refundedDebtDetermination.value');

      if (!refundedDebtDetermination) {
        return [];
      }

      var determinations = [];

      if (refundings && refundedDebtDetermination) {
        Object.keys(refundings).forEach(function (refundedObligationId) {
          var obligationRefunding = refundings[refundedObligationId];
          var obligationDetermination = Ember.get(refundedDebtDetermination, 'obligationRefundDeterminationsById.' + refundedObligationId);

          if (obligationRefunding && obligationDetermination) {
            Object.keys(Ember.get(obligationRefunding, 'refundedSecurities')).forEach(function (refundedSecurityId) {
              var refundedSecurity = Ember.get(obligationRefunding, 'refundedSecurities.' + refundedSecurityId);
              var securityDetermination = Ember.get(refundedDebtDetermination, 'securityRefundDeterminationsById.' + refundedSecurityId);

              if (refundedSecurity && securityDetermination) {

                determinations.push({
                  obligationDetermination: obligationDetermination,
                  securityDetermination: securityDetermination
                });
              }
            });
          }
        });
      }

      return determinations;
    }),

    actions: {
      doSubmit: function doSubmit(obligation) {
        var integratedObligation = this.get('model.integratedObligation');
        var issuerId = integratedObligation.get('value.obligation.legalEntity.id');

        var controller = this;

        integratedObligation.submit().then(function () {
          controller.transitionToRoute('issuer', issuerId);
        });
      },
      deleteObligation: function deleteObligation(obligation) {
        this.get('onboarding').send('deleteObligation', obligation);
      },
      sortColumn: function sortColumn(prop) {
        console.log('sort column by', prop);
        this.set('desc', !this.get('desc'));
        this.set('activeColumn', prop);
      }
    }
  });
});