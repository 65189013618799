define('pretty-nuts/router', ['exports', 'pretty-nuts/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('login');
    this.route('forgot-password');
    this.route('verification');
    this.route('auth', function () {
      this.route('logout');
    });
    this.route('profile', function () {
      this.route('index', { path: '/' });
      this.route('edit');
    });
    this.route('obligations');
    this.route('obligation', { path: '/obligation/:obligationId' }, function () {
      this.route('overview');
      this.route('cashflow');
      this.route('security', { path: '/security/:securityId' });
      this.route('calloptions');
      this.route('events');
      this.route('documents');
      this.route('providers');
      this.route('variable-rates');
      this.route('service-providers');
      this.route('version-history');
      this.route('statistics');
      this.route('refunding');
    });
    this.route('reporting', { path: '/reporting/:organizationId' }, function () {
      this.route('legal-entities');
      this.route('obligations');
      this.route('legal-entity', { path: '/legal-entity/:legalEntityId' }, function () {
        this.route('30-year');
        this.route('cashflow');
        this.route('period');
      });
      this.route('obligation', { path: '/obligation/:obligationId' }, function () {
        this.route('overview');
        this.route('debt-service');
      });
      this.route('cashflow');
    });
    this.route('calendar', function () {});
    this.route('documents');
    this.route('providers', function () {
      this.route('my-service-providers');
      this.route('all-service-providers');
    });
    this.route('people');
    this.route('person', { path: '/person/:uid' });
    this.route('myprofile');
    this.route('provider', { path: '/provider/:uid' });
    this.route('onboarding', { path: '/onboarding/:obligationId' }, function () {
      this.route('overview', { path: '/' });
      this.route('parties');
      this.route('refunding');
      this.route('call-options');
      this.route('securities');
      this.route('events');
      this.route('documents');
      this.route('review');
      this.route('sources-uses');
      this.route('attributes');
      this.route('resume', { path: '/resume/:obligationId' });
      this.route('cashflow');
      this.route('variable-rates');
      this.route('export');
      this.route('debug');
    });
    this.route('myorganization');
    this.route('notifications');
    this.route('admin');
    this.route('settings');
    this.route('orgsettings');
    this.route('legal-entities');
    this.route('org-submissions');
    this.route('my-submissions');
    this.route('legal-entity', { path: '/legal-entity/:uid' });
    this.route('complete-signup');
    this.route('aquorn-admin', function () {
      this.route('access-rules');
      this.route('whitelist');
      this.route('network');
      this.route('upgrade');
      this.route('cache');
      this.route('obligations');
      this.route('api-docs');
    });
    this.route('issuer', { path: '/issuer/:legalUnitId' }, function () {
      this.route('events');
      this.route('documents');
      this.route('cashflow');
      this.route('service-providers');
    });
    this.route('government', { path: '/government/:organizationId' }, function () {
      this.route('employees');
      this.route('documents');
      this.route('events');
      this.route('cashflow');
      this.route('service-providers');
    });
    this.route('governments');
    this.route('structures');
    this.route('event');
    this.route('license-agreement');
    this.route('logout');
    this.route('loading');
  });

  exports.default = Router;
});