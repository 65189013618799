define('pretty-nuts/controllers/government/documents', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    government: Ember.inject.controller(),
    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        $('.obligations-table').floatThead({ top: 66 });
      });
      this.set('entered', false);
    }.observes('entered'),
    documents: Ember.computed('model.documents', function () {
      console.log('DOCS', this.get('government').get('model.documents'));
      return this.get('government').get('model.documents');
    })
  });
});