define("pretty-nuts/models/document-view", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    documentId: _emberData.default.attr(),
    aquornId: _emberData.default.attr(),
    documentName: _emberData.default.attr(),
    documentType: _emberData.default.attr(),
    description: _emberData.default.attr(),
    documentDate: _emberData.default.attr(),
    createdDate: _emberData.default.attr(),
    lastModifiedDate: _emberData.default.attr(),
    documentStatus: _emberData.default.attr(),
    documentPhase: _emberData.default.attr(),
    resource: _emberData.default.belongsTo("resource"),
    group: _emberData.default.attr(),
    category: _emberData.default.attr(),
    categoryId: _emberData.default.attr(),
    ownerId: _emberData.default.attr(),
    ownerFirstName: _emberData.default.attr(),
    ownerLastName: _emberData.default.attr(),
    preparerOrgId: _emberData.default.attr(),
    prepareOrgName: _emberData.default.attr(),
    preparerUserId: _emberData.default.attr(),
    preparerUserFirstName: _emberData.default.attr(),
    preparerUserLastName: _emberData.default.attr(),
    preparerUserFullName: _emberData.default.attr(),
    organizationId: _emberData.default.attr(),
    organizationName: _emberData.default.attr(),
    legalEntityId: _emberData.default.attr(),
    legalEntityName: _emberData.default.attr(),
    obligationId: _emberData.default.attr(),
    obligationShortName: _emberData.default.attr()
  });
});