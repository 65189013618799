define('pretty-nuts/routes/aquorn-admin/network', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return new Date();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },


    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});