define('pretty-nuts/routes/obligation/overview', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    obligationService: Ember.inject.service(),
    documentService: Ember.inject.service(),

    model: function model(params) {
      var currentEmployment = this.get('currentUser').getCurrentEmployment();
      var obligation = this.get('model.obligation');
      this.set('parent', currentEmployment.get('organization'));
      this.set('obligationModel', this.modelFor('obligation'));
      return this.modelFor('obligation');
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    }
  });
});