define("pretty-nuts/helpers/contains-substring", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.containsSubstring = containsSubstring;
  function containsSubstring(params /*, hash*/) {
    if (Ember.isEmpty(params)) {
      return "";
    }

    if (Ember.isEmpty(params[0])) {
      return "";
    }
    var string = params[0];
    var substring = params[1];

    return string.toLowerCase().indexOf(substring.toLowerCase()) !== -1;
  }

  exports.default = Ember.Helper.helper(containsSubstring);
});