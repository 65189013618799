define('pretty-nuts/routes/aquorn-admin/api-docs', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    backend: Ember.inject.service(),

    model: function model() {
      return this.get('backend').post({
        url: '/api/v2/api-docs'
      }).then(function (response) {
        return {
          apiDocsObject: response
        };
      });
    }
  });
});