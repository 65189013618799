define('pretty-nuts/controllers/obligation/statistics', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    onboarding: Ember.inject.controller(),
    obligationService: Ember.inject.service(),
    sumOtherSources: 0,

    sourceOfFundsTypes: ["Type A", "Type B"],

    sourceOfFundsSubTypes: ["Sub Type A", "Sub Type B"],

    financeTeamTypes: [_constants.Constants.BOND_COUNSEL, _constants.Constants.FINANCIAL_ADVISOR, _constants.Constants.UNDERWRITERS_COUNSEL, _constants.Constants.DISCLOSURE_COUNSEL, _constants.Constants.SPECIAL_TAX_COUNSEL, _constants.Constants.VERIFICATION_AGENT, _constants.Constants.CREDIT_ENHANCER, _constants.Constants.TRUSTEE, _constants.Constants.PAYING_AGENT, _constants.Constants.ESCROW_AGENT, _constants.Constants.AUDITOR, _constants.Constants.GENERAL_COUNCEL, _constants.Constants.FEASIBILITY_CONSULTANT, _constants.Constants.FISCAL_CONSULTANT, _constants.Constants.DISSEMINATION_AGENT],

    underwriterExpenses: [],
    underwriterDetails: [],
    costsOfIssuance: [],
    primaryUses: [],

    //totalSourcesOfFunds: null,
    totalUsesOfFunds: null,

    refundingsOberver: function () {
      // const refundings = this.get('model.integratedObligation');
      // console.log('statistics refunding observer', refundings);
      var refundings = this.get('model.integratedObligation.refundings');
      // console.log('statistics refunding computed', refundings);

      if (!refundings) {
        return [];
      }

      var securityRefundings = [];

      Object.keys(refundings).forEach(function (refundedObligationId) {
        var obligationRefunding = Ember.get(refundings, refundedObligationId);
        var refundedSecurities = Ember.get(obligationRefunding, 'refundedSecurities');
        // console.log('refundedSecurities', refundedSecurities);
        Object.keys(refundedSecurities).forEach(function (refundedSecurityId) {
          // console.log('adding security refund');
          var securityRefunding = Ember.get(refundedSecurities, refundedSecurityId);
          securityRefundings.push({
            securityRefunding: securityRefunding,
            obligationRefunding: obligationRefunding
          });
        });
      });

      // console.log('refundings', securityRefundings);
      this.set('refundings', securityRefundings);
    }.observes('model.integratedObligation.refundings', 'model.integratedObligation'),

    useOfFundsObserver: function () {
      //console.log("Firing useOfFunds Observer");

      // let usesOfFunds = this.get('model.uses');
      var usesOfFunds = this.get('model.integratedObligation.usesOfFunds');
      var serviceProviders = this.get('model.integratedObligation.serviceProviders');
      var underwriterExpenses = [];
      var underwriterDetails = [];
      var costsOfIssuance = [];
      var primaryUses = [];
      var financingTeam = [];
      var providerCostOfIssuance = {};

      // console.log('useOfFunds', usesOfFunds);

      if (!Ember.isNone(usesOfFunds)) {

        // usesOfFunds.forEach((useOfFunds) => {
        Object.keys(usesOfFunds).forEach(function (useOfFundsId) {

          var useOfFunds = usesOfFunds[useOfFundsId];

          // console.log('use of funds:', useOfFunds)
          var type = useOfFunds.get('type');
          var subType = useOfFunds.get('subType');

          switch (type) {
            case _constants.Constants.UseOfFundsType.UNDERWRITER_DISCOUNT:
              {

                switch (subType) {
                  case _constants.Constants.UseOfFundsSubType.UNDERWRITERS_EXPENSES:
                    {
                      underwriterExpenses.push(useOfFunds);
                      break;
                    }

                  case _constants.Constants.UseOfFundsSubType.UNDERWRITERS_DETAILS:
                    {
                      underwriterDetails.push(useOfFunds);
                      break;
                    }

                  default:
                    {
                      console.log("Unknown underwriter discount:", useOfFunds.toJSON());
                    }
                }

                break;
              }

            case _constants.Constants.UseOfFundsType.COST_OF_ISSUANCE:
              {
                if (_constants.Constants.UseOfFundsSubType.PROVIDER_COST_OF_ISSUANCE === useOfFunds.get('subType')) {
                  var serviceProviderId = useOfFunds.get('serviceProviderId') ? useOfFunds.get('serviceProviderId') : useOfFunds.get('provider.id');
                  providerCostOfIssuance[serviceProviderId] = useOfFunds;
                } else {
                  costsOfIssuance.push(useOfFunds);
                }

                break;
              }

            case _constants.Constants.UseOfFundsType.PRIMARY_USE:
              {

                primaryUses.push(useOfFunds);
                break;
              }

            default:
              {
                console.log('Unknown use of funds:', useOfFunds);
              }

          }
        });

        // console.log('serviceProviders', serviceProviders);

        if (serviceProviders) {
          var financingTeamTypes = this.get('financeTeamTypes');

          Object.keys(serviceProviders).forEach(function (serviceProviderId) {
            var serviceProvider = serviceProviders[serviceProviderId];

            if (serviceProvider) {
              // console.log('sp type', serviceProvider.get('type'));
              if (financingTeamTypes.includes(serviceProvider.get('type'))) {
                var _costsOfIssuance = providerCostOfIssuance[serviceProviderId];

                // console.log('adding finance team cost', {
                //   costOfIssuance: costsOfIssuance,
                //   serviceProvider: serviceProvider
                // });

                financingTeam.push({
                  costOfIssuance: _costsOfIssuance,
                  serviceProvider: serviceProvider
                });
              }
            }
          });
        }

        // console.log('financing team', financingTeam);

        // this.set('primaryUses', primaryUses);
        // this.set('costsOfIssuance', costsOfIssuance);
        // this.set('underwriterDetails', underwriterDetails);
        // this.set('underwriterExpenses', underwriterExpenses);
        // this.set('financingTeam', financingTeam);


        this.set('primaryUses', primaryUses);
        this.set('costsOfIssuance', costsOfIssuance);
        this.set('underwriterDetails', underwriterDetails);
        this.set('underwriterExpenses', underwriterExpenses);
        this.set('financingTeam', financingTeam);

        // console.log('primaryUses', primaryUses);
        // console.log('costsOfIssuance', costsOfIssuance);
        // console.log('underwriterDetails', underwriterDetails);
        // console.log('underwriterExpenses', underwriterExpenses);
        // console.log('financingTeam', financingTeam);
      }
    }.observes('model.integratedObligation.usesOfFunds'),

    actions: {
      sortColumn: function sortColumn(prop) {
        console.log('sort column by', prop);
        this.set('desc', !this.get('desc'));
        this.set('activeColumn', prop);
      }
    }
  });
});