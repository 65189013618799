define('pretty-nuts/components/select-location', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    modalService: Ember.inject.service(),
    organization: null,
    state: null,
    addresses: null,
    editAddress: null,

    stateObserver: function () {
      var state = this.get('state.abbreviation');
      if (typeof state == 'string') {
        this.set('editAddress.state', state);
      }
    }.observes('state'),

    actions: {
      selectHQAddress: function selectHQAddress(hqId) {
        this.get('addresses').forEach(function (address) {
          address.set('hq', address.get('id') == hqId);
        });
      },
      addNewAddress: function addNewAddress() {
        var that = this;
        var addressAttrs = { organization: this.get('organization') };
        var newAddress = this.get('store').createRecord('address', addressAttrs);
        var args = {
          address: newAddress,
          callbacks: {
            success: function success(address) {
              that.get('addresses').pushObject(address);
            }
          }
        };
        this.get('modalService').showModal('create_address', args);
      },
      editAddress: function editAddress(address) {
        console.log('EDIT IT DAMNIT', address.get('id'));
        $('#address_fields').show();
        $('.edit-address-link').show();
        $('#edit-address-link-' + address.get('id')).hide();
        this.set('editAddress', address);
        this.set('state', address.get('state'));
      },
      deleteAddress: function deleteAddress(address) {
        var that = this;
        var args = {
          prompt: 'are you sure you want to delete?',
          confirmWord: 'Yes',
          cancelWord: 'No',
          callbacks: {
            success: function success() {
              var row = $("#address-row-" + address.get('id'));
              row.remove();
              address.destroyRecord();
            }
          }
        };
        this.get('modalService').showModal('confirm', args);
      }
    }
  });
});