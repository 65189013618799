define('pretty-nuts/models/refunded-debt-determination', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligationRefundDeterminations: _emberData.default.attr('embedded-models', { modelName: 'obligation-refund-determination' }),

    totalRefundPrincipal: _emberData.default.attr()
  });
});