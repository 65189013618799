define('pretty-nuts/controllers/reporting/legal-entity', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    // groups: ['None','Legal Units', 'Obligations'],
    // groupedBy: 'None',
    // reports: [{value:'period',name:'Period Debt Service'},{value:'cashflow',name:'Cashflow'},{value:'30-year',name:'30 Year Report'}],
    // currentReport: {value:'period',name:'Period Debt Service'},
    // periodStart: moment().format("YYYY-MM-DD"), //first day of month
    // periodEnd: moment(), // defaults 1 year later
    // onGroupedBy: function(){
    //     console.log('Grouped By',this.get('groupedBy'))
    //     if (this.get('groupedBy') == 'None'){
    //       console.log(':-(')
    //     }
    // }.observes("groupedBy"),
    //
    // period: function(){
    //     console.log('Date Change',this.get('periodStart'),this.get('periodEnd'))
    //     if (this.get('groupedBy') == 'None'){
    //     }
    //     return
    // }.observes("periodStart","periodEnd"),
    // onCurrentReport: function(o){
    //   // go to the value as route
    //   this.transitionToRoute('reporting.legal-entity.'+o.get('currentReport.value'));
    // }.observes('currentReport'),
  });
});