define('pretty-nuts/models/security-other-info', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    principalPayments: _emberData.default.attr(),

    premium: _emberData.default.attr('decimal', { precision: 6 }),
    discount: _emberData.default.attr('decimal', { precision: 6 }),
    accruedInterest: _emberData.default.attr('decimal', { precision: 6 }),

    bondYears: _emberData.default.attr('decimal', { precision: 6 }),
    bondYearsCoupon: _emberData.default.attr('decimal', { precision: 6 }),

    firstPrincipalDate: _emberData.default.attr(),
    finalPrincipalDate: _emberData.default.attr(),

    principalFrequency: _emberData.default.attr(),
    maturityFrequency: _emberData.default.attr(),

    resetDateOffset: _emberData.default.attr(),
    interestPaymentOffset: _emberData.default.attr(),

    totalCurrentRefundingAmount: _emberData.default.attr('currency'),
    totalAdvanceRefundingAmount: _emberData.default.attr('currency'),

    refundingDetermination: _emberData.default.attr(),

    status: _emberData.default.attr('enum', { type: _constants.Constants.SecurityStatus })
  });
});