define('pretty-nuts/components/top-nav-obligation-sub', ['exports', 'pretty-nuts/db'], function (exports, _db) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function nav() {
    var _this = this;

    var param = _db.default.getTable('obligations').find(function (o) {
      return o.ObligationShortName === _this.get('selectedLocation');
    }).ObligationNumber.toString();
    var lens = this.get('selectedLens');
    var subRoute = this.get('subRoutes')[this.get('lenses').indexOf(lens)];
    this.get('router').transitionTo('obligation.' + subRoute, param);
  }

  exports.default = Ember.Component.extend({
    groups: ['Enterprise - Airport', 'Enterprise - Water', 'General Fund', 'CF Districts'],
    locations: [],
    lenses: ['Overview', 'Cashflow', 'Call Options', 'Events', 'Documents', 'Service Providers'],
    subRoutes: ['overview', 'cashflow', 'calloptions', 'events', 'documents', 'providers'],

    selectedLocation: '',
    selectedLens: '',
    selectedGroup: 'All',

    init: function init() {
      this._super();
      //this.set('oid', db.getTable('obligations').find(o => o.ObligationShortName ===
      //  this.get('selectedLocation')).ObligationNumber.toString());
    },


    actions: {
      navToLocation: function navToLocation(loc) {
        this.set('selectedLocation', loc);
        nav.call(this);
      },
      navToLens: function navToLens(lens) {
        this.set('selectedLens', lens);
        nav.call(this);
      },
      navToGroup: function navToGroup(group) {
        this.set('selectedGroup', group);
        nav.call(this);
      }
    }
  });
});