define('pretty-nuts/routes/onboarding/call-options', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      var model = this.modelFor('onboarding');

      // model.callOptions.update();

      // model.uiSteps.set('call-options', true);
      // model.uiSteps.save();

      return model;
    },


    afterModel: function afterModel(model) {
      this.controllerFor('onboarding').updateProgress(model, 'call-options');
    }
  });
});