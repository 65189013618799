define('pretty-nuts/routes/reporting', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    organizationService: Ember.inject.service(),
    obligationService: Ember.inject.service(),

    model: function model(params) {
      var obligationService = this.get('obligationService');
      var organizationService = this.get('organizationService');

      return Ember.RSVP.hash({
        organization: organizationService.getOrganization(params.organizationId),
        debtProfile: obligationService.getOrganizationDebtProfile(params.organizationId)
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    },


    actions: {
      didTransition: function didTransition() {
        this._super();
        this.controller.get('chartRebuild');
      },
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});