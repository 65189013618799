define('pretty-nuts/db', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var tables = ['people', 'organizations', 'obligations', 'securities', 'termbonds', 'cashflows', 'callschedule', 'documents', 'events', 'notifications', 'security-details', 'county-overview-1', 'county-overview-2', 'county-overview-3', 'county-overview-4', 'state-overview-1', 'state-overview-7'];

  function getTableKey(table) {
    return 'demo-data-' + table;
  }

  /**
   * Gets JSON with space in keys removed
   */
  function getJSON(data) {
    return JSON.stringify(data, function (key, value) {
      if (value && (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && !(value instanceof Array)) {
        var replacement = {};
        for (var k in value) {
          if (Object.hasOwnProperty.call(value, k)) {
            var kk = k.split(' ').join('').split('_').join('').replace('&', '').replace(',', '').replace('-', '');
            replacement[kk] = value[k];
          }
        }
        return replacement;
      }
      return value;
    });
  }

  function prepDemo() {
    function processTable(table, proc) {
      var data = getTable(table);
      data.forEach(function (e, i) {
        var d = e;
        d.Id = (i + 1).toString();
        proc && proc(d);
      });
      updateTable(table, data);
    }

    processTable('people', function (p) {
      var u = p;
      u.Password = '123456';
    });
    processTable('documents');
    processTable('obligations');
    processTable('organizations');
  }

  function init() {
    Promise.all(tables.map(function (table) {
      return new Promise(function (resolve) {
        var url = '/data/' + table + '.json';
        var key = getTableKey(table);
        $.get(url, function (data) {
          localStorage.setItem(key, getJSON(data));
          resolve();
        });
      });
    })).then(prepDemo);
  }

  function getTable(table) {
    return JSON.parse(localStorage.getItem(getTableKey(table)));
  }

  function updateTable(table, data) {
    var key = getTableKey(table);
    localStorage.setItem(key, JSON.stringify(data));
  }

  exports.default = { init: init, getTable: getTable, updateTable: updateTable };
});