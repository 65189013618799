define('pretty-nuts/controllers/my-submissions', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		bar: 'bitches',
		'big-number': Ember.computed('bar', function () {
			var bn = new BigNumber(5).plus(97).minus(53).plus(434).times(5435423).add(321453).times(21).div(2).pow(2);
			return bn;
			// 760056543044267246001
		}),
		actions: {
			foo: function foo(a) {
				console.log('value:', $(event)[0].target.value, a);
			}
		}
	});
});