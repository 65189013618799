define('pretty-nuts/routes/government/employees', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    organizationService: Ember.inject.service(),

    model: function model() {
      var employment = this.get('currentUser').getCurrentEmployment();
      var organizationService = this.get('organizationService');
      var governmentModel = this.modelFor('government');
      var organization = governmentModel.organization;

      if (Ember.isNone(employment)) {
        return {};
      }

      return Ember.RSVP.hash({
        organization: organization,
        legalUnits: organizationService.getOrganizationLegalUnits(organization.get('id')),
        employeeUserProfiles: organizationService.getEmployeeUserProfiles(organization.get('id'))
      });
    },


    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }

  });
});