define('pretty-nuts/models/phone', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    phoneId: _emberData.default.attr(),
    userId: _emberData.default.attr(),
    type: _emberData.default.attr('string', {
      defaultValue: 'WORK'
    }),
    number: _emberData.default.attr(),
    countryCode: _emberData.default.attr('string', {
      defaultValue: '+1'
    })
  });
});