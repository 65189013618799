define('pretty-nuts/controllers/login', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    session: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    authapi: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    queryParams: ['userId', 'forgotPassword', 'token'],
    userId: null,
    forgotPassword: null,
    token: null,
    signupDoneErr: false,
    signupDoneMsg: '',
    init: function init() {
      this._super();
      var verifyUser = this.get('verifyUser');
      Ember.run.schedule("afterRender", this, function () {
        var userId = this.get('userId');
        var forgotPassword = this.get('forgotPassword');
        var token = this.get('token');

        if (token !== undefined && token !== null) {
          if (forgotPassword !== undefined && forgotPassword !== null && forgotPassword === 'true') {
            $('#forgotPasswordResetModal').foundation('open');
          } else {
            var authapi = this.get('authapi');
            authapi.verifyUser(token, userId);
            this.set('userId', null);
            this.set('token', null);
          }
        }
      });
    },

    actions: {
      login: function login(params) {
        var currentUser = this.get('currentUser');

        var _getProperties = this.getProperties('email', 'password'),
            email = _getProperties.email,
            password = _getProperties.password;

        var session = this.get('session');
        var notifications = this.get('notifications');
        var controller = this;

        session.authenticate('authenticator:aquornauth', { email: email, password: password }).catch(function (error) {
          // const notification = controller.get('notifications');
          notifications.error("Incorrect username/password.");
        });
      },
      password: function password() {
        $('#password').focus();
      },
      signup: function signup() {
        var _this = this;

        var authapi = this.get('authapi');
        authapi.signup({
          email: this.get('email'),
          firstName: this.get('firstName'),
          lastName: this.get('lastName'),
          password: this.get('password'),
          confirmPassword: this.get('confirmPassword')
        }, function (err, data) {
          if (!err) {
            _this.set('signupFailed', false);
            _this.set('signupDone', true);
            if (data.code !== undefined && data.code != 200) {
              _this.set('signupDoneErr', true);
              _this.set('signupDoneMsg', data.message);
            }
          } else {
            _this.set('signupFailed', true);
            _this.set('errorMessage', JSON.stringify(data.message));
          }
        });
      },
      showLogin: function showLogin() {
        this.set('isSignup', false);
      },
      showSignup: function showSignup() {
        this.set('isSignup', true);
      },
      resetPassword: function resetPassword() {},
      sendForgotPasswordEmail: function sendForgotPasswordEmail(email) {
        var authapi = this.get('authapi');
        authapi.forgotPasswordRequest(email);
      },
      sendResetPassword: function sendResetPassword(resetPassword, confirmResetPassword) {
        var notifications = this.get('notifications');
        if (resetPassword !== confirmResetPassword) {
          notifications.error('Confirm password does not match the new password.');
        } else {
          var authapi = this.get('authapi');
          authapi.resetPasswordRequest(resetPassword, this.get('token'), this.get('userId'));
          this.set('userId', null);
          this.set('token', null);
          this.set('forgotPassword', null);
        }
      }
    }
  });
});