define('pretty-nuts/models/domain-use-of-funds', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('domain-obligation'),

    perspectiveDate: _emberData.default.attr(),

    provider: _emberData.default.belongsTo('organization'),
    serviceProviderId: _emberData.default.attr(),

    type: _emberData.default.attr('enum', { type: _constants.Constants.UseOfFundsType }),
    subType: _emberData.default.attr('enum', { type: _constants.Constants.UseOfFundsSubType }),

    name: _emberData.default.attr(),
    role: _emberData.default.attr(),

    price: _emberData.default.attr(),
    pricePerBond: _emberData.default.attr(),
    parPrice: _emberData.default.attr()
  });
});