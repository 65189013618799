define('pretty-nuts/services/organization-service', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    backend: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    operationService: Ember.inject.service(),
    resourceService: Ember.inject.service(),

    getOrganization: function getOrganization(organizationId) {
      return this.get('store').findRecord('organization', organizationId);
    },
    getOrganizationSubsidiaries: function getOrganizationSubsidiaries(organizationId) {
      return this.get('store').query('organization', {
        'parentOrgId': organizationId,
        'filter': ''
      });
    },
    getOrganizationAddresses: function getOrganizationAddresses(organizationId) {
      return this.get('store').query('address', { organizationId: organizationId });
    },
    getLegalUnit: function getLegalUnit(legalUnitId) {
      return this.get('store').findRecord('legal-unit', legalUnitId);
    },


    // getLegalUnitByAquornId(legalEntityId) {
    //   // return this.get('store').queryRecord('legal-unit', {aquornId: legalEntityId});
    //   // return this.get('store').query('legal-unit', {aquornId: legalEntityId}).then(legalEntities => {
    //   //   return legalEntities[0];
    //   // });
    //   return this.get('store').findRecord('domain-legal-entity', legalEntityId);
    // },

    getLegalUnitsById: function getLegalUnitsById(legalUnitIds) {
      return this.get('store').query('legal-unit', { legalUnitIds: legalUnitIds.join() });
    },
    getRoleRequests: function getRoleRequests(organizationId) {
      return this.get('store').query('organization-role-request', { organizationId: organizationId });
    },
    getUserRoleRequests: function getUserRoleRequests(organizationId) {
      return this.get('store').query('organization-user-role-request', { organizationId: organizationId });
    },
    getMyRolesRequests: function getMyRolesRequests(userId, status) {
      var filters = {};

      //For aquorn admin, any regular user will only see there own
      if (userId) {
        filters['userId'] = userId;
      }

      //Allowed values - Pending, Approved, Denied, Cancelled
      if (status) {
        filters['status'] = status;
      }

      return this.get('store').query('my-role-request', filters);
    },
    getOrganizationUserRoles: function getOrganizationUserRoles(organizationId) {
      return this.get('store').query('organization-user-role', { organizationId: organizationId });
    },
    getOrganizationMembers: function getOrganizationMembers(organizationId) {
      return this.getOrganizationUserRoles(organizationId).then(function (roles) {
        var members = [];

        if (!Ember.isNone(roles)) {
          roles.forEach(function (role) {
            members.pushObject(role.get('user'));
          });
        }

        return Ember.RSVP.all(members).then(function (values) {
          return values;
        });
      });
    },
    getEmployeeUserProfiles: function getEmployeeUserProfiles(organizationId) {
      var _this = this;

      return this.getOrganizationMembers(organizationId).then(function (members) {
        return members.map(function (member) {
          return _this.get('store').findRecord('integrated-user-profile', member.get('id'));
        });
      });
    },
    requestUserOrganizationRole: function requestUserOrganizationRole(user, organization, role) {
      var roleRequest = this.get('store').createRecord('organization-role-request', {
        requestType: 'Request',
        requestorId: user,
        requestorType: 'User',
        objectId: organization,
        objectType: 'Organization',
        role: role
      });
      return roleRequest.save().then(function () {
        return roleRequest;
      });
    },
    inviteUserOrganizationRole: function inviteUserOrganizationRole(user, organization, role) {
      var roleRequest = this.get('store').createRecord('organization-role-request', {
        requestType: 'Invitation',
        requestorId: user,
        requestorType: 'User',
        objectId: organization,
        objectType: 'Organization',
        role: role
      });
      return roleRequest.save().then(function () {
        return roleRequest;
      });
    },
    requestUserOrganizationRole2: function requestUserOrganizationRole2(roleRequest) {
      var roleRequest = this.get('store').createRecord('organization-role-request', roleRequest);

      return roleRequest.save().then(function () {
        return roleRequest;
      });
    },
    approveRoleRequest: function approveRoleRequest(roleRequest) {
      var backend = this.get('backend');
      var store = this.get('store');

      return backend.post({
        url: '/organizationRoleRequests/' + roleRequest.get('id') + '/approve'
      }).then(function (response) {
        // Refresh the role request attributes
        return roleRequest.reload().then(function () {
          return {
            role: store.createRecord('role', response.data),
            roleRequest: roleRequest
          };
        });
      });
    },


    /**
     *
     * @param roleRequest
     */
    rejectRoleRequest: function rejectRoleRequest(roleRequest) {
      var backend = this.get('backend');
      var store = this.get('store');

      return backend.post({
        url: '/organizationRoleRequests/' + roleRequest.get('id') + '/reject'
      }).then(function (response) {
        // Refresh the role request attributes
        return roleRequest.reload();
      });
    },
    removeUserOrganizationRole: function removeUserOrganizationRole(role) {
      return role.destroyRecord();
    },
    getOrganizationLegalUnits: function getOrganizationLegalUnits(organizationId) {
      return this.get('store').query('legal-unit', { organizationId: organizationId });
    },
    addLegalUnitToOrganization: function addLegalUnitToOrganization(organization, legalUnit) {
      legalUnit.set('organization', organization);

      return legalUnit.save();
    },


    /**
     * Split a flat roles array for an organization's user roles into a key value object
     * mapping by user.
     *
     * {
     *   @user1: [@role, @role, ...],
     *   @user2: [@role, @role, ...],
     *   ...
     * }
     *
     * @param roles
     * @returns {}
     */
    splitRolesByUser: function splitRolesByUser(roles) {
      var rolesByUserId = {};

      roles.forEach(function (role) {
        var user = role.get('user');

        if (Ember.isNone(rolesByUserId[user.get('id')])) {
          rolesByUserId[user.get('id')] = {
            user: user,
            roles: []
          };
        }

        rolesByUserId[user.get('id')].roles.pushObject(role);
      });

      var userRoles = [];

      for (var key in rolesByUserId) {
        userRoles.pushObject(rolesByUserId[key]);
      }

      return userRoles;
    },
    splitRoleRequestsByUser: function splitRoleRequestsByUser(roleRequests) {
      var roleReq = [];

      roleRequests.forEach(function (roleRequest) {
        console.log('role requests', roleRequest.serialize());
        console.log('role requests', roleRequest.toJSON());
        var user = roleRequest.get('requestorId');
        var org = roleRequest.get('objectId');
        if (Ember.isNone(roleReq[user.get('id')])) {
          roleReq[user.get('id')] = {
            user: user,
            roleRequests: [],
            org: org,
            role: null
          };
        }
        roleReq[user.get('id')].roleRequests.pushObject(roleRequest);
      });

      var userRoleRequests = [];
      Object.keys(roleReq).forEach(function (userId) {
        userRoleRequests.pushObject(roleReq[userId]);
      });

      return userRoleRequests;
    },
    splitRolesAndRequestsByUser: function splitRolesAndRequestsByUser(roles, roleRequests) {
      var _this2 = this;

      var rolesAndRequestsByUserId = {};

      roles.forEach(function (role) {
        var user = role.get('user');

        if (Ember.isNone(rolesAndRequestsByUserId[user.get('id')])) {
          rolesAndRequestsByUserId[user.get('id')] = {
            user: user,
            emails: _this2.get('store').query('email', { userId: user.get('id') }),
            roles: [],
            roleRequests: [],
            org: null,
            role: null
          };
        }

        rolesAndRequestsByUserId[user.get('id')].roles.pushObject(role);
      });
      roleRequests.forEach(function (roleRequest) {
        var user = roleRequest.get('requestorId');
        var org = roleRequest.get('objectId');
        if (Ember.isNone(rolesAndRequestsByUserId[user.get('id')])) {
          rolesAndRequestsByUserId[user.get('id')] = {
            user: user,
            emails: _this2.get('store').query('email', { userId: user.get('id') }),
            roles: [],
            roleRequests: [],
            org: org,
            role: roleRequest.get('role')
          };
        }
        rolesAndRequestsByUserId[user.get('id')].roleRequests.pushObject(roleRequest);
      });

      var userRolesAndRequests = [];
      Object.keys(rolesAndRequestsByUserId).forEach(function (userId) {
        userRolesAndRequests.pushObject(rolesAndRequestsByUserId[userId]);
      });

      userRolesAndRequests.forEach(function (userRolesAndRequest) {
        userRolesAndRequest['rolesList'] = userRolesAndRequest.roles.map(function (role) {
          return role.get('role');
        }).join(', ');
        userRolesAndRequest['roleRequestsList'] = userRolesAndRequest.roleRequests.map(function (roleRequest) {
          return roleRequest.get('role');
        }).join(', ');
      });

      return userRolesAndRequests;
    },
    getLegalUnitDocuments: function getLegalUnitDocuments(legalUnitId) {
      return this.get('store').query('document', { legalUnitId: legalUnitId });
    },
    getOrganizationDocuments: function getOrganizationDocuments(organizationId) {
      return this.get('store').query('document', { organizationId: organizationId });
    },
    getOrganizationByDomain: function getOrganizationByDomain(domain) {}
  });
});