define('pretty-nuts/components/nav-tools', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend(_defineProperty({
    actions: {
      logout: function logout() {
        this.get('onLogout')();
      }
    }
  }, 'actions', {
    foo: function foo() {
      console.log(this);
    }
  }));
});