define('pretty-nuts/components/organization-reporting-bread-crumb', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var alias = Ember.computed.alias,
	    observer = Ember.observer;
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		organizationService: Ember.inject.service(),
		obligationService: Ember.inject.service(),

		currentRouteName: alias('router.currentRouteName'),
		routerState: alias('router.currentState.routerJsState'),

		init: function init() {
			this._super.apply(this, arguments);
			this.buildBreadCrumbs();
		},


		fileStateObserver: function () {
			if (this.get('currentRouteName') == 'reporting.index' || this.get('currentRouteName') == 'reporting.cashflow') {
				this.buildBreadCrumbs();
			}
		}.observes('routerState'),

		buildBreadCrumbs: function buildBreadCrumbs() {
			var organizationService = this.get('organizationService');
			var organizationId = this.get('routerState.params')['reporting']['organizationId'];

			this.set('organization', organizationService.getOrganization(organizationId));
		},

		actions: {
			onSelectionChange: function onSelectionChange(item) {
				this.get('router').transitionTo(item[1], { queryParams: item[2] });
			}
		}
	});
});