define('pretty-nuts/authenticators/aquornauth', ['exports', 'ember-simple-auth/authenticators/base', 'pretty-nuts/constants'], function (exports, _base, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    /**
     * A session is currently saved, check that it is still valid and active,
     * by calling the auth touch endpoint to determine whether it is successful
     * with the existing session state. The session is then resolved as valid if
     * no error occurred, otherwise the session state is considered invalid.
     *
     * @param storedData The stored session data
     */
    restore: function restore(storedData) {
      // const url = '/api/auth/check';
      var url = _constants.Constants.API_PREFIX + '/auth/check';

      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.get({
          url: url,
          headers: {
            'SESSION': storedData.sessionId
          }
        }).done(function (response) {
          var sessionData = {
            sessionId: storedData.sessionId,
            userId: Ember.get(response, 'user.id'),
            userAquornId: Ember.get(response, 'user.userId'),
            isAquornAdmin: response.aquornAdmin,
            accessStructures: response.userAccessStructures
          };

          resolve(sessionData);
        }).fail(function (xhr) {
          reject('Session no longer active');
        });
      });
    },


    /**
     * Custom authenticate method for retrieve the credentials from the UI controls
     * and sending them to the server for authentication.
     *
     * @param options
     */
    authenticate: function authenticate(options) {
      // const url = '/api/auth/login';
      var url = _constants.Constants.API_PREFIX + '/auth/login';
      var that = this;

      // The JSON request to send to the server as the credentials
      var credentials = {
        email: options.email,
        password: options.password,
        source: 'AQUORN'
      };

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return $.post({
          url: url,
          data: JSON.stringify(credentials),
          headers: {
            'Content-Type': 'application/json'
          }
        }).done(function (data, error, response) {
          return that.restore({
            'sessionId': response.getResponseHeader('session')
          }).then(function (session_response) {
            resolve(session_response);
          });
        }).fail(function (xhr, status, error) {
          console.log('Authentication error:', error);
          console.log('Authentication status:', status);
          console.log('Authentication responseText:', xhr.responseText);

          reject('Auth failed');
        });
      });
    },


    /**
     * Invalidate the local session by telling the server to logout
     *
     * @param data
     */
    invalidate: function invalidate(storedData) {
      // const url = '/api/auth/logout';
      var url = _constants.Constants.API_PREFIX + '/auth/logout';

      return new Ember.RSVP.Promise(function (resolve, reject) {
        $.post({
          url: url,
          headers: {
            'SESSION': storedData.sessionId
          }
        }).done(function (response) {
          resolve('Logout ok');
        }).fail(function (xhr) {
          reject('Logout problem');
        });
      });
    }
  });
});