define('pretty-nuts/routes/issuer', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    organizationService: Ember.inject.service(),
    obligationService: Ember.inject.service(),

    model: function model(params) {
      var _this = this;

      var legalUnitId = params.legalUnitId;
      var organizationService = this.get('organizationService');
      var obligationService = this.get('obligationService');
      this.set('legalUnit', organizationService.getLegalUnit(legalUnitId)).then(function (legalUnit) {
        console.log('ida1', legalUnit.get('organization').get('id'));
        console.log('ida2', legalUnit.get('organization').get('aquornId'));
        _this.set('parent', legalUnit.get('organization'));
      });
      return Ember.RSVP.hash({
        legalUnit: this.get('legalUnit')
      }).then(function (model) {
        console.log('id1', Ember.get(model, 'legalUnit.organization.id'));
        console.log('id2', Ember.get(model, 'legalUnit.organization.id'));

        return Ember.RSVP.hash({
          debtProfile: obligationService.getObligorDebtProfile(Ember.get(model, 'legalUnit.id')),
          parentOrgAddresses: organizationService.getOrganizationAddresses(Ember.get(model, 'legalUnit.organization.id'))
        }).then(function (model2) {
          model.legalEntityId = params.legalUnitId;
          model.organization = model.legalUnit.get('organization');
          model.debtProfile = model2.debtProfile;
          model.integratedObligations = model2.integratedObligations;
          model.parentOrgAddresses = model2.parentOrgAddresses;
          return model;
        });
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    }
  });
});