define('pretty-nuts/models/contextual-obligation', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    // obligation: DS.belongsTo('obligation'),
    obligation: _emberData.default.attr('embedded-model', { modelName: 'obligation' }),

    obligationId: _emberData.default.attr(),

    perspectiveDate: _emberData.default.attr(),

    outstandingPrincipal: _emberData.default.attr()

    // obligation: null,

    // obligation: Ember.computed('obligationId', 'perspectiveDate', function () {
    //   const obligationId = this.get('obligationId');
    //   const perspectiveDate = this.get('perspectiveDate');
    //
    //   if (!obligationId || !perspectiveDate) {
    //     return null;
    //   }
    //
    //   const store = this.get('store');
    //
    //   return store.queryRecord('obligation', {
    //     id: obligationId,
    //     perspectiveDate: perspectiveDate
    //   });
    // }),

    // obligationObserver: function () {
    //   const obligationId = this.get('obligationId');
    //   const perspectiveDate = this.get('perspectiveDate');
    //
    //   if (!obligationId || !perspectiveDate) {
    //     return null;
    //   }
    //
    //   const store = this.get('store');
    //
    //   let v = store.queryRecord('obligation', {
    //     id: obligationId,
    //     perspectiveDate: perspectiveDate
    //   });
    //
    //   this.set('obligation', v);
    // }.observes('obligationId', 'perspectiveId')

  });
});