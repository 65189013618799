define('pretty-nuts/helpers/is-org-admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Helper.extend({
    currentUser: Ember.inject.service('current-user'),

    compute: function compute(params) {
      var currentUser = this.get('currentUser');

      var organizationId = params[0];

      if (!organizationId && currentUser.getMyOrganization() != null) {
        organizationId = Ember.get(currentUser.getMyOrganization(), 'id');
      }

      return currentUser.hasAccess(organizationId, 'springapp/organizations/roles/approveRequest');
    }
  });
});