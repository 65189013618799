define('pretty-nuts/components/tabbed-obligation-list', ['exports', 'pretty-nuts/constants', 'ember-uuid'], function (exports, _constants, _emberUuid) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    Constants: _constants.Constants,

    application: null,
    canDelete: null,
    tabsId: null,
    legalUnitId: null,

    pendingStatuses: [_constants.Constants.ObligationStatus.PENDING, _constants.Constants.ObligationStatus.SOLD],
    outstandingStatuses: [_constants.Constants.ObligationStatus.OUTSTANDING, _constants.Constants.ObligationStatus.PARTIALLY_REFUNDED],
    // retiredStatuses: [Constants.ObligationStatus.PAID, Constants.ObligationStatus.REFUNDED],
    retiredStatuses: [_constants.Constants.ObligationStatus.RETIRED],

    pendingObligations: [],
    outstandingObligations: [],
    retiredObligations: [],

    // State //
    ///////////

    copyStatusOverviews: function copyStatusOverviews(obligationOverviews, statuses) {
      var statusOverviews = [];

      statuses.forEach(function (status) {
        var overviews = obligationOverviews[status.jsonValues[0]];

        if (overviews) {
          statusOverviews = statusOverviews.concat(overviews);
        }
      });

      return statusOverviews;
    },


    obligationsObserver: function () {

      var obligationOverviews = this.get('obligationOverviews');

      console.log('obligationObserver', obligationOverviews);

      if (obligationOverviews) {
        console.log('filtering obligations');
        var pendingObligations = this.copyStatusOverviews(obligationOverviews, this.get('pendingStatuses'));
        var outstandingObligations = this.copyStatusOverviews(obligationOverviews, this.get('outstandingStatuses'));
        var retiredObligations = this.copyStatusOverviews(obligationOverviews, this.get('retiredStatuses'));

        this.set('pendingObligations', pendingObligations);
        this.set('outstandingObligations', outstandingObligations);
        this.set('retiredObligations', retiredObligations);
      }
    }.observes('obligationOverviews').on('init'),

    init: function init() {
      console.log('INIT tabbed-obligation-list');
      this._super.apply(this, arguments);
      this.set('tabsId', (0, _emberUuid.v4)());
      window.setTimeout(function () {
        window.scrollTo(0, 1);
      }, 100);
      if (this.get('legalUnitId')) {
        this.set('tabsId', 'tabs-' + this.get('legalUnitId'));
      } else {
        this.set('tabsId', 'tabs-' + Math.random());
      }
      $('.tabbed-obligation-list').on('change.zf.tabs', function () {
        console.log('scrolly', window.scrollY + 1);
        window.setTimeout(function () {
          window.scrollTo(0, window.scrollY + 1);
        }, 100);
      });
    }
  });
});