define('pretty-nuts/models/organization', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    legacyId: _emberData.default.attr(),
    aquornId: _emberData.default.attr(),
    type: _emberData.default.attr(),
    name: _emberData.default.attr(),
    companyUrl: _emberData.default.attr(),
    // companyLogo: DS.belongsTo('resource'),
    logoResourceAquornId: _emberData.default.attr(),
    description: _emberData.default.attr(),
    industry: _emberData.default.attr(),
    companySize: _emberData.default.attr(),
    foundedDate: _emberData.default.attr(),
    services: _emberData.default.attr(),
    parent: _emberData.default.belongsTo('organization', { inverse: null }),
    verified: _emberData.default.attr(),
    verifiedByUser: _emberData.default.belongsTo('user'),
    verifiedByOrganization: _emberData.default.belongsTo('organization', { inverse: null }),
    otherInfo: _emberData.default.attr('json-blob'),
    operatingStatus: _emberData.default.attr(),
    category: _emberData.default.attr(),
    subCategory: _emberData.default.attr(),
    phoneNumber: _emberData.default.attr(),
    email: _emberData.default.attr(),

    legalUnits: _emberData.default.hasMany('legal-unit'),

    logoImageUrl: null,

    logoImageObserver: function () {
      if (this.get('logoResourceAquornId')) {
        this.set('logoImageUrl', _constants.Constants.RESOURCE_LOCATION + '/' + this.get('logoResourceAquornId'));
      }
    }.observes('logoResourceAquornId').on('init'),

    /**
     * Name, image url, and details for query select boxes
     */
    imageUrl: Ember.computed('logoImageUrl', function () {
      return this.get('logoImageUrl');
    }),

    details: Ember.computed('', function () {
      return null;
    })
  });
});