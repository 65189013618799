define('pretty-nuts/components/report-tools', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      edit: function edit(item) {
        $('#' + item + ' .display').hide();
        $('#' + item + ' .edit').show();
      },
      display: function display(item) {
        $('#' + item + ' .display').show();
        $('#' + item + ' .edit').hide();
      }
    }
  });
});