define('pretty-nuts/models/refunding', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({

    creator: _emberData.default.belongsTo('user'),
    createdTime: _emberData.default.attr(),
    lastUpdated: _emberData.default.attr(),
    refundedSecurityAquornId: _emberData.default.attr(),
    refundingSecurity: _emberData.default.belongsTo('security'),
    callOption: _emberData.default.belongsTo('call-option'),
    refundedAmount: _emberData.default.attr(),
    refundDate: _emberData.default.attr(),
    callPrice: _emberData.default.attr(),

    // refundedSecurity: DS.attr('model-query', {modelName: 'security'})

    refundedSecurityObserver: function () {
      var refundedSecurityAquornId = this.get('refundedSecurityAquornId');

      console.log('firing');

      if (!refundedSecurityAquornId) {
        return null;
      }

      var that = this;

      this.store.query('security', { aquornId: refundedSecurityAquornId, draft: false }).then(function (securities) {
        that.notifyPropertyChange('');
        var security = securities.objectAt(0);
        // console.log('security', security);
        Ember.set(that, 'refundedSecurity', security);
      });
    }.observes('refundedSecurityAquornId').on('init')

  });
});