define('pretty-nuts/serializers/obligation-archive', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'archiveId',

    attrs: {
      obligation: 'obligationId'
    }
  });
});