define('pretty-nuts/controllers/aquorn-admin/access-rules', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    notifications: Ember.inject.service('notification-messages'),
    permissions: [{ role: 'Guest', type: 'option' }, { role: 'Member', type: 'option' }, { role: 'Submitter', type: 'option' }, { role: 'Approver', type: 'option' }, { role: 'Admin', type: 'option' }, { role: 'Owner', type: 'option' }],
    actions: {
      remove: function remove(item) {
        $('#' + item).hide();
      },
      addAction: function addAction() {
        $('#new_action').show();
      },
      saveNewAction: function saveNewAction() {
        $('#new_action').hide();
      },
      setPermission: function setPermission(item, where) {
        console.log('setPermission', 'where:', where);
        console.log('item:', item.role);
        this.set(where, item.role);
      },
      display: function display() {}
    }
  });
});