define('pretty-nuts/controllers/onboarding/attributes', ['exports', 'pretty-nuts/constants', 'pretty-nuts/changeset'], function (exports, _constants, _changeset) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    onboarding: Ember.inject.controller(),
    purposes: ['New Money', 'Mixed Use', 'Refunding'],
    structures: ['Fixed Rate', 'Variable Rate'],
    // sale_methods: ['Competitive', 'Negotiated'],
    sale_methods: [_constants.Constants.SaleType.COMPETITIVE, _constants.Constants.SaleType.NEGOTIATED],
    // offerings: ['Public', 'Private'],
    offerings: [_constants.Constants.OfferingType.PRIVATE, _constants.Constants.OfferingType.PUBLIC],
    // amt: ['AMT', 'Non-AMT'],
    amt: [_constants.Constants.AMTStatus.AMT, _constants.Constants.AMTStatus.Non_AMT],
    // taxStatusOptions: ['Tax-Exempt', 'Taxable'],
    taxStatusOptions: [_constants.Constants.TaxStatus.TAX_EXEMPT, _constants.Constants.TaxStatus.TAXABLE],
    // securityPledgeOptions: ['General Obligation', 'Revenue', 'Other'],
    securityPledgeOptions: [_constants.Constants.SecurityPledge.GENERAL_OBLIGATION, _constants.Constants.SecurityPledge.REVENUE],
    // day_basis: ['30/360', 'Actual/360', 'Actual/365', 'Actual/Actual'],
    day_basis: [_constants.Constants.DayBasis.DB_30_360, _constants.Constants.DayBasis.DB_ACTUAL_360, _constants.Constants.DayBasis.DB_ACTUAL_365, _constants.Constants.DayBasis.DB_ACTUAL_ACTUAL],
    generalUseOfProceedsOptions: ["Airports", "Combined Utilities", "Economic Development", "Education", "Public Power", "General Purpose", "Healthcare", "Housing", "Industrial Development", "Nursing Homes/Life care", "Pollution Control", "Seaports/Marine Terminals", "Solid Waste/Resource Recovery", "Student Loans", "Transportation", "Water, Sewer & Gas Facilities"],
    mainUseOfProceedsOptions: [],
    useOfProceedsOptionsMapping: {
      "Airports": ["Airports"],
      "Combined Utilities": ["Combined Utilities"],
      "Economic Development": ["Economic Development"],
      "Education": ["Primary & Secondary Education", "Higher Education", "Libraries & Museums", "Other Education"],
      "Public Power": ["General Power", "Renewable Facilities"],
      "General Purpose": ["Agriculture", "Churches, Temples & Mosques", "Civic & Convention Centers", "Correctional Faclities", "Fire Stations & Equipment", "General Purpose", "Government Buildings", "Parks, Zoos & Beaches", "Other Recreation", "Police Stations & Equipment", "Stadiums & Sports Complexes", "Telecommunications", "Theatres", "Veterans (Excluding Housing)"],
      "Healthcare": ["General Medical", "General Acute Care Hospital", "Childrens Hospital", "Single Specialty Hospital", "Hospital Equipment Loans"],
      "Housing": ["Single Family", "Multi-Family"],
      "Industrial Development": ["Industrial Development", "Office Buildings"],
      "Nursing Homes/Life care": ["Assisted Living", "Continuous Care Retirement Facilities", "Nursing Homes"],
      "Pollution Control": ["Pollution Control"],
      "Seaports/Marine Terminals": ["Seaports/Marine Terminals"],
      "Solid Waste/Resource Recovery": ["Recycling", "Sanitation", "Solid Waste"],
      "Student Loans": ["Student Loans"],
      "Transportation": ["Bridges", "Mass Transportation", "Parking Facilities", "Highways & Streets", "Toll Roads", "Tunnels", "Other Transportation"],
      "Water, Sewer & Gas Facilities": ["Flood Control", "Gas", "Gas", "Water & Sewer Facilities"]
    },

    ratingAgencyOptions: ['Moody\'s', 'S&P', 'Fitch', 'KBRA'],

    moodysLongRatings: ['Aaa', 'Aa1', 'Aa2', 'Aa3', 'A1', 'A2', 'A3', 'Baa1', 'Baa2', 'Baa3', 'Ba1', 'Ba2', 'Ba3', 'B1', 'B2', 'B3', 'Caa1', 'Caa2', 'Caa3', 'Ca', 'C'],

    moodysShortRatings: ['P-1', 'P-2', 'P-3', 'Not prime'],

    spLongRatings: ['AAA', 'AA+', 'AA', 'AA−', 'A+', 'A', 'A−', 'BBB+', 'BBB', 'BBB−', 'BB+', 'BB', 'BB−', 'B+', 'B', 'B−', 'CCC+', 'CCC', 'CCC−', 'CC', 'C', 'D'],

    spShortRatings: ['A-1+', 'A-1', 'A-2', 'A-3', 'B', 'C'],

    fitchLongRatings: ['AAA', 'AA+', 'AA', 'AA−', 'A+', 'A', 'A−', 'BBB+', 'BBB', 'BBB−', 'BB+', 'BB', 'BB−', 'B+', 'B', 'B−', 'CCC', 'DDD', 'DD', 'D'],

    fitchShortRatings: ['F1+', 'F1', 'F2', 'F3', 'B', 'C'],

    kbraLongRatings: ['AAA', 'AA+', 'AA', 'AA−', 'A+', 'A', 'A−', 'BBB+', 'BBB', 'BBB−', 'BB+', 'BB', 'BB−', 'B+', 'B', 'B−', 'CCC+', 'CCC', 'CCC−', 'CC', 'C', 'D'],

    kbraShortRatings: ['K1+', 'K1', 'K2', 'K3', 'B', 'C', 'D'],

    obligation: null,

    modelObserver: function () {
      var obligation = this.get('model.obligation');
      this.set('obligation', _changeset.default.create({ source: obligation }));
    }.observes('model', 'model.obligation'),

    proceedsObserver: function () {
      var obligation = this.get('model.integratedObligation.value.obligation');

      if (!obligation) {
        return;
      }

      var generalUseOfProceeds = obligation.get('generalUseOfProceeds');
      var mainUseOfProceeds = obligation.get('mainUseOfProceeds');
      var mapping = this.get('useOfProceedsOptionsMapping');
      var mainUseOfProceedsOptions = mapping[generalUseOfProceeds];

      this.set('mainUseOfProceedsOptions', mainUseOfProceedsOptions);

      // if(mainUseOfProceedsOptions.indexOf(mainUseOfProceeds) == -1) {
      //   this.set('obligation.mainUseOfProceeds', null);
      // }
    }.observes('model.integratedObligation.value.obligation.generalUseOfProceeds').on('init'),

    ratingTermObserver: function () {
      var structureType = this.get('obligation.structureType');

      var term = _constants.Constants.LONG_TERM;

      if (structureType === _constants.Constants.VARIABLE_RATE) {
        term = _constants.Constants.SHORT_TERM;
      }

      this.set('ratingTerm', term);

      if (term === _constants.Constants.SHORT_TERM) {
        this.set('kbraRatings', this.ratingsOptions(this.get('kbraShortRatings')));
        this.set('fitchRatings', this.ratingsOptions(this.get('fitchShortRatings')));
        this.set('spRatings', this.ratingsOptions(this.get('spShortRatings')));
        this.set('moodysRatings', this.ratingsOptions(this.get('moodysShortRatings')));
      } else {
        this.set('kbraRatings', this.ratingsOptions(this.get('kbraLongRatings')));
        this.set('fitchRatings', this.ratingsOptions(this.get('fitchLongRatings')));
        this.set('spRatings', this.ratingsOptions(this.get('spLongRatings')));
        this.set('moodysRatings', this.ratingsOptions(this.get('moodysLongRatings')));
      }
    }.observes('model.integratedObligation.value.obligation.structureType').on('init'),

    ratingsOptions: function ratingsOptions(ratings) {
      var array = ratings.map(function (rating) {
        return { label: rating, value: rating };
      });
      array.unshift({ label: "Not Rated", value: null });
      return array;
    },

    actions: {
      setPurpose: function setPurpose(value) {
        if (value === 'Refunding' || value === 'Mixed Use') {
          this.get('onboarding').send('isrefunding', true);
        } else {
          this.get('onboarding').send('isrefunding', false);
        }

        console.log("Setting purpose not supported: " + value);
      },
      actionSaveAndTransition: function actionSaveAndTransition() {
        var onboardingController = this.get('onboarding');
        var integratedObligation = this.get('model.integratedObligation');

        integratedObligation.saveAttributes(['obligation.saleType', 'obligation.offeringType', 'obligation.securityPledge', 'obligation.federalTaxStatus', 'obligation.stateTaxStatus', 'obligation.amtStatus', 'obligation.generalUseOfProceeds', 'obligation.mainUseOfProceeds', 'obligation.moodysRating', 'obligation.spRating', 'obligation.fitchRating', 'obligation.kbraRating']).then(function () {
          onboardingController.next('attributes');
        });
        // this.get('onboarding.saveObligationAndTransitionTask').perform('attributes', obligation)
      },
      deleteObligation: function deleteObligation(obligation) {
        this.get('onboarding').send('deleteObligation', obligation);
      }
    }
  });
});