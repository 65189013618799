define("pretty-nuts/services/resource-service", ["exports", "pretty-nuts/constants", "ember-concurrency"], function (exports, _constants, _emberConcurrency) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Service.extend({

    backend: Ember.inject.service(),
    store: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    session: Ember.inject.service(),
    operationService: Ember.inject.service(),

    /**
     * Given an absolute file path, determine the basename
     *
     * source: stackoverflow.com/questions/423376/how-to-get-the-file-name-from-a-full-path-using-javascript
     *
     * @param path the absolute path
     */
    baseName: function baseName(path) {

      return path.replace(/^.*[\\\/]/, '');
    },


    // /**
    //  * Create a new resource and upload the data
    //  *
    //  * @param type the type of resource to create: AVATAR, ORGANIZATION_LOGO, etc...
    //  * @param formData the form containing the file data to upload
    //  */
    // createResource(type, formData) {
    //   let userId = this.get('currentUser').getUserId();
    //
    //   let resourceInit = {
    //     userId: userId,
    //     resourceType: type
    //   };
    //
    //   // Wrap the local form data into transmittable form data
    //   let form = new FormData(formData.get(0));
    //
    //   // Create the resource record
    //   let resource = this.get('store').createRecord('resource', resourceInit);
    //
    //   return resource.save().then(function () {
    //
    //     // Upload the resource data
    //     return Ember.$.ajax({
    //       method: 'POST',
    //       url: '/api/upload/uploadResource?resourceId=' + resource.get('id'),
    //       data: form,
    //       processData: false,
    //       contentType: false
    //     }).then(function () {
    //
    //       // Update the resource state after upload
    //       return resource.reload();
    //     });
    //   });
    // },
    //
    // addObligationDocument(obligationId) {
    //   const that = this;
    //   const backend = this.get('backend');
    //
    //   //const url = '/obligations/${obligationId}/uploadDocument';
    //   const url = '/obligations/' + obligationId + '/uploadDocument';
    //
    //   return backend.post({
    //     url: url
    //
    //   }).then((response) => {
    //     console.log('response', response);
    //     return that.get('store').findRecord('operation', response.data.id);
    //   });
    // },
    //
    // addLegalUnitDocument(legalUnitId) {
    //   const that = this;
    //   const backend = this.get('backend');
    //
    //   //const url = '/obligations/${obligationId}/uploadDocument';
    //   const url = '/legalUnit/' + legalUnitId + '/uploadDocument';
    //
    //   return backend.post({
    //     url: url
    //
    //   }).then((response) => {
    //     console.log('response', response);
    //     return that.get('store').findRecord('operation', response.data.id);
    //   });
    // },
    //
    // addOrganizationDocument(organizationId) {
    //   const that = this;
    //   const backend = this.get('backend');
    //
    //   const url = '/organizations/' + organizationId + '/uploadDocument';
    //
    //   return backend.post({
    //     url: url
    //   }).then((response) => {
    //     return that.get('store').findRecord('operation', response.data.id);
    //   });
    // },

    deleteDocument: function deleteDocument(document) {
      var backend = this.get('backend');
      var that = this;

      var url = '/documents/' + document.get('id');

      return backend.delete({
        url: url
      }).then(function (response) {
        return that.get('store').findRecord('operation', response.data.id);
      });
    },
    uploadUserAvatar: function uploadUserAvatar(file, userId, completion_method) {
      var params = {
        objectId: userId,
        objectType: _constants.Constants.ResourceObjectType.USER
      };

      return this.uploadAvatarOperation(file, params, completion_method);
    },
    uploadOrganizationLogo: function uploadOrganizationLogo(file, organizationId, completion_method) {

      var params = {
        objectId: organizationId,
        objectType: _constants.Constants.ResourceObjectType.ORGANIZATION
      };

      return this.uploadAvatarOperation(file, params, completion_method);

      // const params = {
      //   type: 'ORGANIZATION_AVATAR',
      //   organizationId: organizationId
      // };
      //
      // return this.uploadAvatarOperation(file, params);
    },
    uploadLegalEntityLogo: function uploadLegalEntityLogo(file, legalEntityId, completion_method) {
      var params = {
        objectId: legalEntityId,
        objectType: _constants.Constants.ResourceObjectType.LEGAL_ENTITY
      };

      return this.uploadAvatarOperation(file, params, completion_method);
    },
    uploadAvatarOperation: function uploadAvatarOperation(file, params, completion_method) {

      var operationService = this.get('operationService');

      return this.get('backend').request({
        type: 'POST',
        url: '/resources',
        data: JSON.stringify({
          data: {
            objectId: params.objectId,
            objectType: params.objectType.jsonValues[0],
            key: file.get('key')
          }
        })
      }).then(function (response) {
        try {
          return operationService.handleDeferredOperationResponse2(response, _constants.Constants.OperationStatusResolver.UPLOAD_AVATAR, completion_method);
        } catch (e) {
          console.error(e);
        }
      });

      // let resource = this.get('store').createRecord('resource', {
      //   location: file.key,
      //   legalEntityId: params.legalEntityId,
      //   organizationId: params.organizationId,
      //   userId: params.userId
      // });
      //
      // return this.get('operationService').operationModelCreate(resource);
    },
    uploadObligationDocument: function uploadObligationDocument(file, obligation, additionalParams) {
      var params = {
        type: 'OBLIGATION_DOCUMENT',
        obligation: obligation
      };

      return this.uploadDocumentOperation(file, Object.assign(params, additionalParams));
    },
    uploadOrganizationDocument: function uploadOrganizationDocument(file, organization, additionalParams) {
      var params = {
        type: 'ORGANIZATION_DOCUMENT',
        organization: organization
      };

      return this.uploadDocumentOperation(file, Object.assign(params, additionalParams));
    },
    uploadLegalEntityDocument: function uploadLegalEntityDocument(file, legalEntity, additionalParams) {
      var params = {
        type: 'LEGAL_ENTITY_DOCUMENT',
        legalEntity: legalEntity
      };

      return this.uploadDocumentOperation(file, Object.assign(params, additionalParams));
    },
    uploadDocumentOperation: function uploadDocumentOperation(file, params, headers) {
      var _store$createRecord;

      var session = this.get('session');
      var store = this.get('store');

      params['documentDate'] = moment(params['documentDate']).format('YYYY-MM-DD') + "T00:00:00.000";
      console.log('document params', params);
      // console.log('file data', file);

      var document = store.createRecord('document', (_store$createRecord = {
        key: file.key,
        // documentType: params.type,
        documentName: file.filename,
        legalEntityId: params.legalEntityId,
        // obligationId: params.obligationId,
        organization: params.organization,
        // documentCategory: params.documentCategory,
        categoryId: params.categoryId,
        documentType: params.documentType,
        documentDate: params.documentDate,
        obligationId: params.obligationId,
        preparerOrg: params.preparerOrg,
        preparerUser: params.preparerUser
      }, _defineProperty(_store$createRecord, "documentDate", params.documentDate), _defineProperty(_store$createRecord, "description", params.description), _store$createRecord));

      console.log('uploadDocumentOperation', document.toJSON());

      return this.get('operationService').operationModelCreate(document);
    },
    getSignedDocumentViewRequest: function getSignedDocumentViewRequest(documentId) {
      var backend = this.get('backend');

      return backend.get({
        url: "/documents/" + documentId + "/view"
      }).then(function (response) {
        console.log('response.data', response.data);
        return response.data;
      });
    },
    getUploadUrl: function getUploadUrl() {
      return this.get('backend').get({
        url: "/resources/uploadUrl"
      });
    },
    getSignedUploadRequest: function getSignedUploadRequest() {
      var backend = this.get('backend');

      return backend.get({
        url: '/resources/uploadSignature'
      }).then(function (response) {
        console.log('response.data', response.data);
        return response.data;
      });
    }
  });
});