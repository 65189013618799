define('pretty-nuts/controllers/obligation/events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    government: Ember.inject.controller(),
    queryParams: ['selectedStartDate', 'selectedEndDate'],
    selectedStartDate: null,
    selectedEndDate: null,

    height: function height() {
      return $(window).height() - 200;
    },

    events: Ember.computed('model.events', function () {
      var events = this.get('model.events');
      return events;
    })
  });
});