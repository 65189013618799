define('pretty-nuts/routes/obligation/security', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/constants'], function (exports, _aquornAuthenticatedRouteMixin, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    obligationService: Ember.inject.service(),
    organizationService: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    securityId: '',
    obligationShortName: '',
    obligationId: '',

    model: function model(params) {

      var obligationService = this.get('obligationService');
      var organizationService = this.get('organizationService');
      var securityId = params.securityId;
      this.set('securityId', securityId);
      var model = this.modelFor('obligation');
      var obligation = this.modelFor('obligation');
      var currentEmployment = this.get('currentUser').getCurrentEmployment();
      this.set('parent', currentEmployment.get('organization'));

      return Ember.RSVP.hash({
        obligation: obligation,
        cashflow: obligationService.getSecurityCashflow(securityId),
        securityId: securityId,
        security: model.securitiesById[securityId]
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    }
  });
});