define('pretty-nuts/models/call-option', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    obligation: _emberData.default.belongsTo('obligation'),
    callDate: _emberData.default.attr(),
    price: _emberData.default.attr(),
    type: _emberData.default.attr(),
    availability: _emberData.default.attr()
  });
});