define('pretty-nuts/paginated-result', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend(Ember.Enumerable, {

    ///////////
    // Services
    store: null,
    // Services
    ///////////

    /////////////
    // Query data
    model: null,
    query: null,
    pagination: null,
    // Query data
    /////////////

    ///////////////
    // Results data
    length: 0,
    results: null,
    // Results data
    ///////////////

    /**
     * Ensure that the page number is not out-of-bounds for the pagination specifications
     *
     * Since page counts are 1 based (starts at 1, not 0) the current page number cannot go below
     * 1 and cannot be more than the last page number
     *
     * When the desired page number is below 1, the returned page number is the minimum (1).
     *
     * When the desired page number is greater than the last page, the returned page number is the last page
     *
     * @param pageNo The page number to clamp
     * @returns {*} The clamped page number, between 1 and pagination.totalPages
     * @private
     */
    _clampPageNumber: function _clampPageNumber(pageNo) {
      var pagination = this.get('pagination');
      var maxPageNo = pagination.totalPages;

      if (pageNo < 1) {
        pageNo = 1;
      } else if (pageNo > maxPageNo) {
        pageNo = maxPageNo;
      }

      return pageNo;
    },


    /**
     * Attempt to move a specified number of pages.
     *
     * For example:
     *
     * If modifier = 1, then the next page (clamped to pagination.totalPages) is returned.
     *
     * If modifier = -1, the previous page (clamped to 1) is returned
     *
     * If modidfer = 2, skip the next page to the one after (clamped to pagination.totalPages)
     *
     * @param modifier The number of pages to move
     * @returns {*} The new page number
     * @private
     */
    _getPageNo: function _getPageNo(modifier) {
      var pagination = this.get('pagination');
      var currentPage = pagination.currentPage;

      // If there is no current page, set to first page as current
      if (Ember.isNone(currentPage)) {
        currentPage = 1;
      }

      // Apply the modifier to the current page
      var nextPage = currentPage + modifier;

      // Return the next page number clamped to pagination bounds
      return this._clampPageNumber(nextPage);
    },


    /**
     * Internal helper for going to specific page to mimic _nextPageNo and _prevPageNo
     *
     * @param pageNo The page number to go to
     * @returns {*} The page number specified or the clamped page at the pagination bounds
     * @private
     */
    _gotoPageNo: function _gotoPageNo(pageNo) {
      return this._clampPageNumber(pageNo);
    },


    /**
     * Get the page number of the next page in the pagination context
     *
     * @returns {*} The next page number, or the current page number if current page is end
     * @private
     */
    _nextPageNo: function _nextPageNo() {
      return this._getPageNo(1);
    },


    /**
     * Get the page umber of the previous page in the pagination context
     *
     * @returns {*} The previous page number, or the current page number if already at beginning
     * @private
     */
    _prevPageNo: function _prevPageNo() {
      return this._getPageNo(-1);
    },


    /**
     * Build the query for the next page in the pagination context
     * @private
     */
    _nextPageQuery: function _nextPageQuery() {
      var query = this.get('query');
      query.pageNo = this._nextPageNo();
      return query;
    },


    /**
     * Build the query for the previous page in the pagination context
     * @private
     */
    _prevPageQuery: function _prevPageQuery() {
      var query = this.get('query');
      query.pageNo = this._prevPageNo(query);
      return query;
    },


    /**
     * Build the query for the specified page in the pagination context
     *
     * @param pageNo The page number in the pagination context to go to
     * @private
     */
    _gotoPageQuery: function _gotoPageQuery(pageNo) {
      var query = this.get('query');
      query.pageNo = this._gotoPageNo(pageNo);
      return query;
    },


    /**
     * Make the query to get the data from the next page in the pagination context
     *
     * @param query The query parameters
     * @private
     */
    _query: function _query(query) {
      var store = this.get('store');
      var model = this.get('model');
      var that = this;

      return store.query(model, query).then(function (results) {
        that.set('query', query);
        that._setResults(results);
        return that;
      });
    },


    /**
     * Update the wrapper pagination context from the query results
     *
     * @param results is the returned results from a paginated query
     * @private
     */
    _setResults: function _setResults(results) {
      var pagination = results.get('meta.pagination');
      var query = results.get('query');

      this.set('results', results); // Set the results
      this.set('length', length); // Update the length
      this.set('pagination', pagination); // Save the current pagination context
      this.set('query', query); // Save the query parameters used for this query result
    },


    /**
     * Initialize the wrapper with a query result
     * @param results
     */
    initData: function initData(results) {

      var store = results.get('store');
      var model = results.get('type.modelName');

      this.set('store', store); // Set the local reference to the store
      this.set('model', model); // Remember the model

      this._setResults(results);
    },


    ///////////////////////////////////////////
    // Methods for changing the current page //
    ///////////////////////////////////////////

    /**
     * Update the results to the next page in the context
     *
     * @returns {*} Promise for query for next page
     */
    nextPage: function nextPage() {
      var query = this._nextPageQuery();
      return this._query(query);
    },


    /**
     * Update the results to the previous page in the context
     *
     * @returns {*} Promise for query for previous page
     */
    prevPage: function prevPage() {
      var query = this._prevPageQuery();
      return this._query(query);
    },


    /**
     * Update the results to the specified page in the context
     *
     * @param index The 1 based page index to retrieve
     * @returns {*} Promise for the query to the specified page
     */
    gotoPage: function gotoPage(index) {
      var query = this._gotoPageQuery(index);
      return this._query(query);
    },


    /////////////////////////////////////////
    // Required methods for being iterable //
    /////////////////////////////////////////

    nextObject: function nextObject(index, prevObject, context) {
      console.log('nextObject');
      var results = this.get('results');

      return results.nextObject(index, prevObject, context);
    },
    forEach: function forEach(callback, target) {
      console.log('forEach');
      return this.get('results').forEach(callback, target);
    }
  });
});