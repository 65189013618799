define('pretty-nuts/controllers/calendar/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    government: Ember.inject.controller(),

    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {
        $('.menu.vertical a').removeClass('active');
        $('#calendarNav a').addClass('active');
      });
      this.set('entered', false);
    }.observes('entered'),

    height: function height() {
      return $(window).height() - 130;
    },

    events: Ember.computed('model.events', function () {
      var events = this.get('model.events');
      return events;
    })
  });
});