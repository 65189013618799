define('pretty-nuts/components/infinite-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      // we want to make sure 'this' inside `didScroll` refers
      // to the IndexView, so we use jquery's `proxy` method to bind it
      $(window).on('scroll', $.proxy(this.didScroll, this));
    },

    willDestroyElement: function willDestroyElement() {
      // have to use the same argument to `off` that we did to `on`
      $(window).off('scroll', $.proxy(this.didScroll, this));
    },

    // this is called every time we scroll
    didScroll: function didScroll() {
      if (this.isScrolledToBottom()) {
        console.log(this.get('actionMore'));
        var actionMore = this.get('actionMore');
        actionMore();
        this.rerender();
      }
    },

    // we check if we are at the bottom of the page
    isScrolledToBottom: function isScrolledToBottom() {
      var distanceToViewportTop = $(document).height() - $(window).height();
      var viewPortTop = $(document).scrollTop();

      if (viewPortTop === 0) {
        // if we are at the top of the page, don't do
        // the infinite scroll thing
        return false;
      }

      return Math.abs(viewPortTop - distanceToViewportTop) <= 20;
    }
  });
});