define('pretty-nuts/models/domain-refunded-debt-determination', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    totalRefundPrincipal: _emberData.default.attr(),
    obligationRefundDeterminations: _emberData.default.attr(),

    obligationRefundDeterminationsById: Ember.computed('obligationRefundDeterminations', function () {
      var determinations = [];

      var obligationRefundDeterminations = this.get('obligationRefundDeterminations');

      obligationRefundDeterminations.forEach(function (ord) {
        determinations[ord.obligationId] = ord;
      });

      return determinations;
    }),

    securityRefundDeterminationsById: Ember.computed('obligationRefundDeterminations', function () {
      var determinations = [];

      var obligationRefundDeterminations = this.get('obligationRefundDeterminations');

      obligationRefundDeterminations.forEach(function (ord) {
        ord.securityRefundDeterminations.forEach(function (srd) {
          determinations[srd.securityId] = srd;
        });
      });

      return determinations;
    })
  });
});