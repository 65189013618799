define("pretty-nuts/models/five_ranges", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    label: "Label 1",
    group: "Group One",
    value: 20
  }, {
    label: "Label 2",
    group: "Group One",
    value: 22
  }, {
    label: "Label 3",
    group: "Group One",
    value: 18
  }, {
    label: "Label 4",
    group: "Group One",
    value: 2
  }, {
    label: "Label 5",
    group: "Group One",
    value: 6
  }, {
    label: "Label 1",
    group: "Group Two",
    value: 26
  }, {
    label: "Label 2",
    group: "Group Two",
    value: 18
  }, {
    label: "Label 3",
    group: "Group Two",
    value: 150
  }, {
    label: "Label 4",
    group: "Group Two",
    value: 160
  }, {
    label: "Label 5",
    group: "Group Two",
    value: 200
  }, {
    label: "Label 1",
    group: "Group Three",
    value: 14
  }, {
    label: "Label 2",
    group: "Group Three",
    value: 31
  }, {
    label: "Label 3",
    group: "Group Three",
    value: 44
  }, {
    label: "Label 4",
    group: "Group Three",
    value: 30
  }, {
    label: "Label 5",
    group: "Group Three",
    value: 62
  }, {
    label: "Label 1",
    group: "Group Four",
    value: 75
  }, {
    label: "Label 2",
    group: "Group Four",
    value: 114
  }, {
    label: "Label 3",
    group: "Group Four",
    value: 19
  }, {
    label: "Label 4",
    group: "Group Four",
    value: 129
  }, {
    label: "Label 5",
    group: "Group Four",
    value: 52
  }, {
    label: "Label 1",
    group: "Group Five",
    value: 200
  }, {
    label: "Label 2",
    group: "Group Five",
    value: 14
  }, {
    label: "Label 3",
    group: "Group Five",
    value: 31
  }, {
    label: "Label 4",
    group: "Group Five",
    value: 44
  }, {
    label: "Label 5",
    group: "Group Five",
    value: 30
  }];
});