define('pretty-nuts/serializers/legal-unit', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    primaryKey: 'aquornId',

    attrs: {
      organization: 'organizationAquornId',
      logoResource: 'logoResourceId',
      address: 'addressAquornId'
    }
  });
});