define('pretty-nuts/controllers/settings', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Controller.extend({
		authapi: Ember.inject.service(),
		oldPassword: '',
		newPassword: '',
		confirmed: false,
		verified: true, // TODO this should be set to false but I want button testable
		unconfirmed: false,
		changePassword: false,
		dupeNewPassword: '',
		resetPasswordInstructions: '',

		currentUser: Ember.computed('model.user', function () {
			return 'foo'; // TODO acquire pasword verification - might be true false to the 'verifyPassword' function;
		}),
		myLogin: Ember.computed('model.emails', function () {
			var modelEmails = this.get('model.emails');
			var emails = [];
			if (!Ember.isNone(modelEmails)) {
				modelEmails.forEach(function (email) {
					emails.pushObject(email.get('address'));
				});
			}
			console.log('MYLOGIN', emails);
			return emails;
		}),
		init: function init() {
			this.set('resetPasswordInstructions', 'We need to send you an email for security purposes.  This email will have a link to where you can change your password.');
		},
		saveProfile: function saveProfile() {
			var user = this.get('model').user;
			//user.set('otherInfo', btoa(JSON.stringify(this.get('profileDetails'))));
			console.log('user.toJSON: ', user.toJSON());
			user.save();
		},

		actions: {
			sendForgotPasswordEmail: function sendForgotPasswordEmail(email) {
				//TODO need to say "MESSAGE SENT"
				//and change the button text to send another
				this.set('resetPasswordInstructions', 'You should be receiving an email with instructions shortly.');
				var authapi = this.get('authapi');
				authapi.forgotPasswordRequest(email);
			},
			cancelPWChange: function cancelPWChange() {
				this.get('model').user.rollbackAttributes();
				this.send('display', 'change_password');
			},
			display: function display(item) {
				$('#' + item + ' .display').show();
				$('#' + item + ' .edit').hide();
			},
			savePW: function savePW() {
				this.saveProfile();
				this.send('display', 'change_password');
			},
			edit: function edit(item) {
				console.log('EDIT', item);
				$('#' + item + ' .display').hide();
				$('#' + item + ' .edit').show();
				if (item === 'personal') {
					Backend.verifyDocumentAccess(Backend.userId(), 'Profile Image', function (data) {});
				}
			},
			confirmPassword: function confirmPassword(val) {
				var newPassword = this.get('newPassword');
				var dupeNewPassword = this.get('dupeNewPassword');
				if (newPassword == dupeNewPassword && newPassword != '' && dupeNewPassword != '') {
					this.set('confirmed', true);
					this.set('unconfirmed', false);
				} else {
					this.set('confirmed', false);
					this.set('unconfirmed', true);
				}
				this.send('checkData');
			},
			verifyPassword: function verifyPassword(val) {
				var currentPassword;
				var oldPassword = this.get('oldPassword');
				var currentUser = this.get('currentUser');
				if (oldPassword == currentPassword) {
					this.set('verified', true);
					this.send('checkData');
				}
			},
			checkData: function checkData() {
				if (this.get('confirmed') && this.get('verified')) {
					this.set('changePassword', true);
				} else {
					this.set('changePassword', false);
				}
			},
			sendChangePassword: function sendChangePassword() {
				var authapi = this.get('authapi');
				authapi.changePassword(this.get('oldPassword'), this.get('newPassword'));
			}
		}
	});
});