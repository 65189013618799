define('pretty-nuts/controllers/people', ['exports', 'pretty-nuts/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _Ember$Controller$ext;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Controller.extend((_Ember$Controller$ext = {
    application: Ember.inject.controller(),

    /*
      // setup our query params
      queryParams: ["page", "perPage"],
    
      // set default values, can cause problems if left out
      // if value matches default, it won't display in the URL
      page: 1,
      perPage: 10,
    
      // can be called anything, I've called it pagedContent
      // remember to iterate over pagedContent in your template
      pagedContent: pagedArray('content', {pageBinding: "page", perPageBinding: "perPage"}),
    
      // binding the property on the paged array
      // to a property on the controller
      totalPagesBinding: "pagedContent.totalPages",
    */

    sortAttribute: 'primaryEmployment.organizationName',
    query: null,
    activeColumn: 'name',
    desc: false,

    previewModel: {},
    pageSize: 1,
    infiniteModel: Ember.computed('model', function () {
      return this.get('model');
    }),

    runEveryTime: function () {
      console.log('running every time I enter the page');
      var count = 0;
      Ember.run.schedule('afterRender', this, function () {
        this.set('activeColumn', 0);
        $('.menu.vertical a').removeClass('active');
        $('#peopleNav a').addClass('active');
        $('.obligations-table').floatThead({ top: 66 });
        var controller = this;
        console.log('every time');
        console.log('MODEL', this.get('model'));
      });
      this.set('entered', false);
      // this.set('model.accumulatedUsers',null);
    }.observes('entered')

  }, _defineProperty(_Ember$Controller$ext, 'query', function () {
    console.log('observed a change in query', this.get('application.query'));
    this.send('actionReloadFilter', this.get('application.query'), this.get('activeColumn'), this.get('desc'));
  }.observes('application.query', 'activeColumn', 'desc')), _defineProperty(_Ember$Controller$ext, 'actions', {
    clickRow: function clickRow(id) {
      this.transitionToRoute('person', id);
    },
    actionPrevPage: function actionPrevPage() {
      this.get('model.paginatedUsers').prevPage();
    },
    actionNextPage: function actionNextPage() {
      var that = this;
      /*
      this.get('model.users').nextPage().then((content) => {
        that.set('model.users', []);
        that.set('model.users', content);
      });
      */
      /*
      this.get('model.users').nextPage().then(function () {
        that.notifyPropertyChange('model');
      });
      */
      this.get('model.paginatedUsers').nextPage();
    },
    actionGotoPage: function actionGotoPage(pageNo) {
      this.get('model.paginatedUsers').gotoPage(pageNo + 1);
    },
    actionMore: function actionMore() {
      this.get('model.accumulatedUsers').more();
    },


    // loadMoreModel(){
    //   var pageSize = this.get('pageSize')
    //   pageSize = pageSize + 100;
    //   this.set('pageSize', pageSize);
    //   this.set('model',this.store.query('user',{ filter:'', pageSize:pageSize}));
    //   console.log('pageSize',this.get('model'),pageSize)
    // },

    // fetchMore: function(callback) {
    //   var promise = this.fetchMoreItems();
    //   console.log('infinte scroll is being called')
    //   callback(promise);
    // },
    activeMenu: function activeMenu(id) {
      $('.active-icon-menu').removeClass('active-icon-menu');
      $('#' + id + ' i').addClass('active-icon-menu');
    },
    remove_person: function remove_person(id) {},
    logout: function logout() {
      this.get('onLogout')();
    },
    sortColumn: function sortColumn(prop) {
      this.set('sortAttribute', prop);
      this.set('desc', !this.get('desc'));
    },
    foo: function foo() {
      console.log(this);
    }
  }), _Ember$Controller$ext));
});