define('pretty-nuts/routes/government/events', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {

    eventService: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    organizationService: Ember.inject.service(),

    queryParams: {
      selectedStartDate: {
        refreshModel: true
      },
      selectedEndDate: {
        refreshModel: true
      }
    },

    setupController: function setupController(controller, model) {
      this.set('query', '');
      this._super(controller, model);
      controller.set('entered', true);
    },
    model: function model(params) {
      var orgModel = this.modelFor('government');
      var organization = orgModel.organization;
      var eventService = this.get('eventService');
      var organizationService = this.get('organizationService');

      // for obligation onboarding events list ---> eventService.getEventMasterDrafts(obligationId)
      return Ember.RSVP.hash({
        events: eventService.getEvents(params.selectedStartDate, params.selectedEndDate, organization.get('id'), '', '') }).then(function (model) {
        return model;
      });
    }
  });
});