define('pretty-nuts/models/domain-stats-cashflow-column', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    columnId: _emberData.default.attr(),
    values: _emberData.default.attr(),
    attributes: _emberData.default.attr(),
    valueType: _emberData.default.attr('enum', { type: _constants.Constants.ColumnValueType })
  });
});