define('pretty-nuts/routes/people', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'ember-infinity/mixins/route', 'pretty-nuts/paginated-result', 'pretty-nuts/accumulated-result'], function (exports, _aquornAuthenticatedRouteMixin, _route, _paginatedResult, _accumulatedResult) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, _route.default, {
    notifications: Ember.inject.service('notification-messages'),
    authapi: Ember.inject.service(),
    perPageParam: "pageSize", // instead of "per_page"
    pageParam: "pageNo", // instead of "page"
    totalPagesParam: "meta.total", // instead of "meta.total_pages"
    query: null,
    sortBy: '',

    model: function model() {
      console.log('QUERY', this.get('query'));
      // var data = this.infinityModel('user',{ filter:'', perPage:50, startingPage:1});
      //var data = this.infinityModel('user',{ filter:'', pageSize:100, pageNo:1});
      //data.reachedInfinity = false;
      // data.meta.total = 65;
      //console.log('MODEL for PEOPLE',data);

      return Ember.RSVP.hash({
        //users: this.store.query('user', {
        users: this.store.query('integrated-user-profile', {
          filter: '',
          pageSize: 25,
          pageNo: 1
        }),

        //usersInfinite: this.store.query('user', {
        usersInfinite: this.store.query('integrated-user-profile', {
          filter: this.get('query'),
          pageSize: 50,
          searchType: 'WILD_CARD',
          sortBy: this.get('sortBy'),
          isAsc: this.get('desc'),
          pageNo: 1
        })
      }).then(function (model) {
        var paginatedUsers = _paginatedResult.default.create();
        paginatedUsers.initData(model.users);

        var accumulatedUsers = _accumulatedResult.default.create();
        accumulatedUsers.initData(model.usersInfinite);
        return {
          paginatedUsers: paginatedUsers,
          accumulatedUsers: accumulatedUsers
        };
      });

      //return data;
    },
    setupController: function setupController(controller, model) {
      this.set('query', '');
      this._super(controller, model);
      controller.set('entered', true);
    },

    actions: {
      actionReloadFilter: function actionReloadFilter(query, sortBy, desc) {
        this.set('query', query);
        this.set('sortBy', sortBy);
        this.set('desc', desc);
        this.refresh();
      }
    }
  });
});