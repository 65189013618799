define('pretty-nuts/routes/myorganization', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    organizationService: Ember.inject.service(),

    model: function model() {
      var organizationService = this.get('organizationService');
      var organization = this.get('currentUser').getMyOrganization();

      if (Ember.isNone(organization)) {
        return {};
      }

      return organization.then(function (organization) {
        return Ember.RSVP.hash({
          organization: organization,
          addresses: organizationService.getOrganizationAddresses(organization.get('id')),
          employeeUserProfiles: organizationService.getEmployeeUserProfiles(organization.get('id'))
        }).then(function (model) {
          model.headquarters = model.addresses.find(function (address) {
            return address.get('hq');
          });
          model.addresses = model.addresses.toArray();
          return model;
        });
      });
    },


    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    }
  });
});