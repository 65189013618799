define('pretty-nuts/mixins/aquorn-authenticated-route-mixin', ['exports', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_authenticatedRouteMixin.default, {

    /**
     * The application current user service
     */
    currentUser: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),

    /**
     * Override for handling transition redirection based on user setup progress
     *
     * @param transition The current transition to this route
     */
    beforeModel: function beforeModel(transition) {
      var currentUser = this.get('currentUser');
      var session = this.get('session');

      session.set('redirectUrl', '');

      // If the user hasn't completed the user profile setup, redirect them there only
      if (session.isAuthenticated) {
        // User is logged in and session is active

        if (currentUser.isValid()) {
          // User service has successfully loaded

          if (!currentUser.isLicenseAccepted()) {
            // User has not accepted the license terms
            return this.transitionTo('license-agreement');
          }

          if (!currentUser.isSetupComplete()) {
            console.log('Transitioning to complete-signup');
            // User has not completed the intial profile setup, force them there
            return this.transitionTo('complete-signup');
          }

          // If the routing page is login, redirect to myprofile
          console.log(this.get('routing'));
          if (this.get('routing').get('currentRouteName') === 'login') {
            // return this.transitionTo('myprofile');
            location.reload();
          }
        }

        //
        // For additional conditional authenticated redirects, add here
        //
      }

      //
      // For additional conditional unauthenticated redirects, add here
      //
      session.set('redirectUrl', window.location.href);

      return this._super.apply(this, arguments);
    }
  });
});