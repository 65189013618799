define('pretty-nuts/models/organization-user-role-request', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    requestType: _emberData.default.attr(),

    user: _emberData.default.belongsTo('user'),

    organization: _emberData.default.belongsTo('organization'),

    requestDate: _emberData.default.attr(),
    approvalDate: _emberData.default.attr(),

    requestStatus: _emberData.default.attr(),

    role: _emberData.default.attr()

  });
});