define('pretty-nuts/components/onboarding-bread-crumb', ['exports', 'pretty-nuts/constants'], function (exports, _constants) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var alias = Ember.computed.alias,
	    observer = Ember.observer;
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),

		currentRouteName: alias('router.currentRouteName'),
		routerState: alias('router.currentState.routerJsState'),

		init: function init() {
			this._super.apply(this, arguments);
			this.buildBreadCrumbs();
		},


		fileStateObserver: function () {
			if (this.get('currentRouteName').includes('obligation.')) {
				this.buildBreadCrumbs();
			}
		}.observes('routerState'),

		buildBreadCrumbs: function buildBreadCrumbs() {
			var that = this;
			var obligationId = this.get('routerState.params')['onboarding']['obligationId'];

			this.set('obligationId', obligationId);
			this.set('obligation', this.get('store').findRecord('domain-obligation', obligationId));
		},

		actions: {
			onSelectionChange: function onSelectionChange(item) {
				this.get('router').transitionTo(item[1], { queryParams: item[2] });
			}
		}
	});
});