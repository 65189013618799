define('pretty-nuts/controllers/issuer/events', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['selectedStartDate', 'selectedEndDate'],
    selectedStartDate: null,
    selectedEndDate: null,

    height: function height() {
      return $(window).height() - $('#personal').height() - 155;
    }
  });
});