define('pretty-nuts/models/domain-security-refunding', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    refundedSecurity: _emberData.default.belongsTo('domain-security'),
    callDate: _emberData.default.attr(),
    callPrice: _emberData.default.attr(),

    totalRefundedAmount: _emberData.default.attr(),

    refundedPayments: _emberData.default.attr()
  });
});