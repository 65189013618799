define('pretty-nuts/components/edit-avatar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    resourceService: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    actions: {
      editAvatar: function editAvatar() {
        var resourceService = this.get('resourceService');
        // console.log('HERE')

        // resourceService.getSignedUploadRequest().then((policy) => {
        //   let filestack = this.get('filestack.instance');
        //   filestack.setSecurity({
        //     policy: policy.encodedPolicy,
        //     signature: policy.signature
        //   });
        //   this.set('showPicker', true);
        // });
      },
      pickerClosed: function pickerClosed() {
        this.set('showPicker', false);
      },
      pickerSelect: function pickerSelect(file) {
        var controller = this;
        var resourceService = this.get('resourceService');
        var user = this.get('user');

        // console.log('selected file', result);

        // if (result.filesUploaded.length > 0) {
        //   let file = result.filesUploaded[0];
        //   let that = this;
        //   return resourceService.uploadUserAvatar(file, user.get('id'), function () {
        //     that.get('user').reload();
        //   });

        // }

        resourceService.getUploadUrl().then(function (response) {
          // console.log('upload url', response)
          var key = response.data.key;
          // file.upload(response.data).then(response => {
          file.uploadBinary(response.data.url, {
            method: 'PUT'
          }).then(function (response) {
            Ember.set(file, 'key', key);
            return resourceService.uploadUserAvatar(file, user.get('id'), function () {
              user.reload();
            });
          });
        });

        this.set('showPicker', false);
      }
    }
  });
});