define("pretty-nuts/components/tabbed-obligation-list-tab", ["exports", "pretty-nuts/constants"], function (exports, _constants) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    modalService: Ember.inject.service(),
    obligationService: Ember.inject.service(),
    store: Ember.inject.service('store'),
    routing: Ember.inject.service("-routing"),

    Constants: _constants.Constants,

    sortAttribute: 'primaryObligation.finalSaleDate',
    sortValueType: 'finalSaleDate',
    desc: true,

    reloadObligationOverviewsObserver: function () {
      console.log('RELOAD OBSERVER', this);
      this.send('refresh');
      this.set('reloadObligationOverviews', false);
    }.observes('reloadObligationOverviews'),

    actions: {
      createObligation: function createObligation() {
        var newObligation = this.get('store').createRecord('obligation');
        this.get('application').set('editObligationAction', 'Create');
        this.get('application').showObligationCreation(newObligation, function (newObligation) {
          console.log('callback:', newObligation);
        });
      },
      activeLink: function activeLink(obligation) {
        if (obligation.get('active')) {
          console.log('ACTIVE');
          this.get('router').transitionTo('obligation.overview', obligation.id);
        } else {
          console.log('NOT ACTIVE');
          this.get('router').transitionTo('onboarding', obligation.id);
        }
      },
      refresh: function refresh() {
        var currentRouteName = this.get("routing.currentRouteName");
        var currentRouteInstance = Ember.getOwner(this).lookup("route:" + currentRouteName);
        console.log('CURRENT ROUTE', currentRouteInstance);
        currentRouteInstance.refresh();
      },
      clickRow: function clickRow(route, id) {
        this.get('router').transitionTo(route, id);
      },
      sortColumn: function sortColumn(tab, key, prop, sort_value_type) {
        //this.sortObligations(tab, key, prop, isNumber, false);
        // this.set('sortAttribute', `primaryObligation.${prop}`)
        this.set('sortAttribute', "obligation." + prop);
        this.set('desc', !this.get('desc'));
        this.set('sortValueType', sort_value_type);
      },
      editObligation: function editObligation(obligation) {
        this.get('application').set('editObligationAction', 'Edit');
        this.get('application').showObligationCreation(obligation, function (newObligation) {
          console.log('callback:', newObligation);
        });
      },
      deleteObligation: function deleteObligation(obligation) {
        var that = this;
        var onReload = this.get('onReload');
        var args = {
          prompt: 'This action CANNOT be undone. This will permanently delete ' + obligation.get('shortName') + '. Please type in the short name of the obligation to confirm.',
          confirmWord: 'I understand the consequences, delete this obligation',
          cancelWord: 'No',
          textToMatch: obligation.get('shortName'),
          callbacks: {
            success: function success() {
              that.get('obligationService').deleteObligation(obligation).then(function () {
                console.log('reload', onReload);
                if (onReload) {
                  return onReload();
                }
              });
            }
          }
        };
        this.get('modalService').showModal('dual_confirmation', args);
      },
      deleteDraft: function deleteDraft(obligation, shortName) {
        var that = this;
        var args = {
          prompt: 'This action CANNOT be undone. This will permanently delete your draft for ' + shortName + '. Please type in the short name of the obligation to confirm.',
          confirmWord: 'I understand the consequences, delete this obligation draft',
          cancelWord: 'No',
          textToMatch: shortName,
          callbacks: {
            success: function success() {
              that.get('obligationService').deleteObligation(obligation).perform(function () {
                that.set('reloadObligationOverviews', true);
              });
            }
          }
        };
        this.get('modalService').showModal('dual_confirmation', args);
      }
    }
  });
});