define('pretty-nuts/models/obligation-overview', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    finalObligation: _emberData.default.attr('embedded-model', { modelName: 'obligation' }),
    draftObligation: _emberData.default.attr('embedded-model', { modelName: 'obligation' }),

    primaryObligation: Ember.computed('finalObligation', 'draftObligation', function () {
      var finalObligation = this.get('finalObligation');

      if (finalObligation) {
        // console.log('final', finalObligation);
        return finalObligation;
      }

      // console.log('draftObligation', this.get('draftObligation'));
      return this.get('draftObligation');
    })
  });
});