define('pretty-nuts/routes/notifications', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin', 'pretty-nuts/db'], function (exports, _aquornAuthenticatedRouteMixin, _db) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    notificationService: Ember.inject.service(),
    model: function model() {
      var notificationService = this.get('notificationService');
      return Ember.RSVP.hash({
        notifications: notificationService.getNotifications(1, 100, false),
        seenNotifications: notificationService.getNotifications(1, 100, true)
      }).then(function (model) {
        return model;
      });
    },

    actions: {
      reloadModel: function reloadModel() {
        this.refresh();
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('entered', true);
    }
  });
});