define('pretty-nuts/helpers/json-stringify', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.jsonStringify = jsonStringify;
  function jsonStringify(params) {
    return JSON.stringify(params[0]);
  }

  exports.default = Ember.Helper.helper(jsonStringify);
});