define('pretty-nuts/models/user', ['exports', 'ember-data', 'pretty-nuts/mixins/aquorn-embed-model', 'pretty-nuts/constants'], function (exports, _emberData, _aquornEmbedModel, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend(_aquornEmbedModel.default, {
    userId: _emberData.default.attr('string'),
    aquornId: _emberData.default.attr(),
    firstName: _emberData.default.attr('string'),
    middleInitial: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    suffix: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean'),
    createdDate: _emberData.default.attr(),
    activatedDate: _emberData.default.attr(),
    lastLogin: _emberData.default.attr(),
    // profileImage: DS.belongsTo('resource'),
    profileImage: _emberData.default.attr(),
    profileImageResourceAquornId: _emberData.default.attr(),
    otherInfo: _emberData.default.attr('embedded-model', { modelName: 'user-other-info', generateId: true }),

    /**
     * Assemble the full name string for easy use in application (not part of model)
     */
    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + this.get('lastName');
    }),
    primaryEmail: Ember.computed('otherInfo.primaryEmail.address', function () {
      return this.get('otherInfo.primaryEmail.address');
    }),

    /**
     * Generated avatar URL path (not part of model)
     */
    profileImageUrl: null,

    /**
     * Observer for profile image to generate the avatar URL path
     */
    profileImageObserver: function () {
      if (this.get('profileImageResourceAquornId')) {
        this.set('profileImageUrl', _constants.Constants.RESOURCE_LOCATION + '/' + this.get('profileImageResourceAquornId'));
      }

      // var profileResource = this.get('profileImage');
      // var that = this;

      // if (!Ember.isNone(profileResource)) {
      //   profileResource.then(function (resource) {
      //     if (!Ember.isNone(resource)) {
      //       that.set('profileImageUrl', '/' + resource.get('location'));
      //     }
      //   });
      // }
    }.observes('profileImageResourceAquornId').on('init'),

    /**
     * Name, image url, and details for query select boxes
     */
    name: Ember.computed('fullName', function () {
      return this.get('fullName');
    }),

    imageUrl: Ember.computed('profileImageUrl', function () {
      return this.get('profileImageUrl');
    }),

    details: Ember.computed('primaryEmail', function () {
      return this.get('primaryEmail');
    })

  });
});