define('pretty-nuts/serializers/refunded-debt-determination', ['exports', 'pretty-nuts/serializers/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({

    primaryKey: 'obligationId',

    attrs: {
      //obligationRefundDeterminations: {embedded: 'always'}
    }

  });
});