define('pretty-nuts/controllers/obligation', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    store: Ember.inject.service('store'),
    // get documents and find the official statement and pass it to the template
    runEveryTime: function () {
      Ember.run.schedule('afterRender', this, function () {});
      this.set('entered', false);
    }.observes('entered'),
    actions: {
      editObligation: function editObligation(obligation) {
        this.get('application').set('editObligationAction', 'Edit');
        this.get('application').showObligationCreation(obligation, function (newObligation) {
          console.log('callback:', newObligation);
        });
      },
      refresh: function refresh() {
        var currentRouteName = this.get("routing.currentRouteName");
        var currentRouteInstance = getOwner(this).lookup('route:' + currentRouteName);
        console.log('CURRENT ROUTE', currentRouteInstance);
        currentRouteInstance.refresh();
      }
    }
  });
});