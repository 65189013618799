define('pretty-nuts/helpers/parse-json', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.parseJson = parseJson;
  function parseJson(params /*, hash*/) {
    if (params[0] !== undefined) {
      return JSON.parse(params[0]);
    } else {
      return {};
    }
  }

  exports.default = Ember.Helper.helper(parseJson);
});