define('pretty-nuts/components/legal-entity-reporting-bread-crumb', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var alias = Ember.computed.alias,
	    observer = Ember.observer;
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		organizationService: Ember.inject.service(),
		obligationService: Ember.inject.service(),

		currentRouteName: alias('router.currentRouteName'),
		routerState: alias('router.currentState.routerJsState'),

		readcrumbsConfiguration: null,
		selectedLegalEntityOption: null,

		legalEntityReportViews: [['Cashflow Report', 'reporting.legal-entity.cashflow'], ['Period Report', 'reporting.legal-entity.period'], ['30 Year Report', 'reporting.legal-entity.30-year']],

		init: function init() {
			this._super.apply(this, arguments);
			this.buildBreadCrumbs();
		},


		fileStateObserver: function () {
			if (this.get('currentRouteName').includes('reporting.legal-entity')) {
				this.set('selectedLegalEntityReportView', ['Cashflow Report', 'reporting.legal-entity.cashflow']);
				this.buildBreadCrumbs();
			}
		}.observes('routerState', 'breadcrumbsConfiguration'),

		buildBreadCrumbs: function buildBreadCrumbs() {
			var that = this;
			var organizationService = this.get('organizationService');
			var organizationId = this.get('routerState.params')['reporting']['organizationId'];
			var legalEntityId = this.get('routerState.params')['reporting.legal-entity']['legalEntityId'];

			this.set('organizationId', organizationId);
			this.set('legalEntityId', legalEntityId);
			organizationService.getLegalUnit(legalEntityId).then(function (legalEntity) {
				that.set('legalEntity', legalEntity);
				that.set('selectedLegalEntityOption', [legalEntity.get('name'), '/reporting/' + organizationId + '/legal-entity/' + legalEntityId + '/cashflow']);
			});

			this.buildLegalEntitiesList();
		},

		buildLegalEntitiesList: function buildLegalEntitiesList() {
			var _this = this;

			var that = this;
			var organizationService = this.get('organizationService');
			var organizationId = this.get('organizationId');
			var legalEntitiesOptions = organizationService.getOrganizationLegalUnits(organizationId).then(function (legalUnits) {
				return legalUnits.map(function (lu) {
					return [lu.get('name'), '/reporting/' + organizationId + '/legal-entity/' + lu.get('id') + '/cashflow'];
				});
			});

			this.set('selectedLegalEntityReportView', this.get('legalEntityReportViews').find(function (view) {
				return view[1] == _this.get('currentRouteName');
			}));
			this.set('legalEntitiesOptions', legalEntitiesOptions);
		},

		actions: {
			onSelectionChange: function onSelectionChange(item) {
				this.get('router').transitionTo(item[1], { queryParams: item[2] });
			}
		}
	});
});