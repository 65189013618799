define('pretty-nuts/routes/issuer/service-providers', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    model: function model() {
      var model = this.modelFor('issuer');

      return model;

      // return Ember.RSVP.hash({
      //   //serviceProviders: this.get('store').query('service-provider', {organizationId: model.organizationId}),
      //   serviceProviderSummaries: this.get('store').query('service-provider-summary', {issuerId: model.legalUnit.id})
      //
      // }).then((hash) => {
      //
      //   //model.serviceProviders = hash.serviceProviders;
      //   model.serviceProviderSummaries = hash.serviceProviderSummaries;
      //
      //   return model;
      // });
    }
  });
});