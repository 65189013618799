define('pretty-nuts/snapshot', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({

    snapshot: null,
    model: null,

    init: function init() {
      this._super.apply(this, arguments);

      var model = this.get('model');

      if (model) {
        if (model.serialize) {
          this.set('snapshot', JSON.parse(JSON.stringify(model.serialize())));
        } else {
          this.set('snapshot', model);
        }

        console.log('snapshotted', this.get('snapshot'));
      } else {
        console.debug('Empty snapshot model');
      }
    },
    createPatch: function createPatch(patch_data, changes, skip) {
      var changed_attributes = [];

      if (!skip) {
        skip = [];
      }

      if (changes) {
        changes.forEach(function (change) {
          // console.log('change', change, change.path);
          var path = change.path.join('.');
          // if (!skip.contains(path)) {

          var ignore = false;

          skip.forEach(function (s) {
            if (path.match(s)) {
              ignore = true;
            }
          });

          if (!ignore) {
            changed_attributes.push(path);
            DeepDiff.applyChange(patch_data, change);
          }
        });
      }

      return {
        changedAttributes: changed_attributes,
        data: patch_data
      };
    },
    diff: function diff(model, skip) {
      if (!model) {
        console.debug("Using local model cache for diff");
        model = this.get('model');
      }

      if (!skip) {
        skip = [];
      }

      // const modelName = model._internalModel.modelName;

      var snapshot = this.get('snapshot');
      var serialized_model = JSON.parse(JSON.stringify(model.serialize()));

      // console.log('comparing', snapshot, serialized_model);

      var changes = DeepDiff.diff(snapshot, serialized_model);

      var patch_data = {};

      return this.createPatch(patch_data, changes, skip);
    },
    applyPatch: function applyPatch(model, dest, source, modelName) {
      console.log('applyPatch', model, dest, source, modelName);
      // console.log('diff', dest, source);
      var store = this.get('store');
      var changes = DeepDiff.diff(dest, source);

      console.log('changes', changes);

      if (changes) {
        changes.forEach(function (change) {
          // console.log('change', dest, change);
          DeepDiff.applyChange(dest, change);
        });

        // console.log('currentJson', dest);

        var normalizedModel = store.serializerFor(modelName).normalize(store.modelFor(modelName), dest).data.attributes;
        normalizedModel['id'] = dest['id'];
        // const normalizedModel = store.createRecord(modelName, currentJson);

        // console.log('normalizedModel', normalizedModel);

        changes.forEach(function (change) {
          var path = change.path.join('.');
          var value = Ember.get(normalizedModel, path);

          // console.log('got', path, value, normalizedModel, diff_model);

          if (value === undefined) {
            console.log('deleting', path);
            Ember.set(model, path, value);
          } else {
            // console.log('setting', path, value);
            Ember.set(model, path, value);
          }
        });
      }
    }
  });
});