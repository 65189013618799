define('pretty-nuts/routes/event', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    eventService: Ember.inject.service(),
    organizationService: Ember.inject.service(),

    model: function model() {
      var eventService = this.get('eventService');
      var organizationService = this.get('organizationService');

      return Ember.RSVP.hash({
        eventMaster: eventService.getEventMasterDrafts('501000000000000593')
      }).then(function (model) {
        //console.log(model);
      });
    }
  });
});