define('pretty-nuts/controllers/legal-entities', ['exports', 'pretty-nuts/utils'], function (exports, _utils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    application: Ember.inject.controller(),
    notifications: Ember.inject.service('notification-messages'),
    backendapi: Ember.inject.service(),
    desc: true,
    activeColumn: '',
    previewModel: {},

    legalUnits: Ember.computed('model', 'application.organizations', function () {
      return this.get('model.legalUnits');
    }),

    organizations: Ember.computed('model', 'currentOrganizations', function () {
      var _this = this;

      var organizations = [];

      this.get('model.organizations').forEach(function (organization) {
        var org = {
          org: organization,
          canjoin: !_this.isACurrentOrganization(organization)
        };

        organizations.push(org);
      });

      return organizations;
      //return this.get('model.organizations');
    }),

    currentOrganizations: Ember.computed('model', 'application.organizations', function () {
      console.log("GETTING CURRENT ORGANIZATIONS");
      var currentOrganizations = this.get('application.organizations');
      var lookup = {};

      console.log("1 currentOrganizations: " + JSON.stringify(currentOrganizations));

      if (currentOrganizations) {
        currentOrganizations.forEach(function (organization) {
          lookup[organization.id] = organization;
        });
      }

      return lookup;
    }),

    isACurrentOrganization: function isACurrentOrganization(org) {
      var currentOrganizations = this.get('currentOrganizations');
      console.log("currentOrganizations:" + JSON.stringify(currentOrganizations));
      return !Ember.isNone(currentOrganizations[org.id]);
    },


    actions: {
      sortColumn: function sortColumn(prop) {
        this.set('desc', !this.get('desc'));
        this.set('activeColumn', prop);
        var model = this.get('model').sort(_utils.ArrayComparer.property(prop, this.get('desc')));
        this.set('model', model.slice());
      },
      foo: function foo() {
        console.log(this);
      },
      joinOrg: function joinOrg(orgId) {
        var _this2 = this;

        var orgsapi = this.get('backendapi.orgsapi');
        console.log("Joining org: " + orgId);
        orgsapi.userJoinRequest(orgId, function (err, data) {
          if (!err) {
            _this2.get('notifications').success("Request sent :: request id(" + data.requestId + ")", { autoClear: true });
          } else {
            _this2.get('notifications').error(data);
          }
        });
      },
      joinOrgNow: function joinOrgNow(orgId) {
        var _this3 = this;

        var orgsapi = this.get('backendapi.orgsapi');
        console.log("Joining org as admin now: " + orgId);
        orgsapi.userJoinNow(orgId, function (err, data) {
          if (!err) {
            _this3.get('notifications').success("Got access :: access id(" + data.accessId + ")", { autoClear: true });
          } else {
            _this3.get('notifications').error(data);
          }
        });
      }
    }
  });
});