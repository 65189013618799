define('pretty-nuts/models/employment', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    userId: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization'),
    organizationName: _emberData.default.attr(),
    primary: _emberData.default.attr('boolean'),
    startDate: _emberData.default.attr('string', { defaultValue: '2017-01-01' }),
    endDate: _emberData.default.attr(),
    position: _emberData.default.attr('string', { defaultValue: 'Employee' }),
    description: _emberData.default.attr(),
    email: _emberData.default.attr(),
    emailVerified: _emberData.default.attr('boolean'),
    employmentVerified: _emberData.default.attr('boolean'),
    otherInfo: _emberData.default.attr('json-blob', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    pendingRequest: null,

    //requestRole: DS.attr()

    current: Ember.computed('endDate', function () {
      return Ember.isNone(this.get('endDate'));
    })
  });
});