define('pretty-nuts/routes/orgsettings', ['exports', 'pretty-nuts/mixins/aquorn-authenticated-route-mixin'], function (exports, _aquornAuthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_aquornAuthenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    organizationService: Ember.inject.service(),
    obligationService: Ember.inject.service(),

    queryParams: {
      currentOrganizationId: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var organizationService = this.get('organizationService');
      var obligationService = this.get('obligationService');

      var organizationId = params.currentOrganizationId ? params.currentOrganizationId : this.get('currentUser').getMyOrganization().get('id');

      return Ember.RSVP.hash({
        organization: organizationService.getOrganization(organizationId),
        roleRequests: organizationService.getRoleRequests(organizationId),
        roles: organizationService.getOrganizationUserRoles(organizationId),
        legalUnits: organizationService.getOrganizationLegalUnits(organizationId),
        debtProfile: obligationService.getOrganizationDebtProfile(organizationId)
      }).then(function (model) {
        model.rolesAndRequestsByUser = organizationService.splitRolesAndRequestsByUser(model.roles, model.roleRequests);
        model.roleRequestsByUser = organizationService.splitRoleRequestsByUser(model.roleRequests);

        var obligations = {
          Pending: [],
          Outstanding: [],
          Retired: []
        };

        var legalEntityDebtProfiles = Ember.get(model, 'debtProfile.legalEntityDebtProfiles');

        Object.keys(legalEntityDebtProfiles).forEach(function (legalEntityId) {
          var profile = legalEntityDebtProfiles[legalEntityId];

          if (profile.get('obligations.Pending')) {
            obligations.Pending = obligations.Pending.concat(profile.get('obligations.Pending'));
          }

          if (profile.get('obligations.Outstanding')) {
            obligations.Outstanding = obligations.Outstanding.concat(profile.get('obligations.Outstanding'));
          }

          if (profile.get('obligations.Retired')) {
            obligations.Retired = obligations.Retired.concat(profile.get('obligations.Retired'));
          }
        });

        model.obligationOverviews = obligations;

        return model;
      });
    },


    actions: {
      reloadModel: function reloadModel() {
        return this.refresh();
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (!controller.queryParams["currentOrganizationId"]) {
        controller.set("currentOrganizationId", model.organization.get('id'));
      }
    }
  });
});