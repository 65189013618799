define('pretty-nuts/services/document-view-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    /**
    * Use this to get documents without any groupBy
    *
    * fields searchText, sortBy, isAsc, pageNo and pageSize
    * apply to all document fetch request.
    *
    * objectId - object can be organizationId, legalEntityId, or
    * obligationId based on the lowest level of relation with
    * document
    *
    * searchText - You can search by any STRING field in
    * models/document-view.js
    *
    * sortBy - You can sort by any field in models/document-view.js
    */
    getDocuments: function getDocuments(organizationId, searchText, objectId, sortBy, isAsc, pageNo, pageSize) {
      return this.get('store').query('document-view', {
        'organizationId': organizationId,
        'objectId': objectId,
        'sortBy': sortBy,
        'isAsc': isAsc === undefined ? false : isAsc,
        'pageNo': pageNo === undefined ? 1 : pageNo,
        'pageSize': pageSize == undefined ? 100 : pageSize
      });
    },


    /**
    * When you have a groupBy, this method returns the first level
    * of grouped values.
    *
    * fields searchText, sortBy, isAsc, pageNo and pageSize
    * apply to all document fetch request.
    *
    * groupBy - Group by any field in models/document-view.js.
    * Only returns the distinct values of that field on which you group by
    * Sorted by griupBy field ascending, can be changed to descending.
    *
    * For example: groupBy := documentType. In the result the only
    * documentType field will have a value. All other fields are useless.
    * Result: PDF, TEXT etc.
    * You can groupBy any field in models/document-view.js.
    *
    * objectId - object can be organizationId, legalEntityId, or
    * obligationId based on the lowest level of relation with
    * document
    *
    * searchText - You can search by any STRING field in
    * models/document-view.js
    */
    getFirstLevelByGroupBy: function getFirstLevelByGroupBy(groupBy, organizationId, objectId, searchText, sortBy, isAsc, pageNo, pageSize) {
      return this.get('store').query('document-view', {
        'groupBy': groupBy,
        'organizationId': organizationId,
        // 'objectId': objectId,
        'sortBy': sortBy,
        'isAsc': isAsc === undefined ? false : isAsc,
        'pageNo': pageNo === undefined ? 1 : pageNo,
        'pageSize': pageSize == undefined ? 100 : pageSize
      });
    },


    /**
    * To get the results from a groupBy field, use this method.
    * use groupBy and groupValue.
    *
    * fields searchText, sortBy, isAsc, pageNo and pageSize
    * apply to all document fetch request.
    *
    * groupBy - Group by any field in models/document-view.js.
    * Only returns the distinct values of that field on which you group by
    *
    * objectId - object can be organizationId, legalEntityId, or
    * obligationId based on the lowest level of relation with
    * document
    *
    * searchText - You can search by any STRING field in
    * models/document-view.js
    *
    * sortBy - You can sort by any field in models/document-view.js
    */
    getSecondLevelByGroupBy: function getSecondLevelByGroupBy(groupBy, groupValue, organizationId, objectId, searchText, sortBy, isAsc, pageNo, pageSize) {
      return this.get('store').query('document-view', {
        'groupBy': groupBy,
        'groupValue': groupValue,
        'organizationId': organizationId,
        // 'objectId': objecId,
        'sortBy': sortBy,
        'isAsc': isAsc === undefined ? false : isAsc,
        'pageNo': pageNo === undefined ? 1 : pageNo,
        'pageSize': pageSize == undefined ? 100 : pageSize
      });
    }
  });
});