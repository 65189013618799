define('pretty-nuts/models/legal-unit', ['exports', 'ember-data', 'pretty-nuts/constants'], function (exports, _emberData, _constants) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    aquornId: _emberData.default.attr(),
    organization: _emberData.default.belongsTo('organization', { inverse: 'legalUnits' }),
    name: _emberData.default.attr(),
    // logoResource: DS.belongsTo('resource'),
    logoResourceAquornId: _emberData.default.attr(),
    description: _emberData.default.attr(),
    contact: _emberData.default.belongsTo('user'),
    phoneNumber: _emberData.default.attr(),
    email: _emberData.default.attr(),
    operatingStatus: _emberData.default.attr(),
    category: _emberData.default.attr(),
    subCategory: _emberData.default.attr(),
    address: _emberData.default.belongsTo('address', { inverse: null }),
    otherInfo: _emberData.default.attr('json-blob', {
      defaultValue: function defaultValue() {
        return {};
      }
    }),

    logoImageUrl: null,

    logoImageObserver: function () {
      if (this.get('logoResourceAquornId')) {
        this.set('logoImageUrl', _constants.Constants.RESOURCE_LOCATION + '/' + this.get('logoResourceAquornId'));
      }
    }.observes('logoResourceAquornId').on('init'),

    /**
     * Name, image url, and details for query select boxes
     */
    imageUrl: Ember.computed('logoImageUrl', function () {
      return this.get('logoImageUrl');
    }),

    details: Ember.computed('', function () {
      return null;
    })

  });
});