define('pretty-nuts/components/obligation-reporting-bread-crumb', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var alias = Ember.computed.alias,
	    observer = Ember.observer;
	exports.default = Ember.Component.extend({
		store: Ember.inject.service(),
		organizationService: Ember.inject.service(),
		obligationService: Ember.inject.service(),

		currentRouteName: alias('router.currentRouteName'),
		routerState: alias('router.currentState.routerJsState'),

		selectedLegalEntityOption: null,
		selectedObligationOption: null,

		obligationReportViews: [['Overview Report', 'reporting.obligation.overview'], ['Debt Service Report', 'reporting.obligation.debt-service']],

		init: function init() {
			this._super.apply(this, arguments);
			this.buildBreadCrumbs();
		},


		fileStateObserver: function () {
			if (this.get('currentRouteName').includes('reporting.obligation')) {
				this.buildBreadCrumbs();
			}
		}.observes('routerState'),

		buildBreadCrumbs: function buildBreadCrumbs() {
			var _this = this;

			var that = this;
			var organizationService = this.get('organizationService');
			var obligationService = this.get('obligationService');
			var organizationId = this.get('routerState.params')['reporting']['organizationId'];
			var obligationId = this.get('routerState.params')['reporting.obligation']['obligationId'];

			this.set('organizationId', organizationId);
			this.set('obligationId', obligationId);
			this.set('organization', organizationService.getOrganization(organizationId));
			this.set('obligation', this.get('store').findRecord('domain-obligation', obligationId)).then(function (obligation) {
				that.buildLegalEntitiesList(obligation);
				that.buildObligationsList(obligation);
				that.set('selectedObligationOption', [obligation.get('shortName'), '/reporting/' + organizationId + '/obligation/' + obligationId + '/overview']);
				_this.set('selectedObligationReportView', _this.get('obligationReportViews').find(function (view) {
					return view[1] == _this.get('currentRouteName');
				}));
			});
		},

		buildLegalEntitiesList: function buildLegalEntitiesList(obligation) {
			var _this2 = this;

			var that = this;
			var legalEntityId = obligation.get('legalEntity.id');
			var organizationService = this.get('organizationService');
			var organizationId = this.get('organizationId');
			organizationService.getOrganizationLegalUnits(organizationId).then(function (legalUnits) {
				that.set('legalEntities', legalUnits);

				var legalEntitiesOptions = legalUnits.map(function (lu) {
					return [lu.get('name'), '/reporting/' + organizationId + '/legal-entity/' + lu.get('id') + '/cashflow'];
				}).sort(function (luOption) {
					luOption[1].includes(legalEntityId);
				});
				_this2.set('legalEntitiesOptions', legalEntitiesOptions);
			});
		},

		buildObligationsList: function buildObligationsList(obligation) {
			var that = this;
			var obligationService = this.get('obligationService');
			var organizationId = this.get('routerState.params')['reporting']['organizationId'];
			var legalEntityId = obligation.get('legalEntity.id');

			var obligationsOptions = obligationService.getObligorDebtProfile(legalEntityId).then(function (debtProfile) {
				return debtProfile.get('obligations')['Outstanding'].map(function (o) {
					return [o.get('shortName'), '/reporting/' + organizationId + '/obligation/' + o.get('id') + '/overview'];
				});
			});
			this.set('selectedObligationOption', [obligation.get('shortName'), '/reporting/' + organizationId + '/obligation/' + obligation.get('id') + '/overview']);
			this.set('obligationsOptions', obligationsOptions);
		},

		actions: {
			onSelectionChange: function onSelectionChange(item) {
				this.get('router').transitionTo(item[1], { queryParams: item[2] });
			}
		}
	});
});