define('pretty-nuts/helpers/to-json', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toJSON = toJSON;
  function toJSON(params /*, hash*/) {
    if (!Ember.isNone(params[0])) {
      if (typeof params[0].toJSON === 'function') {
        console.log('to json-ing');
        return JSON.stringify(params[0].toJSON());
      } else {
        console.log('standard json');
        return JSON.stringify(params[0]);
      }
    }

    console.log('completely null');

    return null;
  }

  exports.default = Ember.Helper.helper(toJSON);
});